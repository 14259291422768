import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import AddressFields from '../../address/common/Fields'
import FormCard from '../../forms/FormCard'
import displayName from './displayName'

const Update = props => {
  const {
    item = {},
    onUpdateDelivery,
    history,
    match,
    user,
  } = props
  const { _id, deliveryAddress = {} } = item
  return (
    <FormCard
      form={`order_${item._id}_update_delivery`}
      initialValues={{ deliveryAddress }}
      successMessage="Order Delivery Requested!"
      onSubmit={values => onUpdateDelivery({ _id, ...values }).then(history.goBack)}
      title={`Update Delivery for ${user.firstName} ${user.lastName}`}
    >
      <AddressFields name="deliveryAddress" />
    </FormCard>
  )
}

Update.propTypes = {
  item: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onUpdateDelivery: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

Update.displayName = `${displayName}Update`

export default Update
