import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'redux-form'
import { connect } from 'react-redux'

import FieldText from '../../fields/FieldText'
import Form from '../../forms/FormBase'
import FormButtonSubmit from '../../forms/FormButtonSubmit'
import Loading from '../../common/components/Loading'
import {
  validateCompose,
  validateEmail,
  validateRequired,
} from '../../common/utils/validators'
import displayName from './displayName'

const styles = theme => ({
  Form: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
  },
  fields: {
    padding: theme.spacing(1),
  },
  row: {
    flex: '1 1 100%',
    display: 'flex',
    flexFlow: 'row wrap',
  },
})


class Contact extends Component {
  handleSubmit = async (values) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_NAME}/users/contact`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      })
      const json = await res.json()
      if (res.ok) return json
      throw Error(json)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  render() {
    const {
      classes,
      elevation,
      isFetching,
      style,
      phoneFormat,
      dispatch,
      user,
      ...rest
    } = this.props
    if (isFetching) return <Loading />
    return (
      <div
        {...rest}
        className={classNames(
          classes.root,
          classes.Contact,
          style.background && style.background.backgroundImage && classes.backgroundImage,
          elevation && classes[`elevation${elevation}`],
        )}
        style={style}
      >
        <Form
          form="contact"
          initialValues={user}
          onSubmit={this.handleSubmit}
          className={classes.Form}
        >
          <div className={classes.fields}>
            <div className={classes.row}>
              <Field
                name="firstName"
                label="First name"
                component={FieldText}
                validate={validateRequired}
              />
              <Field
                name="lastName"
                label="Last name"
                component={FieldText}
                validate={validateRequired}
              />
            </div>
            <Field
              name="email"
              label="Email"
              component={FieldText}
              validate={validateCompose(validateEmail, validateRequired)}
            />
            <Field
              name="subject"
              label="Subject"
              component={FieldText}
              validate={validateRequired}
            />
            <Field
              name="message"
              label="Message"
              component={FieldText}
              validate={validateRequired}
              multiline
            />
          </div>
          <CardActions>
            <FormButtonSubmit variant="contained" fullWidth>Contact</FormButtonSubmit>
          </CardActions>
        </Form>
      </div>
    )
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  handler: PropTypes.object,
  id: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  phone: PropTypes.string,
  phoneFormat: PropTypes.string,
  user: PropTypes.object,
}

Contact.displayName = `${displayName}Contact`

export default connect(
  ({
    app: {
      isFetching: appIsFetching,
      address: { phone },
      brand: { phoneFormat }
    },
    user,
  }) => ({
    isFetching: !!(appIsFetching || user.isFetching),
    user,
    phone,
    phoneFormat,
  }),
)(withStyles(styles)(Contact))
