import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import MuiStepper from '@material-ui/core/Stepper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import Card from '../common/components/Card'
import Delivery from './Delivery'
import Payment from './Payment'
import Review from './Review'
import displayName from './displayName'
import withGoogleAnalytics from '../common/hocs/withGoogleAnalytics'

const styles = (theme) => ({
  root: {
    margin: 'auto',
    maxWidth: theme.breakpoints.values.sm,
  },
  stepper: {
    padding: `${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
  },
})

const steps = ['Delivery Details', 'Payment Details', 'Review']


const getStepContent = ({ activeStep, ...rest }) => {
  switch (activeStep) {
    case 0: {
      return <Delivery {...rest} />
    }
    case 1: {
      return <Payment {...rest} />
    }
    case 2:
      return <Review {...rest} />
    default:
      throw new Error('Unknown step')
  }
}

function Stepper(props) {
  const [activeStep, setActiveStep] = useState(0)
  const onBack = () => setActiveStep(activeStep > 0 ? activeStep - 1 : activeStep)
  const onNext = () => Promise.resolve().then(() => setActiveStep(activeStep < steps.length - 1 ? activeStep + 1 : activeStep))
  const { classes, ...rest } = props
  return (
    <main className={classes.root}>
      <Card>
        <CardContent className={classes.header}>
          <Typography component="h1" variant="h4" align="center">
            Checkout
          </Typography>
          <MuiStepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </MuiStepper>
        </CardContent>
        {getStepContent({ activeStep, onBack, onNext, ...rest })}
      </Card>
    </main>
  )
}

Stepper.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  addresses: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
}

Stepper.displayName = `${displayName}Stepper`

export default withGoogleAnalytics(withStyles(styles)(Stepper))
