import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Buttons from './Buttons'
import Loading from '../../common/components/Loading'
import None from '../../common/components/None'
import displayName from './displayName'
import itemReducer from '../../common/reducers/itemReducer'
import useReducer from '../../common/hooks/useReducer'
import * as actions from './actions'
import bindActionCreators from '../../common/utils/bindActionCreators'
import DetailBase from '../common/DetailBase'


const Detail = props => {
  const [state, dispatch] = useReducer(itemReducer, {
    error: null,
    isFetching: false,
    isLoading: true,
    item: {},
  })
  const boundActions = bindActionCreators(actions, dispatch)
  const {
    item,
    isFetching,
    isLoading,
  } = state

  useEffect(() => {
    boundActions.onFindOne({ _id: props.match.params._id })
  }, [])

  if (isLoading || isFetching) return <Loading />
  if (!item) return <None>Sorry, that order was not found</None>

  return (
    <DetailBase
      {...props}
      {...state}
      boundActions={boundActions}
      buttons={Buttons}
    />
  )
}


Detail.propTypes = {
  adminUser: PropTypes.object,
}

const mapStateToProps = ({
  app: { isFetching, shop },
}) => ({
  isFetching,
  shop,
})

Detail.displayName = `${displayName}Detail`

export default connect(mapStateToProps)(Detail)
