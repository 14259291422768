import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'

const Adornment = () => (
  <InputAdornment position="end">
    <CircularProgress size={16} />
  </InputAdornment>
)

const styles = theme => ({
  root: {
    flex: '1 1 auto',
    padding: `${theme.spacing(1) / 2}px ${theme.spacing(1)}px`,
  },
  select: {
    minWidth: 150,
  },
  fullWidth: {
    width: '100%',
  },
  h1: theme.typography.h1,
  h2: theme.typography.h2,
  h3: theme.typography.h3,
  h4: theme.typography.h4,
  h5: theme.typography.h5,
  h6: theme.typography.h6,
  subtitle1: theme.typography.subtitle1,
  subtitle2: theme.typography.subtitle2,
  body1: theme.typography.body1,
  body2: theme.typography.body2,
  caption: theme.typography.caption,
  overline: theme.typography.overline,
})

const FieldText = ({
  InputLabelProps,
  InputProps,
  children,
  className,
  classes,
  fullWidth,
  helperText,
  input,
  meta: {
    active,
    asyncValidating,
    error,
    invalid,
    submitError,
    touched,
  },
  select,
  typography,
  ...rest
}) => {
  const rootClassName = classNames(classes.root, select && classes.select, fullWidth && classes.fullWidth, className)
  return (
    <div className={rootClassName}>
      <TextField
        {...rest}
        {...input}
        fullWidth
        select={select}
        error={touched && invalid}
        helperText={helperText || ((touched && invalid) ? (error || submitError) : ' ')}
        margin="none"
        InputLabelProps={{
          ...InputLabelProps,
          className: typography && (!active && !input.value) ? classes[typography] : null,
        }}
        InputProps={{
          ...InputProps,
          className: typography ? classes[typography] : null,
          endAdornment: asyncValidating && <Adornment />,
        }}
      >
        {children}
      </TextField>
    </div>
  )

}

FieldText.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  error: PropTypes.string,
  handleClick: PropTypes.func,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}


export default withStyles(styles)(FieldText)
