import { useState, useEffect } from 'react'

import getImageSrc from '../../image/common/getImageSrc'

const loadImage = async src => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = getImageSrc(src)
    if (image.complete) return resolve(false)
    image.onerror = () => reject(false)
    image.onload = () => resolve(false)
  })
}

const useImage = imageSrc => {
  const [isImageLoading, setImageLoading] = useState(imageSrc ? true : false)
  useEffect(() => {
    if (imageSrc) {
      loadImage(imageSrc)
        .then(res => setImageLoading(res))
        .catch(err => setImageLoading(err))
    }
    setImageLoading(false)
  }, [])
  return isImageLoading
}

export default useImage