import React from 'react'
import { CSSTransition } from 'react-transition-group'

import './transitions.css'

const CrossFade = ({ children, style, ...props }) => (
  <CSSTransition
    {...props}
    timeout={1000}
    classNames="cross-fade"
  >
    <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      ...style
    }}>
      {children}
    </div>
  </CSSTransition>
)

export default CrossFade
