import { useReducer as useReactReducer, useEffect, useState } from 'react'

const augmentedDispatch = dispatch => input => {
  if (input instanceof Function) {
    return input(dispatch)
  }
  return dispatch(input)
}

const useReducer = (reducer, initialState) => {
  const [state, reactDispatch] = useReactReducer(reducer, initialState || { isLoading: true })
  const dispatch = augmentedDispatch(reactDispatch)
  useEffect(() => {
    reactDispatch({ type: "@init" })
  }, [])
  return [
    state,
    dispatch
  ]
}

export default useReducer