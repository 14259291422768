import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import displayName from './displayName'
import useDnDSortList from '../../common/hooks/useDnDSortList'
import ListItem from './ListItem'
import isArrayEqual from '../../common/utils/isArrayEqual'

const List = (props) => {
  const {
    childComponents,
    classes,
    component,
    components,
    isEditing,
    itemType,
    onComponentUpdateComponents,
    onEdit,
    onPageUpdateComponents,
    page,
  } = props
  const { items, onMove } = useDnDSortList({ items: childComponents })

  const onDrop = useCallback((e) => {
    e.stopPropagation()
    if (isArrayEqual(childComponents, items)) return undefined
    if (component) {
      return onComponentUpdateComponents({
        _id: component,
        components: items,
        page,
      })
    }
    return onPageUpdateComponents({
      _id: page,
      components: items,
    })
  }, [childComponents, component, items, onComponentUpdateComponents, onPageUpdateComponents, page])

  if (
    !childComponents
    || !childComponents.length
    || !(items && items.length)
  ) return null

  return items.map((id, index) => (
    <ListItem
      classes={classes}
      component={components[id]}
      components={components}
      id={id}
      index={index}
      isEditing={isEditing}
      itemType={itemType}
      key={id}
      onComponentUpdateComponents={onComponentUpdateComponents}
      onDrop={onDrop}
      onEdit={onEdit}
      onMove={onMove}
      onPageUpdateComponents={onPageUpdateComponents}
      page={page}
    />
  ))
}

List.propTypes = {
  childComponents: PropTypes.array,
  classes: PropTypes.object.isRequired,
  component: PropTypes.string,
  components: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  itemType: PropTypes.string.isRequired,
  onComponentUpdateComponents: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onPageUpdateComponents: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
}

List.displayName = `${displayName}List`

export default List
