import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import IconAdd from '@material-ui/icons/Add'
import IconClear from '@material-ui/icons/Clear'
import IconRemove from '@material-ui/icons/Remove'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import ButtonAsync from '../common/components/ButtonAsync'
import Card from '../common/components/Card'
import displayName from './displayName'
import getImageSrc from '../image/common/getImageSrc'
import { formatDollar } from '../common/utils/formatters'

const styles = theme => ({
  li: {
    flex: '1 1 100%',
  },
  card: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  imageContainer: {
    flex: '1 1 200px'
  },
  image: {
    maxWidth: '100%',
    minWidth: '100%',
    verticalAlign: 'top',
    width: '100%',
  },
  textContainer: {
    flex: '12 12 400px',
    display: 'flex',
    flexFlow: 'row wrap',
  },
  namePriceContainer: {
    flex: '3 3 300px',
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  name: {
    flex: '1 1 auto',
  },
  price: {
    flex: '1 1 auto',
    textAlign: 'right',
  },
  totals: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'start',
  },
  buttonContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flex: '1 1 auto',
    alignItems: 'center'
  },
  quantity: {
    flex: '1 1 50px',
    width: 40,
    textAlign: 'center',
    borderBottom: '1px solid rgb(224, 224, 224)',
    minWidth: 50,
    marginRight: 0,
  },
  quantityBtn: {
    fontSize: 24,
    marginRight: 0
  },
  removeBtn: {
    marginLeft: theme.spacing(1),
  },
  totalContainer: {
    flex: '1 1 auto',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  total: {
    textAlign: 'right'
  },
})

const ListItem = props => {
  const {
    classes,
    onUpdate,
    history,
    shop,
    item = {}
  } = props
  const {
    variant = {},
    name,
    price,
    priceDiscounted,
    quantity,
    subtotal,
    subtotalDiscounted
  } = item
  const {
    _id,
    image = {}
  } = variant
  return (
    <li className={classes.li}>
      <Card
        className={classes.card}
        imageSrc={image && image.src}
        to={`/${shop.slug}/${item.slug}`}
      >
        {image && image.src &&
          <div className={classes.imageContainer}>
            <img
              alt={name}
              src={getImageSrc(image.src)}
              className={classes.image}
            />
          </div>
        }
        <div className={classes.textContainer}>
          <div className={classes.namePriceContainer}>
            <CardContent className={classes.name}>
              <Typography>{name}</Typography>
            </CardContent>
            <CardContent className={classes.price}>
              <Typography>
                <span style={priceDiscounted ? { textDecorationLine: 'line-through' } : null}>{formatDollar(price)}</span>
                {priceDiscounted ? <span>&nbsp;{formatDollar(priceDiscounted)}</span> : null}
              </Typography>
            </CardContent>
          </div>
          <div className={classes.totals}>
            <CardContent className={classes.buttonContainer}>
              <ButtonAsync
                className={classes.quantityBtn}
                color="primary"
                onClick={() => onUpdate({ type: 'REDUCE_FROM_CART', variantId: _id, variantQty: 1 })}
                variant="contained"
                size="small"
              >
                <IconRemove />
              </ButtonAsync>
              <Typography className={classes.quantity}>{quantity}</Typography>
              <ButtonAsync
                className={classes.quantityBtn}
                color="primary"
                onClick={() => onUpdate({ type: 'ADD_TO_CART', variantId: _id, variantQty: 1 })}
                variant="contained"
                size="small"
              >
                <IconAdd />
              </ButtonAsync>
              <ButtonAsync
                className={classes.removeBtn}
                color="primary"
                onClick={() => onUpdate({ type: 'REMOVE_FROM_CART', variantId: _id })}
                variant="contained"
                size="small"
              >
                <IconClear />
              </ButtonAsync>
            </CardContent>
            <div className={classes.totalContainer}>
              <CardContent className={classes.total}>
                <Typography>{formatDollar(subtotal)}</Typography>
              </CardContent>
            </div>
          </div>
        </div>
      </Card>
    </li>

  )
}

ListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
}

ListItem.displayName = `${displayName}ListItem`

export default withStyles(styles)(ListItem)
