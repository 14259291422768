import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import CardActionArea from '@material-ui/core/CardActionArea'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import BlogListItem from '../blog/user/ListItem'
import Card from '../common/components/Card'
import ComponentListItem from '../component/public/ListItem'
import Pagination from '../list/Pagination'
import ProductListItem from '../product/public/ListItem'
import { onDelete, onFetch } from './actions'
import createStyles from '../page/common/createStyles'
import highlightText from '../common/utils/highlightText'
import Loading from '../common/components/Loading'
import getImageSrc from '../image/common/getImageSrc'
import { formatDollar } from '../common/utils/formatters'
import RatingSummary from '../rating/RatingSummary'
import HighlightText from '../common/components/HighlightText'

const styles = theme => ({
  ...createStyles(theme),
  Pagination: {
    display: 'flex',
    flexFlow: 'column',
  },
  li: {
    display: 'flex',
    flexFlow: 'column',
    margin: theme.spacing(1),
  },
  highlighted: {
    backgroundColor: theme.palette.primary.light,
  },
  Typography: {
    margin: theme.spacing(1),
    lineHeight: 1,
  },
  image: {
    margin: theme.spacing(1),
    width: 100,
    height: '100%',
  },
  CardActionArea: {
    padding: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  productContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  content: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'column',
  },
  header: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
  },
})

function SearchListItem({
  classes,
  item,
  shop,
  onDelete,
  search,
  pages,
}) {
  const {
    _id,
    price,
    name,
    published = {},
    description,
    detail,
    page,
    rating,
    reviews,
    imageSrc,
  } = item
  const { children } = published
  if (children) {
    const slug = pages[page].slug === 'home' ? '' : pages[page].slug
    return (
      <li className={classes.li}>
        <Card
          onClick={onDelete}
          to={`/${slug}#${_id}`}
          elevation={1}
        >
          <CardActionArea className={classes.CardActionArea}>
            <Typography className={classes.Typography} variant="h6">{`/${slug}`}</Typography>
            <Typography className={classes.Typography} variant="body1">
              <HighlightText text={children} highlightText={search.value} />
            </Typography>
          </CardActionArea>
        </Card>
      </li>
    )
  }
  if (price || name) {
    const to = price ? `/${shop.slug}/${item.slug}` : `/blogs/${item.slug}`
    return (
      <li className={classes.li}>
        <Card
          onClick={onDelete}
          to={to}
          elevation={1}
        >
          <CardActionArea className={classes.CardActionArea}>
            <Typography className={classes.Typography} variant="h6">{to}</Typography>
            <div className={classes.container}>
              <img alt={name} src={getImageSrc(imageSrc)} className={classes.image} />
              <div className={classes.content}>
                <div className={classes.header}>
                  <Typography className={classes.Typography} variant="h6">{name}</Typography>
                  {price ? <Typography className={classes.Typography} variant="h6">{formatDollar(price)}</Typography> : null}
                </div>
                {reviews ? <RatingSummary rating={rating} reviews={reviews} /> : null}
                {description ? (
                  <Typography className={classes.Typography} variant="body1">
                    <HighlightText text={description} highlightText={search.value} />
                  </Typography>
                ) : null}
                {detail ? (
                  <Typography className={classes.Typography} variant="body1">
                    <HighlightText text={detail} highlightText={search.value} />
                  </Typography>
                ) : null}
              </div>
            </div>
          </CardActionArea>
        </Card>
      </li>
    )
  }
  return null
}

const mapStateToProps = ({
  app: { isFetching: appIsFetching, shop },
  pages: { isFetching: pagesIsFetching, items: pages },
  search,
}) => ({
  isFetching: appIsFetching || pagesIsFetching,
  shop,
  pages,
  search,
})

export default withStyles(styles)(connect(mapStateToProps)(SearchListItem))
