import React, { useState, useEffect } from 'react'
import { BrowserRouter as ReactRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'

import Dialog from '../dialogs/Dialog'
import DialogAuth from '../dialogs/DialogAuth'
import ErrorBoundary from '../error/ErrorBoundary'
import Footer from '../footer/Footer'
import HeadApp from '../head/HeadApp'
import Header from '../header/Header'
import SnackbarProvider from '../snackbar/SnackbarProvider'
import ThemeProvider from '../theme/ThemeProvider'
import loadImages from '../image/common/loadImages'
import withApp from './withApp'
import Router from '../router/Router'
import FetchProvider from '../fetch/FetchProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    flex: '1 1 auto',
    '&>*': {
      letterSpacing: theme.typography.letterSpacing,
    },
  },
}))

const onImages = (business = {}, setImageReady) => {
  const { image = {} } = business
  const { src } = image
  if (src) return loadImages([src]).then(() => setImageReady(true))
  return setImageReady(true)
}

const App = () => {
  const classes = useStyles()
  const app = useSelector((state) => state.app)
  const [isImageReady, setImageReady] = useState(false)

  useEffect(() => {
    onImages(app.business, setImageReady)
  }, [app.business])

  if (!isImageReady) return null

  return (
    <ErrorBoundary>
      <SnackbarProvider>
        <FetchProvider>
          <ThemeProvider>
            <CssBaseline>
              <ReactRouter>
                <div className={classes.root}>
                  <HeadApp />
                  <Header />
                  <Router />
                  <Footer />
                  <Dialog />
                  <DialogAuth />
                </div>
              </ReactRouter>
            </CssBaseline>
          </ThemeProvider>
        </FetchProvider>
      </SnackbarProvider>
    </ErrorBoundary>
  )
}

export default withApp(App)
