import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

const HeadList = ({
  brandName,
  name,
  slug,
  urls,
}) => {
  const list = urls.map((url, i) => {
    return {
      '@type': 'ListItem',
      position: i,
      url
    }
  })
  return (
    <Helmet>
      <title>{`${brandName} - ${name}`}</title>
      <meta property="og:title" content={`${brandName} - ${name}`} />
      <meta property="og:url" content={`https://${process.env.REACT_APP_NAME}/${slug}`} />
      <script type="application/ld+json">
        {`{
          "@context":"http://schema.org",
          "@type":"ItemList",
          "itemListElement": ${JSON.stringify(list)}
        }`}
      </script>
    </Helmet>
  )
}

HeadList.propTypes = {
  brandName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  urls: PropTypes.array.isRequired,
}

const mapStateToProps = ({
  app: { brand: { name: { children: brandName } } }
}) => ({
  brandName
})

export default connect(mapStateToProps)(HeadList)
