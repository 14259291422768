import { useCallback, useReducer, useMemo } from 'react'

import { Action, State } from './types'

export const initialState: State = {
  isOpen: false,
  message: '',
  onSnackClose: () => { },
  onSnackOpen: () => { },
  severity: 'info',
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'set_snack': {
      const { severity, message } = action
      return {
        ...state,
        isOpen: true,
        severity,
        message,
      }
    }
    case 'close':
      return {
        ...state,
        isOpen: false,
        message: '',
        severity: 'info',
      }
    default:
      return state
  }
}

function useSnackbar() {
  const [state, dispatch] = useReducer(reducer, initialState)

  const onSnackOpen = useCallback(({ message, severity }) => {
    dispatch({ type: 'set_snack', severity, message })
  }, [])

  const onSnackClose = useCallback(() => dispatch({ type: 'close' }), [])
  return useMemo(() => ({
    ...state,
    onSnackOpen,
    onSnackClose,
  }), [onSnackClose, onSnackOpen, state])
}

export default useSnackbar
