import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import * as actions from './actions'
import Loading from '../../common/components/Loading'
import None from '../../common/components/None'
import OrderSupplierRouter from '../../order/supplier/Router'
import Page from '../../common/components/Page'
import Update from './Update'
import bindActionCreators from '../../common/utils/bindActionCreators'
import displayName from './displayName'
import itemReducer from '../../common/reducers/itemReducer'
import useReducer from '../../common/hooks/useReducer'
import Account from './Account'


function AccountRouter(props) {
  const { match, user } = props

  const [state, dispatch] = useReducer(itemReducer, {
    error: null,
    isFetching: false,
    isLoading: true,
    item: {},
  })
  const {
    item,
    isFetching,
    isLoading,
  } = state
  const boundActionCreators = bindActionCreators(actions, dispatch)
  const { onFindOne, onUpdate } = boundActionCreators

  useEffect(() => {
    onFindOne({ _id: match.params._id })
  }, [])

  if (isLoading || isFetching) return <Loading />
  if (!item) return <None>Sorry, that supplier was not found</None>

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        {route => (
          <Page>
            <Account
              {...route}
              user={user}
            />
          </Page>
        )}
      </Route>
      <Route path={`${match.path}/orders`}>
        {route => (
          <OrderSupplierRouter
            {...route}
            supplier={item}
            user={user}
            input={{ supplier: item._id }}
          />
        )}
      </Route>
      <Route path={`${match.path}/orders/requires-shipping`}>
        {route => (
          <OrderSupplierRouter
            {...route}
            supplier={item}
            user={user}
            input={{ supplier: item._id, shippedAt: { exists: false } }}
          />
        )}
      </Route>
      <Route exact path={`${match.path}/profile`}>
        {route => (
          <Page>
            <Update
              {...route}
              key={props.match.url}
              rootUrl={match.path}
              item={item}
              onUpdate={onUpdate}
            />
          </Page>
        )}
      </Route>
    </Switch>
  )
}

AccountRouter.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}

AccountRouter.displayName = `${displayName}AccountRouter`

export default AccountRouter
