import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'

import ComponentList from '../../component/public/List'
import Loading from '../../common/components/Loading'
import None from '../../common/components/None'
import PageHead from '../common/Head'
import ScrollToTopOnMount from '../../common/components/ScrollToTopOnMount'
import buildBackground from '../../common/utils/buildBackground'
import createStyles from '../common/createStyles'
import displayName from './displayName'
import withGoogleAnalytics from '../../common/hocs/withGoogleAnalytics'

const styles = theme => createStyles(theme)

const Creator = (props) => {
  const {
    classes,
    children,
    components,
    error,
    isFetching,
    pageIds,
    match: { params },
    pages,
    sections,
  } = props
  if (isFetching) return <Loading />
  if (error) return <None>{error.message}</None>
  const pageSlug = params.slug || 'home'
  const pageId = pageIds.find(id => pages[id].slug === pageSlug)
  const page = pages[pageId]
  if (!page) return <None>That page was not found</None>

  const { name, slug, published = {} } = page
  const { components: childComponents = [], description, style = {} } = published
  const background = style.background && buildBackground(style.background)
  return (
    <div className={classes.creatorRoot}>
      <PageHead
        description={description}
        name={name}
        slug={slug}
      />
      <div className={classes.pageRoot}>
        <ScrollToTopOnMount>
          <div
            style={{ ...style, ...background }}
            className={style.background && style.background.backgroundImage ? classes.backgroundImage : null}
          >
            <ComponentList
              classes={classes}
              page={page}
              components={components}
              childComponents={childComponents}
            />
          </div>
        </ScrollToTopOnMount>
      </div>
    </div>
  )
}

Creator.propTypes = {
  components: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  pageIds: PropTypes.array.isRequired,
  pages: PropTypes.object.isRequired,
}

const mapStateToProps = ({
  app: { isFetching: appIsFetching, pages: pageIds },
  components: { isFetching: componentsIsFetching, items: components },
  pages: { isFetching: pagesIsFetching, items: pages, error },
}) => ({
  components,
  error,
  isFetching: !!(appIsFetching || componentsIsFetching || pagesIsFetching),
  pageIds,
  pages,
})

Creator.displayName = `${displayName}Creator`

export default withGoogleAnalytics(connect(mapStateToProps)(withStyles(styles)(Creator)))
