import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import IconAccount from '@material-ui/icons/AccountCircle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import { onSignout } from '../user/user/actions'


class DrawerUser extends Component {
  constructor(props) {
    super(props)
    const isOpen = window.location.pathname.includes('account')
    this.state = {
      isOpen
    }
  }
  handleOpen = () => this.setState({ isOpen: !this.state.isOpen })
  handleSignout = () => {
    const { history } = this.context.router
    const { onSignout, onDrawer } = this.props
    onDrawer()
    return onSignout().then(() => history.push('/user/signin'))
  }
  renderList = () => {
    const { classes, onDrawer, user } = this.props
    if (user.firstName) {
      return (
        <List disablePadding>
          <ListItem
            activeClassName={classes.navLinkActive}
            button
            className={classes.nested}
            component={NavLink}
            onClick={onDrawer}
            to="/user/account"
          >
            <ListItemText primary="Account" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={this.handleSignout}
          >
            <ListItemText primary="Sign out" />
          </ListItem>
        </List>
      )
    }
    return (
      <List disablePadding>
        <ListItem
          button
          className={classes.nested}
          component={NavLink}
          onClick={onDrawer}
          to="/user/signin"
        >
          <ListItemText primary="Sign in" />
        </ListItem>
        <ListItem
          button
          className={classes.nested}
          component={NavLink}
          onClick={onDrawer}
          to="/user/signup"
        >
          <ListItemText primary="Sign up" />
        </ListItem>
      </List>
    )
  }
  render() {
    const {
      classes,
      user,
    } = this.props
    if (user.isFetching) return null
    return (
      <Fragment>
        <ListItem button onClick={this.handleOpen}>
          {user.firstName ? <ListItemText primary={`Hello, ${user.firstName}`} /> : <ListItemIcon><IconAccount /></ListItemIcon>}
          {this.state.isOpen ? <ExpandLess className={classes.marginLeftAuto} /> : <ExpandMore className={classes.marginLeftAuto} />}
        </ListItem>
        <Collapse component="li" in={this.state.isOpen} timeout="auto" unmountOnExit>
          {this.renderList()}
        </Collapse>
      </Fragment>
    )
  }
}

const mapStateToProps = ({
  user
}) => ({
  user
})

const mapDispatchToProps = dispatch => ({
  onSignout: () => dispatch(onSignout())
})

DrawerUser.contextTypes = {
  router: PropTypes.object
}

DrawerUser.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawer: PropTypes.func.isRequired,
  onSignout: PropTypes.func.isRequired,
  user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawerUser)
