import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    flex: '1 1 auto',
    minWidth: 150,
    padding: `${theme.spacing(1) / 2}px ${theme.spacing(1)}px`,
  },
})

const FieldCheckbox = ({
  classes,
  input,
  label,
  className,
  meta: { active, error, submitError, touched }
}) => {
  const isError = touched && Boolean(error || submitError)
  return (
    <div className={classNames(classes.root, className)}>
      <FormControl aria-describedby={label}>
        <FormControlLabel
          control={
            <Checkbox
              checked={input.value ? true : false}
              onChange={input.onChange}
            />
          }
          label={label}
        />
        <FormHelperText
          id={label}
          error={isError}
        >
          {touched && (error || submitError) ? (error || submitError) : ' '}
        </FormHelperText>
      </FormControl>
    </div>

  )
}

export default withStyles(styles)(FieldCheckbox)
