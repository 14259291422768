import createActions from '../../redux/createActions2'

import { reducerName } from './reducers'

const {
  ADD_ITEM,
  ADD_ITEMS,
  DELETE_ITEM,
  DELETE_ITEMS,
  ERROR,
  FETCHING,
  RESET,
  SET_ITEM,
  SET_ITEMS,
  UPDATE_ITEM,
  UPDATE_ITEMS,
} = createActions(reducerName)

const onError = error => ({ type: ERROR, error })
export const onFetch = () => ({ type: FETCHING })
export const onReset = () => ({ type: RESET })
export const onUpdateItem = item => ({ type: UPDATE_ITEM, item })
export const onUpdateItems = items => ({ type: UPDATE_ITEMS, items })
export const onSetItems = items => ({ type: SET_ITEMS, items })
