import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ButtonMui from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'


const styles = {
  root: {
    display: 'flex',
    margin: '0px !important',
  },
  fullWidth: {
    width: '100%',
  },
}

class Button extends Component {
  renderButtonVariant = () => {
    const {
      classes,
      variant,
      staticContext,
      ...rest
    } = this.props
    if ((variant && variant === 'text') || !variant) {
      if (typeof this.props.children === 'string' || this.props.children.length > 1) {
        return <ButtonMui variant={variant} {...rest} />
      }
      return <IconButton variant={variant} {...rest} />
    }
    if (variant === 'fab') {
      return <Fab {...rest} />
    }
    return <ButtonMui variant={variant} {...rest} />
  }

  renderButton = () => {
    const {
      classes,
      className,
      fullWidth,
    } = this.props
    return (
      <span className={classNames(classes.root, className, fullWidth && classes.fullWidth)}>
        {this.renderButtonVariant()}
      </span>
    )
  }

  render() {
    const { tooltip } = this.props
    if (tooltip) {
      return (
        <Tooltip
          enterDelay={300}
          id={tooltip}
          leaveDelay={100}
          title={tooltip}
        >
          {this.renderButton()}
        </Tooltip>
      )
    }
    return this.renderButton()
  }
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  tooltip: PropTypes.string,
  fullWidth: PropTypes.bool,
}

export default withStyles(styles)(Button)
