import React from 'react'
import PropTypes from 'prop-types'

import displayName from './displayName'
import ListItem from './ListItem'

const List = (props) => {
  const { classes, childComponents, components, page } = props

  if (!childComponents || !childComponents.length) return null

  return childComponents.map(id => (
    <ListItem
      classes={classes}
      component={components[id]}
      components={components}
      id={id}
      key={id}
      page={page}
    />
  ))
}

List.propTypes = {
  components: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
}

List.displayName = `${displayName}List`

export default List
