import React from 'react'
import SvgIconBase from '@material-ui/core/SvgIcon'
import classNames from 'classnames'

function SvgIcon({
  classes,
  elevation,
  id,
  onClick,
  onDrop,
  path,
  style,
  viewBox,
}) {
  const { stroke, strokeWidth } = style
  return (
    <SvgIconBase
      className={classNames(
        classes.root,
        style.background && style.background.backgroundImage && classes.backgroundImage,
        elevation && classes[`elevation${elevation}`],
      )}
      id={id}
      onClick={onClick}
      onDrop={onDrop}
      style={style}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={path}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </SvgIconBase>
  )
}

export default SvgIcon
