import fetchGraphQL from '../graphql/fetchGraphQL'

// QUERIES
export const commentFind = async input => {
  const { commentFind: docs } = await fetchGraphQL({
    query: `
      query CommentFind($input: CommentFindInput!) {
        commentFind(input: $input) {
          _id
          likes
          parentComment
          review
          text
          onModel
          user {
            _id
            firstName
            lastName
          }
        }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return docs
}





// MUTATIONS
export const commentAdd = async input => {
  const { commentAdd: doc } = await fetchGraphQL({
    query: `
      mutation CommentAdd($input: CommentAddInput!) {
        commentAdd(input: $input) {
          _id
          likes
          parentComment
          review
          text
          user {
            _id
            firstName
            lastName
          }
        }
      }
      `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return doc
}



export const commentDelete = async input => {
  const { commentDelete: doc } = await fetchGraphQL({
    query: `
      mutation CommentDelete($input: CommentDeleteInput!) {
        commentDelete(input: $input) {
          _id
        }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return doc
}




export const commentDeleteAdmin = async input => {
  const { commentDeleteAdmin: doc } = await fetchGraphQL({
    query: `
      mutation CommentDeleteAdmin($input: CommentDeleteInput!) {
        commentDeleteAdmin(input: $input) {
          _id
        }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return doc
}







export const commentReportAbuse = async input => {
  const { commentReportAbuse } = await fetchGraphQL({
    query: `
      mutation CommentReportAbuse($input: CommentReportAbuseInput!) {
        commentReportAbuse(input: $input) {
          onModel
        }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return commentReportAbuse
}



export const commentUpdate = async input => {
  const { commentUpdate: doc } = await fetchGraphQL({
    query: `
      mutation CommentUpdate($input: CommentUpdateInput!) {
        commentUpdate(input: $input) {
          _id
          likes
          parentComment
          review
          text
          user {
            _id
            firstName
            lastName
          }
        }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return doc
}


export const commentUpdateLikes = async input => {
  const { commentUpdateLikes: doc } = await fetchGraphQL({
    query: `
      mutation CommentUpdateLikes($input: CommentUpdateLikesInput!) {
        commentUpdateLikes(input: $input) {
          _id
          likes
          parentComment
          review
          text
          user {
            _id
            firstName
            lastName
          }
        }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return doc
}
