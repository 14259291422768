import React from 'react'
import TextField from '@material-ui/core/TextField'

import displayName from './displayName'

const Expiry = props => {
  const {
    input,
    classes,
    onKeyDown,
    inputRef,
    meta: { error, submitError, touched }
  } = props
  return (
    <div className={classes.cardExpiry}>
      <TextField
        {...input}
        fullWidth
        label="MM/YY"
        inputRef={inputRef}
        error={touched && Boolean(error)}
        helperText={touched && error ? error || submitError : ' '}
        onKeyDown={e => onKeyDown(e, input.value)}
      />
    </div>
  )
}

Expiry.displayName = `${displayName}Expiry`

export default Expiry