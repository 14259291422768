import { reducerName } from './actions'

const swipeables = (state = {
  autoplay: true
}, action) => {
  switch (action.type) {
    case `START_${reducerName}_START`:
      return {
        ...state,
        autoplay: true
      }
    case `STOP_${reducerName}`:
      return {
        ...state,
        autoplay: false
      }
    default:
      return state
  }
}

export default swipeables
