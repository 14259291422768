import React, { Component } from 'react'
import payment from 'payment'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

import displayName from './displayName'
import Number from './Number'
import Expiry from './Expiry'
import CVC from './CVC'
import isExpiryInvalid from './creditCardValidateExpiry'

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  image: {
    width: '1.5em'
  },
  cardNumber: {
    flex: '3 3 200px',
    padding: `${theme.spacing(1) / 2}px ${theme.spacing(1)}px`,
  },
  expCvcContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flex: '1 1 100px',
    minWidth: 150
  },
  cardExpiry: {
    flex: '1 1 auto',
    padding: `${theme.spacing(1) / 2}px ${theme.spacing(1)}px`,
  },
  cardCVC: {
    flex: '1 1 auto',
    padding: `${theme.spacing(1) / 2}px ${theme.spacing(1)}px`,
  },
  labelRoot: {
    marginLeft: 32
  },
  labelShrink: {
    marginLeft: 0
  }
})

const BACKSPACE_KEY_CODE = 8
const acceptedBrands = [
  'amex',
  'discover',
  'mastercard',
  'visa',
]


class Fields extends Component {
  constructor(props) {
    super(props)
    this.cardNumber = React.createRef()
    this.cardExpiry = React.createRef()
    this.cardCVC = React.createRef()
  }
  handleCardNumberChange = (e, value) => {
    const { input } = this.props
    const { card = {}, onChange, name } = input
    if (value) {
      const brand = payment.fns.cardType(value)
      const isAccepted = acceptedBrands.includes(brand)
      isAccepted && card.brand !== brand && onChange({ brand })
    }
    if (payment.fns.validateCardNumber(value)) {
      return this.cardExpiry.current.focus()
    }
  }
  handleCardNumberFormat = value => {
    if (value) {
      const onlyNums = value.replace(/[^\d]/g, '')
      return payment.fns.formatCardNumber(onlyNums)
    }
    return ''
  }
  handleCardNumberParse = value => {
    if (!value) return value
    const onlyNums = value.replace(/[^\d]/g, '')
    return onlyNums
  }
  handleCardNumberValidate = value => {
    if (!value) return 'Card number is required'
    if (!payment.fns.validateCardNumber(value)) return 'Card number is invalid'
    return undefined
  }
  handleCardExpiryChange = (e, value) => {
    const cardExpiry = e.target.value.split(' / ').join('/')
    const expiryError = isExpiryInvalid(cardExpiry)
    if (cardExpiry.length > 4) {
      if (!expiryError) {
        return this.cardCVC.current.focus()
      }
    }
  }
  handleCardExpiryFormat = value => {
    if (!value) return ''
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 2) return onlyNums
    return `${onlyNums.slice(0, 2)} / ${onlyNums.slice(2, 4)}`
  }
  handleCardExpiryParse = value => {
    if (!value) return value
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 2) return onlyNums
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`
  }
  handleCardExpiryValidate = value => {
    if (!value) return 'Expiry is required'
    const cardExpiry = value.split(' / ').join('/')
    const errorText = isExpiryInvalid(cardExpiry)
    if (errorText) {
      return errorText
    }
    return undefined
  }

  handleCardCVCParse = value => {
    if (!value) return value
    if (value) {
      const { card = {} } = this.props
      const cardType = payment.fns.cardType(card.number)
      if (cardType === 'amex') {
        return value.slice(0, 4)
      }
      return value.slice(0, 3)
    }
  }
  handleCardCVCValidate = value => {
    if (!value) return 'CVC is required'
    const { card = {} } = this.props
    const cardType = payment.fns.cardType(card.number)
    if (!payment.fns.validateCardCVC(value, cardType)) {
      return 'CVC is invalid'
    }
    return undefined
  }

  handleKeyDown = ref => {
    return (e, value) => {
      if (e.keyCode === BACKSPACE_KEY_CODE && !value) {
        ref.current.focus()
      }
    }
  }

  render = () => {
    const { classes, input } = this.props
    const { name } = input

    return (
      <div className={classes.root}>
        <Field
          autoComplete="cc-number"
          classes={classes}
          component={Number}
          format={this.handleCardNumberFormat}
          parse={this.handleCardNumberParse}
          onChange={this.handleCardNumberChange}
          inputRef={this.cardNumber}
          label="Card number"
          name={`${name}.number`}
          type="tel"
          validate={this.handleCardNumberValidate}
        />
        <div className={classes.expCvcContainer}>
          <Field
            autoComplete="cc-exp"
            classes={classes}
            component={Expiry}
            format={this.handleCardExpiryFormat}
            parse={this.handleCardExpiryParse}
            inputRef={this.cardExpiry}
            onKeyDown={this.handleKeyDown(this.cardNumber)}
            label="MM/YY"
            name={`${name}.expiry`}
            onChange={this.handleCardExpiryChange}
            type="tel"
            validate={this.handleCardExpiryValidate}
          />
          <Field
            autoComplete="cc-cvc"
            classes={classes}
            component={CVC}
            parse={this.handleCardCVCParse}
            inputRef={this.cardCVC}
            label="CVC"
            name={`${name}.cvc`}
            onKeyDown={this.handleKeyDown(this.cardExpiry)}
            type="tel"
            validate={this.handleCardCVCValidate}
          />
        </div>

      </div>
    )
  }
}

const selector = formValueSelector('checkout')

const mapStateToProps = state => {
  const card = selector(state, 'payment.card')
  return {
    card
  }
}

Fields.displayName = `${displayName}Fields`

export default connect(mapStateToProps)(withStyles(styles)(Fields))