import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import moment from 'moment'

import CartListItem from './CartListItem'
import Phone from '../../common/components/Phone'
import creditCardImages from '../../creditCard/common/creditCardImages'
import displayName from './displayName'
import { formatDollar } from '../../common/utils/formatters'

const styles = theme => ({
  cardContent: {
    paddingBottom: 0
  },
  dates: {
    padding: '8px 0'
  },
  detail: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    padding: '8px 0'
  },
  name: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  cardImage: {
    height: '1em',
    marginLeft: 8,
  },
  orderCartList: {
    padding: theme.spacing(1),
  },
  totals: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-end',
  },
  summary: {
    textAlign: 'right',
  },
  summaryDetails: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: '16px',
  },
  textRight: {
    textAlign: 'right',
  },
  ul: {
    display: 'flex',
    flexFlow: 'column',
    margin: `-${theme.spacing(1)}px`,
    listStyle: 'none',
    padding: 0,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  cardActions: {
    justifyContent: 'flex-end'
  }
})

const dateFormat = 'dddd, MMMM Do YYYY, h:mm a'

const DetailBase = props => {
  const {
    classes,
    adminUser,
    history,
    match,
    item,
    shop,
    boundActions,
    buttons: Buttons
  } = props
  const {
    _id,
    createdAt,
    refundedAt,
    discountCode,
    deliverBy,
    shippedAt,
    shipRequestedAt,
    refundRequestedAt,
    deliveryAddress = {},
    deliveredAt,
    items,
    paymentAddress = {},
    paymentCharge = {},
    paymentGiftCard = {},
    shipping = {},
    subtotal,
    subtotalDiscounted,
    tax = {},
    total,
  } = item
  return (
    <div>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6">Order #: {_id}</Typography>
        <div>
          <div className={classes.dates}>
            <Typography>Created At: {moment(createdAt).format(dateFormat)}</Typography>
            {deliverBy ? <Typography>Deliver By: {moment(createdAt).format(dateFormat)}</Typography> : null}
            {shipRequestedAt ? <Typography>Shipping Requested At: {moment(shipRequestedAt).format(dateFormat)}</Typography> : null}
            <Typography>Shipped At: {shippedAt ? moment(shippedAt).format(dateFormat) : 'In progress'}</Typography>
            {deliveredAt ? <Typography>Delivered At: {moment(deliveredAt).format(dateFormat)}</Typography> : null}
            {refundRequestedAt ? <Typography>Refund Requested At: {moment(refundRequestedAt).format(dateFormat)}</Typography> : null}
            {refundedAt ? <Typography>Refunded At: {moment(refundedAt).format(dateFormat)}</Typography> : null}
          </div>
        </div>
        <div className={classes.detail}>
          <div>
            <Typography gutterBottom><strong>Delivery Details</strong></Typography>
            <Typography>{deliveryAddress.firstName} {deliveryAddress.lastName}</Typography>
            <Typography>{deliveryAddress.phone ? <Phone>{deliveryAddress.phone}</Phone> : null}</Typography>
            <Typography>{deliveryAddress.email}</Typography>
            <Typography>{deliveryAddress.street1}</Typography>
            {deliveryAddress.street2 ? <Typography>{deliveryAddress.street2}</Typography> : null}
            <Typography>{deliveryAddress.city}, {deliveryAddress.state} {deliveryAddress.zip}</Typography>
            {deliverBy ? <Typography>Deliver by {deliverBy}</Typography> : null}
          </div>
          <div>
            <Typography gutterBottom><strong>Payment Details</strong></Typography>
            <div className={classes.name}>
              <Typography>{paymentAddress.firstName} {paymentAddress.lastName}</Typography>
              {paymentCharge && paymentCharge.brand ? <img src={creditCardImages[paymentCharge.brand]} alt={paymentCharge.brand} className={classes.cardImage} /> : null}
            </div>
            {paymentGiftCard.amount ? <Typography>Gift Card {paymentGiftCard.number}</Typography> : null}
            <Typography><Phone>{paymentAddress.phone}</Phone></Typography>
            <Typography>{paymentAddress.email}</Typography>
            <Typography>{paymentAddress.street1}</Typography>
            {paymentAddress.street2 ? <Typography>{paymentAddress.street2}</Typography> : null}
            {paymentAddress.city ? <Typography>{paymentAddress.city}, {paymentAddress.state} {paymentAddress.zip}</Typography> : null}
          </div>

        </div>
      </CardContent>
      <CardContent>
        <ul className={classes.ul}>
          {items.map(item => (
            <CartListItem
              shop={shop}
              item={item}
              key={item._id}
              to={`/${shop.slug}/${item.slug}`}
            />
          ))}
        </ul>
      </CardContent>
      <CardContent>
        <Typography className={classes.summary} gutterBottom><strong>Order Summary</strong></Typography>
        <div className={classes.totals}>
          <div className={classes.summaryDetails}>
            <Typography>Subtotal:</Typography>
            <Typography className={classes.textRight}>{formatDollar(subtotal)}</Typography>
            {discountCode ? <Typography>Discount Code:</Typography> : null}
            {discountCode ? <Typography className={classes.textRight}>{discountCode}</Typography> : null}
            {subtotalDiscounted ? <Typography>Subtotal Discounted:</Typography> : null}
            {subtotalDiscounted ? <Typography className={classes.textRight}>{formatDollar(subtotalDiscounted)}</Typography> : null}
            <Typography>Tax {tax.price ? `${tax.rate * 100}%` : null}:</Typography>
            <Typography className={classes.textRight}>{formatDollar(tax.price)}</Typography>
            <Typography>Shipping:</Typography>
            <Typography className={classes.textRight} gutterBottom>{shipping.price ? formatDollar(shipping.price) : '$0.00'}</Typography>
            <Typography><strong>Total:</strong></Typography>
            <Typography className={classes.textRight}><strong>{formatDollar(total)}</strong></Typography>
          </div>
        </div>
      </CardContent>
      {props.buttons ?
        <CardActions className={classes.cardActions}>
          <Buttons
            {...boundActions}
            history={history}
            item={item}
            match={match}
          />
        </CardActions>
        : null}
    </div>
  )
}


DetailBase.propTypes = {
  adminUser: PropTypes.object,
  classes: PropTypes.object.isRequired,

}


DetailBase.displayName = `${displayName}DetailBase`

export default withStyles(styles)(DetailBase)
