import React from 'react'

import Page from './Page'
import None from './None'

function PageNotFound() {
  return (
    <Page>
      <None>
        Sorry, that page was not found.
      </None>
    </Page>
  )
}

export default PageNotFound