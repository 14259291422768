const styleFields = `
  alignItems
  alignSelf
  background
  backgroundClip
  backgroundColor
  backgroundImage
  backgroundPositionX
  backgroundPositionY
  border
  borderRadius
  boxShadow
  color
  display
  fill
  filter
  flex
  flexBasis
  flexDirection,
  flexFlow
  flexGrow
  flexShrink
  fontFamily
  fontSize
  fontWeight
  gridColumn
  gridGap
  gridRow
  gridTemplateColumns
  gridTemplateRows
  height
  justifyContent
  letterSpacing
  margin
  maxHeight
  maxWidth
  minHeight
  objectFit
  padding
  stroke
  strokeWidth
  textAlign
  textShadow
  WebkitTextStroke
  width
`

export default styleFields
