export const itemsInitialState = {
  count: 0,
  error: '',
  isInitialized: false,
  isFetching: false,
  items: {},
  lastItem: {},
}

const itemsReducerFn = (
  reducerName,
  state = itemsInitialState,
  action,
) => {
  switch (action.type) {
    case `${reducerName}_SET_ACTIONS`: {
      const { boundActions } = action
      return {
        ...state,
        ...boundActions,
        isInitialized: true,
      }
    }
    case `${reducerName}_ADD_ITEM`:
      return {
        ...state,
        count: state.count + 1,
        isInitialized: false,
        isFetching: false,
        items: {
          [action.item._id]: { ...action.item },
          ...state.items,
        },
      }
    case `${reducerName}_ADD_ITEMS`: {
      const items = action.items.reduce((a, v) => {
        a[v._id] = v
        return a
      }, {})
      const count = action.items.length
      const lastItem = count > 0 ? action.items[count - 1] : state.lastItem
      return {
        ...state,
        count,
        error: null,
        isFetching: false,
        isInitialized: false,
        items: { ...state.items, ...items },
        lastItem,
      }
    }
    case `${reducerName}_DELETE_ITEM`: {
      const { [action._id]: deletedItem, ...rest } = state.items
      return {
        ...state,
        isInitialized: false,
        isFetching: false,
        items: { ...rest },
      }
    }
    case `${reducerName}_DELETE_ITEMS`: {
      const nextItems = { ...state.items }
      action.ids.forEach((id) => {
        if (nextItems[id]) {
          delete nextItems[id]
        }
      })
      return {
        ...state,
        isInitialized: false,
        isFetching: false,
        items: nextItems,
      }
    }
    case `${reducerName}_ERROR`:
      return {
        ...state,
        error: action.error.message,
        isFetching: false,
        isInitialized: false,
      }
    case `${reducerName}_RESET`: {
      return itemsInitialState
    }
    case `${reducerName}_SET_ITEM`:
      return {
        ...state,
        count: state.count + 1,
        isInitialized: false,
        isFetching: false,
        items: {
          ...state.items,
          [action.item._id]: { ...action.item },
        },
      }
    case `${reducerName}_SET_ITEMS`: {
      const items = action.items.reduce((a, v) => {
        a[v._id] = v
        return a
      }, {})
      const count = action.items.length
      const lastItem = count > 0 ? action.items[count - 1] : state.lastItem
      return {
        ...state,
        count,
        error: null,
        isFetching: false,
        isInitialized: false,
        items,
        lastItem,
      }
    }
    case `${reducerName}_UPDATE_ITEM`:
      return {
        ...state,
        isInitialized: false,
        isFetching: false,
        items: {
          ...state.items,
          [action.item._id]: {
            ...state.items[action.item._id],
            ...action.item,
          },
        },
      }
    case `${reducerName}_UPDATE_ITEMS`: {
      const items = action.items.reduce((a, v) => {
        a[v._id] = v
        return a
      }, {})
      return {
        ...state,
        error: null,
        isFetching: false,
        isInitialized: false,
        items: { ...state.items, ...items },
      }
    }
    case `${reducerName}_SET_FETCHING`:
      return {
        ...state,
        isFetching: true,
        isInitialized: true,
      }
    default:
      return state
  }
}

export default itemsReducerFn
