import React from 'react'
import PropTypes from 'prop-types'
import MuiButton from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import displayName from './displayName'

const Button = props => {
  const {
    children,
    classes,
    color,
    elevation,
    href,
    size,
    style = {},
    variant,
    ...rest
  } = props
  const hrefProps = rest.onClick ? {} : href.includes('http') ? {
    component: 'a',
    href: href,
  } : {
      component: Link,
      to: href
    }
  return (
    <div
      {...rest}
      className={classNames(
        classes.root,
      )}
    >
      <MuiButton
        {...hrefProps}
        className={classNames(
          classes.Button,
          style.background && style.background.backgroundImage && classes.backgroundImage,
          elevation && classes[`elevation${elevation}`]
        )}
        color={color || 'primary'}
        size={size}
        style={style}
        variant={variant}
      >
        {children}
      </MuiButton>
    </div>

  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  href: PropTypes.string,
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  variant: PropTypes.string.isRequired,
}

Button.displayName = `${displayName}Button`

export default Button
