import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'

import ULVerticalBase from '../../common/components/ULVerticalBase'
import ULHorizontalBase from '../../common/components/ULHorizontalBase'
import CategoryListItem from '../../category/user/ListItem'
import Loading from '../../common/components/Loading'
import displayName from './displayName'


const CategoryListVertical = props => {
  const {
    categories,
    categoryItems,
    classes,
    elevation,
    isFetching,
    shop,
    style,
    ...rest
  } = props
  if (isFetching) return <Loading />
  const ids = Object.keys(categoryItems).filter(id => categoryItems[id].categories.some(v => categoryItems.includes(v)))
  return (
    <ULVerticalBase
      {...rest}
      className={classNames(
        classes.root,
        classes.CategoryList,
        style.background && style.background.backgroundImage && classes.backgroundImage,
        elevation && classes[`elevation${elevation}`]
      )}
      style={style}
    >
      {ids.map(id => {
        return (
          <CategoryListItem
            category={categories[id]}
            className={classes.CategoryListItem}
            key={id}
            to={`/${shop.slug}/${categories[id].slug}`}
          />
        )
      })}
    </ULVerticalBase>
  )
}

CategoryListVertical.propTypes = {
  categories: PropTypes.object.isRequired,
  categoryIds: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  direction: PropTypes.string,
  id: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  shop: PropTypes.object.isRequired,
  style: PropTypes.object,
}

const mapStateToProps = ({
  app: { isFetching: appIsFetching, shop },
  categories: { isFetching: categoriesIsFetching, items: categoryItems },
}) => ({
  categoryItems,
  isFetching: appIsFetching || categoriesIsFetching,
  shop,
})

CategoryListVertical.displayName = `${displayName}CategoryListVertical`

export default connect(mapStateToProps)(CategoryListVertical)