import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import AddressFields from '../common/Fields'
import FormCard from '../../forms/FormCard'
import Loading from '../../common/components/Loading'
import None from '../../common/components/None'
import displayName from './displayName'

const Update = props => {
  const {
    address = {},
    history,
    isFetching,
    match,
    onDelete,
    onFindOne,
    onUpdate,
    user,
  } = props
  const {
    _id,
    city,
    email,
    firstName,
    lastName,
    phone,
    state,
    street1,
    street2,
    zip
  } = address

  useEffect(() => {
    if (!_id) {
      onFindOne({ _id: match.params._id })
    }
  }, [])
  if (isFetching) return <Loading />
  if (!_id) return <None>Sorry, that address was not found</None>

  return (
    <FormCard
      form={`address${address._id}`}
      initialValues={{
        city,
        email,
        firstName,
        lastName,
        phone,
        state,
        street1,
        street2,
        zip
      }}
      onSubmit={values => onUpdate({ _id, ...values }).then(history.goBack)}
      title={`Address for ${user.firstName} ${user.lastName}`}
      onDelete={() => onDelete({ _id }).then(history.goBack)}
    >
      <AddressFields />
    </FormCard>
  )
}

Update.propTypes = {
  address: PropTypes.object,
  history: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

Update.displayName = `${displayName}Update`

export default Update
