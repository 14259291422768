import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import getImageSrc from '../image/common/getImageSrc'


class HeadApp extends PureComponent {
  render() {
    const {
      description,
      image,
      brandName,
      phone,
    } = this.props
    const imageSrc = image && image.src ? getImageSrc(image.src) : null
    return (
      <Helmet
        title={brandName}
        meta={[
          { name: 'description', content: description },
          { property: 'og:description', content: description },
          { property: 'og:image', content: imageSrc },
          { property: 'og:site_name', content: brandName },
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: window.location.href },
        ]}
        link={[
          { rel: 'icon', type: 'image/png', href: imageSrc },
          { rel: 'shortcut icon', href: imageSrc },
          { rel: 'canonical', href: window.location.href },
        ]}
        script={[{
          context: 'http://schema.org',
          type: 'Organization',
          url: `https://${process.env.REACT_APP_NAME}`,
          logo: imageSrc,
          contactPoint: [{
            type: 'ContactPoint',
            telephone: phone,
            contactType: 'customer service',
          }],
        }]}
      />
    )
  }
}


const mapStateToProps = ({
  app: {
    address: { phone },
    brand: {
      image,
      name: { children: brandName },
    },
    seo: {
      keywords,
      description,
    },
    isFetching,
    socialMedia,
  },
}) => ({
  brandName,
  description,
  image,
  isFetching,
  keywords,
  phone,
  socialMedia,
})

HeadApp.propTypes = {
  brandName: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  keywords: PropTypes.string,
  name: PropTypes.object,
  phone: PropTypes.string,
  socialMedia: PropTypes.object,
}

export default connect(mapStateToProps)(HeadApp)
