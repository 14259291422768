import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    listStyle: 'none',
    margin: 0,
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px 0`,
  },
  item: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
})

const BreadCrumbs = (props) => {
  const {
    classes,
    crumbs,
  } = props
  return (
    <ul className={classes.root}>
      {crumbs.map((crumb, i) => {
        const lastIndex = crumbs.length - 1
        if (i === lastIndex) {
          return (
            <li key={crumb.slug}>
              <Typography>{crumb.name}</Typography>
            </li>
          )
        }
        return (
          <li key={crumb.slug} className={classes.item}>
            <Typography component={Link} to={`/${crumb.slug}`}>{crumb.name}</Typography>
            &nbsp;/&nbsp;
          </li>
        )
      })}
    </ul>
  )
}

export default withRouter(withStyles(styles)(BreadCrumbs))
