import React from 'react'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'

import CreditCardField from '../custom/Fields'
import StripeCardField from '../stripe/StripeCardField'
import Loading from '../../common/components/Loading'

const FieldCreditCard = (props) => {
  const {
    billingDescriptor,
    isFetching,
    payment,
  } = props
  if (isFetching) return <Loading />
  const { provider } = payment
  switch (provider) {
    case 'stripe':
      return (
        <>
          <StripeCardField {...props} />
          {billingDescriptor
            ? (
              <Typography style={{ padding: 8 }}>
                Charges on your statement will appear as
                {' '}
                {billingDescriptor}
              </Typography>
            )
            : null}
        </>

      )
    case 'authorize.net':
      return (
        <>
          <CreditCardField {...props} />
          {billingDescriptor
            ? (
              <Typography style={{ padding: 8 }}>
                Charges on your statement will appear as
                {' '}
                {billingDescriptor}
              </Typography>
            )
            : null}
        </>
      )
    default:
      return (
        <>
          <CreditCardField {...props} />
          {billingDescriptor
            ? (
              <Typography style={{ padding: 8 }}>
                Charges on your statement will appear as
                {' '}
                {billingDescriptor}
              </Typography>
            )
            : null}
        </>
      )
  }
}

const mapStateToProps = ({
  app: { isFetching, payment, business: { billingDescriptor } },
}) => ({
  isFetching,
  payment,
  billingDescriptor,
})

export default connect(mapStateToProps)(FieldCreditCard)
