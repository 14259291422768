import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Detail from './Detail'
import Loading from '../../common/components/Loading'
import None from '../../common/components/None'
import displayName from './displayName'
import useReducer from '../../common/hooks/useReducer'
import withGoogleAnalytics from '../../common/hocs/withGoogleAnalytics'
import { onFindOne, onUpdateSuccess } from './actions'
import { itemReducer } from './reducers'


function DetailContainer(props) {
  const [state, dispatch] = useReducer(itemReducer)
  const {
    item: product,
    isFetching,
    isLoading,
  } = state
  useEffect(() => {
    dispatch(onFindOne({ slug: props.match.params.slug }))
  }, [])

  if (isLoading || isFetching) return <Loading />
  if (!product) return <None>Sorry, that product was not found</None>

  return (
    <Detail
      {...props}
      product={product}
      onUpdateSuccess={item => dispatch(onUpdateSuccess(item))}
    />
  )
}

DetailContainer.propTypes = {
  businessName: PropTypes.string,
  match: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
}

const mapStateToProps = ({
  app: { shop },
}) => ({
  shop,
})

DetailContainer.displayName = `${displayName}DetailContainer`

export default withGoogleAnalytics(connect(mapStateToProps)(DetailContainer))
