import createActions from '../../redux/createActions2'

import {
  pageFind,
} from './api'

import { reducerName } from './reducers'

const {
  ADD_ITEM,
  ADD_ITEMS,
  DELETE_ITEM,
  DELETE_ITEMS,
  ERROR,
  FETCHING,
  RESET,
  SET_ITEM,
  SET_ITEMS,
  UPDATE_ITEM,
  UPDATE_ITEMS,
} = createActions(reducerName)

const onError = error => ({ type: ERROR, error })
export const onAddItems = items => ({ type: ADD_ITEMS, items })
export const onDeleteItem = item => ({ type: DELETE_ITEM, item })
export const onFetch = () => ({ type: FETCHING })
export const onReset = () => ({ type: RESET })
export const onSetItems = items => ({ type: SET_ITEMS, items })
export const onUpdateItem = item => ({ type: UPDATE_ITEM, item })

export const onFind = input => async (dispatch) => {
  dispatch(onFetch())
  const items = await pageFind(input).catch((error) => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onSetItems(items))
}
