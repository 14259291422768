import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import IconAccount from '@material-ui/icons/AccountCircle'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link } from 'react-router-dom'

import Card from '../../common/components/Card'
import Main from '../../common/components/Main'
import FieldText from '../../fields/FieldText'
import Form from '../../forms/FormBase'
import FormButtonSubmit from '../../forms/FormButtonSubmit'
import FormFields from '../../forms/FormFields'
import displayName from './displayName'
import { validateEmail } from '../../common/utils/validators'

const validate = (values) => {
  const errors = {}
  const requiredFields = ['email']
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}

const styles = (theme) => ({
  root: {
    width: 'auto',
    maxWidth: theme.breakpoints.values.sm,
    margin: 'auto',
  },
  header: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  fields: {
    padding: '0 8px',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  actions: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
  },
  link1: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  link2: {
    color: theme.palette.primary.main,
  },
})

const Recovery = (props) => {
  const {
    classes,
    history,
    onRecovery,
  } = props
  return (
    <Main justifyContent="center">
      <Card>
        <CardContent className={classes.header}>
          <Avatar className={classes.avatar}>
            <IconAccount />
          </Avatar>
          <Typography component="h1" variant="h5">
            Recover Account
          </Typography>
        </CardContent>
        <Form
          form="recovery"
          onSubmit={(values) => onRecovery(values).then(() => {
            const redirect = localStorage.getItem('redirect')
            if (redirect) {
              localStorage.removeItem('redirect')
              return history.push(redirect)
            }
            history.push('/')
          })}
          validate={validate}
          enableReinitialize
        >
          <FormFields
            className={classes.fields}
            fields={[
              { name: 'email', label: 'Email', validate: validateEmail, component: FieldText },
            ]}
            flexFlow="column"
          />
          <CardActions>
            <FormButtonSubmit color="primary" variant="contained" fullWidth>
              Recover
            </FormButtonSubmit>
          </CardActions>
        </Form>
        <CardContent className={classes.actions}>
          <Typography>
            Have an account?
            {' '}
            <Link to="/user/signin" className={classes.link1}>Sign In!</Link>
            {' '}
          </Typography>
          <Typography>
            Don&#39;t have an account?
            {' '}
            <Link to="/user/signup" className={classes.link2}>Sign Up!</Link>
          </Typography>
        </CardContent>
      </Card>
    </Main>
  )
}


Recovery.propTypes = {
  classes: PropTypes.object.isRequired,
  onRecovery: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

Recovery.displayName = `${displayName}Recovery`

export default withStyles(styles)(Recovery)
