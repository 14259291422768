import React from 'react'
import PropTypes from 'prop-types'
import IconShip from '@material-ui/icons/LocalShipping'

import displayName from './displayName'
import ButtonAsync from '../../common/components/ButtonAsync'
import IconDelivered from '../../icons/IconDelivered'

const Buttons = props => {
  const {
    onShip,
    onDeliver,
    item = {}
  } = props
  const {
    _id,
    shippedAt,
    deliveredAt,
  } = item
  return (
    <>
      {shippedAt ? null :
        <ButtonAsync
          confirm="Are you sure you want to mark this order shipped?"
          onClick={() => onShip({ _id })}
          tooltip="Mark Shipped"
          displaySuccess={true}
        >
          <IconShip />
        </ButtonAsync>
      }
      {deliveredAt ? null :
        <ButtonAsync
          confirm="Are you sure you want to mark this order delivered?"
          onClick={() => onDeliver({ _id })}
          tooltip="Mark Delivered"
          displaySuccess={true}
        >
          <IconDelivered />
        </ButtonAsync>
      }
    </>
  )
}

Buttons.propTypes = {
  _id: PropTypes.string,
  onShip: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
}

Buttons.displayName = `${displayName}Buttons`

export default Buttons
