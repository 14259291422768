import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'

import UserPurchase from './UserPurchase'
import displayName from './displayName'
import { formatDollar } from '../../common/utils/formatters'

const UserDetail = props => {
  const {
    balance,
    history,
  } = props
  return (
    <>
      <CardContent>
        <Typography variant="h5">Credit {formatDollar(balance)}</Typography>
      </CardContent>
      <UserPurchase history={history} />
    </>
  )
}

UserDetail.propTypes = {
  balance: PropTypes.number,
  history: PropTypes.object.isRequired,
}


const mapStateToProps = ({
  user: { isFetching: userIsFetching, email },
  credit: { isFetching: creditIsFetching, item: { balance } }
}) => ({
  balance,
})

UserDetail.displayName = `${displayName}UserDetail`

export default connect(mapStateToProps)(UserDetail)