import React from 'react'
import Box, { BoxProps } from '@material-ui/core/Box'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Fade from '@material-ui/core/Fade'

import ScrollToOnMount from './ScrollToOnMount'

const useStyles = makeStyles((theme) => createStyles({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.breakpoints.values.md,
    flex: '1 1 auto',
    width: '100%',
  },
}))

const Main: React.FC<BoxProps> = ({
  children,
  component = 'main',
  ...rest
}) => {
  const classes = useStyles()
  return (
    <ScrollToOnMount>
      <Fade in>
        <Box
          {...rest}
          className={classes.root}
          component={component}
          py={2}
        >
          {children}
        </Box>
      </Fade>
    </ScrollToOnMount>
  )
}

export default Main
