import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import buildBackground from '../../common/utils/buildBackground'
import displayName from './displayName'
import dictionary from '../common/dictionary'
import List from './List'


const ListItem = (props) => {
  const {
    id,
    classes,
    component,
    components,
    page,
  } = props

  const { published = {} } = component
  const { style = {}, children, component: c, components: childComponents, ...rest } = published
  const background = style.background && buildBackground(style.background)
  const Comp = dictionary[c] || c
  return (
    <Comp
      {...rest}
      key={id}
      id={id}
      style={{ ...style, ...background }}
      classes={classes}
    >
      {(children && childComponents && childComponents.length) ? (
        <>
          {children}
          <List
            childComponents={childComponents}
            classes={classes}
            components={components}
            page={page}
          />
        </>
      ) : children || (
        <List
          childComponents={childComponents}
          classes={classes}
          components={components}
          page={page}
        />
      )}
    </Comp>
  )
}


ListItem.propTypes = {
  components: PropTypes.object,
  page: PropTypes.object.isRequired,
}

ListItem.displayName = `${displayName}ListItem`

export default ListItem
