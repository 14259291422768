const onDate = () => {
  const options = {
    timeZone: 'America/Los_Angeles',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }
  const date = new Date()
  const datePSTString = new Intl.DateTimeFormat('en-US', options).format(date)
  const datePST = new Date(datePSTString)

  return datePST
}

export default onDate