import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import IconAccount from '@material-ui/icons/AccountCircle'
import IconCart from '@material-ui/icons/ShoppingCart'
import IconShipping from '@material-ui/icons/LocalShipping'

import displayName from './displayName'
import Card from '../../common/components/Card'

const styles = theme => ({
  ul: {
    display: 'flex',
    flexFlow: 'row wrap',
    listStyle: 'none',
    margin: 0,
    padding: `0 ${theme.spacing(1)}px`,
  },
  cardContent: {
    paddingBottom: theme.spacing(1)
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
  }
})

const Account = props => {
  const {
    classes,
    match: { url },
    user,
    userId,
  } = props
  return (
    <div>
      <CardContent className={classes.cardContent}>
        <Typography variant="h5">Supplier Account for {user.email}</Typography>
      </CardContent>
      <ul className={classes.ul}>

        <li>
          <Card to={`${url}/profile`}>
            <CardContent>
              <Typography variant="body1">Profile</Typography>
              <div className={classes.icon}>
                <IconAccount color="primary" />
              </div>
            </CardContent>
          </Card>
        </li>

        <li>
          <Card to={`${url}/orders`}>
            <CardContent>
              <Typography variant="body1">All Orders</Typography>
              <div className={classes.icon}>
                <IconCart color="primary" />
              </div>
            </CardContent>
          </Card>
        </li>

        <li>
          <Card to={`${url}/orders/requires-shipping`}>
            <CardContent>
              <Typography variant="body1">Orders Requiring Shipping</Typography>
              <div className={classes.icon}>
                <IconShipping color="primary" />
              </div>
            </CardContent>
          </Card>
        </li>

      </ul>
    </div>
  )
}

Account.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}

Account.displayName = `${displayName}Account`

export default withStyles(styles)(Account)
