import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import CarouselBase from '../../common/components/CarouselBase'
import displayName from './displayName'
import dictionary from './dictionary'
import List from '../admin/List'
import buildBackground from '../../common/utils/buildBackground'
import cleanObj from '../../common/utils/cleanObj'

const Carousel = (props) => {
  const {
    classes,
    components,
    elevation,
    style: containerStyle,
    ...rest
  } = props
  const childrenList = props.children.filter(Boolean)
  const children = childrenList[0]
  const { onEdit, onComponentUpdateComponents, onPageUpdateComponents, isEditing } = children

  const containerBackground = containerStyle.background && buildBackground(containerStyle.background)
  return (
    <div
      {...rest}
      className={classNames(
        classes.root,
        classes.Carousel,
        containerStyle.background && containerStyle.background.backgroundImage && classes.backgroundImage,
        elevation && classes[`elevation${elevation}`],
      )}
      style={{ ...containerStyle, ...containerBackground }}
    >
      <CarouselBase isPlaying>
        {children.props.childComponents.map((id) => {
          const component = components[id]
          const { published = {}, draft = {}, isDraft, isPublished, parent } = component
          const componentValues = isDraft ? draft : published
          const { style = {}, children, component: c, components: componentValuesComponents, ...componentRest } = componentValues
          const background = style.background && buildBackground(style.background)
          const Comp = dictionary[c] || c
          return (
            <Comp
              {...componentRest}
              classes={classes}
              id={id}
              isEditing={false}
              style={{ ...style, ...background }}
              components={components}
              page={component.page}
              onClick={(e) => {
                e.stopPropagation()
                return onEdit({
                  _id: id,
                  form: 'component',
                  ...parent && { parent },
                  initialValues: { component: cleanObj({ style, children, component: c, ...rest }) },
                })
              }}

            >
              {children}
              {componentValuesComponents && componentValuesComponents.length
                ? (
                  <List
                    childComponents={componentValuesComponents}
                    classes={classes}
                    components={components}
                    onComponentUpdateComponents={onComponentUpdateComponents}
                    onEdit={onEdit}
                    onPageUpdateComponents={onPageUpdateComponents}
                    page={component.page}
                    isEditing={isEditing}
                    parent={component._id}
                  />
                )
                : null}
            </Comp>
          )
        })}
      </CarouselBase>
    </div>

  )
}

Carousel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  classes: PropTypes.object.isRequired,
  elevation: PropTypes.number,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
}

Carousel.displayName = `${displayName}Carousel`

export default Carousel
