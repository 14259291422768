import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import Account from './Account'
import AccountProfile from './AccountProfile'
import AddressesRouter from '../../address/user/Router'
import CreditUserDetail from '../../credit/user/UserDetail'
import OrderUserRouter from '../../order/user/Router'
import OrderRecipientRouter from '../../order/recipient/Router'
import Page from '../../common/components/Page'
import ReviewUserList from '../../review/user/List'
import SupplierRouter from '../../supplier/user/Router'
import displayName from './displayName'


function AccountRouter(props) {
  const {
    match,
    user,
    onUpdate,
    onUpdatePassword,
    onUpdateAddresses,
    onDelete
  } = props
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        {route => (
          <Page>
            <Account
              {...route}
              user={user}
            />
          </Page>
        )}
      </Route>
      <Route path={`${match.path}/addresses`}>
        {route => (
          <AddressesRouter
            {...route}
            user={user}
          />
        )}
      </Route>
      <Route path={`${match.path}/credit`}>
        {route => (
          <Page>
            <CreditUserDetail
              {...route}
            />
          </Page>
        )}
      </Route>
      <Route path={`${match.path}/deliveries`}>
        {route => (
          <OrderRecipientRouter
            {...route}
            user={user}
          />
        )}
      </Route>
      <Route path={`${match.path}/orders`}>
        {route => (
          <OrderUserRouter
            {...route}
            user={user}
          />
        )}
      </Route>
      <Route exact path={`${match.path}/profile`}>
        {route => (
          <Page>
            <AccountProfile
              {...route}
              user={user}
              onUpdate={onUpdate}
              onUpdatePassword={onUpdatePassword}
              onUpdateAddresses={onUpdateAddresses}
              onDelete={onDelete}
            />
          </Page>
        )}
      </Route>
      <Route exact path={`${match.path}/reviews`}>
        {route => (
          <Page>
            <ReviewUserList
              {...route}
              user={user}
            />
          </Page>
        )}
      </Route>
      <Route path={`${match.path}/suppliers`}>
        {route => (
          <SupplierRouter
            {...route}
            user={user}
          />
        )}
      </Route>
    </Switch>
  )
}

AccountRouter.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}

AccountRouter.displayName = `${displayName}AccountRouter`

export default AccountRouter
