import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import IconButton from '@material-ui/core/IconButton'

import Loading from '../common/components/Loading'

const styles = (theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
  },
  list: {
    margin: `-${theme.spacing(0.25)}px`,
    [theme.breakpoints.up('sm')]: {
      margin: `-${theme.spacing(0.75)}px`,
    },
    WebkitOverflowScrolling: 'touch',
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    scrollBehavior: 'smooth',
    willChange: 'transform',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  vertical: {
    flexFlow: 'row wrap',
    justifyContent: 'space-around',
  },
  horizontal: {
    alignItems: 'center',
    flexFlow: 'row nowrap',
    overflowY: 'auto',
  },
  more: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  moreVertical: {
    flex: '1 1 100%',
  },
  btnContainer: {
    position: 'absolute',
    top: 0,
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    zIndex: 1,
  },
  chevronLeft: {
    left: 0,
  },
  chevronRight: {
    right: 0,
  },
})

class Pagination extends PureComponent {
  constructor(props) {
    super(props)
    this.ticking = false
    this.list = React.createRef()
    this.listEnd = React.createRef()
    this.state = {
      isIndicators: false,
    }
  }

  handleScrollUpdate = () => {
    this.ticking = false
    const {
      direction,
      count,
      error,
      isFetching,
      onFetch,
      lastItem,
    } = this.props
    const list = this.list.current
    const listEnd = this.listEnd.current.getBoundingClientRect()
    const listOffsetLeft = window.innerWidth >= list.offsetWidth ? (window.innerWidth - list.offsetWidth) / 2 : 0
    const listWidth = listOffsetLeft + list.offsetWidth
    const isVisible = direction === 'horizontal'
      ? listEnd.x - listEnd.width - 16 <= listWidth
      : listEnd.y <= window.innerHeight
    // console.log({
    //   isVisible,
    //   listEndX: listEnd.x,
    //   listEndWidth: listEnd.width,
    //   listWidth: listWidth
    // })

    if (isVisible && (count > 0) && !isFetching && !error) {
      onFetch(lastItem)
    }
  }

  handleScroll = () => {
    if (!this.ticking) {
      this.ticking = true
      requestAnimationFrame(this.handleScrollUpdate)
    }
  }

  handleNext = () => this.list.current.scrollLeft += this.list.current.offsetWidth

  handlePrevious = () => this.list.current.scrollLeft -= this.list.current.offsetWidth

  handleResizeUpdate = () => {
    this.ticking = false
    if (this.props.direction === 'horizontal') {
      const isIndicators = this.list.current.scrollWidth > this.list.current.offsetWidth
      if (isIndicators !== this.state.isIndicators) {
        this.setState({ isIndicators })
      }
    }
  }

  handleResize = () => {
    if (!this.ticking) {
      this.ticking = true
      requestAnimationFrame(this.handleResizeUpdate)
    }
  }

  componentDidMount() {
    const { direction } = this.props
    if (direction === 'horizontal') {
      window.addEventListener('resize', this.handleResize, false)
      window.addEventListener('orientationchange', this.handleResize, false)
      this.list.current.addEventListener('scroll', this.handleScroll, true)
      const isIndicators = this.list.current.scrollWidth > this.list.current.offsetWidth
      return this.setState({ isIndicators }, this.handleResizeUpdate)
    }
    window.addEventListener('scroll', this.handleScroll, false)
  }

  componentWillUnmount() {
    const { direction } = this.props
    if (direction === 'horizontal') {
      window.removeEventListener('resize', this.handleResize, false)
      window.removeEventListener('orientationchange', this.handleResize, false)
      return this.list.current.removeEventListener('scroll', this.handleScroll, false)
    }
    window.removeEventListener('scroll', this.handleScroll, false)
  }

  renderIcon = () => {
    const {
      count,
      direction,
      isFetching,
      onFetch,
      lastItem,
      limit,
    } = this.props
    if (isFetching) return <Loading />
    if (direction === 'horizontal') return null
    if (count >= limit && !isFetching) {
      return (
        <IconButton
          type="button"
          variant="contained"
          onClick={() => onFetch(lastItem)}
          tooltip="Fetch More"
        >
          <IconExpandMore />
        </IconButton>
      )
    }
    return null
  }

  render() {
    const {
      children,
      className,
      count,
      items,
      lastItem,
      classes,
      limit,
      onFetch,
      direction,
      style,
    } = this.props
    const props = {
      count,
      items,
      lastItem,
      limit,
      onFetch,
    }
    return (
      <div className={classes.root} ref={this.container}>
        {this.state.isIndicators
          ? (
            <div className={classNames(classes.btnContainer, classes.chevronLeft)}>
              <IconButton onClick={this.handlePrevious} color="primary">
                <ChevronLeft />
              </IconButton>
            </div>
          )
          : null}
        <ul className={classNames(classes.list, classes[direction], className)} ref={this.list} style={style}>
          {children(props)}
          <li className={classNames(classes.more, direction === 'vertical' && classes.moreVertical)} ref={this.listEnd}>
            {this.renderIcon()}
          </li>
        </ul>
        {this.state.isIndicators
          ? (
            <div className={classNames(classes.btnContainer, classes.chevronRight)}>
              <IconButton onClick={this.handleNext} color="primary">
                <ChevronRight />
              </IconButton>
            </div>
          )
          : null}
      </div>
    )
  }
}

Pagination.propTypes = {
  children: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  direction: PropTypes.string,
  error: PropTypes.object,
  onFetch: PropTypes.func.isRequired,
  headline: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  items: PropTypes.object.isRequired,
  lastItem: PropTypes.object,
  limit: PropTypes.number.isRequired,
}

Pagination.defaultProps = {
  direction: 'vertical',
}

export default withStyles(styles)(Pagination)
