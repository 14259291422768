export const itemInitialState = {
  error: null,
  isLoading: true,
  isFetching: false,
  item: {},
}

const itemReducerFn = (
  reducerName,
  state = itemInitialState,
  action,
) => {
  switch (action.type) {
    case `${reducerName}_ADD_ITEM`: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        item: {
          ...state.item,
          ...action.item,
        },
      }
    }
    case `${reducerName}_DELETE_ITEM`: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        item: {},
      }
    }
    case `${reducerName}_ERROR`: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        isFetching: false,
      }
    }
    case `${reducerName}_SET_ITEM`: {
      return {
        ...state,
        isLoading: false,
        error: null,
        isFetching: false,
        item: action.item,
      }
    }
    case `${reducerName}_FETCHING`: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case `${reducerName}_RESET`: {
      return itemInitialState
    }
    case `${reducerName}_UPDATE_ITEM`: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        item: {
          ...state.item,
          ...action.item,
        },
      }
    }
    default:
      return state
  }
}

export default itemReducerFn
