import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconCheck from '@material-ui/icons/Check'
import IconError from '@material-ui/icons/Error'
import classNames from 'classnames'
import green from '@material-ui/core/colors/green'
import withStyles from '@material-ui/core/styles/withStyles'

import Button from './Button'

const styles = theme => ({
  root: {
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
  },
  isSubmittingContained: {
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: theme.shadows[2],
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '& svg': {
        color: theme.palette.common.white
      }
    }
  },
  isSuccessContained: {
    '&:disabled': {
      backgroundColor: green[500],
      color: theme.palette.common.white,
      boxShadow: theme.shadows[2],
      '&:hover': {
        backgroundColor: green[700],
      },
    }
  },
  isErrorContained: {
    '&:disabled': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      boxShadow: theme.shadows[2],
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    }
  },
  isSuccessText: {
    '&:disabled': {
      color: green[500],
      '&:hover': {
        color: green[700],
      },
    }
  },
  isErrorText: {
    '&:disabled': {
      color: theme.palette.error.main,
      '&:hover': {
        color: theme.palette.error.dark,
      },
    }
  },
})

class ButtonAsync extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isError: false,
      isFetching: false,
      isSuccess: false,
      timeoutId: null,
    }
    this.mounted = true
  }
  handleChildClick = () => {
    const { onClick, displaySuccess } = this.props
    this.mounted && this.setState({ isFetching: true })
    this.mounted && onClick()
      .then((res) => {
        if (this.mounted) {
          displaySuccess ? this.handleTimeout({
            isSuccess: true,
            isError: false,
            isFetching: false
          }) : this.setState({
            isFetching: false,
            isError: false
          })
          return Promise.resolve(res)
        }
        return
      })
      .catch(error => {
        this.mounted && this.handleTimeout({ isError: true, isSuccess: false, isFetching: false })
        this.mounted && Promise.reject(error)
      })
  }
  handleClick = (e) => {
    e.preventDefault()
    const { confirm } = this.props
    if (confirm) {
      if (window.confirm(confirm)) {
        return this.handleChildClick()
      }
      return Promise.resolve(false)
    }
    this.handleChildClick()
  }
  handleTimeout = (obj) => {
    this.state.timeoutId && clearTimeout(this.state.timeoutId)
    const timeoutId = setTimeout(() => {
      clearTimeout(this.state.timeoutId)
      this.mounted && this.setState({
        isError: false,
        isFetching: false,
        isSuccess: false,
        timeoutId: null,
      })
    }, 3000)
    return this.mounted && this.setState({
      ...obj,
      timeoutId,
    })
  }
  componentDidMount() {
    this.mounted = true
  }
  componentWillUnmount() {
    const { timeoutId } = this.state
    timeoutId && clearTimeout(timeoutId)
    this.mounted = false
  }
  renderChildren = () => {
    const { children, displaySuccess } = this.props
    const { isSuccess, isError, isFetching } = this.state
    if (isError) return <IconError />
    if (displaySuccess && isSuccess) return <IconCheck />
    if (isFetching) return <CircularProgress size={20} />
    return children
  }
  renderClassName = () => {
    const { classes, variant } = this.props
    const { isError, isSuccess } = this.state
    if (variant === 'contained' || variant === 'fab' || variant === 'raised') {
      if (isError) return classes.isErrorContained
      if (isSuccess) return classes.isSuccessContained
      return null
    }
    if (isError) return classes.isErrorText
    if (isSuccess) return classes.isSuccessText
    return null
  }
  render() {
    const {
      className,
      classes,
      disabled,
      size,
      displaySuccess,
      submitting,
      ...rest
    } = this.props
    const {
      isError,
      isFetching,
      isSuccess
    } = this.state
    return (
      <Button
        {...rest}
        disabled={disabled || isError || (displaySuccess ? isSuccess : false) || isFetching || submitting}
        className={classNames(classes.root, this.renderClassName(), className)}
        onClick={this.handleClick}
        size={size || 'small'}
        type="button"
      >
        {this.renderChildren()}
      </Button>
    )
  }
}

ButtonAsync.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  confirm: PropTypes.string,
  displaySuccess: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string,
  submitting: PropTypes.bool,
  variant: PropTypes.string,
}

export default withStyles(styles)(ButtonAsync)
