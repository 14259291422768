import { reducerName } from './reducers'

const theme = (state = {
  isFetching: false,
  palette: {},
  typography: {},
}, action) => {
  switch (action.type) {
    case `ADD_${reducerName}`:
      return {
        ...state,
        isFetching: false,
        ...action.item
      }
    case `DELETE_${reducerName}`:
      return {
        ...state,
        isFetching: false,
        ...state.items
      }
    case `ERROR_${reducerName}`:
      return {
        ...state,
        isFetching: false,
      }
    case `RECEIVE_${reducerName}`:
      return {
        ...state,
        isFetching: false,
        ...action.item
      }
    case `REQUEST_${reducerName}`:
      return {
        ...state,
        isFetching: true
      }
    case `UPDATE_${reducerName}`:
      return {
        ...state,
        isFetching: false,
        ...action.item
      }
    default:
      return state
  }
}

export default theme
