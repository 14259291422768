import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import displayName from './displayName'
import ListVertical from './ListVertical'
import DetailContainer from './DetailContainer'
import Page from '../../common/components/Page'


const Router = (props) => {
  const { match } = props
  return (
    <Switch>
      <Route exact path={match.path}>
        {route => (
          <Page style={{ paddingTop: 0 }}>
            <ListVertical
              key={match.path}
              {...props}
              {...route}
            />
          </Page>
        )}
      </Route>
      <Route exact path={`${match.path}/:slug`}>
        {route => (
          <Page>
            <DetailContainer
              {...props}
              {...route}
              key={route.match.params.slug}
              rootUrl={match.path}
            />
          </Page>
        )}
      </Route>
    </Switch>
  )
}

Router.displayName = `${displayName}Router`

Router.propTypes = {
  match: PropTypes.object.isRequired,
}

export default Router