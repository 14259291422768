import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

import ButtonAsync from '../common/components/ButtonAsync'
import DiscountCodeField from './common/DiscountCodeField'
import FieldCreditCard from '../creditCard/common/FieldCreditCard'
import FieldList from '../fields/FieldList'
import FieldPhone from '../fields/FieldPhone'
import FieldText from '../fields/FieldText'
import GiftCardField from './common/GiftCardField'
import ScrollToTopOnMount from '../common/components/ScrollToTopOnMount'
import displayName from './displayName'
import { getTotal } from './utils'
import { validateRequired, validateEmail } from '../common/utils/validators'
import CreditSelect from '../credit/common/CreditSelect'

const styles = {
  buttons: {
    justifyContent: 'flex-end',
  },
}

function Payment(props) {
  const {
    appTax,
    cart = {},
    classes,
    credit,
    deliveryAddress = {},
    giftCard,
    handleSubmit,
    invalid,
    onBack,
    onNext,
    paymentAddress = {},
    submitting,
  } = props
  const total = getTotal({
    credit,
    cart,
    deliveryAddress,
    tax: appTax,
    giftCard,
  })
  return (
    <ScrollToTopOnMount>
      <form>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Payment Details
          </Typography>
          <FieldList>
            <Field component={FieldText} label="First Name" name="paymentAddress.firstName" validate={validateRequired} />
            <Field component={FieldText} label="Last Name" name="paymentAddress.lastName" validate={validateRequired} />
            <FieldPhone component={FieldText} label="Phone" name="paymentAddress.phone" validate={validateRequired} fullWidth />
            <Field component={FieldText} label="Email" name="paymentAddress.email" validate={[validateRequired, validateEmail]} />
            <Field component={DiscountCodeField} name="discount" />
            <Field component={GiftCardField} name="giftCard" total={total} email={paymentAddress.email} />
            {credit._id ? <CreditSelect name="creditAmount" label="Select Credit To Apply" max={credit.balance} /> : null}
            {total.totalDue <= 0 ? null : <Field component={FieldCreditCard} name="creditCard" label="Credit Card" />}
          </FieldList>
        </CardContent>
        <CardActions className={classes.buttons}>
          <Button onClick={onBack}>Back</Button>
          <ButtonAsync
            disabled={submitting || invalid}
            variant="contained"
            color="primary"
            onClick={handleSubmit(onNext)}
            type="submit"
          >
            Next
          </ButtonAsync>
        </CardActions>
      </form>
    </ScrollToTopOnMount>
  )
}

Payment.defaultProps = {
  giftCard: undefined,
}

Payment.propTypes = {
  appTax: PropTypes.instanceOf(Object).isRequired,
  cart: PropTypes.instanceOf(Object).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  credit: PropTypes.instanceOf(Object).isRequired,
  deliveryAddress: PropTypes.instanceOf(Object).isRequired,
  giftCard: PropTypes.instanceOf(Object),
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  paymentAddress: PropTypes.instanceOf(Object).isRequired,
  submitting: PropTypes.bool.isRequired,
}

const selector = formValueSelector('checkout')

const mapStateToProps = (state) => {
  const {
    deliveryAddress,
    giftCard,
    paymentAddress,
  } = selector(state,
    'deliveryAddress',
    'giftCard',
    'paymentAddress')
  return {
    deliveryAddress,
    giftCard,
    paymentAddress,
  }
}

Payment.displayName = `${displayName}Payment`

export default connect(mapStateToProps)(reduxForm({
  form: 'checkout', // Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withStyles(styles)(Payment)))
