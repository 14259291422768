function createActions(reducerName) {
  return {
    ADD_ITEM: `${reducerName}_ADD_ITEM`,
    ADD_ITEMS: `${reducerName}_ADD_ITEMS`,
    DELETE_ITEM: `${reducerName}_DELETE_ITEM`,
    DELETE_ITEMS: `${reducerName}_DELETE_ITEMS`,
    ERROR: `${reducerName}_ERROR`,
    FETCHING: `${reducerName}_FETCHING`,
    RESET: `${reducerName}_RESET`,
    SET_ACTIONS: `${reducerName}_SET_ACTIONS`,
    SET_ITEM: `${reducerName}_SET_ITEM`,
    SET_ITEMS: `${reducerName}_SET_ITEMS`,
    UPDATE_ITEM: `${reducerName}_UPDATE_ITEM`,
    UPDATE_ITEMS: `${reducerName}_UPDATE_ITEMS`,
  }
}

export default createActions
