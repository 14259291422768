function createNamedWrapperReducer({ reducerFunction, reducerName }) {
  return (state, action) => {
    const { type } = action
    const isInitializationCall = state === undefined
    if (!type.startsWith(`${reducerName}_`) && !isInitializationCall) return state
    return reducerFunction(reducerName, state, action)
  }
}

export default createNamedWrapperReducer
