import React, { useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

import displayName from './displayName'
import Form from '../common/Form'
import Rating from '../../rating/Rating'
import Buttons from './Buttons'
import getImageSrc from '../../image/common/getImageSrc';
import { reviewUserUpdate } from './api'

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    padding: '8px 8px 16px',
  },
  imageContainer: {
    flex: '1 1 100px',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    margin: '8px 8px 16px',
  },
  image: {
    maxWidth: '100%',
    minWidth: '100%',
    verticalAlign: 'top',
    width: '100%',
    boxShadow: theme.shadows[2]
  },
  textContainer: {
    flex: '18 18 auto',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    padding: 8,
    flex: '12 12 auto',
  },
  author: {
    padding: 8,
    flex: '1 1 auto',
  },
  rating: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    padding: 8,
    flex: '1 1 auto',
  },
  date: {
    marginRight: 8,
    paddingTop: 2
  },
  isPublished: {
    padding: 8,
    flex: '1 1 auto',
  },
  text: {
    flex: '1 1 auto',
    padding: 8
  },
  form: {
    flex: '1 1 auto',
  },
})


const ListItem = props => {
  const [isOpen, setOpen] = useState(false)
  const {
    classes,
    history,
    onDelete,
    onUpdate,
    review,
    shop,
    user,
  } = props
  const handleNavigation = () => {
    return history.push(`/${shop.slug}/${review.onDocSlug}`)
  }
  const handleSubmit = values => {
    return onUpdate({
      ...values,
      _id: review._id,
      onDocName: review.onDocName,
    }).then(() => setOpen(false)).catch(() => setOpen(false))
  }
  return (
    <Card className={classes.root} elevation={0}>
      <div className={classes.imageContainer} onClick={handleNavigation}>
        {review.onDocImageSrc &&
          <img
            alt={review.onDocName}
            src={getImageSrc(review.onDocImageSrc)}
            className={classes.image}
          />
        }
      </div>
      <div className={classes.textContainer}>
        {isOpen ?
          <Form
            form={`review${review._id}`}
            initialValues={{ rating: review.rating, text: review.text }}
            onSubmit={handleSubmit}
            onClose={() => setOpen(false)}
          />
          :
          <div>
            <div className={classes.header}>
              <Typography variant="body1" className={classes.name}>{review.onDocName}</Typography>
              <Typography className={classes.author}>by {review.firstName} {review.lastName}</Typography>
              <div className={classes.rating}>
                <Typography className={classes.date}>{moment(review.createdAt).format("MMMM Do, YYYY")}</Typography>
                <Rating stars={review.rating} reviews={null} />
              </div>
              <div className={classes.rating}>
                <Typography className={classes.isPublished}>{review.isPublished ? 'Published' : 'Not Published'}</Typography>
                <Buttons
                  onEdit={() => setOpen(!isOpen)}
                  onDelete={onDelete}
                  review={review}
                  user={user}
                />
              </div>
            </div>
            <Typography className={classes.text}>{review.text}</Typography>
          </div>
        }
      </div>
    </Card>
  )
}


ListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  review: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}

ListItem.displayName = `${displayName}ListItem`

export default withStyles(styles)(ListItem)
