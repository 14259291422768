import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import withStyles from '@material-ui/core/styles/withStyles'
import { injectStripe } from 'react-stripe-elements'
import { untouch } from 'redux-form'

import StripeCardElement from './StripeCardElement'

const styles = theme => ({
  root: {
    flex: '1 1 100%',
    padding: `${theme.spacing(1) / 2}px ${theme.spacing(1)}px`,
  },
  label: {
    fontSize: 12,
  },
  error: {
    color: theme.palette.error.main,
  },
  active: {
    color: theme.palette.primary.main,
  },
  inputLabel: {
    '&:focused': {
      display: 'none',
    },
  },
})

async function onBlur({ e, input, stripe }) {
  try {
    const { token, error } = await stripe.createToken()
    if (error) throw error
    input.onChange({ stripeToken: token.id })
    return token
  } catch (error) {
    return error
  }
}

function StripeCardInput(props) {
  const {
    classes,
    input,
    label,
    stripe,
    meta,
  } = props
  const {
    dispatch,
    form,
    active,
    error,
    submitError,
    touched,
  } = meta

  useEffect(() => () => {
    input.onChange(null)
    dispatch(untouch(form, input.name))
  }, [])

  const isError = !!(touched && (error || submitError))
  const inputProps = isError ? {
    className: classes.error,
    'aria-label': label,
  } : null
  const inputLabelFocused = !isError && !active ? { color: 'rgba(0,0,0,0)' } : null

  return (
    <div className={classes.root}>
      <FormControl fullWidth>
        <InputLabel
          error={isError}
          focused={active}
          shrink={active || isError || input.value.empty}
          style={{ ...inputLabelFocused }}
        >
          {label}
        </InputLabel>
        <Input
          error={isError}
          fullWidth
          inputComponent={StripeCardElement}
          inputProps={{ ...inputProps, isError }}
          onBlur={e => onBlur({ e, input, stripe })}
          onChange={input.onChange}
          onFocus={input.onFocus}
        />
        <FormHelperText
          id={label}
          className={classes.error}
        >
          {isError ? error || submitError : ' '}
        </FormHelperText>
      </FormControl>
    </div>
  )
}

StripeCardInput.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
}

export default injectStripe(withStyles(styles)(StripeCardInput))
