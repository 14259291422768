import React, { useEffect } from 'react'

const ScrollToOnMount: React.FC = ({ children }) => {
  const { location, scrollTo } = window

  useEffect(() => {
    if (!location.hash) {
      scrollTo(0, 0)
    } else {
      const id = window.location.hash.replace('#', '')
      const el = document.getElementById(id)
      if (el) {
        el.scrollIntoView()
      }
    }
  }, [location.hash, location.pathname, scrollTo])

  return <>{children}</>
}

export default ScrollToOnMount
