import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

import SnackbarContext from '../../snackbar/SnackbarContext'
import displayName from './displayName'
import Form from '../common/Form'

const Update = ({
  onClose,
  onDocName,
  onUpdateParentSuccess,
  onUpdate,
  review = {},
}) => {
  const { onSnackOpen } = useContext(SnackbarContext)
  const { rating, text } = review

  const handleSubmit = useCallback((values) => onUpdate({
    ...values,
    _id: review._id,
    href: window.location.href,
    onDocName,
    populateItem: false,
  })
    .then(({ blog, product, review }) => {
      if (values.rating < 3) {
        onSnackOpen({ severity: 'success', message: 'Review update submitted!' })
      }
      onUpdateParentSuccess(blog || product)
      onClose()
    })
    .catch(() => onClose()), [onClose, onDocName, onSnackOpen, onUpdate, onUpdateParentSuccess, review._id])

  return (
    <Form
      form={`review${review._id}`}
      initialValues={{ rating, text }}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  )
}

Update.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDocName: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  review: PropTypes.object.isRequired,
}


Update.displayName = `${displayName}Update`

export default Update
