import createActions from '../../redux/createActions'
import fetchApi from '../../fetch/fetchApi'

import { onDialog } from '../../dialogs/actions'
import { onReset as onAddressReset } from '../../address/user/actions'
import { onFindOneSuccess as onCreditFindOneSuccess, onAddSuccess as onCreditAddSuccess } from '../../credit/user/actions'
import {
  userFindOne,
  userUpdate,
  userUpdatePassword,
  userUpdateAddresses,
  userDelete,
} from './api'
import { reducerName } from './reducers'

const route = 'users'


const {
  ADD,
  DELETE,
  ERROR,
  RECEIVE,
  REQUEST,
  RESET,
  UPDATE,
} = createActions(reducerName)

const CONTACT = `CONTACT_${reducerName}`

const onError = (error) => ({ type: ERROR, error })
export const onRequest = () => ({ type: REQUEST })


// QUERIES
export const onFindOneSuccess = (item) => ({ type: RECEIVE, item })
export const onFindOne = (input) => async (dispatch) => {
  dispatch(onRequest())
  const item = await userFindOne(input).catch((error) => {
    localStorage.removeItem('x-access-token')
    localStorage.removeItem('x-refresh-token')
    dispatch({ type: DELETE })
    dispatch(onError(error))
    return Promise.reject(error)
  })
  dispatch(onFindOneSuccess(item))
}


// MUTATIONS
export const onUpdateSuccess = (item) => ({ type: UPDATE, item })
export const onUpdate = (input) => async (dispatch) => {
  const item = await userUpdate(input).catch((error) => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onUpdateSuccess(item))
}
export const onUpdatePassword = (input) => async (dispatch) => {
  const item = await userUpdatePassword(input).catch((error) => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onUpdateSuccess(item))
}
export const onUpdateAddresses = (input) => async (dispatch) => {
  const item = await userUpdateAddresses(input).catch((error) => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onUpdateSuccess(item))
}


const onDeleteSuccess = () => ({ type: DELETE })
export const onDelete = (input) => async (dispatch) => {
  const item = await userDelete(input).catch((error) => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  localStorage.removeItem('x-access-token')
  localStorage.removeItem('x-refresh-token')
  return dispatch(onDeleteSuccess())
}


export const onSignup = (values) => async (dispatch) => {
  await fetchApi({
    auth: false,
    body: values,
    method: 'POST',
    endpoint: route,
  })
}


const onAddSuccess = (item) => ({ type: ADD, item })
export const onAdd = (values) => async (dispatch) => {
  const { user, credit } = await fetchApi({
    auth: false,
    body: values,
    method: 'POST',
    endpoint: `${route}/add/${values.token}`,
  })
  dispatch(onCreditAddSuccess(credit))
  return dispatch(onAddSuccess(user))
}


export const onSignin = (values) => async (dispatch) => {
  const json = await fetchApi({
    auth: false,
    body: values,
    method: 'POST',
    endpoint: `${route}/signin`,
  })
  const { user, credit } = json
  dispatch(onCreditFindOneSuccess(credit))
  return dispatch(onFindOneSuccess(user))
}


export const onSignout = () => async (dispatch) => {
  const json = await fetchApi({
    auth: true,
    body: null,
    method: 'POST',
    endpoint: `${route}/signout`,
  })
  localStorage.removeItem('x-access-token')
  localStorage.removeItem('x-refresh-token')
  dispatch(onAddressReset())
  return dispatch(onDeleteSuccess())
}


export const onRecovery = ({ email }) => async (dispatch) => {
  const json = await fetchApi({
    auth: false,
    body: { email },
    method: 'POST',
    endpoint: `${route}/recovery`,
  })
  return dispatch(onDialog({ title: 'Recovery', content: `A recovery email has been sent to ${email}` }))
}


export const onReset = ({ password, resetToken }) => async (dispatch) => {
  const json = await fetchApi({
    auth: false,
    body: { password },
    method: 'POST',
    endpoint: `${route}/reset/${resetToken}`,
  })
  return dispatch(onFindOneSuccess(json))
}


const onContactSuccess = (values) => ({ type: CONTACT, values })
export const onContact = (values) => async (dispatch) => {
  const json = await fetchApi({
    auth: false,
    body: values,
    method: 'POST',
    endpoint: `${route}/contact`,
  })
  dispatch(onContactSuccess(json))
}
