import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'

import { onAuth } from '../../auth/actions'
import Add from './Add'
import Filter from '../../filter/Filter3'
import ListItem from './ListItem'
import Loading from '../../common/components/Loading'
import ULVerticalBase from '../../common/components/ULVerticalBase'
import displayName from './displayName'
import filter from './filter'
import useReviews from './useReviews'
import usePaginateVertical from '../../common/hooks/usePaginateVertical'

import {
  reviewAdd,
  reviewUpdate,
  reviewFind,
  reviewDelete,
  reviewDeleteAdmin,
  reviewUpdateLikes,
} from './api'

const styles = (theme) => ({
  page: {
    paddingTop: 0,
  },
  cardContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
})

const ListVertical = ({
  classes,
  onAuth,
  onDoc,
  onDocName,
  onModel,
  onUpdateParentSuccess,
  reviewCount,
  user,
}) => {
  const {
    count,
    isFetching,
    isLoading,
    items,
    onAdd,
    onDelete,
    onDeleteAdmin,
    onFilter,
    onFind,
    onUpdate,
    onUpdateLikes,
    input,
  } = useReviews({
    onFind: reviewFind,
    onAdd: reviewAdd,
    onUpdate: reviewUpdate,
    onUpdateLikes: reviewUpdateLikes,
    onDelete: reviewDelete,
    onDeleteAdmin: reviewDeleteAdmin,
    onUpdateParentSuccess,
    input: { onDoc, onModel },
  })

  const { ulRef, ulEndRef, isVisible } = usePaginateVertical()

  useEffect(() => {
    if (isVisible && count > 0) onFind({})
  }, [count, isVisible, onFind])

  if (isLoading) return <Loading />
  return (
    <div>
      <Add
        onModel={onModel}
        onDoc={onDoc}
        onDocName={onDocName}
        onAdd={onAdd}
        onAuth={onAuth}
        user={user}
      />
      <CardContent className={classes.cardContent}>
        <Filter
          count={count}
          onFilter={onFilter}
          filterOptions={filter.filterOptions}
          filterDefaults={filter.filterDefaults}
        />
      </CardContent>
      <ULVerticalBase ulRef={ulRef} ulEndRef={ulEndRef} isFetching={isFetching} onFind={onFind}>
        {Object.keys(items).map((_id) => (
          <ListItem
            key={_id}
            onAuth={onAuth}
            onDelete={onDelete}
            onDeleteAdmin={onDeleteAdmin}
            onDoc={onDoc}
            onModel={onModel}
            onDocName={onDocName}
            onUpdate={onUpdate}
            onUpdateLikes={onUpdateLikes}
            review={items[_id]}
            user={user}
          />
        ))}
      </ULVerticalBase>
    </div>
  )
}

ListVertical.propTypes = {
  classes: PropTypes.object.isRequired,
  onDoc: PropTypes.string.isRequired,
  onDocName: PropTypes.string.isRequired,
  onModel: PropTypes.string.isRequired,
  onUpdateParentSuccess: PropTypes.func.isRequired,
  onAuth: PropTypes.func.isRequired,
  user: PropTypes.object,
}

const mapStateToProps = ({ user }) => ({ user })
const mapDispatchToProps = (dispatch) => ({
  onAuth: () => dispatch(onAuth()),
})

ListVertical.displayName = `${displayName}ListVertical`

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListVertical))
