import logError from '../error/logError'
import onError from '../error/onError'

const API_ROOT = process.env.REACT_APP_API_ENDPOINT
const appName = process.env.REACT_APP_NAME

const fetchApi = async ({
  body: fetchBody,
  method = 'POST',
  endpoint = 'graphql',
}) => {
  try {
    const body = fetchBody && { body: JSON.stringify(fetchBody) }
    const xAccess = localStorage.getItem('x-access-token') && { 'x-access-token': localStorage.getItem('x-access-token') }
    const xRefresh = localStorage.getItem('x-refresh-token') && { 'x-refresh-token': localStorage.getItem('x-refresh-token') }
    const requestBody = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...xAccess,
        ...xRefresh,
      },
      ...body,
    }
    const fullUrl = `${API_ROOT}/${appName}/${endpoint}`
    const res = await fetch(fullUrl, requestBody, body)
    const json = await res.json()
    if (!res.ok || json.errors) {
      throw json
    }
    const accessToken = res.headers.get('x-access-token')
    const refreshToken = res.headers.get('x-refresh-token')
    const cartId = res.headers.get('cartId')
    if (accessToken) localStorage.setItem('x-access-token', accessToken)
    if (refreshToken) localStorage.setItem('x-refresh-token', refreshToken)
    if (cartId) localStorage.setItem('cartId', cartId)
    return json
  } catch (error) {
    logError(error)
    onError(error)
    return Promise.reject(error)
  }
}

export default fetchApi
