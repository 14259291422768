import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { AppProps } from '../app/types'

interface HeadProps {
  name: string,
  description: string,
}

const Head: React.FC<HeadProps> = ({
  name,
  description,
}) => {
  const brandName = useSelector(({ app }: { app: AppProps }) => app.brand.name.children)
  return (
    <Helmet>
      <title>{`${brandName} - ${name}`}</title>
      {description ? <meta name="description" content={description} /> : null}
      <link rel="canonical" href={window.location.pathname} />
      <meta property="og:title" content={`${brandName} - ${name}`} />
      {description ? <meta property="og:description" content={description} /> : null}
      <meta property="og:url" content={window.location.pathname} />
    </Helmet>
  )
}

export default Head
