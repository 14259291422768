import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'

import ULHorizontalBase from '../../common/components/ULHorizontalBase'
import ListItem from './ListItem'
import displayName from './displayName'
import usePaginateHorizontal from '../../common/hooks/usePaginateHorizontal'
import { onFind } from './actions'
import useReducer from '../../common/hooks/useReducer'
import { itemsReducer } from './reducers'


const ListHorizontal = ({ shop, input }) => {
  const [state, dispatch] = useReducer(itemsReducer)
  const { ulRef, ulEndRef, isVisible } = usePaginateHorizontal()

  const {
    items = {},
    lastItem,
    isLoading,
    isFetching,
    count,
  } = state

  useEffect(() => {
    dispatch(onFind({ ...input, lastItem, sort: 'name-asc' }))
  }, [])

  useEffect(() => {
    if (isVisible && count > 0) dispatch(onFind({ ...input, lastItem, sort: 'name-asc' }))
  }, [isVisible])

  return (
    <ULHorizontalBase
      isFetching={isFetching}
      onFind={() => dispatch(onFind({ ...input, lastItem, sort: 'name-asc' }))}
      ulEndRef={ulEndRef}
      ulRef={ulRef}
    >
      {Object.keys(items).map(_id => (
        <ListItem key={_id} item={items[_id]} shop={shop} />
      ))}
    </ULHorizontalBase>
  )
}

ListHorizontal.propTypes = {
  shop: PropTypes.instanceOf(Object).isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
}

const mapStateToProps = ({
  app: { isFetching, shop },
}) => ({
  isFetching,
  shop,
})

ListHorizontal.displayName = `${displayName}ListHorizontal`

export default connect(mapStateToProps)(ListHorizontal)
