import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const GithubIcon = (props) => (
  <SvgIcon
    width="1792"
    height="1792"
    viewBox="0 0 1792 1792"
    {...props}
  >
    <path d="M647 1200q4-6-3-13-9-7-14-2-4 6 3 13 9 7 14 2zm-28-41q-5-7-12-4-6 4 0 12 7 8 12 5 6-4 0-13zm-41-40q2-4-5-8-7-2-8 2-3 5 4 8 8 2 9-2zm21 23q2-1 1.5-4.5t-3.5-5.5q-6-7-10-3t1 11q6 6 11 2zm86 75q2-7-9-11-9-3-13 4-2 7 9 11 9 3 13-4zm42 3q0-8-12-8-10 0-10 8t11 8 11-8zm39-7q-2-7-13-5t-9 9q2 8 12 6t10-10zm642-317q0-212-150-362t-362-150-362 150-150 362q0 167 98 300.5t252 185.5q18 3 26.5-5t8.5-20q0-52-1-95-6 1-15.5 2.5t-35.5 2-48-4-43.5-20-29.5-41.5q-23-59-57-74-2-1-4.5-3.5l-8-8-7-9.5 4-7.5 19.5-3.5q6 0 15 2t30 15.5 33 35.5q16 28 37.5 42t43.5 14 38-3.5 30-9.5q7-47 33-69-49-6-86-18.5t-73-39-55.5-76-19.5-119.5q0-79 53-137-24-62 5-136 19-6 54.5 7.5t60.5 29.5l26 16q58-17 128-17t128 17q11-7 28.5-18t55.5-26 57-9q29 74 5 136 53 58 53 137 0 57-14 100.5t-35.5 70-53.5 44.5-62.5 26-68.5 12q35 31 35 95 0 40-.5 89t-.5 51q0 12 8.5 20t26.5 5q154-52 252-185.5t98-300.5zm256-480v960q0 119-84.5 203.5t-203.5 84.5h-960q-119 0-203.5-84.5t-84.5-203.5v-960q0-119 84.5-203.5t203.5-84.5h960q119 0 203.5 84.5t84.5 203.5z" />
  </SvgIcon>
)

export default GithubIcon
