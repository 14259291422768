import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'

import buildBackground from '../common/utils/buildBackground'

const styles = theme => ({
  root: {
    backgroundRepeat: 'no-repeat !important',
    backgroundSize: 'cover !important',
    width: '100%',
  }
})

const Background = (props) => {
  const {
    image,
    children,
    classes,
    className,
    style,
  } = props
  const backgroundStyle = image ? { ...style, ...buildBackground(image) } : { ...style }
  return (
    <div className={classNames(classes.root, className)}
      style={backgroundStyle}
    >
      {children}
    </div>
  )
}

Background.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  image: PropTypes.object,
  style: PropTypes.object,
}

export default withStyles(styles)(Background)
