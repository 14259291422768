import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'


const styles = {
  root: {
    margin: '0 0 2px 8px'
  }
}


const ActionButton = ({
  classes,
  actionButton: {
    href,
    color,
    children
  },
}) => {
  if (children) {
    const hrefProps = href.includes('http') ? {
      component: 'a',
      href,
    } : {
      component: Link,
      to: href
    }
    return (
      <Button
        color={color}
        variant="contained"
        size="small"
        className={classes.root}
        {...hrefProps}
      >
        {children}
      </Button>
    )
  }
  return null
}


ActionButton.propTypes = {
  classes: PropTypes.object.isRequired,
  href: PropTypes.string,
  actionButton: PropTypes.object.isRequired,
}

export default withStyles(styles)(ActionButton)
