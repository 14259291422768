import fetchApi from '../fetch/fetchApi'
import createActions from '../redux/createActions'
import * as themeActions from '../theme/actions'
import { reducerName } from './reducers'

import cleanNull from '../common/utils/cleanNull'

import { onRequest as categoryRequest, onFindSuccess as categoryFindSuccess } from '../category/user/actions'
import { onFetch as componentFetch, onSetItems as componentSetItems } from '../component/public/actions'
import { onRequest as creditRequest, onFindOneSuccess as creditFindOneSuccess } from '../credit/user/actions'
import { onFetch as pageFetch, onSetItems as pageSetItems } from '../page/public/actions'
import { onRequest as priceRuleRequest, onFindSuccess as priceRuleFindSuccess } from '../priceRule/actions'
import { onRequest as userRequest, onFindOneSuccess as userFindOneSuccess } from '../user/user/actions'
import { onRequest as themeRequest, onFindOneSuccess as themeFindOneSuccess } from '../theme/actions'

const route = 'apps'

const {
  ADD,
  DELETE,
  RECEIVE,
  REQUEST,
  UPDATE,
} = createActions(reducerName)

export const onRequest = () => ({ type: REQUEST })

// Create
const onAddSuccess = item => ({ type: ADD, item })
export const onAdd = add => (dispatch, getState) => {
  dispatch(themeActions.onRequest())
  return fetchApi({
    auth: true,
    body: add,
    method: 'POST',
    endpoint: route,
  })
    .then((json) => {
      const {
        app,
        theme,
      } = json
      dispatch(themeActions.onAddSuccess(theme))
      return dispatch(onAddSuccess(app))
    })
}


// Read
const onFetchSuccess = item => ({ type: RECEIVE, item })
export const onFetch = () => (dispatch, getState) => {
  dispatch(onRequest())
  return fetchApi({
    auth: !!localStorage.getItem('x-access-token'),
    body: null,
    method: 'GET',
    endpoint: route,
  })
    .then(json => dispatch(onFetchSuccess(json)))
}

export function onFetchApp() {
  return (dispatch, getState) => {
    dispatch(categoryRequest())
    dispatch(componentFetch())
    dispatch(onRequest())
    dispatch(pageFetch())
    dispatch(priceRuleRequest())
    if (localStorage.getItem('x-refresh-token')) {
      dispatch(userRequest())
      dispatch(creditRequest())
    }
    return fetchApi({
      body: null,
      method: 'GET',
      endpoint: `${route}/app`,
    })
      .then((json) => {
        const {
          app,
          categories,
          components,
          credit,
          pages,
          priceRules,
          theme,
          user,
        } = json
        dispatch(onFetchSuccess(app))
        dispatch(categoryFindSuccess(categories))
        dispatch(componentSetItems(cleanNull(components)))
        dispatch(pageSetItems(cleanNull(pages)))
        dispatch(priceRuleFindSuccess(priceRules))
        if (localStorage.getItem('x-refresh-token')) {
          dispatch(userFindOneSuccess(user))
          dispatch(creditFindOneSuccess(credit))
        }
        return dispatch(themeFindOneSuccess(theme))
      })
  }
}


// Update
export const onUpdateSuccess = item => ({ type: UPDATE, item })
export const onUpdate = ({ path, update }) => (dispatch, getState) => fetchApi({
  auth: true,
  body: update,
  method: 'PATCH',
  endpoint: `${route}/${path}`,
})
  .then(json => dispatch(onUpdateSuccess(json)))


// Delete
const onDeleteSuccess = _id => ({ type: DELETE, _id })
export const onDelete = _id => (dispatch, getState) => fetchApi({
  auth: true,
  body: null,
  method: 'DELETE',
  endpoint: `${route}/${_id}`,
})
  .then(_id => dispatch(onDeleteSuccess(_id)))
