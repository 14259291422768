import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import getImageSrc from './getImageSrc'
import classNames from '../../common/utils/classNames'
import StyleProps from '../../common/types/StyleProps'

type ObjectMap = {
  [key: string]: any,
}

export interface ImageProps {
  alt: string,
  className: string,
  elevation: number,
  src: string,
  style: React.CSSProperties,
}

const useStyles = makeStyles((theme: Theme) => {
  const elevations: ObjectMap = {}
  theme.shadows.forEach((shadow, index) => {
    elevations[`elevation${index}`] = {
      boxShadow: shadow,
    }
  })
  return createStyles({
    root: {
      width: '100%',
      verticalAlign: 'top',
      height: 'auto',
    },
    ...elevations,
  })
})


const Image: React.FC<ImageProps> = ({
  alt,
  className,
  elevation,
  src,
  style,
}) => {
  const classes: ObjectMap = useStyles()
  return (
    <img
      alt={alt}
      src={getImageSrc(src)}
      className={classNames(
        classes.root,
        elevation && classes[`elevation${elevation}`],
        className,
      )}
      style={style}
    />
  )
}

export default Image
