import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import CardActionArea from '@material-ui/core/CardActionArea'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Loading from '../common/components/Loading'
import Pagination from '../list/Pagination'
import SearchListItem from './SearchListItem'
import createStyles from '../page/common/createStyles'
import * as actions from './actions'

const styles = theme => ({
  ...createStyles(theme),
  Pagination: {
    padding: theme.spacing(1),
    display: 'flex',
    flexFlow: 'column',
  },
  li: {
    margin: theme.spacing(1),
  },
  highlighted: {
    backgroundColor: theme.palette.primary.light,
  },
})


function SearchList({
  classes,
  search,
  onFetch,
  onDelete,
}) {
  return (
    <Pagination
      {...search}
      className={classes.Pagination}
      display="vertical"
      headline="Search Results"
      onFetch={onFetch}
    >
      {({ items }) => {
        const keys = Object.keys(items)
        if (keys.length) {
          return keys.map((id) => {
            const item = items[id]
            return <SearchListItem item={item} onDelete={onDelete} />
          })
        }
        if (search.isFetching) return <Loading />
        return (
          <CardActionArea>
            <Typography variant="h5">No matches</Typography>
          </CardActionArea>
        )
      }}
    </Pagination>
  )
}

SearchList.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
  pages: PropTypes.instanceOf(Object).isRequired,
  search: PropTypes.instanceOf(Object).isRequired,
}

const mapStateToProps = ({
  app: { shop },
  pages: { items: pages },
  search,
}) => ({
  pages,
  search,
  shop,
})

const mapDispatchToProps = dispatch => ({
  onDelete: () => dispatch(actions.onDelete()),
  onFetch: () => dispatch(actions.onFetch()),
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(SearchList)))
