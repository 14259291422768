import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

import ButtonMenu from '../common/components/ButtonMenu'
import getSlug from '../common/utils/getSlug'

const styles = theme => ({
  root: {
    minWidth: 'fit-content',
  },
  navLinkActive: {
    '& span:first-child': {
      borderBottom: '2px solid !important',
      width: 'inherit',
    },
  },
  button: {
    whiteSpace: 'nowrap',
  },
})

const AppBarPage = (props) => {
  const {
    classes,
    color,
    pages,
    item,
  } = props

  if (item.pages.length > 1) {
    return (
      <ButtonMenu
        style={{ color }}
        className={classes.button}
        isHover
        menuItems={item.pages.map(id => ({
          to: `/${pages[id].slug}`,
          children: pages[id].name,
        }))}
      >
        {item.name}
      </ButtonMenu>
    )
  }
  return (
    <Button
      className={classNames(classes.button, classes.pageLink)}
      style={{ color }}
      size="small"
      component={NavLink}
      to={`/${pages[item.pages[0]].slug}`}
      activeClassName={classes.navLinkActive}
    >
      {item.name}
    </Button>
  )
}


AppBarPage.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  pages: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppBarPage)
