import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import DetailBase from '../common/DetailBase'
import displayName from './displayName'
import Buttons from './Buttons'


const Detail = props => {
  return (
    <DetailBase {...props} buttons={Buttons} />
  )
}


Detail.propTypes = {
  adminUser: PropTypes.object,
}

const mapStateToProps = ({
  app: { isFetching, shop },
}) => ({
  isFetching,
  shop,
})

Detail.displayName = `${displayName}Detail`

export default connect(mapStateToProps)(Detail)
