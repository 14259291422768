import fetchGraphQL from '../../graphql/fetchGraphQL'
import { creditType } from '../common/types'

export const creditAdd = async input => {
  try {
    const { creditAdd: item } = await fetchGraphQL({
      query: `
        mutation CreditAdd($input: CreditAddInput!) {
          creditAdd(input: $input) { ${creditType} }
        }
      `,
      variables: { input }
    })
    return item
  } catch (error) {
    return Promise.reject(error)
  }
}