import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { NavLink } from 'react-router-dom'

class DrawerCollapse extends Component {
  constructor(props) {
    super(props)
    const { pathname } = window.location
    const { label } = this.props
    this.state = {
      isOpen: pathname.includes(label.toLowerCase())
    }
  }
  toggleCollapse = () => this.setState({ isOpen: !this.state.isOpen })
  render() {
    const {
      classes,
      label,
      children,
      onDrawer,
    } = this.props
    return (
      <>
        <ListItem button onClick={this.toggleCollapse} className={classes.nested}>
          <ListItemText primary={label} />
          {this.state.isAdminOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse component="li" in={this.state.isOpen} timeout="auto" unmountOnExit>
          <List disablePadding>
            {children.map(child => (
              <ListItem
                key={child.label}
                activeClassName={classes.navLinkActive}
                button
                className={classes.nested2}
                component={NavLink}
                onClick={onDrawer}
                to={child.to}
                exact
              >
                <ListItemText primary={child.label} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </>
    )
  }
}


DrawerCollapse.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawer: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
}

export default DrawerCollapse
