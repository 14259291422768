import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import IconButton from '@material-ui/core/IconButton'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'

import CrossFade from './CrossFade'

const styles = {
  root: {
    width: '100%'
  },
  crossFade: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  btnContainer: {
    position: 'absolute',
    top: 0,
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center'
  },
  chevronLeft: {
    left: 0
  },
  chevronRight: {
    right: 0
  },
  icon: {
    fontSize: 20
  },
  selectContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    width: '100%',
    flexFlow: 'row nowrap'
  }
}

const CarouselBase = props => {
  const {
    classes,
    children,
  } = props
  const [state, setState] = useState({
    length: 0,
    index: 0,
    intervalId: null,
    isPlaying: false,
  })
  useEffect(() => {
    if (props.isPlaying) {
      setState({ ...state, isPlaying: props.isPlaying })
      onStart()
    }
    return () => clearInterval(state.intervalId)
  }, [])

  const onStart = () => {
    const intervalId = setInterval(() => {
      setState(eState => ({
        ...state,
        index: eState.index < children.length - 1 ? eState.index + 1 : 0
      }))
    }, 3000)
    setState({ ...state, intervalId, isPlaying: true })
  }
  const onStop = () => {
    clearInterval(state.intervalId)
    setState({ ...state, intervalId: null, isPlaying: false })
  }
  const onSelect = i => {
    onStop()
    setState({ ...state, index: i })
  }
  const onNext = () => {
    const index = state.index < children.length - 1 ? state.index + 1 : 0
    setState({ ...state, index })
    if (state.intervalId) return
    onStart()
  }
  const onPrevious = () => {
    const index = state.index > 0 ? state.index - 1 : children.length - 1
    setState({ ...state, index })
    if (state.intervalId) return
    onStart()
  }
  return (
    <div className={classes.root}>
      <TransitionGroup>
        <CrossFade key={state.index} className={classes.crossFade}>
          {children[state.index]}
        </CrossFade>
      </TransitionGroup>
      <div>
        <div className={classNames(classes.btnContainer, classes.chevronLeft)}>
          <IconButton onClick={onPrevious}>
            <ChevronLeft />
          </IconButton>
        </div>
        <div className={classNames(classes.btnContainer, classes.chevronRight)}>
          <IconButton onClick={onNext}>
            <ChevronRight />
          </IconButton>
        </div>
        <div className={classes.selectContainer}>
          {children.map((v, i) => (
            <IconButton
              key={i}
              index={i}
              onClick={() => onSelect(i)}
            >
              {state.index === i ?
                <RadioButtonCheckedIcon className={classes.icon} />
                :
                <RadioButtonUncheckedIcon className={classes.icon} />
              }
            </IconButton>
          ))}
        </div>
      </div>
    </div>
  )
}

CarouselBase.propTypes = {
  children: PropTypes.array.isRequired,
  style: PropTypes.object,
  isPlaying: PropTypes.bool.isRequired
}


export default withStyles(styles)(CarouselBase)
