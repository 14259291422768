import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MuiButtonBase from '@material-ui/core/ButtonBase'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    display: 'block',
    textAlign: 'inherit',
    width: '100%',
    '&:hover $focusHighlight': {
      opacity: theme.palette.action.hoverOpacity,
    },
    '&$focusVisible $focusHighlight': {
      opacity: 0.12,
    },
  },
  focusHighlight: {
    pointerEvents: 'none',
    position: 'absolute',
    backgroundColor: 'currentcolor',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.short,
    }),
  },
  focusVisible: {},
})

const ButtonBase = props => {
  const {
    children,
    className,
    classes,
    href,
    style,
    onClick,
  } = props
  const hrefProps = onClick ? {} : href && href.includes('https') ? {
    component: 'a',
    href,
  } : href ? {
    component: Link,
    to: href
  } : {
        component: 'div'
      }
  return (
    <MuiButtonBase
      {...hrefProps}
      focusRipple
      onClick={onClick}
      className={classNames(classes.root, className)}
      focusVisibleClassName={classes.focusVisible}
      style={style}
    >
      {children}
      <span className={classes.focusHighlight} />
    </MuiButtonBase>
  )
}

ButtonBase.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  elevation: PropTypes.number,
  href: PropTypes.string,
  style: PropTypes.object,
}

export default withStyles(styles)(ButtonBase)
