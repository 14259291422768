import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

import displayName from './displayName'
import FieldText from '../../fields/FieldText'
import FieldPhone from '../../fields/FieldPhone'
import { parseState, parseZip } from '../../common/utils/parsers'
import { validateRequired, validateEmail } from '../../common/utils/validators'
import FieldSelectState from '../../fields/FieldSelectState'

const styles = theme => ({
  fullWidth: {
    flex: '1 1 100%'
  },
  email: {
    minWidth: 230
  },
  row: {
    flex: '1 1 100%',
    display: 'flex',
    flexFlow: 'row wrap'
  },
  stateZip: {
    flex: '1 1 100px',
    flexFlow: 'row nowrap'
  },
})


const Fields = props => {
  const {
    classes,
    name,
    label,
  } = props
  return (
    <>
      <div className={classes.row}>
        <Field
          component={FieldText}
          label={`${label ? `${label} ` : ''}First name`}
          name={`${name ? `${name}.` : ''}firstName`}
          validate={validateRequired}
        />
        <Field
          component={FieldText}
          label={`${label ? `${label} ` : ''}Last name`}
          name={`${name ? `${name}.` : ''}lastName`}
          validate={validateRequired}
        />
      </div>
      <div className={classes.row}>
        <Field
          className={classes.email}
          component={FieldText}
          label={`${label ? `${label} ` : ''}Email`}
          name={`${name ? `${name}.` : ''}email`}
          validate={[validateRequired, validateEmail]}
        />
        <FieldPhone
          component={FieldText}
          label={`${label ? `${label} ` : ''}Phone`}
          name={`${name ? `${name}.` : ''}phone`}
        />
      </div>
      <Field
        className={classes.fullWidth}
        component={FieldText}
        label="Street line 1"
        name={`${name ? `${name}.` : ''}street1`}
        validate={validateRequired}
      />
      <Field
        className={classes.fullWidth}
        component={FieldText}
        label="Street line 2"
        name={`${name ? `${name}.` : ''}street2`}
      />
      <div className={classNames(classes.row)}>
        <Field
          className={classes.city}
          component={FieldText}
          label="City"
          name={`${name ? `${name}.` : ''}city`}
          validate={validateRequired}
        />
        <div className={classNames(classes.row, classes.stateZip)}>
          <Field
            component={FieldSelectState}
            label="State"
            name={`${name ? `${name}.` : ''}state`}
            validate={validateRequired}
          />
          <Field
            component={FieldText}
            label="Zip"
            name={`${name ? `${name}.` : ''}zip`}
            parse={parseZip}
            postal-code={'postal-code'}
            validate={validateRequired}
          />
        </div>
      </div>
    </>
  )
}


Fields.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
}

Fields.displayName = `${displayName}Fields`

export default withStyles(styles)(Fields)

