import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import * as actions from './actions'
import Detail from './Detail'
import Loading from '../../common/components/Loading'
import None from '../../common/components/None'
import Page from '../../common/components/Page'
import bindActionCreators from '../../common/utils/bindActionCreators'
import displayName from './displayName'
import { itemReducer } from './reducers'
import useReducer from '../../common/hooks/useReducer'
import Update from './Update'

const UpdateRouter = props => {
  const { match, user = {} } = props
  const [state, dispatch] = useReducer(itemReducer)
  const boundActions = bindActionCreators(actions, dispatch)
  const {
    item,
    isFetching,
    isLoading,
  } = state

  useEffect(() => {
    boundActions.onFindOne({ _id: props.match.params._id })
  }, [])

  if (isLoading || isFetching) return <Loading />
  if (!item) return <None>Sorry, that order was not found</None>

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        {route => (
          <Page>
            <Detail
              {...props}
              {...route}
              {...state}
              boundActions={boundActions}
            />
          </Page>
        )}
      </Route>
      <Route exact path={`${match.path}/delivery`}>
        {route => (
          <Page>
            <Update {...props} {...route} {...state} {...boundActions} />
          </Page>
        )}
      </Route>
    </Switch>
  )
}

UpdateRouter.displayName = `${displayName}UpdateRouter`

UpdateRouter.propTypes = {
  match: PropTypes.object.isRequired,
}

export default UpdateRouter