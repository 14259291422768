import createActions from '../redux/createActions'

import {
  priceRuleAdd,
  priceRuleFind,
  priceRuleAdminFindOne,
  priceRuleUpdate,
  priceRuleDelete,
} from './api'

import { reducerName } from './reducers'

const {
  ADD,
  DELETE,
  FILTER,
  RECEIVE,
  REQUEST,
  UPDATE,
  RESET,
  ERROR,
} = createActions(reducerName)


const onError = error => ({ type: ERROR, error })
export const onRequest = () => ({ type: REQUEST })
export const onReset = () => ({ type: RESET })


export const onAddSuccess = item => ({ type: ADD, item })
export const onAdd = input => async dispatch => {
  const item = await priceRuleAdd(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  dispatch(onAddSuccess(item))
}


export const onFindSuccess = items => ({ type: RECEIVE, items })
export const onFind = input => async dispatch => {
  dispatch({ type: REQUEST })
  const items = await priceRuleFind(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onFindSuccess(items))
}

const onFindOneSuccess = item => ({ type: ADD, item })
export const onFindOne = input => async dispatch => {
  dispatch({ type: REQUEST })
  const item = await priceRuleAdminFindOne(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onFindOneSuccess(item))
}



const onUpdateSuccess = item => ({ type: UPDATE, item })
export const onUpdate = input => async dispatch => {
  const item = await priceRuleUpdate(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onUpdateSuccess(item))
}


const onDeleteSuccess = _id => ({ type: DELETE, _id })
export const onDelete = input => async dispatch => {
  const item = await priceRuleDelete(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onDeleteSuccess(item._id))
}



