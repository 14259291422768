import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import IconCancel from '@material-ui/icons/Cancel'
import withStyles from '@material-ui/core/styles/withStyles'
import FormButtonContext from './FormButtonContext'
import IconDelete from '@material-ui/icons/Delete'
import IconChevronLeft from '@material-ui/icons/ChevronLeft'
import { withRouter } from 'react-router'

import Button from '../common/components/Button'
import ButtonSubmit from '../common/components/ButtonSubmit'
import ButtonAsync from '../common/components/ButtonAsync'
import ButtonPublish from '../common/components/ButtonPublish'

const styles = {
  actions: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
  },
}

function FormButtons(props) {
  const context = useContext(FormButtonContext)
  const {
    children,
    classes,
    isDraft,
    isPublished,
    submitText,
    history,
    reset,
    pristine,
    onBack,
    onCancel,
    onDelete,
    onPublish,
    ...rest
  } = props
  return (
    <span className={classes.actions}>
      <ButtonSubmit {...rest} {...context} tooltip="Save">
        {children}
      </ButtonSubmit>
      {onPublish ?
        <ButtonPublish
          {...rest}
          onClick={onPublish}
          isDraft={isDraft}
          isPublished={isPublished}
          submitting={context.submitting}
          confirm={`Are you sure you want to ${isPublished ? 'publish' : 'unpublish'} this item`}
        />
        : null}
      {onDelete ?
        <ButtonAsync
          {...rest}
          confirm="Are you sure you want to delete this item?"
          onClick={onDelete}
          submitting={context.submitting}
          tooltip="Delete"
          confirm="Are you sure you want to delete this item?"
        >
          <IconDelete />
        </ButtonAsync>
        : null}
      <Button
        {...rest}
        disabled={context.pristine}
        onClick={context.reset}
        tooltip="Cancel"
        type="button"
      >
        <IconCancel />
      </Button>
      <Button
        {...rest}
        onClick={() => history.goBack()}
        tooltip="Back"
        type="button"
      >
        <IconChevronLeft />
      </Button>
    </span>
  )
}

FormButtons.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  isDraft: PropTypes.bool,
  isPublished: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onPublish: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default withStyles(styles)(withRouter(FormButtons))
