import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Star from '@material-ui/icons/Star'
import StarBorder from '@material-ui/icons/StarBorder'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  iconButton: {
    width: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  error: {
    marginLeft: 8,
    color: theme.palette.error.main,
  },
})

class RatingFieldAdapter extends Component {
  constructor(props) {
    super(props)
    this.state = { hoverValue: this.props.input.value }
  }
  renderIcon = (i) => {
    const { input: { value } } = this.props
    const isFilled = i <= value
    const isHovered = i <= this.state.hoverValue
    switch (true) {
      case (isHovered && !isFilled):
        return <StarBorder color="primary" />
      case (isFilled):
        return <Star color="primary" />
      default:
        return <StarBorder color="disabled" />
    }
  }
  render() {
    const {
      classes,
      input,
      meta: { error, touched },
    } = this.props
    return (
      <div className={classes.root}>
        {[1, 2, 3, 4, 5].map((s, i) => (
          <IconButton
            key={i + 1}
            classes={{
              root: classes.iconButton
            }}
            onMouseEnter={() => this.setState({ hoverValue: i + 1 })}
            onMouseLeave={() => this.setState({ hoverValue: input.value })}
            onClick={() => input.onChange(i + 1)}
          >
            {this.renderIcon(i + 1)}
          </IconButton>
        ))}
        {touched && error && <Typography className={classes.error}>{error}</Typography>}
      </div>
    )
  }
}

RatingFieldAdapter.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  value: PropTypes.number,
}

export default withStyles(styles)(RatingFieldAdapter)
