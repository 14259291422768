import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

const styles = {
  root: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    height: '100%',
  },
  right: {
    textAlign: 'right'
  },
  left: {
    textAlign: 'left'
  },
  center: {
    textAlign: 'center'
  }
}

function None({ classes, children, variant, textAlign }) {
  return (
    <div className={classes.root}>
      <Typography variant={variant || 'h5'} className={classNames(textAlign && classes[textAlign])}>
        {children}
      </Typography>
    </div>
  )
}

None.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

export default withStyles(styles)(None)
