import React from 'react'

import FormButtonContext from './FormButtonContext'
import ButtonSubmit from '../common/components/ButtonSubmit'


const FormButtonSubmit = (props) => (
  <FormButtonContext.Consumer>
    {(context) => (
      <ButtonSubmit {...props} {...context} />
    )}
  </FormButtonContext.Consumer>
)


export default FormButtonSubmit