import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import IconAccount from '@material-ui/icons/AccountCircle'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'redux-form'

import Main from '../../common/components/Main'
import Card from '../../common/components/Card'
import FieldPhone from '../../fields/FieldPhone'
import FieldText from '../../fields/FieldText'
import Form from '../../forms/FormBase'
import FormButtonSubmit from '../../forms/FormButtonSubmit'
import displayName from './displayName'
import withGoogleAnalytics from '../../common/hocs/withGoogleAnalytics'

const validate = (values) => {
  const errors = {}
  const requiredFields = ['firstName', 'lastName', 'phone', 'password', 'passwordConfirm']
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  if (values.password !== values.passwordConfirm) {
    errors.passwordConfirm = 'Passwords must match'
  }
  return errors
}

const styles = (theme) => ({
  header: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  fields: {
    padding: '0 8px',
  },
  actions: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
  },
  link1: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  link2: {
    color: theme.palette.primary.main,
  },
})

const Add = (props) => {
  const {
    classes,
    onAdd,
    history,
    match,
  } = props
  return (
    <Main justifyContent="center">
      <Card>
        <CardContent className={classes.header}>
          <Avatar className={classes.avatar}>
            <IconAccount />
          </Avatar>
          <Typography component="h1" variant="h5">
            Add Account
          </Typography>
        </CardContent>
        <Form
          form="userAdd"
          onSubmit={(values) => onAdd({ ...values, token: match.params.token }).then(() => {
            const redirect = localStorage.getItem('redirect')
            if (redirect) {
              localStorage.removeItem('redirect')
              return history.push(redirect)
            }
            history.push('/user/account')
          })}
          validate={validate}
          enableReinitialize
        >
          <div className={classes.fields}>
            <Field component={FieldText} label="First Name" name="firstName" />
            <Field component={FieldText} label="Last Name" name="lastName" />
            <FieldPhone component={FieldText} label="Phone" name="phone" />
            <Field component={FieldText} label="password" name="password" type="password" />
            <Field component={FieldText} label="Password Confirm" name="passwordConfirm" type="password" />
          </div>
          <CardActions>
            <FormButtonSubmit color="primary" variant="contained" fullWidth>
              Add
            </FormButtonSubmit>
          </CardActions>
        </Form>
      </Card>
    </Main>
  )
}


Add.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
}

Add.displayName = `${displayName}Add`

export default withGoogleAnalytics(withStyles(styles)(Add))
