import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import GoogleAnalytics from 'react-ga'

const withGoogleAnalytics = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    GoogleAnalytics.set({
      page,
      ...options,
    })
    GoogleAnalytics.pageview(page)
  }
  class WithGoogleAnalytics extends Component {
    componentDidMount() {
      const { googleAnalyticsUA, location: { pathname }} = this.props
      if (googleAnalyticsUA) {
        GoogleAnalytics.initialize(googleAnalyticsUA)
        trackPage(pathname)
      }
    }
    componentDidUpdate({ location: { pathname }}) {
      if (this.props.location.pathname !== pathname && this.props.googleAnalyticsUA) {
        trackPage(pathname)
      }
    }
    render() {
      const { isFetching } = this.props
      return isFetching ? null : <WrappedComponent {...this.props} />
    }
  }
  WithGoogleAnalytics.propTypes = {
    googleAnalyticsUA: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
  }
  const mapStateToProps = ({
    app: {
      isFetching,
      seo: { googleAnalyticsUA }
    },
  }) => ({
    googleAnalyticsUA,
    isFetching
  })
  return connect(mapStateToProps)(WithGoogleAnalytics)
}

export default withGoogleAnalytics
