import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Loading from '../common/components/Loading'

import displayName from './displayName'
import ProductListHorizontal from './ProductListHorizontal'
import CartList from './ListVertical'
import withGoogleAnalytics from '../common/hocs/withGoogleAnalytics'
import { formatDollar } from '../common/utils/formatters'
import { onUpdate } from './actions'

const styles = {
  pageEmptyCart: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subtotal: {
    textAlign: 'right',
  },
  buttons: {
    display: 'grid',
    justifyContent: 'end',
    gridTemplateColumns: 'repeat(auto-fill, minmax(min-content, 1fr))',
    gridAutoFlow: 'column',
    gridGap: '24px',
  },
}

const Detail = (props) => {
  const {
    isFetching,
    appShop,
    appCart,
    appCheckout,
    classes,
    categories,
    cart,
    user,
    onUpdate,
    history,
  } = props
  if (isFetching) return <Loading />
  return (
    <div>
      <CardContent>
        <Typography variant="h5">
          {cart.items.length ? 'Cart' : 'Nothing in your cart yet'}
        </Typography>
      </CardContent>
      {cart.items.length
        ? (
          <div>
            <CartList
              cart={cart}
              onUpdate={onUpdate}
              history={history}
              shop={appShop}
            />
            <CardContent className={classes.subtotal}>
              <Typography variant="h6">
                Subtotal
                {' '}
                {formatDollar(cart.subtotal)}
              </Typography>
            </CardContent>
            <CardContent className={classes.buttons}>
              {user._id ? (
                <Button
                  color="primary"
                  variant="contained"
                  component={Link}
                  to="/checkout"
                  size="large"
                >
                  Checkout
                </Button>
              ) : (
                <>
                    <Button
                      color="primary"
                      onClick={() => localStorage.setItem('redirect', '/checkout')}
                      variant="contained"
                      component={Link}
                      to="/user/signin"
                      size="large"
                    >
                      Sign in & checkout
                    </Button>
                    <Button
                      variant="contained"
                      component={Link}
                      to="/checkout"
                      size="large"
                    >
                      Guest Checkout
                    </Button>
                  </>
              )}
            </CardContent>
            {appCart.categories && appCart.categories.length ? (
              <ProductListHorizontal
                input={{
                  _id: { nin: cart.items.map(i => i.variant._id) },
                  categories: { in: appCart.categories },
                  sort: 'name-asc',
                }}
                onUpdate={onUpdate}
              />
            ) : null}

          </div>
        )
        : null}
    </div>
  )
}

Detail.propTypes = {
  cart: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  appShop: PropTypes.object.isRequired,
  appCart: PropTypes.object.isRequired,
  user: PropTypes.object,
}

const mapStateToProps = ({
  app: {
    isFetching,
    shop: appShop,
    cart: appCart,
    checkout: appCheckout,
  },
  cart,
  categories: { items: categories, isFetching: categoriesIsFetching },
  user,
}) => ({
  isFetching: isFetching || cart.isFetching || categoriesIsFetching,
  appShop,
  appCheckout,
  appCart,
  categories,
  cart,
  user,
})

const mapDispatchToProps = dispatch => ({
  onUpdate: update => dispatch(onUpdate(update)),
})

Detail.displayName = `${displayName}Detail`

export default withGoogleAnalytics(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Detail)),
)
