import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CardActions from '@material-ui/core/CardActions'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'

import displayName from '../public/displayName'
import FormBase from '../../forms/FormBase'
import FormFields from '../../forms/FormFields'
import FormButtonSubmit from '../../forms/FormButtonSubmit'
import RatingFieldAdapter from '../../rating/RatingFieldAdapter'
import FieldText from '../../fields/FieldText'
import { validateRequired } from '../../common/utils/validators'

const fields = [
  { label: 'Rating', name: 'rating', type: 'number', component: RatingFieldAdapter, validate: validateRequired },
  { label: 'Comments', name: 'text', multiline: true, component: FieldText, validate: validateRequired },
]

const styles = {
  formFields: {
    flexFlow: 'column',
    paddingBottom: 0
  },
  btnContainer: {
    paddingTop: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end'
  }
}

const Form = props => {
  const {
    classes,
    form,
    initialValues,
    onClose,
    onSubmit,
  } = props
  return (
    <FormBase
      onSubmit={onSubmit}
      form={form}
      initialValues={initialValues}
    >
      <FormFields fields={fields} className={classes.formFields} />
      <CardActions className={classes.btnContainer}>
        <FormButtonSubmit>Save</FormButtonSubmit>
        <Button type="button" onClick={onClose} size="small">Cancel</Button>
      </CardActions>
    </FormBase>
  )
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

Form.displayName = `${displayName}Form`

export default withStyles(styles)(Form)
