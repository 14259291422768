import React from 'react'

function highlightText({
  text,
  highlightText,
  className,
  style
}) {
  const cleaned = text.replace(/<[^>]*>?/gm, '')
  const parts = cleaned.split(new RegExp(`(${highlightText})`, 'i')).filter(Boolean)
  return (
    <span>
      {parts.map((part, i) => {
        if (part.toLowerCase() === highlightText.toLowerCase()) {
          return <span className={className} style={style} key={i}>{part}</span>
        }
        return <span key={i}>{part}</span>
      })}
    </span>
  )
}

export default highlightText