import createActions from '../redux/createActions'
import fetchApi from '../fetch/fetchApi'
import { reducerName } from './reducers'

const route = 'themes'

const {
  ADD,
  DELETE,
  RECEIVE,
  REQUEST,
  UPDATE,
} = createActions(reducerName)


export const onRequest = () => ({ type: REQUEST })

// Create
export const onAddSuccess = (item) => ({ type: ADD, item })
export const onAdd = (add) => {
  return (dispatch, getState) => {
    return fetchApi({
      auth: true,
      body: add,
      method: 'POST',
      endpoint: route,
    })
      .then(json => {
        dispatch(onAddSuccess(json))
      })
  }
}



// Read
export const onFindOneSuccess = (item) => ({ type: RECEIVE, item })
export const onFindOne = () => {
  return (dispatch, getState) => {
    dispatch(onRequest())
    return fetchApi({
      auth: false,
      body: null,
      method: 'GET',
      endpoint: route,
    })
      .then(json => {
        return dispatch(onFindOneSuccess(json))
      })
  }
}


// Update
const onUpdateSuccess = (item) => ({ type: UPDATE, item })
export const onUpdate = ({ path, update }) => {
  return (dispatch, getState) => {
    return fetchApi({
      auth: true,
      body: update,
      method: 'PATCH',
      endpoint: `${route}/${path}`,
    })
      .then(json => dispatch(onUpdateSuccess(json)))
  }
}


// Delete
const onDeleteSuccess = (_id) => ({ type: DELETE, _id })
export const onDelete = (_id) => {
  return (dispatch, getState) => {
    return fetchApi({
      auth: true,
      body: null,
      method: 'DELETE',
      endpoint: `${route}/${_id}`,
    })
      .then(_id => dispatch(onDeleteSuccess(_id)))
  }
}
