import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

import ScrollToTopOnMount from './ScrollToTopOnMount'

const styles = (theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(5),
    },
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.breakpoints.values.md,
    flex: '1 1 100%',
    height: '100%',
    width: '100%',
  },
})

function Page({
  children,
  className,
  classes,
  style,
}) {
  return (
    <ScrollToTopOnMount>
      <div className={classNames(classes.root, className)} style={style}>
        {children}
      </div>
    </ScrollToTopOnMount>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Page)
