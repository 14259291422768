import { useEffect, useState, useReducer } from 'react'
import itemsReducer from '../../common/reducers/itemsReducer'
import getLastQuery from '../../common/utils/getLastQuery'

const useReviews = (props) => {
  const [stateInput, setStateInput] = useState(props.input || {})
  const [state, dispatch] = useReducer(itemsReducer, {
    count: 0,
    error: null,
    isFetching: false,
    isLoading: true,
    items: {},
    lastItem: {},
  })

  const onError = error => {
    dispatch({ type: 'ERROR', error })
    return Promise.reject(error)
  }

  const onAdd = props.onAdd ? async input => {
    const { blog, product, review } = await props.onAdd(input).catch(onError)
    review && dispatch({ type: 'ADD', item: review })
    return blog || product && props.onUpdateParentSuccess(blog || product)
  } : () => Promise.resolve(false)

  const onDelete = props.onDelete ? async input => {
    const { blog, product, review } = await props.onDelete(input).catch(onError)
    dispatch({ type: 'DELETE', _id: review._id })
    return blog || product && props.onUpdateParentSuccess(blog || product)
  } : () => Promise.resolve(false)

  const onDeleteAdmin = props.onDeleteAdmin ? async input => {
    const { blog, product, review } = await props.onDeleteAdmin(input).catch(onError)
    dispatch({ type: 'DELETE', _id: review._id })
    return blog || product && props.onUpdateParentSuccess(blog || product)
  } : () => Promise.resolve(false)

  const onFilter = props.onFind ? async input => {
    dispatch({ type: 'REQUEST' })
    setStateInput({ ...stateInput, ...input })
    const items = await props.onFind({ ...stateInput, ...input }).catch(onError)
    return dispatch({ type: 'FILTER', items })
  } : () => Promise.resolve(false)

  const onFind = props.onFind ? async input => {
    dispatch({ type: 'REQUEST' })
    const items = await props.onFind({ ...stateInput, ...input, lastItem: state.lastItem }).catch(onError)
    return dispatch({ type: 'RECEIVE', items })
  } : () => Promise.resolve(false)

  const onFindOne = props.onFindOne ? async input => {
    dispatch({ type: 'REQUEST' })
    const item = await props.onFindOne(input).catch(onError)
    return dispatch({ type: 'RECEIVE', items: [item] })
  } : () => Promise.resolve(false)


  const onUpdate = props.onUpdate ? async input => {
    const { blog, product, review } = await props.onUpdate(input).catch(onError)
    review && dispatch({ type: 'UPDATE', item: review })
    return blog || product && props.onUpdateParentSuccess(blog || product)
  } : () => Promise.resolve(false)

  const onUpdateLikes = props.onUpdateLikes ? async input => {
    const { review } = await props.onUpdateLikes(input).catch(onError)
    return dispatch({ type: 'UPDATE', item: review })
  } : () => Promise.resolve(false)

  useEffect(() => {
    onFind({})
  }, [stateInput])

  return {
    ...state,
    onAdd,
    onDelete,
    onDeleteAdmin,
    onFilter,
    onFind,
    onFindOne,
    onUpdate,
    onUpdateLikes,
    input: stateInput,
  }
}

export default useReviews