import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Search from '@material-ui/icons/Search'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import { NavLink } from 'react-router-dom'

import AppBarActions from './AppBarActions'
import AppBarPage from './AppBarPage'
import AppBarPhone from './AppBarPhone'
import AppBarUser from './AppBarUser'
import ButtonMenu from '../common/components/ButtonMenu'

const styles = {
  root: {
    width: '100%',
  },
  hidden: {
    display: 'none !important',
  },
  navigationContainer: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
  },
  pageListContainer: {
    flex: '1 1 auto',
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
  },
  pageList: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
  },
  button: {
    whiteSpace: 'nowrap',
  },
  navLinkActive: {
    '& span:first-child': {
      borderBottom: '2px solid !important',
      width: 'inherit',
    },
  },
}


class AppBarNavigation extends Component {
  constructor(props) {
    super(props)
    this.ticking = false
    this.pageListContainer = React.createRef()
    this.pageList = React.createRef()
    this.user = React.createRef()
    this.state = {
      pageListContainerWidth: null,
      pageListWidth: null,
      userWidth: null,
      isPageListDisplay: true,
      isUserDisplay: true,
      isDialogOpen: false,
    }
  }

  handleUpdate = () => {
    this.ticking = false
    const isPageListDisplay = (this.state.pageListWidth < this.pageListContainer.current.offsetWidth - 32)
    const isUserDisplay = (this.state.userWidth / window.innerWidth) < 0.22
    if (isPageListDisplay !== this.state.isPageListDisplay || isUserDisplay !== this.state.isUserDisplay) {
      this.setState({
        isPageListDisplay,
        isUserDisplay,
        pageListContainerWidth: this.pageListContainer.current.offsetWidth,
      })
    }
  }

  handleResize = () => {
    if (!this.ticking) {
      this.ticking = true
      requestAnimationFrame(this.handleUpdate)
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize, false)
    window.addEventListener('orientationchange', this.handleResize, false)
    const pageListContainerWidth = this.pageListContainer.current.offsetWidth
    const pageListWidth = this.pageList.current.offsetWidth
    const userWidth = this.user.current.offsetWidth
    this.setState({ pageListContainerWidth, pageListWidth, userWidth }, this.handleUpdate)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize, false)
    window.removeEventListener('orientationchange', this.handleResize, false)
  }

  render() {
    const {
      classes,
      isBlogs,
      isProducts,
      categories,
      header = {},
      onSearch,
      pages,
      shop,
    } = this.props
    const {
      isPhoneDisplay,
      style = {},
    } = header
    const { color } = style
    const { isPageListDisplay, isUserDisplay } = this.state
    return (
      <div className={classes.root}>
        {isPhoneDisplay ? <AppBarPhone /> : null}
        <div className={classes.navigationContainer}>
          <div className={classes.pageListContainer} ref={this.pageListContainer}>
            <div className={classNames(classes.pageList, !isPageListDisplay && classes.hidden)} ref={this.pageList}>
              {isProducts && shop.categories && shop.categories.length ? (
                <ButtonMenu
                  style={{ color }}
                  activeClassName={classes.navLinkActive}
                  className={classes.button}
                  isHover
                  to={`/${shop.slug}`}
                  component={NavLink}
                  menuItems={shop.categories.map(id => ({
                    to: `/${shop.slug}/${categories[id].slug}`,
                    children: categories[id].name,
                  }))}
                >
                  {shop.name}
                </ButtonMenu>
              ) : null}
              {header.navigation && header.navigation.length ? header.navigation.map(item => (
                <AppBarPage key={item.name} item={item} pages={pages} color={color} />
              )) : null}
              {isBlogs ? (
                <Button
                  activeClassName={classes.navLinkActive}
                  className={classNames(classes.pageLink, classes.button)}
                  style={{ color }}
                  component={NavLink}
                  to="/blogs"
                  size="small"
                >
                  Blogs
                </Button>
              ) : null}
            </div>

          </div>
          <IconButton onClick={onSearch}>
            <Search style={{ color }} />
          </IconButton>
          <div ref={this.user} className={classNames(!isUserDisplay && classes.hidden)}>
            <AppBarUser color={color} />
          </div>
          <AppBarActions />
        </div>
      </div>
    )
  }
}


AppBarNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  isBlogs: PropTypes.bool.isRequired,
  isProducts: PropTypes.bool.isRequired,
  header: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  pageIds: PropTypes.array,
  pages: PropTypes.object,
  shop: PropTypes.object.isRequired,
}


export default withStyles(styles)(AppBarNavigation)
