import { reducerName } from './actions'

const auth = (state = {
  form: 'signin',
  isOpen: false,
}, action) => {
  switch (action.type) {
    case `UPDATE_FORM_${reducerName}`:
      return {
        ...state,
        form: action.form
      }
    case `TOGGLE_${reducerName}`:
      return {
        ...state,
        isOpen: !state.isOpen
      }
    default:
      return state
  }
}

export default auth
