import React from 'react'

export interface FetchBody {
  [key: string]: any,
}

export interface Fetch {
  body: FetchBody,
  method: string,
  endpoint: string,
}

export interface Value {
  onFetch: (props: Fetch) => Promise<any>,
  onFetchGraphQL: (props: FetchBody) => Promise<any>,
}

export const initialValue: Value = {
  onFetch: () => Promise.resolve({}),
  onFetchGraphQL: () => Promise.resolve({}),
}

const FetchContext = React.createContext(initialValue)

export default FetchContext
