import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Loading from '../common/components/Loading'
import None from '../common/components/None'
import { onFetchApp as fetchAppAction } from './actions'
import { onFetch as fetchCartAction } from '../cart/actions'

const withApp = (ComposedComponent) => {
  class WithApp extends PureComponent {
    componentDidMount() {
      const {
        onFetchApp,
        onFetchCart,
      } = this.props
      const cartId = localStorage.getItem('cartId')
      if (cartId) onFetchCart(cartId)
      return onFetchApp().catch((err) => console.log('We have the error ', err))
    }

    render() {
      const {
        appId,
        error,
        isFetching,
        onFetchApp,
        onFetchCart,
        ...rest
      } = this.props
      if (isFetching) return <Loading />
      if (error) return <None>{error.message}</None>
      if (!appId) return <None>{process.env.REACT_APP_NAME}</None>
      if (appId) return <ComposedComponent {...rest} />
      return null
    }
  }
  WithApp.propTypes = {
    appId: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
    onFetchApp: PropTypes.func.isRequired,
    onFetchCart: PropTypes.func.isRequired,
  }
  const mapStateToProps = ({
    auth,
    app: {
      _id: appId,
      brand,
      error,
      isFetching,
    },
  }) => ({
    appId,
    auth,
    brand,
    error,
    isFetching,
  })
  const mapDispatchToProps = (dispatch) => ({
    onFetchApp: (arg) => dispatch(fetchAppAction(arg)),
    onFetchCart: (arg) => dispatch(fetchCartAction(arg)),
  })
  return connect(mapStateToProps, mapDispatchToProps)(WithApp)
}

export default withApp
