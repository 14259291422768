import fetchGraphQL from '../../graphql/fetchGraphQL'

import { orderType } from '../common/types'
import { creditType } from '../../credit/common/types'


// QUERIES 
export const orderRecipientFind = async input => {
  const { orderRecipientFind: items } = await fetchGraphQL({
    query: `
        query OrderRecipientFind($input: OrderFindInput!) {
          orderRecipientFind(input: $input) { ${orderType} }
        }
      `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return items
}


export const orderRecipientFindOne = async input => {
  const { orderRecipientFindOne: item } = await fetchGraphQL({
    query: `
      query OrderRecipientFindOne($input: OrderFindOneInput!) {
        orderRecipientFindOne(input: $input) { ${orderType} }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}







// MUTATIONS

export const orderRecipientShipRequest = async input => {
  const { orderRecipientShipRequest: item } = await fetchGraphQL({
    query: `
      mutation OrderRecipientShipRequest($input: OrderUpdateInput!) {
        orderRecipientShipRequest(input: $input) { ${orderType} }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}

export const orderRecipientUpdateDelivery = async input => {
  const { orderRecipientUpdateDelivery: item } = await fetchGraphQL({
    query: `
      mutation OrderRecipientUpdateDelivery($input: OrderUpdateDeliveryInput!) {
        orderRecipientUpdateDelivery(input: $input) { ${orderType} }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}