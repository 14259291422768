import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

import Rating from '../rating/Rating'

const styles = {
  rating: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  reviews: {
    marginLeft: 8,
    paddingTop: 2
  }
}

const RatingSummary = ({ classes, rating, reviews }) => (
  <div className={classes.rating}>
    <Rating stars={rating} />
    <Typography className={classes.reviews}>{reviews}</Typography>
  </div>
)

RatingSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  rating: PropTypes.number.isRequired,
  reviews: PropTypes.number.isRequired,
}

export default withStyles(styles)(RatingSummary)
