import React, { useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link, Route, Switch } from 'react-router-dom'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'

import ListHorizontal from './ListHorizontal'
import ListVertical from './ListVertical'
import ProductRouter from '../../product/public/Router'
import ProductListHorizontal from '../../product/public/ListHorizontal'
import displayName from './displayName'
import Page from '../../common/components/Page'
import Header from './Header'

const styles = theme => ({
  page: {
    paddingTop: 0,
  },
  list: {
    justifyContent: 'space-around',
  },
  listItem: {
    flex: '0 1 300px',
  },
})

function Router({
  isFetching,
  classes,
  categories,
  match,
  shop,
}) {
  if (isFetching) return null
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`}>
          {(route) => {
            if (route.match.params.slug) {
              const id = Object.keys(categories).find(id => categories[id].slug === route.match.params.slug)
              const category = categories[id]
              return (
                <Page className={classes.page}>
                  <Header name={category.name} description={category.description} shop={shop} />
                  <ListVertical category={category} categories={categories} shop={shop} {...route} />
                </Page>
              )
            }
            return (
              <Page className={classes.page}>
                <Header name={shop.name} description={shop.description} shop={shop} />
                {shop.categories.map(id => (
                  <div key={id} className={classes.shopCategory}>
                    <CardContent>
                      <Typography
                        component={Link}
                        to={`${match.url}/${categories[id].slug}`}
                        variant="h5"
                      >
                        {categories[id].name}
                      </Typography>
                    </CardContent>
                    {categories[id].isProducts
                      ? <ProductListHorizontal input={{ categories: { in: [id] } }} />
                      : (
                        <ListHorizontal
                          category={categories[id]}
                          categories={categories}
                          shop={shop}
                          {...route}
                        />
                      )
                    }
                  </div>
                ))}
              </Page>
            )
          }}
        </Route>
        <Route path={`${match.path}/:slug`}>
          {(route) => {
            const _id = Object.keys(categories).find(id => categories[id].slug === route.match.params.slug)
            const category = categories[_id]
            if (!_id || category.isProducts) {
              return (
                <ProductRouter
                  match={_id ? route.match : match}
                  shop={shop}
                  input={!_id ? { slug: route.match.params.slug } : { categories: { in: [_id] } }}
                  category={category}
                  categories={categories}
                />
              )
            }
            return (
              <Router
                {...route}
                isFetching={isFetching}
                classes={classes}
                categories={categories}
                shop={shop}
              />
            )
          }}
        </Route>
      </Switch>
    </>
  )
}

Router.propTypes = {
  categories: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

const mapStateToProps = ({
  app: { isFetching: appIsFetching, shop },
  categories: { isFetching: categoriesIsFetching, items: categories },
}) => ({
  categories,
  shop,
  isFetching: appIsFetching || categoriesIsFetching,
})

Router.displayName = `${displayName}Router`

export default connect(mapStateToProps)(withStyles(styles)(Router))
