import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import IconAccount from '@material-ui/icons/AccountCircle'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link } from 'react-router-dom'

import SnackbarContext from '../../snackbar/SnackbarContext'
import Card from '../../common/components/Card'
import Main from '../../common/components/Main'
import FieldText from '../../fields/FieldText'
import Form from '../../forms/FormBase'
import FormButtonSubmit from '../../forms/FormButtonSubmit'
import FormFields from '../../forms/FormFields'
import displayName from './displayName'
import withGoogleAnalytics from '../../common/hocs/withGoogleAnalytics'
import { validateEmail, validateRequired } from '../../common/utils/validators'


const styles = (theme) => ({
  root: {
    width: 'auto',
    maxWidth: theme.breakpoints.values.sm,
    margin: 'auto',
  },
  header: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  fields: {
    padding: '0 8px',
  },
  actions: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
  },
  link1: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  link2: {
    color: theme.palette.primary.main,
  },
})

const Signup = ({ classes, onSignup }) => {
  const { onSnackOpen } = useContext(SnackbarContext)
  return (
    <Main justifyContent="center">
      <Card>
        <CardContent className={classes.header}>
          <Avatar className={classes.avatar}>
            <IconAccount />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
        </CardContent>
        <Form
          form="userSignup"
          onSubmit={(values) => onSignup(values).then(() => {
            onSnackOpen({ severity: 'success', message: 'Check your email for further instructions!' })
          })}
          enableReinitialize
        >
          <FormFields
            className={classes.fields}
            fields={[
              { name: 'email', label: 'Email', type: 'text', validate: [validateRequired, validateEmail], component: FieldText },
            ]}
            flexFlow="column"
          />

          <CardActions>
            <FormButtonSubmit color="primary" variant="contained" fullWidth>
              Sign Up
            </FormButtonSubmit>
          </CardActions>
        </Form>
        <CardContent className={classes.actions}>
          <Typography>
            Have an account?
            {' '}
            <Link to="/user/signin" className={classes.link1}>Sign In!</Link>
            {' '}
          </Typography>
          <Typography>
            Don&#39;t have an account?
            {' '}
            <Link to="/user/recovery" className={classes.link2}>Recover account</Link>
          </Typography>
        </CardContent>
      </Card>
    </Main>
  )
}

Signup.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onSignup: PropTypes.func.isRequired,
}

Signup.displayName = `${displayName}Signup`

export default withGoogleAnalytics(withStyles(styles)(Signup))
