import { Component } from 'react'

class ScrollIntoView extends Component {
  handleScroll = () => {
    if (window.location.hash) {
      const { id } = this.props
      if (id && window.location.hash.includes(id)) {
        const el = document.getElementById(id)
        el.scrollIntoView()
      }
    }
  }
  componentDidMount() {
    this.handleScroll()
  }
  componentDidUpdate() {
    //this.handleScroll()
  }
  render() {
    return null
  }
}

export default ScrollIntoView
