import React from 'react'
import PropTypes from 'prop-types'

import displayName from './displayName'
import Form from './Form'
import { commentAdd } from './api'

const Add = props => {
  const {
    onAdd,
    onClose,
    onDocName,
    parentCommentId,
    review,
  } = props
  const handleSubmit = (values) => {
    return onAdd({
      ...values,
      href: window.location.href,
      onDoc: review.onDoc,
      onDocName,
      onModel: review.onModel,
      parentCommentId,
      reviewId: review._id,
    })
      .then(() => onClose())
      .catch(() => onClose())
  }
  return (
    <Form
      form="commentAdd"
      onSubmit={handleSubmit}
      initialValues={{}}
      onClose={onClose}
    />
  )
}

Add.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDocName: PropTypes.string.isRequired,
  parentCommentId: PropTypes.string,
  review: PropTypes.object.isRequired,
}

Add.displayName = `${displayName}Add`

export default Add
