import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import Card from '../../common/components/Card'
import Phone from '../../common/components/Phone'
import displayName from './displayName'
import useDnDSortListItem from '../../common/hooks/useDnDSortListItem'

const styles = theme => ({
  li: {
    flex: '0 1 auto',
  },
})

function ListItem(props) {
  const {
    address = {},
    classes,
    id,
    index,
    match,
    onDrop,
    onMove,
    user,
  } = props
  const {
    _id,
    firstName,
    lastName,
    phone,
    street1,
    city,
    zip,
    state,
  } = address
  const { ref, isDragging } = useDnDSortListItem({ itemType: `ADDRESS_${user._id}`, index, onMove, id })
  return (
    <li className={classes.li} ref={ref} onDrop={onDrop} style={{ opacity: isDragging ? 0 : 1, cursor: 'move' }}>
      <Card to={`${match.url}/${_id}`}>
        <CardContent>
          <Typography variant="body1">
            {firstName}
            {' '}
            {lastName}
          </Typography>
          <Typography>
            {phone ? <Phone>{phone}</Phone> : null}
          </Typography>
          <Typography>{street1}</Typography>
          <Typography>
            {city}
            ,
            {' '}
            {state}
            {' '}
            {zip}
          </Typography>
        </CardContent>
      </Card>
    </li>

  )
}

ListItem.propTypes = {
  address: PropTypes.object,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

ListItem.displayName = `${displayName}ListItem`

export default withStyles(styles)(ListItem)
