import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import displayName from './displayName'
import { onFind, onFindOne, onReset, onAdd, onUpdate, onDelete } from './actions'
import { onUpdateAddresses } from '../../user/user/actions'
import List from './List'
import Add from './Add'
import Update from './Update'
import Page from '../../common/components/Page'

const Router = props => {
  const {
    addresses,
    match,
  } = props
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        {route => (
          <Page>
            <CardContent>
              <Typography variant="h5">Addresses</Typography>
            </CardContent>
            <List {...props} {...route} />
          </Page>
        )}
      </Route>
      <Route exact path={`${match.path}/add`}>
        {route => (
          <Page>
            <Add {...props} {...route} />
          </Page>
        )}
      </Route>
      <Route exact path={`${match.path}/:_id`}>
        {route => {
          const address = addresses[route.match.params._id] || {}
          return (
            <Page>
              <Update {...props} {...route} address={address} />
            </Page>
          )
        }}
      </Route>
    </Switch>
  )
}

Router.propTypes = {
  adminUser: PropTypes.object,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userId: PropTypes.string,
}


const mapStateToProps = ({
  addresses: { isFetching, items: addresses }
}) => ({
  isFetching,
  addresses,
})

const mapDispatchToProps = dispatch => ({
  onAdd: input => dispatch(onAdd(input)),
  onDelete: input => dispatch(onDelete(input)),
  onFind: input => dispatch(onFind(input)),
  onReset: () => dispatch(onReset()),
  onUpdate: input => dispatch(onUpdate(input)),
  onFindOne: input => dispatch(onFindOne(input)),
  onUpdateAddresses: input => dispatch(onUpdateAddresses(input))
})

Router.displayName = `${displayName}Router`

export default connect(mapStateToProps, mapDispatchToProps)(Router)
