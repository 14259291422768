import React, { useState, useEffect, useRef } from 'react'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'

function Loading() {
  const [isIn, setIsIn] = useState(false)
  const timeoutId = useRef<number | null>(null)
  useEffect(() => {
    const nextTimeoutId = window.setTimeout(() => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
        timeoutId.current = null
      }
      setIsIn(true)
    }, 900)
    timeoutId.current = nextTimeoutId
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
    }
  }, [])
  return (
    <Fade in={isIn}>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    </Fade>
  )
}

export default Loading
