import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Popover from '@material-ui/core/Popover'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link } from 'react-router-dom'

const styles = theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1) / 2,
  },
  popover: {
    pointerEvents: 'none',
  },
  menuList: {
    pointerEvents: 'auto',
  },
})

class ButtonMenu extends Component {
  state = {
    anchorEl: null,
    isMenu: false,
    timeoutId: null,
  }

  handleButtonClick = (event) => {
    const state = this.state.anchorEl ? { anchorEl: null } : { anchorEl: event.currentTarget }
    this.setState(state)
  }

  handleButtonMouseEnter = (e) => {
    this.setState({ anchorEl: e.currentTarget })
  }

  handleButtonMouseLeave = () => {
    if (this.state.isMenu) return
    const timeoutId = setTimeout(() => {
      this.state.timeoutId && clearTimeout(this.state.timeoutId)
      if (this.state.isMenu) return
      this.setState({ anchorEl: null, timeoutId: null })
    }, 100)
    this.setState({ timeoutId })
  }

  handleMenuItemClick = (onClick) => {
    onClick && onClick()
    this.setState({ anchorEl: null })
  }

  handleMenuListMouseEnter = () => {
    this.setState({ isMenu: true })
  }

  handleMenuListMouseLeave = () => {
    this.setState({ isMenu: false, anchorEl: null })
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeoutId)
  }

  renderExpand = () => {
    const { classes, isHover } = this.props
    if (isHover) return null
    if (this.state.anchorEl) {
      return <IconExpandLess className={classes.rightIcon} />
    }
    return <IconExpandMore className={classes.rightIcon} />
  }

  renderButtonProps = () => {
    const {
      isHover,
      menuItems,
      component,
      to,
      activeClassName,
    } = this.props
    if (isHover) {
      const linkProps = menuItems && menuItems.length && to ? {
        component: component || Link, to, activeClassName,
      } : null
      return {
        onMouseEnter: this.handleButtonMouseEnter,
        onMouseLeave: this.handleButtonMouseLeave,
        onClick: this.handleButtonClick,
        ...linkProps,
      }
    }
    return {
      onClick: this.handleButtonClick,
    }
  }

  renderMenuProps = () => {
    const { isHover } = this.props
    if (isHover) {
      return {
        onMouseEnter: this.handleMenuListMouseEnter,
        onMouseLeave: this.handleMenuListMouseLeave,
      }
    }
  }

  renderButton = () => {
    const {
      children,
      classes,
      className,
      isHover,
      menuItems,
      ...rest
    } = this.props
    const { anchorEl } = this.state
    const isOpen = Boolean(anchorEl)
    const props = {
      ...rest,
      'aria-haspopup': true,
      'aria-owns': isOpen ? `${children}` : null,
      className,
      size: 'small',
      ...this.renderButtonProps(),
    }
    if (typeof children === 'string' || children.length > 1) {
      return (
        <Button {...props}>
          {children}
          {' '}
          {this.renderExpand()}
        </Button>
      )
    }
    return (
      <IconButton {...props}>
        {children}
        {' '}
        {this.renderExpand()}
      </IconButton>
    )
  }

  render() {
    const {
      classes,
      children,
      menuItems,
    } = this.props
    const { anchorEl } = this.state
    const isOpen = Boolean(anchorEl)
    return (
      <div>
        {this.renderButton()}
        <Popover
          anchorEl={anchorEl}
          className={classes.popover}
          id={`${children}`}
          onClose={this.handleClose}
          open={isOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuList className={classes.menuList} {...this.renderMenuProps()}>
            {menuItems.map(({ to, children, onClick }, i) => {
              const componentProps = to ? { component: Link, to } : {}
              return (
                <MenuItem
                  key={i}
                  {...componentProps}
                  onClick={() => this.handleMenuItemClick(onClick)}
                >
                  {children}
                </MenuItem>
              )
            })}
          </MenuList>
        </Popover>
      </div>
    )
  }
}

ButtonMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  classes: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isHover: PropTypes.bool,
  menuItems: PropTypes.array.isRequired,
}

export default withStyles(styles)(ButtonMenu)
