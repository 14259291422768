import React from 'react'
import PropTypes from 'prop-types'

import displayName from './displayName'
import ListItem from './ListItem'
import ULVerticalBase from '../common/components/ULVerticalBase'


const ListVertical = ({
  cart,
  onUpdate,
  history,
  shop,
}) => {
  return (
    <ULVerticalBase>
      {cart.items.map(item => (
        <ListItem
          onUpdate={onUpdate}
          history={history}
          item={item}
          key={item.product}
          shop={shop}
        />
      ))}
    </ULVerticalBase>
  )
}

ListVertical.propTypes = {
  cart: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
}

ListVertical.displayName = `${displayName}ListVertical`

export default ListVertical
