import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import IconFavoriteBorder from '@material-ui/icons/FavoriteBorder'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import displayName from './displayName'
import IconButton from '../../icons/IconButton'
import ButtonPublish from '../../common/components/ButtonPublish'

const styles = {
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
}

const Buttons = ({
  classes,
  onDelete,
  onEdit,
  review,
  user,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isReviewOwner = review.user === user._id
  const isAdmin = user.roles.includes('admin') ? user : null
  const handleDelete = () => {
    setAnchorEl(null)
    if (window.confirm('Are you sure you would like to delete this review?')) {
      if (review && review._id) {
        onDelete({ _id: review._id })
      }
    }
  }
  const handleEdit = () => {
    setAnchorEl(null)
    return onEdit()
  }
  return (
    <div className={classes.root}>
      <IconButton
        aria-label="More"
        aria-owns={anchorEl ? 'long-menu' : null}
        aria-haspopup="true"
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {isReviewOwner ? <MenuItem onClick={handleEdit}>Edit</MenuItem> : null}
        {isReviewOwner ? <MenuItem onClick={handleDelete}>Delete</MenuItem> : null}
      </Menu>
    </div>
  )
}


Buttons.propTypes = {
  classes: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  review: PropTypes.object.isRequired,
  user: PropTypes.object,
}

Buttons.displayName = `${displayName}Buttons`

export default withStyles(styles)(Buttons)