import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import IconAccount from '@material-ui/icons/AccountCircle'

import { onSignout } from '../user/user/actions'
import ButtonMenu from '../common/components/ButtonMenu'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    minWidth: 'fit-content',
    whiteSpace: 'nowrap',
  }
})

class AppBarUser extends Component {
  state = {
    redirect: null,
  }
  render() {
    const {
      classes,
      color,
      onSignout,
      user: { firstName },
    } = this.props
    const menuItems = firstName ? [{
      to: '/user/account',
      children: 'Account',
    }, {
      to: '/user/signin',
      onClick: onSignout,
      children: 'Sign Out'
    }] : [{
      to: '/user/signin',
      children: 'Sign In',
    }, {
      to: '/user/signup',
      children: 'Sign Up',
    }]
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <div className={classes.root}>
        <ButtonMenu
          style={{ color }}
          menuItems={menuItems}
          isHover={true}
          className={classes.button}
        >
          {firstName ? `Hello, ${firstName}` : <IconAccount />}
        </ButtonMenu>
      </div>
    )
  }
}

const mapStateToProps = ({ user }) => ({ user })
const mapDispatchToProps = dispatch => ({ onSignout: () => dispatch(onSignout()) })

AppBarUser.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  onSignout: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppBarUser))
