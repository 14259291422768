import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import Button from '../common/components/Button'
import FilterCheckbox from './FilterCheckbox'
import FilterRadio from './FilterRadio'

function FilterDialog({
  filterOptions,
  filterState,
  isOpen,
  onChange,
  onClose,
  onOk,
}) {
  return (
    <Dialog
      disableEscapeKeyDown
      open={isOpen}
      onClose={onClose}
    >
      <DialogContent>
        {Object.keys(filterOptions).map(key => {
          const props = {
            field: filterOptions[key],
            key,
            onChange: onChange(key),
            value: filterState[key],
          }
          if (filterOptions[key].type === 'radio') return <FilterRadio key={key} {...props} />
          return <FilterCheckbox key={key} {...props} />
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

FilterDialog.propTypes = {
  filterOptions: PropTypes.object.isRequired,
  filterState: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
}

export default FilterDialog
