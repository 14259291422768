import React from 'react'
import PropTypes from 'prop-types'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import Card from '../../common/components/Card'
import FadeInIfNeeded from '../../common/components/FadeInIfNeeded'
import displayName from './displayName'
import getImageSrc from '../../image/common/getImageSrc'

const styles = (theme) => ({
  li: {
    flex: '0 0 400px',
  },
  headline: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
  },
  rating: {
    marginBottom: 8,
  },
  image: {
    maxWidth: '100%',
    minWidth: '100%',
    verticalAlign: 'top',
    width: '100%',
  },
})

const ListItem = (props) => {
  const {
    classes,
    shop,
    item,
    onClick,
  } = props
  const {
    name,
    imageSrc,
    description,
  } = item
  const cardProps = onClick ? { onClick } : { to: `/blogs/${item.slug}` }
  return (
    <li className={classes.li}>
      <Card {...cardProps} imageSrc={imageSrc}>
        <CardActionArea>
          <img alt={name} src={getImageSrc(imageSrc)} className={classes.image} />
          <CardContent className={classes.headline}>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="h6">{description}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </li>
  )
}

ListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

ListItem.displayName = `${displayName}ListItem`

export default withStyles(styles)(ListItem)
