import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import withStyles from '@material-ui/core/styles/withStyles'


const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
})

const getOptions = (options) => {
  return options.map(option => {
    switch (option) {
      case 'amount':
        return [
          { name: 'Amount (highest to lowest)', value: `${option}-desc` },
          { name: 'Amount (lowest to highest)', value: `${option}-asc` }
        ]
      case 'createdAt':
        return [
          { name: 'Newest', value: `${option}-desc` },
          { name: 'Oldest', value: `${option}-asc` }
        ]
      case 'email':
        return [
          { name: 'Email (a - z)', value: `${option}-asc` },
          { name: 'Email (z - a)', value: `${option}-desc` },
        ]
      case 'isPublished':
        return [
          { name: 'All', value: '' },
          { name: 'Published', value: 'true' },
          { name: 'Not Published', value: 'false' }
        ]
      case 'isShipped':
        return [
          { name: 'All', value: '' },
          { name: 'Shipped', value: 'true' },
          { name: 'Not Shipped', value: 'false' }
        ]
      case 'name':
        return [
          { name: 'Name (a - z)', value: `${option}-asc` },
          { name: 'Name (z - a)', value: `${option}-desc` },
        ]
      case 'price':
        return [
          { name: 'Price (highest to lowest)', value: `${option}-desc` },
          { name: 'Price (lowest to highest)', value: `${option}-asc` }
        ]
      case 'rating-avg':
        return [
          { name: 'Rating (highest to lowest)', value: `${option}-desc` },
          { name: 'Rating (lowest to highest)', value: `${option}-asc` },
        ]
      case 'rating':
        return [
          { name: 'Rating (highest to lowest)', value: `${option}-desc` },
          { name: 'Rating (lowest to highest)', value: `${option}-asc` },
        ]
      case 'reviews':
        return [
          { name: 'Reviews (highest to lowest)', value: `${option}-desc` },
          { name: 'Reviews (lowest to highest)', value: `${option}-asc` },
        ]
      case 'total':
        return [
          { name: 'Total (highest to lowest)', value: `${option}-desc` },
          { name: 'Total (lowest to highest)', value: `${option}-asc` }
        ]
      default:
        return []
    }
  }).reduce((a, b) => {
    return a.concat(b)
  }, [])
}

function FilterRadio({
  classes,
  field,
  onChange,
  value
}) {
  const options = getOptions(field.options)
  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{field.name}</FormLabel>
      <RadioGroup
        aria-label={field.name}
        name={field.name}
        className={classes.group}
        value={value}
        onChange={onChange}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.name}
            value={option.value}
            control={<Radio />}
            label={option.name}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}




FilterRadio.propTypes = {
  classes: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default withStyles(styles)(FilterRadio)
