import React from 'react'
import PropTypes from 'prop-types'
import renderHTML from 'react-render-html'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'

import displayName from './displayName'


const Wysiwyg = props => {
  const {
    children,
    classes,
    elevation,
    style = {},
    ...rest
  } = props
  const child = children.length ? children[0] : children
  return (
    <Typography
      {...rest}
      className={classNames(
        classes.root,
        classes.Wysiwyg,
        style.background && style.background.backgroundImage && classes.backgroundImage,
        elevation && classes[`elevation${elevation}`]
      )}
      component="div"
      style={style}
    >
      {renderHTML(child)}
    </Typography>
  )
}

Wysiwyg.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  style: PropTypes.object,
}

Wysiwyg.displayName = `${displayName}Wysiwyg`

export default Wysiwyg
