import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import CommonButtonBase from '../../common/components/ButtonBase'
import displayName from './displayName'

const ButtonBase = (props) => {
  const {
    children,
    classes,
    elevation,
    href,
    style,
    ...rest
  } = props
  return (
    <CommonButtonBase
      {...rest}
      href={href}
      className={classNames(
        classes.root,
        classes.ButtonBase,
        style.background && style.background.backgroundImage && classes.backgroundImage,
        elevation && classes[`elevation${elevation}`],
      )}
      style={style}

    >
      {children}
    </CommonButtonBase>
  )
}

ButtonBase.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  elevation: PropTypes.number,
  onClick: PropTypes.func,
  href: PropTypes.string,
  style: PropTypes.object,
}

ButtonBase.displayName = `${displayName}ButtonBase`

export default ButtonBase
