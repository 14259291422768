import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, SubmissionError } from 'redux-form'

import FormButtonContext from './FormButtonContext'
import SnackbarContext from '../snackbar/SnackbarContext'

const Form = ({
  children,
  className,
  clearSubmitErrors,
  dispatch,
  error,
  form,
  handleSubmit,
  invalid,
  isReset,
  onSubmit,
  pristine,
  reset,
  submitFailed,
  submitSucceeded,
  submitting,
  successMessage,
}) => {
  const { onSnackOpen } = useContext(SnackbarContext)

  const onFormSubmit = useCallback((values) => onSubmit(values)
    .then(() => {
      if (successMessage) onSnackOpen({ severity: 'success', message: successMessage })
      if (isReset) reset()
    })
    .catch((error) => {
      const submissionError = error.field ? {
        [error.field]: error.message,
        _error: error.message,
      } : { _error: error.message }
      throw new SubmissionError(submissionError)
    }), [isReset, onSnackOpen, onSubmit, reset, successMessage])

  const buttonSuccessableProps = {
    clearSubmitErrors,
    error,
    form,
    invalid,
    onSubmit: handleSubmit(onFormSubmit),
    pristine,
    reset,
    submitFailed,
    submitSucceeded,
    submitting,
  }
  return (
    <FormButtonContext.Provider value={buttonSuccessableProps}>
      <form className={className}>
        {children}
      </form>
    </FormButtonContext.Provider>
  )
}


Form.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  clearSubmitErrors: PropTypes.func.isRequired,
  error: PropTypes.string,
  form: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  invalid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  validate: PropTypes.func,
}


export default reduxForm({ enableReinitialize: true })(Form)
