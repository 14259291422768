import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'

import ListItem from './ListItem'
import Loading from '../common/components/Loading'
import displayName from './displayName'
import Add from './Add'
import useReducer from '../common/hooks/useReducer'
import { itemsReducer } from './reducers'
import bindActionCreators from '../common/utils/bindActionCreators'
import * as Actions from './actions'

const styles = theme => ({
  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  cardContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
})

const ListVertical = props => {
  const {
    classes,
    isReplyingToReview,
    onAuth,
    onDoc,
    onDocName,
    onModel,
    onReplyToReview,
    review,
    user,
  } = props

  const [state, dispatch] = useReducer(itemsReducer, {
    count: 0,
    error: null,
    isFetching: false,
    isLoading: true,
    items: {},
    lastItem: {},
  })
  const {
    onAdd,
    onDelete,
    onDeleteAdmin,
    onFind,
    onUpdate,
    onUpdateLikes,
  } = bindActionCreators(Actions, dispatch)
  const {
    count,
    items,
    lastItem,
    isLoading,
    isFetching,
  } = state

  useEffect(() => {
    onFind({ review: review._id })
  }, [])

  if (isFetching) return <Loading />

  const ids = Object.keys(items).filter(i => items[i].review === review._id)
  const parentComments = ids.filter(id => !items[id].parentComment)

  return (
    <div>
      {isReplyingToReview ?
        <Add
          comment={{}}
          form={`commentAdd`}
          initialValues={{}}
          onAdd={onAdd}
          onClose={() => onReplyToReview(false)}
          onDoc={onDoc}
          onDocName={onDocName}
          onModel={onModel}
          parentCommentId={null}
          reviewId={review._id}
        />
        : null}
      <ul className={classes.ul}>
        {parentComments.map(_id => (
          <ListItem
            comment={items[_id]}
            ids={ids}
            items={items}
            key={_id}
            onAdd={onAdd}
            onAuth={onAuth}
            onDelete={onDelete}
            onDeleteAdmin={onDeleteAdmin}
            onDocName={onDocName}
            onUpdate={onUpdate}
            onUpdateLikes={onUpdateLikes}
            review={review}
            user={user}
          />
        ))}
      </ul>
    </div>
  )
}

ListVertical.propTypes = {
  classes: PropTypes.object.isRequired,
  isReplyingToReview: PropTypes.bool.isRequired,
  onAuth: PropTypes.func.isRequired,
  onDoc: PropTypes.string.isRequired,
  onDocName: PropTypes.string.isRequired,
  onModel: PropTypes.string.isRequired,
  onReplyToReview: PropTypes.func.isRequired,
  review: PropTypes.object.isRequired,
  user: PropTypes.object,
}

ListVertical.displayName = `${displayName}ListVertical`

export default withStyles(styles)(ListVertical)