const clean = object => {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === 'object') {
      clean(v)
    }
    if ((v && typeof v === 'object' && !Object.keys(v).length && !(v instanceof Date)) || v === null || v === undefined) {
      if (Array.isArray(object)) {
        object.splice(k, 1);
      } else {
        delete object[k]
      }
    }
  })
  return object
}

const cleanObj = obj => {
  const object = { ...obj }
  const cleaned = clean(object)
  return cleaned
}

export default cleanObj