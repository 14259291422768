import fetchGraphQL from '../../graphql/fetchGraphQL'
import styleFields from '../../common/fields/styleFields'
import { componentType } from '../../component/public/types'

const pageFields = `
  name
  slug
  url
  published {
    childComponents
    description
    style { ${styleFields} }
  }
`

// QUERIES
export const pageFind = async input => {
  try {
    const { pageFind: item } = await fetchGraphQL({
      query: `
        query PageFind($input: PageFindInput!) {
          pageFind(input: $input) { 
            components { ${componentType} }
            pages { ${pageFields} }
          }
        }
    `,
      variables: { input }
    })
    return item
  } catch (error) {
    return Promise.reject(error)
  }
}