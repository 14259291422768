import fetchGraphQL from '../../graphql/fetchGraphQL'


// QUERIES
export const productFind = async (input) => {
  const { productFind: items } = await fetchGraphQL({
    query: `
      query ProductFind($input: ProductFindInput!) {
        productFind(input: $input) {
          _id
          appName
          imageSrc
          name
          price
          rating
          reviews
          slug
        }
      }
    `,
    variables: { input },
  }).catch((error) => Promise.reject(error))
  return items
}


export const productFindOne = async (input) => {
  const { productFindOne: item } = await fetchGraphQL({
    query: `
      query ProductFindOne($input: ProductFindOneInput!) {
        productFindOne(input: $input) {
          _id
          isDraft
          isPublished
          published {   
            categories
            description
            detail
            images { 
              _id 
              src 
            }
            name
            type
            variants { 
              _id
              image
              name
              price
              product
              requiresShipping
              taxable 
            } 
          }
          rating
          reviews
          slug
        }
      }
    `,
    variables: { input },
  }).catch((error) => Promise.reject(error))
  return item
}
