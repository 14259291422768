import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

import displayName from './displayName'
import ScrollIntoView from '../../common/components/ScrollIntoView'
import CommentList from '../../comment/ListVertical'
import Update from './Update'
import Rating from '../../rating/Rating'
import Buttons from './Buttons'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1.5),
    },
    flex: '1 1 100%',
  },
  review: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  reviewContent: {
    flex: '1 1 auto',
    marginTop: 12,
  },
  header: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rating: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  date: {
    marginRight: 8,
    paddingTop: 2,
  },
  form: {
    flex: '1 1 auto',
  },
})


const ListItem = ({
  classes,
  onAuth,
  onDelete,
  onDeleteAdmin,
  onDoc,
  onDocName,
  onModel,
  onUpdate,
  onUpdateLikes,
  onUpdateParentSuccess,
  review,
  user,
}) => {
  const [state, setState] = useState({ isEditing: false, isReplying: false })

  const handleReply = useCallback((bool) => {
    if (bool) {
      if (user._id) {
        setState({ ...state, isReplying: true })
      } else {
        onAuth()
      }
    }
    setState({ ...state, isReplying: false })
  }, [onAuth, state, user._id])

  return (
    <li id={review._id} className={classes.root}>
      <ScrollIntoView id={review._id} />
      {state.isEditing
        ? (
          <div className={classes.form}>
            <Update
              onClose={() => setState({ ...state, isEditing: false })}
              onDocName={onDocName}
              onUpdate={onUpdate}
              review={review}
              onUpdateParentSuccess={onUpdateParentSuccess}
            />
          </div>
        )
        : (
          <div className={classes.review}>
            <div className={classes.reviewContent}>
              <div className={classes.header}>
                <Typography variant="body1">
                  {`${review.user.firstName} ${review.user.lastName}`}
                </Typography>
                <div className={classes.rating}>
                  <Typography className={classes.date}>{moment(review.createdAt).format('MMMM Do, YYYY')}</Typography>
                  <Rating stars={review.rating} />
                </div>
              </div>
              <Typography>{review.text}</Typography>
              <Buttons
                onAuth={onAuth}
                onDelete={onDelete}
                onDeleteAdmin={onDeleteAdmin}
                onDoc={onDoc}
                onDocName={onDocName}
                onEdit={() => setState({ ...state, isEditing: !state.isEditing })}
                onModel={onModel}
                onReply={handleReply}
                onUpdate={onUpdate}
                onUpdateLikes={onUpdateLikes}
                onUpdateParentSuccess={onUpdateParentSuccess}
                review={review}
                user={user}
              />
            </div>
          </div>
        )}
      <CommentList
        isReplyingToReview={state.isReplying}
        onAuth={onAuth}
        onDoc={onDoc}
        onDocName={onDocName}
        onModel={review.onModel}
        onReplyToReview={handleReply}
        review={review}
        user={user}
      />
    </li>
  )
}

ListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  onAuth: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDeleteAdmin: PropTypes.func.isRequired,
  onDoc: PropTypes.string.isRequired,
  onDocName: PropTypes.string.isRequired,
  onModel: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateLikes: PropTypes.func.isRequired,
  review: PropTypes.object.isRequired,
  user: PropTypes.object,
}

ListItem.displayName = `${displayName}ListItem`

export default withStyles(styles)(ListItem)
