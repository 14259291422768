import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import green from '@material-ui/core/colors/green'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import FieldText from '../../fields/FieldText'
import Total from '../Total'
import { formatDollar } from '../../common/utils/formatters'
import { parseOnlyNums, parseInteger } from '../../common/utils/parsers'
import { creditFindOne } from './api'

const styles = theme => ({
  successLabel: {
    color: green[700],
    '&$successLabelFocued': {
      color: green[700],
    },
  },
  successLabelFocued: {},
  successUnderline: {
    '&:after': {
      borderBottomColor: green[700],
    },
  },
  checkoutTotal: {
    padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`
  },
})


const GiftCardField = props => {
  const [error, setError] = useState(null)
  const {
    classes,
    input,
    total,
    email,
  } = props
  const {
    name,
    value
  } = input
  return (
    <>
      <Field
        component={FieldText}
        fullWidth
        label={`Do you have a gift card to redeem ${value.balance ? formatDollar(value.balance) : ''}`}
        name={`${name}.number`}
        onChange={() => error && setError(null)}
        validate={value => {
          if (!value) return undefined
          return error
        }}
        onBlur={async () => {
          if (!value.number) {
            input.onChange({ balance: null })
            return Promise.resolve()
          }
          return creditFindOne({ number: value.number, email })
            .then(credit => {
              if (credit && credit.balance) {
                return input.onChange({ balance: credit.balance })
              }
              setError('That credit was not found')
            })
            .catch(error => {
              setError('That credit was not found')
            })
        }}
        InputLabelProps={{
          classes: {
            root: value.balance && classes.successLabel,
            focused: value.balance && classes.successLabelFocued
          }
        }}
        InputProps={{
          classes: {
            underline: value.balance && classes.successUnderline,
          }
        }}
        parse={parseOnlyNums}
      />

      {value.balance ?
        <Total
          {...total}
          className={classes.checkoutTotal}
          isDisplayRemainingGiftCardBalance={true}
          typography="body2"
        />
        : null}
    </>
  )
}

GiftCardField.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  total: PropTypes.object.isRequired,
}


export default withStyles(styles)(GiftCardField)