import styleFields from '../../common/fields/styleFields'

export const componentValuesType = `
  alt
  categories
  children
  color
  component
  components
  elevation
  href
  size
  src
  style { ${styleFields} }
  title
  titleAccess
  typographies { _id children variant }
  variant
  viewBox
`
