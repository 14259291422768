import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import getImageSrc from '../../image/common/getImageSrc'
import displayName from './displayName'

const Image = props => {
  const {
    alt,
    children,
    classes,
    elevation,
    src,
    style = {},
    id,
    ...rest
  } = props
  const { flex, margin, ...styleRest } = style

  return (
    <div
      {...rest}
      style={{ flex, margin }}
      className={classNames(
        classes.Image,
        style.background && style.background.backgroundImage && classes.backgroundImage,
      )}
    >
      <img
        alt={alt}
        className={classNames(
          classes.root,
          classes.image,
          elevation && classes[`elevation${elevation}`]
        )}
        id={id}
        src={getImageSrc(src)}
        style={styleRest}
      />
      {children}
    </div>

  )
}

Image.propTypes = {
  alt: PropTypes.string,
  background: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  elevation: PropTypes.number,
  src: PropTypes.string.isRequired,
}

Image.displayName = `${displayName}Image`

export default Image
