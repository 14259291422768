import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  highlight: {
    backgroundColor: theme.palette.primary.light,
  },
})

function HighlightText({
  text,
  highlightText,
  classes,
}) {
  const cleaned = text.replace(/<[^>]*>?/gm, '')
  const parts = cleaned.split(new RegExp(`(${highlightText})`, 'i')).filter(Boolean)
  return (
    <span>
      {parts.map((part, i) => {
        if (part.toLowerCase() === highlightText.toLowerCase()) {
          return <span className={classes.highlight} key={i}>{part}</span>
        }
        return <span key={i}>{part}</span>
      })}
    </span>
  )
}

export default withStyles(styles)(HighlightText)
