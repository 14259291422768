import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import CardContent from '@material-ui/core/CardContent'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'

import displayName from './displayName'
import { formatDollar } from '../../common/utils/formatters'
import CartListItem from './CartListItem'
import Card from '../../common/components/Card'

const styles = theme => ({
  li: {
    flex: '1 1 auto',
  },
  card: {
    flexFlow: 'row wrap',
  },
  contents: {
    flex: '1 1 100%',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between'
  },
  content: {
    flex: '1 1 auto',
  },
  buttonsContainer: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  ul: {
    display: 'flex',
    flexFlow: 'column',
    listStyle: 'none',
    padding: 0,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
})

const ListItem = props => {
  const {
    buttons: Buttons,
    classes,
    history,
    item: order = {},
    match,
    shop,
  } = props
  const {
    _id,
    items,
    createdAt,
    deliveryAddress = {},
    isRefunded,
    isShipped,
    shippedAt,
    refundedAt,
    total,
  } = order
  return (
    <li className={classes.li}>
      <Card to={`${match.url}/${order._id}`} className={classes.card} >
        <div className={classes.contents}>
          <CardContent className={classes.content}>
            <Typography>Order #</Typography>
            <Typography>{_id}</Typography>
          </CardContent>
          <CardContent className={classes.content}>
            <Typography>Delivery</Typography>
            <Typography>{deliveryAddress.email}</Typography>
          </CardContent>
          <CardContent className={classes.content}>
            <Typography>Total</Typography>
            <Typography>{formatDollar(total)}</Typography>
          </CardContent>
          <CardContent className={classes.content}>
            <Typography>Date {isRefunded ? 'Refund' : isShipped ? 'Ship' : 'Order'}</Typography>
            <Typography>{moment(isRefunded ? refundedAt : isShipped ? shippedAt : createdAt).format("YYYY-MM-DD, h:mm a")}</Typography>
          </CardContent>
          {Buttons ?
            <CardContent className={classes.buttonsContainer}>
              <Buttons {...props} />
            </CardContent>
            : null}
        </div>
        <ul className={classes.ul}>
          {items.map(item => (
            <CartListItem
              shop={shop}
              item={item}
              key={item._id}
              onClick={() => history.push(`/${shop.slug}/${item.slug}`)}
            />
          ))}
        </ul>
      </Card>
    </li>
  )
}

ListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

const mapStateToProps = ({
  app: { isFetching, shop },
}) => ({
  isFetching,
  shop,
})

ListItem.displayName = `${displayName}ListItem`

export default connect(mapStateToProps)(withStyles(styles)(ListItem))
