const encode = (value) => encodeURIComponent(value)

const buildQuery = (obj) => {
  return obj ? Object.keys(obj)
    .map(key => {
      const value = obj[key]
      if (value === undefined) {
        return ''
      }
      if (value === null) {
        return ''
      }
      if (Array.isArray(value)) {
        const result = []
        for (const value2 of value.slice()) {
          if (value2 === undefined) {
            continue
          }
          result.push(`${encode(key)}=${encode(value)}`)
        }
        return result.join('&')
      }
      return `${encode(key)}=${encode(value)}`
    })
    .filter(x => x.length > 0)
    .join('&') : ''
}

export default buildQuery
