import React from 'react'
import PropTypes from 'prop-types'
import MuiTypography from '@material-ui/core/Typography'
import classNames from 'classnames'

import displayName from './displayName'


const Typography = (props) => {
  const {
    children,
    classes,
    elevation,
    style,
    variant,
    ...rest
  } = props
  return (
    <MuiTypography
      {...rest}
      className={
        classNames(
          classes.root,
          classes.Typography,
          style.background && style.background.backgroundImage && classes.backgroundImage,
          elevation && classes[`elevation${elevation}`],
        )
      }
      style={style}
      variant={variant}
    >
      {children}
    </MuiTypography>
  )
}

Typography.propTypes = {
  style: PropTypes.object,
}

Typography.displayName = `${displayName}Typography`

export default Typography
