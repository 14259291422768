import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

import displayName from './displayName'
import ListItem from './ListItem';
import ULHorizontalBase from '../../common/components/ULHorizontalBase'

const styles = theme => ({
  ul: {
    justifyContent: 'space-around',
  },
})

function ListHorizontal({
  categories,
  category,
  classes,
  match,
  shop,
}) {
  const ids = Object.keys(categories).filter(id => categories[id].categories.includes(category._id))
  return (
    <ULHorizontalBase className={classes.ul}>
      {ids.map(id => {
        return (
          <ListItem
            key={id}
            category={categories[id]}
            to={`${match.url}/${categories[id].slug}`}
          />
        )
      })}
    </ULHorizontalBase>
  )
}

ListHorizontal.propTypes = {
  categories: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
}

ListHorizontal.displayName = `${displayName}ListHorizontal`

export default withStyles(styles)(ListHorizontal)