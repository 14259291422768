import React from 'react'
import PropTypes from 'prop-types'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import displayName from './displayName'
import Card from '../../common/components/Card'
import FadeInIfNeeded from '../../common/components/FadeInIfNeeded'
import getImageSrc from '../../image/common/getImageSrc'

const styles = theme => ({
  li: {
    flex: '0 0 300px',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '100%',
    minWidth: '100%',
    verticalAlign: 'top',
    width: '100%',
  },
})

function ListItem(props) {
  const {
    classes,
    category = {},
    to,
  } = props
  const {
    image = {},
    name
  } = category
  return (
    <li className={classes.li}>
      <Card to={to} imageSrc={image.src}>
        <CardActionArea>
          {image.src ?
            <img
              alt={name}
              src={getImageSrc(image.src)}
              className={classes.image}
            />
            : null}
          <CardContent className={classes.cardContent}>
            <Typography variant="h6">{name}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </li>
  )
}

ListItem.propTypes = {
  category: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
}

ListItem.displayName = `${displayName}ListItem`

export default withStyles(styles)(ListItem)
