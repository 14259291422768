import React from 'react'
import PropTypes from 'prop-types'
import IconRestore from '@material-ui/icons/SettingsBackupRestore'
import IconShip from '@material-ui/icons/LocalShipping'
import Typography from '@material-ui/core/Typography'
import moment from 'moment';

import displayName from './displayName'
import ButtonAsync from '../../common/components/ButtonAsync'
import { onRefundRequest } from './actions';


const Buttons = props => {
  const {
    classes,
    onRefundRequest,
    onShipRequest,
    item = {}
  } = props
  const {
    _id,
    isRefunded,
    refundRequestedAt,
    shipRequestedAt,
    isRefundRequested,
    refundedAt,
    shippedAt,
    isShipped,
    isShippingRequested,
  } = item
  return (
    <>
      {refundRequestedAt ? null :
        <ButtonAsync
          confirm="Are you sure you want to request a refund for this order?"
          onClick={() => onRefundRequest({ _id })}
          tooltip="Request Refund"
          displaySuccess={true}
        >
          <IconRestore />
        </ButtonAsync>
      }

      {shipRequestedAt ? null :
        <ButtonAsync
          confirm="Are you sure you want to request shipping for this order?"
          onClick={() => onShipRequest({ _id })}
          tooltip="Request Shipping"
          displaySuccess={true}
        >
          <IconShip />
        </ButtonAsync>
      }
    </>
  )
}

Buttons.propTypes = {
  _id: PropTypes.string,
  onRefundRequest: PropTypes.func.isRequired,
  onShipRequest: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
}

Buttons.displayName = `${displayName}Buttons`

export default Buttons
