import * as userActions from '../../user/user/actions'
import createActions from '../../redux/createActions'
import {
  addressAdd,
  addressUpdate,
  addressFind,
  addressFindOne,
  addressDelete,
} from './api'


import { reducerName } from './reducers'

const {
  ADD,
  DELETE,
  DELETES,
  ERROR,
  RECEIVE,
  REQUEST,
  RESET,
  UPDATE,
} = createActions(reducerName)

const onError = error => ({ type: ERROR, error })
const onRequest = () => ({ type: REQUEST })
export const onReset = () => ({ type: RESET })


// Create
export const onAddSuccess = item => ({ type: ADD, item })
export const onAdd = input => async (dispatch) => {
  const { address, user } = await addressAdd(input).catch((error) => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  dispatch(onAddSuccess(address))
  return dispatch(userActions.onUpdateSuccess(user))
}


// Read Items
const onFindSuccess = items => ({ type: RECEIVE, items })
export const onFind = input => async (dispatch) => {
  dispatch(onRequest())
  const items = await addressFind(input).catch((error) => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onFindSuccess(items))
}

const onFindOneSuccess = item => ({ type: ADD, item })
export const onFindOne = input => async (dispatch) => {
  dispatch(onRequest())
  const item = await addressFindOne(input).catch((error) => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onFindOneSuccess(item))
}


// Update
const onUpdateSuccess = item => ({ type: UPDATE, item })
export const onUpdate = input => async (dispatch) => {
  const item = await addressUpdate(input).catch((error) => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onUpdateSuccess(item))
}


// Delete
const onDeleteSuccess = _id => ({ type: DELETE, _id })
export const onDelete = input => async (dispatch) => {
  dispatch(userActions.onRequest())
  const { address, user } = await addressDelete(input).catch((error) => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  dispatch(userActions.onUpdateSuccess(user))
  return dispatch(onDeleteSuccess(address._id))
}
