import createActions from '../../redux/createActions'

import {
  categoryAdd,
  categoryUpdate,
  categoryFind,
  categoryDelete
} from './api'

import { reducerName } from './reducers'

const {
  ADD,
  DELETE,
  RECEIVE,
  REQUEST,
  UPDATE,
  RESET,
  ERROR,
} = createActions(reducerName)

const onError = error => ({ type: ERROR, error })
export const onRequest = () => ({ type: REQUEST })
export const onReset = () => ({ type: RESET })


export const onFindSuccess = items => ({ type: RECEIVE, items })
export const onFind = input => async dispatch => {
  dispatch(onRequest())
  const items = await categoryFind(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onFindSuccess(items))
}

