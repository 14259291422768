export const reducerName = 'DIALOG'

const OPEN = `OPEN_${reducerName}`
const CLOSE = `CLOSE_${reducerName}`


export const onDialog = (dialog) => {
  if (dialog) {
    return {
      type: OPEN,
      dialog
    }
  }
  return {
    type: CLOSE,
  }
}

