import createActions from '../redux/createActions'
import fetchApi from '../fetch/fetchApi'
import { reducerName } from './reducers'

const route = 'carts'

const {
  ADD,
  DELETE,
  ERROR,
  RECEIVE,
  REQUEST,
  UPDATE,
} = createActions(reducerName)

const onRequest = () => ({ type: REQUEST })
const onError = error => ({ type: ERROR, error })

// Create
const onAddSuccess = (cart) => ({ type: ADD, cart })
export const onAdd = (update) => {
  return (dispatch, getState) => {
    const cartId = localStorage.getItem('cartId')
    return fetchApi({
      auth: false,
      body: update,
      method: cartId ? 'PATCH' : 'POST',
      endpoint: cartId ? `${route}/${cartId}` : route,
    })
      .then(json => dispatch(onAddSuccess(json)))
  }
}


// Read
const onFetchSuccess = (cart) => ({ type: RECEIVE, cart })
export const onFetch = (cartId) => {
  return (dispatch, getState) => {
    dispatch(onRequest())
    return fetchApi({
      auth: false,
      body: null,
      method: 'GET',
      endpoint: `${route}/${cartId}`,
    })
      .then(json => {
        return dispatch(onFetchSuccess(json))
      })
      .catch(error => {
        localStorage.removeItem('cartId')
        return dispatch(onError(error))
      })
  }
}


// Update
export const onDeleteSuccess = () => ({ type: DELETE })
const onUpdateSuccess = (cart) => ({ type: UPDATE, cart })
export const onUpdate = (update) => {
  return (dispatch, getState) => {
    const cartId = localStorage.getItem('cartId')
    return fetchApi({
      auth: false,
      body: update,
      method: 'PATCH',
      endpoint: `${route}/${cartId}`,
    })
      .then(doc => {
        if (doc._id) return dispatch(onUpdateSuccess(doc))
        localStorage.removeItem('cartId')
        return dispatch(onDeleteSuccess())
      })
  }
}




