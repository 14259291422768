function createActions(reducerName) {
  return {
    ADD: `ADD_${reducerName}`,
    DELETE: `DELETE_${reducerName}`,
    DELETES: `DELETES_${reducerName}`,
    ERROR: `ERROR_${reducerName}`,
    FILTER: `FILTER_${reducerName}`,
    RECEIVE: `RECEIVE_${reducerName}`,
    REQUEST: `REQUEST_${reducerName}`,
    RESET: `RESET_${reducerName}`,
    UPDATE: `UPDATE_${reducerName}`,
  }
}

export default createActions