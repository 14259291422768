import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import Filter from '../../filter/Filter4'
import HeadList from '../../head/HeadList'
import Loading from './Loading'
import ULVerticalBase from './ULVerticalBase'
import usePaginateVertical from '../hooks/usePaginateVertical'
import usePrevious from '../hooks/usePrevious';

const styles = theme => ({
  page: {
    paddingTop: 0
  },
  cardContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
})

const ListVerticalBase = props => {
  const {
    className,
    classes,
    count = 0,
    filter,
    headProps = {},
    isFetching,
    isLoading,
    items = {},
    lastItem,
    listItem: ListItem,
    none,
    title,
    ...rest
  } = props

  const [input, setInput] = useState({ ...props.input, ...filter.filterDefaults })
  const prevInput = usePrevious(input)

  const { ulRef, ulEndRef, isVisible } = usePaginateVertical()

  useEffect(() => {
    props.onFind({ ...input, lastItem })
  }, [])

  useEffect(() => {
    if (input !== prevInput) {
      props.onFilter({ ...input })
    }
  }, [input])

  useEffect(() => {
    if (isVisible && count > 0) {
      props.onFind({ ...input, lastItem })
    }
  }, [isVisible])

  return (
    <div>
      {headProps.name ?
        <HeadList
          name={headProps.name}
          slug={headProps.slug}
          urls={Object.keys(items).map(id => `https://${process.env.REACT_APP_NAME}/${headProps.slug}/${items[id].slug}`)}
        />
        : null}
      <CardContent className={classes.cardContent}>
        {title ? <Typography variant="h5">{title}</Typography> : null}
        <Filter
          count={count}
          onFilter={filter => setInput({ ...input, ...filter })}
          filterOptions={filter.filterOptions}
          filterDefaults={filter.filterDefaults}
          searchPlaceholder="Search"
        />
      </CardContent>
      <ULVerticalBase
        className={className}
        ulRef={ulRef}
        ulEndRef={ulEndRef}
        isFetching={isFetching}
        onFind={() => props.onFind({ ...input, lastItem })}
        none={none}
      >
        {Object.keys(items).map(_id => (
          <ListItem
            {...rest}
            item={items[_id]}
            key={_id}
          />
        ))}
      </ULVerticalBase>
    </div>
  )
}

ListVerticalBase.propTypes = {
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  headProps: PropTypes.object,
  input: PropTypes.object,
  none: PropTypes.string,
  title: PropTypes.string,
}

export default withStyles(styles)(ListVerticalBase)