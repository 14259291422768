import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconFavoriteBorder from '@material-ui/icons/FavoriteBorder'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import SnackbarContext from '../snackbar/SnackbarContext'

import displayName from './displayName'
import IconButton from '../icons/IconButton'
import {
  commentReportAbuse,
  commentDelete,
  commentDeleteAdmin,
  commentUpdateLikes,
} from './api'

const styles = {
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  iconButton: {
    fontSize: 16
  },
}

const Buttons = props => {
  const { onSnackOpen } = useContext(SnackbarContext)
  const [isFetching, setFetching] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const {
    classes,
    comment,
    onAuth,
    onDelete,
    onDeleteAdmin,
    onDocName,
    onEdit,
    onReply,
    onUpdate,
    onUpdateLikes,
    review,
    user,
  } = props
  const isCommentOwner = comment.user._id === user._id
  const isAdmin = user.roles.includes('admin') ? user : null

  const handleEdit = () => {
    setAnchorEl(null)
    return onEdit()
  }
  const handleDelete = () => {
    setAnchorEl(null)
    if (comment && comment._id) {
      if (window.confirm('Are you sure you want to delete this comment?')) {
        isAdmin ? onDeleteAdmin({ _id: comment._id }) : onDelete({ _id: comment._id })
      }
    }
  }
  const handleReportAbuse = () => {
    setFetching(true)
    setAnchorEl(null)
    return commentReportAbuse({
      href: `${window.location.href}#${comment._id}`,
      onDocName,
      onModel: comment.onModel,
      text: comment.text,
    }).then(() => {
      setFetching(false)
      return onSnackOpen({ severity: 'success', message: 'Abuse reported!' })
    })
  }
  const handleAuth = () => {
    if (user._id) return
    return onAuth()
  }
  const handleReply = () => {
    handleAuth()
    if (user._id) return setAnchorEl(null)
    return
  }
  const handleLike = () => {
    handleAuth()
    if (user._id) {
      const hasLike = comment.likes.find(like => like === user._id)
      return onUpdateLikes({
        _id: comment._id,
        like: hasLike ? null : user._id,
        unlike: hasLike ? user._id : null,
      })
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.root}>
        <IconButton onClick={handleLike} tooltip="Like">
          <IconFavoriteBorder className={classes.iconButton} />
        </IconButton>
        <Typography>
          {comment.likes.length > 0 && comment.likes.length}
        </Typography>
      </div>
      <Button size="small" onClick={() => onReply(true)}>Reply</Button>
      <IconButton
        aria-label="More"
        aria-owns={anchorEl ? 'long-menu' : null}
        aria-haspopup="true"
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        {isFetching ? <CircularProgress size={20} /> : <MoreHoriz />}
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {isCommentOwner ? <MenuItem onClick={handleEdit}>Edit</MenuItem> : null}
        {isCommentOwner || isAdmin ? <MenuItem onClick={handleDelete}>Delete</MenuItem> : null}
        {!isCommentOwner ? <MenuItem onClick={handleReportAbuse}>Report abuse</MenuItem> : null}
      </Menu>
    </div>
  )
}

Buttons.propTypes = {
  classes: PropTypes.object.isRequired,
  comment: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  review: PropTypes.object.isRequired,
  user: PropTypes.object,
}

Buttons.displayName = `${displayName}Buttons`

export default withStyles(styles)(Buttons)
