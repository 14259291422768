import getImageSrc from './getImageSrc'

function loadImage(src) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = getImageSrc(src)
    if (image.complete) return resolve({ image, loaded: true })
    image.onload = () => resolve({ image, loaded: false })
  })
}

function loadImages(srcs) {
  const promises = srcs.map(src => loadImage(src))
  return Promise.all(promises).catch((err) => console.warn(err.message))
}

export default loadImages