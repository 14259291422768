const itemsReducerFn = (
  reducerName,
  state = {
    count: 0,
    error: null,
    isLoading: true,
    isFetching: false,
    items: {},
    lastItem: {},
  },
  action,
) => {
  switch (action.type) {
    case `ADD_${reducerName}`:
      return {
        ...state,
        count: state.count + 1,
        isLoading: false,
        isFetching: false,
        items: {
          [action.item._id]: { ...action.item },
          ...state.items,
        },
      }
    case `DELETE_${reducerName}`: {
      const { [action._id]: deletedItem, ...rest } = state.items
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        items: { ...rest },
      }
    }
    case `ERROR_${reducerName}`:
      return {
        ...state,
        error: action.error.message,
        isFetching: false,
        isLoading: false,
      }
    case `FILTER_${reducerName}`: {
      const items = action.items.reduce((a, v) => {
        a[v._id] = v
        return a
      }, {})
      const count = action.items.length
      const lastItem = count > 0 ? action.items[count - 1] : state.lastItem
      return {
        count,
        error: null,
        isFetching: false,
        isLoading: false,
        items,
        lastItem,
      }
    }
    case `RECEIVE_${reducerName}`: {
      const items = action.items.reduce((a, v) => {
        a[v._id] = v
        return a
      }, {})
      const count = action.items.length
      const lastItem = count > 0 ? action.items[count - 1] : state.lastItem
      return {
        ...state,
        count,
        error: null,
        isFetching: false,
        isLoading: false,
        items: { ...state.items, ...items },
        lastItem,
      }
    }
    case `REQUEST_${reducerName}`:
      return {
        ...state,
        isFetching: true,
      }
    case 'RESET': {
      return {
        count: {},
        error: null,
        isFetching: false,
        items: {},
        lastItem: {},
      }
    }
    case `UPDATE_${reducerName}`:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        items: {
          ...state.items,
          [action.item._id]: {
            ...state.items[action.item._id],
            ...action.item,
          },
        },
      }
    default:
      return state
  }
}

export default itemsReducerFn
