import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ProductListItem from '../product/public/ListItem'
import ULHorizontalBase from '../common/components/ULHorizontalBase'
import displayName from './displayName'
import itemsReducer from '../common/reducers/itemsReducer'
import usePaginateHorizontal from '../common/hooks/usePaginateHorizontal'
import useReducer from '../common/hooks/useReducer'
import { variantFindProductCategories } from '../variant/public/api'

async function onFind({ input, dispatch }) {
  dispatch({ type: 'REQUEST' })
  const items = await variantFindProductCategories(input).catch((error) => {
    dispatch({ type: 'ERROR', error })
    return Promise.reject(error)
  })
  return dispatch({ type: 'RECEIVE', items })
}

function ProductListHorizontal({ shop, onUpdate, input }) {
  const [state, dispatch] = useReducer(itemsReducer, {
    count: 0,
    isFetching: false,
  })
  const { ulRef, ulEndRef, isVisible } = usePaginateHorizontal()

  const {
    items = {},
    isFetching,
    lastItem,
    count,
  } = state

  useEffect(() => {
    onFind({
      input,
      dispatch,
      lastItem,
      sort: 'name-asc',
    })
  }, [])

  useEffect(() => {
    if (isVisible && count > 0) {
      onFind({
        input,
        dispatch,
        lastItem,
        sort: 'name-asc',
      })
    }
  }, [isVisible])

  return (
    <ULHorizontalBase
      count={count}
      isFetching={isFetching}
      onFind={() => onFind({
        input,
        dispatch,
        lastItem,
        sort: 'name-asc',
      })}
      ulEndRef={ulEndRef}
      ulRef={ulRef}
    >
      {Object.keys(items).map(_id => (
        <ProductListItem
          key={_id}
          item={items[_id]}
          shop={shop}
          onClick={() => onUpdate({
            type: 'ADD_TO_CART',
            variantId: _id,
            variantQty: 1,
          })}
        />
      ))}
    </ULHorizontalBase>
  )
}

ProductListHorizontal.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  onUpdate: PropTypes.func.isRequired,
  shop: PropTypes.instanceOf(Object).isRequired,
}

const mapStateToProps = ({
  app: { isFetching, shop },
}) => ({
  isFetching,
  shop,
})

ProductListHorizontal.displayName = `${displayName}ProductListHorizontal`

export default connect(mapStateToProps)(ProductListHorizontal)
