
import { componentValuesType } from '../common/types'

export const componentType = `
  _id
  isPublished
  page
  parent
  published: { ${componentValuesType} },
`
