import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import ButtonAsync from '../../common/components/ButtonAsync'
import Card from '../../common/components/Card'
import FieldCreditCard from '../../creditCard/common/FieldCreditCard'
import FieldPhone from '../../fields/FieldPhone'
import FieldText from '../../fields/FieldText'
import SnackbarContext from '../../snackbar/SnackbarContext'
import creditOptions from '../common/creditOptions'
import displayName from './displayName'
import { creditAdd } from './api'
import { formatDollar } from '../../common/utils/formatters'
import { onAdd } from '../user/actions'
import { parseInteger } from '../../common/utils/parsers'
import { validateEmail, validateRequired, validateCompose } from '../../common/utils/validators'

const styles = (theme) => ({
  fields: {
    padding: '0 8px',
  },
  cardActions: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
  },
})

const Purchase = (props) => {
  const { onSnackOpen } = useContext(SnackbarContext)
  const {
    change,
    classes,
    handleSubmit,
    history,
    onAdd,
    invalid,
    reset,
    submitting,
    user,
  } = props

  const onSubmit = useCallback((values) => {
    if (values.deliveryEmail === user.email) {
      return onAdd(values).then(() => {
        reset()
        return onSnackOpen({ severity: 'success', message: 'Credit Purchased!' })
      })
    }
    return creditAdd(values).then(() => {
      reset()
      onSnackOpen({ severity: 'success', message: 'Credit Purchased!' })
    })
  }, [onAdd, onSnackOpen, reset, user.email])

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Purchase Credit</Typography>
      </CardContent>
      <form>
        <CardContent className={classes.fields}>
          <Field
            component={FieldText}
            label="Delivery First Name"
            name="deliveryFirstName"
            validate={validateRequired}
          />
          <Field
            component={FieldText}
            label="Delivery Last Name"
            name="deliveryLastName"
            validate={validateRequired}
          />
          <FieldPhone
            component={FieldText}
            label="Delivery Phone"
            name="deliveryPhone"
            validate={validateRequired}
          />
          <Field
            component={FieldText}
            label="Delivery Email"
            name="deliveryEmail"
            validate={[validateRequired, validateEmail]}
          />
          <Field
            component={FieldText}
            label="Delivery Message"
            name="deliveryMessage"
            multiline
          />
          <Field
            component={FieldText}
            label="Amount"
            name="amount"
            validate={validateRequired}
            parse={parseInteger}
            select
            SelectProps={{
              native: true,
            }}
          >
            {creditOptions.map((option) => (
              <option key={formatDollar(option)} value={option}>
                {formatDollar(option)}
              </option>
            ))}
          </Field>
          <Field
            component={FieldText}
            label="Payment First Name"
            name="paymentFirstName"
            validate={validateRequired}
          />
          <Field
            component={FieldText}
            label="Payment Last Name"
            name="paymentLastName"
            validate={validateRequired}
          />
          <FieldPhone
            component={FieldText}
            label="Payment Phone"
            name="paymentPhone"
            validate={validateRequired}
          />
          <Field
            component={FieldText}
            label="Payment Email"
            name="paymentEmail"
            validate={[validateRequired, validateEmail]}
          />
          <Field
            component={FieldCreditCard}
            label="Credit Card"
            name="creditCard"
            validate={validateRequired}
          />
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button onClick={() => history.goBack()}>
            Back
          </Button>
          <ButtonAsync
            disabled={submitting || invalid}
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            type="submit"
          >
            Process
          </ButtonAsync>
        </CardActions>
      </form>
    </Card>
  )
}

Purchase.propTypes = {
  classes: PropTypes.object.isRequired,
}

Purchase.displayName = `${displayName}Purchase`

const mapStateToProps = ({ user }) => ({
  initialValues: {
    paymentEmail: user.email,
    paymentFirstName: user.firstName,
    paymentLastName: user.lastName,
    paymentPhone: user.phone,
  },
  user,
})
const mapDispatchToProps = (dispatch) => ({
  onAdd: (input) => dispatch(onAdd(input)),
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'creditUserPurchase',
  enableReinitialize: true,
})(withStyles(styles)(Purchase)))
