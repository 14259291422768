import fetchGraphQL from '../../graphql/fetchGraphQL'


// QUERIES
export const userFindOne = async input => {
  const { userFindOne: item } = await fetchGraphQL({
    query: `
      query UserFindOne($input: UserFindOneInput!) {
        userFindOne(input: $input) {
          _id
          accounts
          addresses
          email
          firstName
          lastName
          phone
          roles
          suppliers
        }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}







// MUTATIONS
export const userUpdate = async input => {
  const { userUpdate: item } = await fetchGraphQL({
    query: `
      mutation UserUpdate($input: UserUpdateInput!) {
        userUpdate(input: $input) {
          accounts
          addresses
          email
          firstName
          lastName
          phone
          roles
          suppliers
        }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}



export const userUpdatePassword = async input => {
  const { userUpdatePassword: item } = await fetchGraphQL({
    query: `
      mutation UserUpdatePassword($input: UserUpdatePasswordInput!) {
        userUpdatePassword(input: $input) {
          _id
          accounts
          addresses
          email
          firstName
          lastName
          phone
          roles
          suppliers
        }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}





export const userUpdateAddresses = async input => {
  const { userUpdateAddresses: item } = await fetchGraphQL({
    query: `
      mutation UserUpdateAddresses($input: UserUpdateAddressesInput!) {
        userUpdateAddresses(input: $input) {
          _id
          accounts
          addresses
          email
          firstName
          lastName
          phone
          roles
          suppliers
        }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}





export const userDelete = async input => {
  const { userDelete: item } = await fetchGraphQL({
    query: `
      mutation UserDelete($input: UserDeleteInput!) {
        userDelete(input: $input) {
          _id
        }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}