import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import AppBrand from '../app/AppBrand'
import Phone from '../common/components/Phone'

type ObjectMap = {
  [key: string]: any,
}

type App = {
  app: {
    address: ObjectMap,
    brand: ObjectMap,
    footer: ObjectMap,
  },
}

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  address: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  typography: {
    color: 'inherit',
  },
  copyRight: {
    color: 'inherit',
  },
}))


const FooterBrand = () => {
  const classes = useStyles()
  const app = useSelector(({ app }: App) => app)
  const {
    address = {},
    brand = {},
    footer = {}
  } = app
  const { style: footerStyle = {} } = footer

  return (
    <div className={classes.root} style={footerStyle}>
      <AppBrand
        isImageDisplayed={footer.brand.isImageDisplayed}
        isNameDisplayed={footer.brand.isNameDisplayed}
        isSvgDisplayed={footer.brand.isSvgDisplayed}
        style={footer.brand.style}
      />
      <div>
        <div className={classes.address}>
          {address.street && <Typography variant="caption" className={classes.typography}>{address.street}</Typography>}
          {address.city && (
            <Typography variant="caption" className={classes.typography}>
              {` ${address.city}, ${address.state} ${address.zip}`}
            </Typography>
          )}
        </div>
        <div className={classes.address}>
          {address.phone && (
            <Typography variant="caption" className={classes.typography}>
              <a href={`tel:${address.phone}`}><Phone>{address.phone}</Phone></a>
              &nbsp;|&nbsp;
            </Typography>
          )}
          {address.email && (
            <Typography variant="caption" className={classes.typography}>
              <a href={`mailto:${address.email}`}>{address.email}</a>
              &nbsp;|&nbsp;
            </Typography>
          )}
          <Typography variant="caption" component={Link} to="/" className={classes.copyRight}>
            &copy;
            {' '}
            {brand.name.children || 'App'}
            {' '}
            {new Date().getFullYear()}
          </Typography>
        </div>
      </div>
      {address.license && <Typography className={classes.typography}>{address.license}</Typography>}
    </div>
  )
}


export default FooterBrand
