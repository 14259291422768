export const reducerName = 'CART'

export const cartReducer = (state = {
  error: null,
  isFetching: false,
  items: [],
  quantity: null,
  subtotal: null,
  shipping: {
    service: 'standard',
    price: 0,
  }
}, action) => {
  switch (action.type) {
    case `ADD_${reducerName}`:
      return {
        ...state,
        ...action.cart,
        isFetching: false,
      }
    case `DELETE_${reducerName}`:
      return {
        isFetching: false,
        items: [],
        quantity: null,
        subtotal: null,
        tax: null,
        total: null,
      }
    case `ERROR_${reducerName}`:
      return {
        ...state,
        error: action.error,
        isFetching: false
      }
    case `RECEIVE_${reducerName}`:
      return {
        ...state,
        ...action.cart,
        isFetching: false
      }
    case `REQUEST_${reducerName}`:
      return {
        ...state,
        isFetching: true
      }
    case `UPDATE_${reducerName}`:
      return {
        ...state,
        ...action.cart,
        isFetching: false,
      }
    default:
      return state
  }
}