import createActions from '../redux/createActions'
import {
  commentFind,
  commentAdd,
  commentDelete,
  commentDeleteAdmin,
  commentUpdate,
  commentUpdateLikes,
} from './api'
import { reducerName } from './reducers'

const {
  ADD,
  DELETE,
  ERROR,
  FILTER,
  RECEIVE,
  REQUEST,
  RESET,
  UPDATE,
} = createActions(reducerName)

const onError = error => ({ type: ERROR, error })
const onRequest = () => ({ type: REQUEST })
export const onReset = () => ({ type: RESET })


// QUERIES
const onFindSuccess = items => ({ type: RECEIVE, items })
export const onFind = input => async dispatch => {
  dispatch(onRequest())
  const items = await commentFind(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onFindSuccess(items))
}



// MUTATIONS
export const onAddSuccess = item => ({ type: ADD, item })
export const onAdd = input => async dispatch => {
  const item = await commentAdd(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  dispatch(onAddSuccess(item))
}


const onUpdateSuccess = item => ({ type: UPDATE, item })
export const onUpdate = input => async dispatch => {
  const item = await commentUpdate(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onUpdateSuccess(item))
}
export const onUpdateLikes = input => async dispatch => {
  const item = await commentUpdateLikes(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onUpdateSuccess(item))
}


const onDeleteSuccess = _id => ({ type: DELETE, _id })
export const onDelete = input => async dispatch => {
  const item = await commentDelete(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onDeleteSuccess(item._id))
}

export const onDeleteAdmin = input => async dispatch => {
  const item = await commentDeleteAdmin(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onDeleteSuccess(item._id))
}
