import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import { Field } from 'redux-form'

import displayName from './displayName'
import FieldText from '../fields/FieldText'
import onDate from '../common/utils/onDate'
import { validateRequired } from '../common/utils/validators'
import { formatDollar } from '../common/utils/formatters'
import { onUpdate as onUpdateCart } from '../cart/actions'

const onChangeShipping = ({ change, dispatch, e, value }) => {
  dispatch(change('deliverBy', null))
  return dispatch(onUpdateCart({ shippingService: value }))
}

const ShippingSelect = (props) => {
  const {
    appShipping = {},
    change,
    dispatch,
    cart = {},
    priceRules,
    shippingService,
  } = props
  const { shipping: cartShipping = {} } = cart
  const standardShippingPriceRule = priceRules[appShipping.standardPriceRule] || {}
  return (
    <>
      <Field
        label="Select Shipping Option"
        name="shippingService"
        component={FieldText}
        validate={validateRequired}
        onChange={(e, value) => onChangeShipping({ e, value, change, dispatch })}
        fullWidth
        select
      >
        <MenuItem value="standard">
          Standard 3-7 days {cartShipping.price > 0 ? `${formatDollar(appShipping.standardPrice)} (${standardShippingPriceRule.description})` : null}
        </MenuItem>
        <MenuItem value="priority">
          Priority 2-3 days {formatDollar(appShipping.priorityPrice)}
        </MenuItem>
      </Field>
      {shippingService ?
        <Field
          label="Requested Delivery Date (not guaranteed)"
          name="deliverBy"
          type="date"
          fullWidth
          inputProps={{
            min: moment(onDate(), "YYYY-MM-DD").add('days', shippingService === 'priority' ? 3 : 4).format("YYYY-MM-DD")
          }}
          InputLabelProps={{
            shrink: true,
          }}
          component={FieldText}
        />
        : null}
    </>
  )
}

ShippingSelect.propTypes = {
  addresses: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  delivery: PropTypes.object,
  user: PropTypes.object.isRequired,
  appShipping: PropTypes.object.isRequired,
}

ShippingSelect.displayName = `${displayName}ShippingSelect`

export default ShippingSelect