import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import * as actions from './actions'
import ListItem from './ListItem'
import ListVerticalBase from '../../common/components/ListVerticalBase'
import ShopHeader from '../../shop/ShopHeader'
import bindActionCreators from '../../common/utils/bindActionCreators'
import combineReducers from '../../common/reducers/combineReducers'
import displayName from './displayName'
import filter from './filter'
import useReducer from '../../common/hooks/useReducer'
import { itemsReducer } from './reducers'

const rootReducer = combineReducers({
  products: itemsReducer,
})

const ListVertical = props => {
  const [state, dispatch] = useReducer(rootReducer)
  const boundActions = bindActionCreators(actions, dispatch)
  const { category } = props
  return (
    <>
      <ShopHeader name={category.name} description={category.description} />
      <ListVerticalBase
        {...props}
        {...state.products}
        {...boundActions}
        filter={filter}
        listItem={ListItem}
        none="No products"
        title="Products"
      />
    </>
  )
}


ListVertical.propTypes = {
  category: PropTypes.object.isRequired,
}

ListVertical.displayName = `${displayName}ListVertical`

export default ListVertical