export const parseDollar = (value) => {
  if (!value) return value
  const onlyNums = value.replace(/[^\d]/g, '')
  const dollars = (onlyNums / 100).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
  return `$${dollars}`
}

export const parsePrice = (value) => {
  if (!value) return value
  const onlyNums = value.replace(/[^\d]/g, '')
  const price = (onlyNums / 100).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
  return `$${price}`
}

export const parseNumber = value => (!value ? value : value.replace(/[^\d]/g, ''))

export const parseInteger = (value) => {
  if (!value) return value
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length) return parseInt(onlyNums, 10)
  return onlyNums
}


export const parseDecimal = (value) => {
  if (!value) return value
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length) return parseFloat(onlyNums)
  return onlyNums
}


export const parseOnlyNums = value => (!value ? value : value.replace(/[^\d]/g, ''))

export const parseState = (value) => {
  if (!value) return value
  const toUpperCase = value.toUpperCase()
  if (toUpperCase.length <= 2) return toUpperCase
  return
}

export const parseZip = (value) => {
  if (!value) return value
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 5) return onlyNums
}
