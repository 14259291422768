import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

import displayName from './displayName'
import ListItem from './ListItem';
import ULVerticalBase from '../../common/components/ULVerticalBase'

const styles = theme => ({
  ul: {
    justifyContent: 'space-around',
  },
})

function ListVertical({
  categories,
  category,
  classes,
  match,
  shop,
}) {
  const ids = Object.keys(categories).filter(id => categories[id].categories.includes(category._id))
  return (
    <ULVerticalBase className={classes.ul}>
      {ids.map(id => {
        return (
          <ListItem
            key={id}
            category={categories[id]}
            to={`${match.url}/${categories[id].slug}`}
          />
        )
      })}
    </ULVerticalBase>
  )
}

ListVertical.propTypes = {
  categories: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
}

ListVertical.displayName = `${displayName}ListVertical`

export default withStyles(styles)(ListVertical)