export const reducerName = 'SEARCH'

export const search = (state = {
  count: 0,
  isFetching: false,
  isOpen: false,
  items: {},
  lastBlogId: null,
  lastComponentId: null,
  lastProductId: null,
  limit: 18,
  value: '',
}, action) => {
  switch (action.type) {
    case `ADD_${reducerName}`:
      return {
        ...state,
        count: 0,
        isFetching: false,
        items: {},
        lastBlogId: null,
        lastComponentId: null,
        lastProductId: null,
        limit: 3,
        value: action.value,
      }
    case `DELETE_${reducerName}`:
      return {
        ...state,
        isOpen: false,
        value: '',
      }
    case `DELETES_${reducerName}`:
      return {
        count: 0,
        error: null,
        isFetching: false,
        items: {},
        lastBlogId: null,
        lastComponentId: null,
        lastProductId: null,
        limit: 3,
        value: '',
      }
    case `RECEIVE_${reducerName}`: {
      const blogCount = action.items.blogs.length
      const componentCount = action.items.components.length
      const productCount = action.items.products.length
      const lastBlogId = blogCount > 0 ? action.items.blogs[blogCount - 1]._id : state.lastBlogId
      const lastComponentId = componentCount > 0 ? action.items.components[componentCount - 1]._id : state.lastComponentId
      const lastProductId = productCount > 0 ? action.items.products[productCount - 1]._id : state.lastProductId
      const count = Math.max(blogCount, componentCount, productCount)
      const agg = [...action.items.blogs, ...action.items.components, ...action.items.products]
      const aggObj = agg.reduce((a, v) => { a[v._id] = v; return a }, {})
      return {
        ...state,
        count,
        lastBlogId,
        lastComponentId,
        lastProductId,
        isFetching: false,
        items: { ...state.items, ...aggObj },
      }
    }
    case `REQUEST_${reducerName}`:
      return {
        ...state,
        isFetching: true,
      }
    case `TOGGLE_${reducerName}`:
      return {
        ...state,
        isOpen: !state.isOpen,
        value: '',
      }
    default:
      return state
  }
}
