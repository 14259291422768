import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import * as pageActions from './actions'
import AccountRouter from './AccountRouter'
import Add from './Add'
import Main from '../../common/components/Main'
import Recovery from './Recovery'
import Reset from './Reset'
import RoutePrivate from '../../router/RoutePrivate'
import Signin from './Signin'
import Signup from './Signup'
import displayName from './displayName'


const Router = (props) => {
  const {
    classes,
    match,
  } = props
  return (
    <Switch>
      <RoutePrivate path={`${match.path}/account`} requiredRoles={['user']}>
        {(route) => (
          <AccountRouter {...props} {...route} />
        )}
      </RoutePrivate>
      <Route exact path={`${match.path}/add/:token`}>
        {(route) => (
          <Add {...props} {...route} />
        )}
      </Route>
      <Route exact path={`${match.path}/recovery`}>
        {(route) => (
          <Recovery {...props} {...route} />
        )}
      </Route>
      <Route exact path={`${match.path}/reset/:resetToken`}>
        {(route) => (
          <Reset {...props} {...route} />
        )}
      </Route>
      <Route exact path={`${match.path}/signin`}>
        {(route) => (
          <Signin {...props} {...route} />
        )}
      </Route>
      <Route exact path={`${match.path}/signup`}>
        {(route) => (
          <Signup {...props} {...route} />
        )}
      </Route>
    </Switch>
  )
}

Router.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onRecovery: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSignin: PropTypes.func.isRequired,
  onSignout: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
}


Router.displayName = `${displayName}Router`

export default connect(null, pageActions)(Router)
