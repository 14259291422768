import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import withStyles from '@material-ui/core/styles/withStyles'

import displayName from './displayName'
import Buttons from './Buttons'
import Add from './Add'
import ScrollIntoView from '../common/components/ScrollIntoView'
import Update from './Update'

const styles = {
  root: {
    marginLeft: '2vw',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'column',
  },
  list: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  btn: {
    margin: 16,
  },
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    flex: '1 1 auto',
  },
  content: {
    flex: '1 1 auto',
  },
  typography: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  name: {
    marginRight: 8,
  },
}


const ListItem = (props) => {
  const [isEditing, setEditing] = useState(false)
  const [isReplying, setReplying] = useState(false)

  const {
    classes,
    comment,
    ids,
    items,
    onAdd,
    onAuth,
    onDelete,
    onDeleteAdmin,
    onDocName,
    onUpdate,
    onUpdateLikes,
    review,
    user,
  } = props

  const handleReply = (bool) => {
    if (bool) {
      if (user._id) return setReplying(true)
      return onAuth()
    }
    return setReplying(false)
  }

  const now = new moment()
  const then = new moment(comment.createdAt)
  const filtered = ids.filter(id => items[id].parentComment === comment._id)
  const nextComment = filtered.map((id) => (
    <div key={id} className={classes.root}>
      <ListItem
        classes={classes}
        comment={items[id]}
        ids={ids}
        items={items}
        onAdd={onAdd}
        onAuth={onAuth}
        onDelete={onDelete}
        onDeleteAdmin={onDeleteAdmin}
        onDocName={onDocName}
        onUpdate={onUpdate}
        onUpdateLikes={onUpdateLikes}
        review={review}
        user={user}
      />
    </div>
  ))
  return (
    <div id={comment._id} className={classes.root}>
      <ScrollIntoView id={comment._id} />
      <div className={classes.container}>
        {isEditing
          ? <Update
            comment={comment}
            onClose={() => setEditing(false)}
            onDelete={onDelete}
            onDocName={onDocName}
            review={review}
            onUpdate={onUpdate}
          />
          : <div className={classes.content}>
            <span className={classes.typography}>
              <Typography variant="body2" className={classes.name}>
                {`${comment.user.firstName} ${comment.user.lastName}`}
              </Typography>
              <Typography>
                {`${moment.duration(now.diff(then)).humanize()} ago`}
              </Typography>
            </span>
            <Typography>{comment.text}</Typography>
            <Buttons
              comment={comment}
              onAuth={onAuth}
              onDelete={onDelete}
              onDeleteAdmin={onDeleteAdmin}
              onDocName={onDocName}
              onEdit={() => setEditing(true)}
              onReply={handleReply}
              onUpdate={onUpdate}
              onUpdateLikes={onUpdateLikes}
              parentCommentId={comment._id}
              review={review}
              user={user}
            />
            {isReplying
              ? <Add
                onAdd={onAdd}
                onClose={() => setReplying(false)}
                onDocName={onDocName}
                parentCommentId={comment._id}
                review={review}
              />
              : null}
          </div>
        }
      </div>
      {nextComment}
    </div>
  )
}

ListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  comment: PropTypes.object.isRequired,
  ids: PropTypes.array.isRequired,
  items: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onAuth: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDocName: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  review: PropTypes.object.isRequired,
  user: PropTypes.object,
}

ListItem.displayName = `${displayName}ListItem`

export default withStyles(styles)(ListItem)
