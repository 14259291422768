import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import displayName from './displayName'

const Section = props => {
  const {
    children,
    classes,
    elevation,
    style,
    ...rest
  } = props
  return (
    <section
      {...rest}
      className={classNames(
        classes.root,
        classes.Section,
        style.background && style.background.backgroundImage && classes.backgroundImage,
        elevation && classes[`elevation${elevation}`]
      )}
      style={style}
    >
      {children}
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
}

Section.displayName = `${displayName}Section`

export default Section
