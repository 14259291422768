import React, { Component } from 'react'
import Page from './Page'
import Loading from './Loading'

const asyncComponent = (importComponent) => {
  class AsyncComponent extends Component {
    state = {
      component: null
    }
    async componentDidMount() {
      const { default: component } = await importComponent()
      this.setState({ component })
    }
    render() {
      const C = this.state.component
      return C ?
        <C {...this.props} />
        :
        <Page><Loading /></Page>
    }
  }
  return AsyncComponent
}

export default asyncComponent
