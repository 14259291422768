import React from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray } from 'redux-form'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

import FieldPhone from '../fields/FieldPhone'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: '1 1 auto',
  },
  fieldArray: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  field: {
    padding: theme.spacing(1),
    flex: '1 1 auto',
  },
  row: {
    flexFlow: 'row wrap',
  },
  column: {
    flexFlow: 'column',
  },
})

const FormFields = ({
  classes,
  className,
  fields,
  flexFlow,
}) => (
    <div className={classNames(classes.root, flexFlow && classes[flexFlow], className)}>
      {fields.map((field) => {
        const { label, name, children, kind, ...rest } = field
        if (kind === 'FieldArray') {
          return (
            <div key={name} className={classNames(classes.field, classes.fieldArray)}>
              <FieldArray {...rest} label={label || name} name={name} />
            </div>
          )
        }
        if (name === 'phone') {
          return (
            <FieldPhone {...rest} key={name} label={label || name} name={name} />
          )
        }
        return (
          <Field {...rest} key={name} label={label || name} name={name} >
            {children && children.map((child) => (
              <MenuItem value={child} key={child}>{child}</MenuItem>
            ))}
          </Field>
        )
      })}
    </div>
  )


FormFields.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  fields: PropTypes.array.isRequired,
  flexFlow: PropTypes.string.isRequired,
}

FormFields.defaultProps = {
  flexFlow: 'column',
}

export default withStyles(styles)(FormFields)
