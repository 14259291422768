const drawerAdminList = [{
  label: 'Analytics',
  children: [
    { label: 'Dashboard', to: '/admin/analytics/dashboard' },
  ],
}, {
  label: 'App',
  children: [
    { label: 'Address', to: '/admin/app/address' },
    { label: 'Brand', to: '/admin/app/brand' },
    { label: 'Cart', to: '/admin/app/cart' },
    { label: 'Footer', to: '/admin/app/footer' },
    { label: 'Header', to: '/admin/app/header' },
    { label: 'Order', to: '/admin/app/order' },
    { label: 'Payment Provider', to: '/admin/app/payment' },
    { label: 'Seo', to: '/admin/app/seo' },
    { label: 'Shipping', to: '/admin/app/shipping' },
    { label: 'Shop', to: '/admin/app/shop' },
    { label: 'Social Media', to: '/admin/app/social-media' },
    { label: 'Tax', to: '/admin/app/tax' },
  ],
}, {
  label: 'Blogs',
  children: [
    { label: 'Add', to: '/admin/blogs/add' },
    { label: 'All', to: '/admin/blogs/all' },
    { label: 'Drafts', to: '/admin/blogs/drafts' },
    { label: 'Published', to: '/admin/blogs/published' },
  ],
}, {
  label: 'Categories',
  to: '/admin/categories',
}, {
  label: 'Credits',
  children: [
    { label: 'Add', to: '/admin/credits/add' },
    { label: 'All', to: '/admin/credits/all' },
    { label: 'Unassigned', to: '/admin/credits/unassigned' },
  ],
}, {
  label: 'Discounts',
  to: '/admin/discounts',
}, {
  label: 'Email',
  to: '/admin/email',
}, {
  label: 'Gift Cards',
  children: [
    { label: 'Add', to: '/admin/gift-cards/add' },
    { label: 'All', to: '/admin/gift-cards' },
  ],
}, {
  label: 'Orders',
  children: [
    { label: 'All', to: '/admin/orders' },
    { label: 'Requires Shipping', to: '/admin/orders/requires-shipping' },
  ],
}, {
  label: 'Pages',
  children: [
    { label: 'All', to: '/admin/pages/all' },
    { label: 'Drafts', to: '/admin/pages/drafts' },
    { label: 'Published', to: '/admin/pages/published' },
  ],
}, {
  label: 'Payment',
  to: '/admin/payment',
}, {
  label: 'Price Rules',
  to: '/admin/price-rules',
}, {
  label: 'Products',
  children: [
    { label: 'Add', to: '/admin/products/add' },
    { label: 'All', to: '/admin/products' },
    { label: 'Drafts', to: '/admin/products/drafts' },
    { label: 'Published', to: '/admin/products/published' },
  ],
}, {
  label: 'Reviews',
  to: '/admin/reviews',
}, {
  label: 'Suppliers',
  children: [
    { label: 'Add', to: '/admin/suppliers/add' },
    { label: 'All', to: '/admin/suppliers' },
  ],
}, {
  label: 'Theme',
  children: [
    { label: 'Palette', to: '/admin/theme/palette' },
    { label: 'Typography', to: '/admin/theme/typography' },
  ],
}, {
  label: 'Users',
  to: '/admin/users',
}]

export default drawerAdminList
