import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

import Flex from '../common/Flex'
import displayName from './displayName'
import { formatDollar } from '../common/utils/formatters'

const styles = theme => ({
  root: {
    flex: '1 1 100%',
  },
  row: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
})


function Total(props) {
  const {
    className,
    classes,
    credit,
    creditBalance,
    creditBalanceNext,
    creditToSubtract,
    giftCard,
    giftCardBalance,
    giftCardBalanceNext,
    giftCardToSubtract,
    shippingPrice,
    subtotal,
    subtotalDiscounted,
    taxPrice,
    taxRate,
    total,
    totalMinusCredit,
    totalMinusGiftCard,
    typography,
  } = props

  // Change tax handeling on api consider charginng tax on all purchases
  return (
    <div className={classNames(classes.root, className)}>
      <Flex flexFlow="row wrap" alignItems="center" justifyContent="space-between">
        <Typography variant={typography}>Subtotal: </Typography>
        <Typography variant={typography}>{formatDollar(subtotal)}</Typography>
      </Flex>

      {subtotalDiscounted
        ? (
          <Flex flexFlow="row wrap" alignItems="center" justifyContent="space-between">
            <Typography variant={typography}>Discount Code</Typography>
            <Typography variant={typography}>
              -
              {' '}
              {formatDollar(subtotal - subtotalDiscounted)}
            </Typography>
          </Flex>
        )
        : null}

      {giftCardToSubtract
        ? (
          <Flex flexFlow="row wrap" alignItems="center" justifyContent="space-between">
            <Typography variant={typography}>Gift Card Amount: </Typography>
            <Typography variant={typography}>
              -
              {formatDollar(giftCardToSubtract)}
            </Typography>
          </Flex>
        )
        : null
      }

      {creditToSubtract
        ? (
          <Flex flexFlow="row wrap" alignItems="center" justifyContent="space-between">
            <Typography variant={typography}>Credit Amount: </Typography>
            <Typography variant={typography}>
              -
              {formatDollar(creditToSubtract)}
            </Typography>
          </Flex>
        )
        : null
      }

      <Flex flexFlow="row wrap" alignItems="center" justifyContent="space-between">
        <Typography variant={typography}>Tax</Typography>
        <Typography variant={typography}>{formatDollar(taxPrice)}</Typography>
      </Flex>

      <Flex flexFlow="row wrap" alignItems="center" justifyContent="space-between">
        <Typography variant={typography}>Shipping: </Typography>
        <Typography variant={typography}>{shippingPrice ? formatDollar(shippingPrice) : '$0.00'}</Typography>
      </Flex>

      <Flex flexFlow="row wrap" alignItems="center" justifyContent="space-between">
        <Typography variant={typography}>Total: </Typography>
        <Typography variant={typography}>{formatDollar(total)}</Typography>
      </Flex>


    </div>
  )
}

Total.defaultProps = {
  className: undefined,
  creditBalance: 0,
  creditBalanceNext: 0,
  creditToSubtract: 0,
  giftCardBalance: 0,
  giftCardBalanceNext: 0,
  giftCardToSubtract: 0,
  totalMinusCredit: 0,
  totalMinusGiftCard: 0,
  typography: 'body2',
}

Total.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.instanceOf(Object).isRequired,
  credit: PropTypes.instanceOf(Object).isRequired,
  creditBalance: PropTypes.number,
  creditBalanceNext: PropTypes.number,
  creditToSubtract: PropTypes.number,
  giftCard: PropTypes.instanceOf(Object).isRequired,
  giftCardBalance: PropTypes.number,
  giftCardBalanceNext: PropTypes.number,
  giftCardToSubtract: PropTypes.number,
  shippingPrice: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
  subtotalDiscounted: PropTypes.number.isRequired,
  taxPrice: PropTypes.number.isRequired,
  taxRate: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  totalMinusCredit: PropTypes.number,
  totalMinusGiftCard: PropTypes.number,
  typography: PropTypes.string,
}

Total.displayName = `${displayName}Total`

export default withStyles(styles)(Total)
