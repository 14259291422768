import * as userActions from '../../user/user/actions'
import createActions from '../../redux/createActions'
import fetchApi from '../../fetch/fetchApi'

import {
  creditFindOne,
  creditUserAdd,
} from './api'
import { reducerName } from './reducers'

const {
  ADD,
  DELETE,
  DELETES,
  ERROR,
  RECEIVE,
  RESET,
  REQUEST,
  UPDATE,
} = createActions(reducerName)


export const onRequest = () => ({ type: REQUEST })
const onError = error => ({ type: ERROR })


// QUERIES
export const onFindOneSuccess = item => ({ type: ADD, item })
export const onFindOne = input => async dispatch => {
  dispatch(onRequest())
  const item = await creditFindOne(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onFindOneSuccess(item))
}





// MUTATIONS
export const onAddSuccess = item => ({ type: ADD, item })
export const onAdd = input => async dispatch => {
  const item = await creditUserAdd(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onAddSuccess(item))
}


