import React, { Component } from 'react'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import DrawerCollapse from './DrawerCollapse'
import drawerAdminList from './drawerAdminList'

class DrawerAdmin extends Component {
  constructor(props) {
    super(props)
    const { pathname } = window.location
    this.state = {
      isOpen: pathname.includes('admin'),
    }
  }
  toggleCollapse = () => this.setState({ isOpen: !this.state.isOpen })
  render() {
    const {
      appId,
      classes,
      onDrawer,
      user,
    } = this.props
    const isOwner = user.roles.includes('owner')
    if (!appId && isOwner) {
      return (
        <List disablePadding>
          <ListItem
            activeClassName={classes.navLinkActive}
            button
            component={NavLink}
            onClick={onDrawer}
            to="/admin/app/add-app"
          >
            <ListItemText primary="Add App!" />
          </ListItem>
        </List>
      )
    }
    return (
      <List disablePadding>
        <ListItem button onClick={this.toggleCollapse}>
          <ListItemText primary="Admin" />
          {this.state.isAdminOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse component="li" in={this.state.isOpen} timeout="auto" unmountOnExit>
          <List disablePadding>
            {drawerAdminList.map(item => (
              item.children ?
                <DrawerCollapse key={item.label} {...item} classes={classes} onDrawer={onDrawer} />
                :
                <ListItem
                  key={item.label}
                  activeClassName={classes.navLinkActive}
                  button
                  className={classes.nested}
                  component={NavLink}
                  onClick={onDrawer}
                  to={item.to}
                >
                  <ListItemText primary={item.label} />
                </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
    )
  }
}


DrawerAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawer: PropTypes.func.isRequired,
  user: PropTypes.object,
}

export default DrawerAdmin
