import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import DialogMUI from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { connect } from 'react-redux'

import { onDialog } from './actions'

function Dialog(props) {
  const {
    content,
    isOpen,
    title,
    onDialog,
  } = props
  return (
    <DialogMUI
      open={isOpen}
      onClose={onDialog}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      {title ? <DialogTitle id="dialog-title">{title}</DialogTitle> : null}
      {content ?
        <DialogContent>
          <DialogContentText id="dialog-description">{content}</DialogContentText>
        </DialogContent>
        : null}
      <DialogActions>
        <Button onClick={onDialog} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </DialogMUI>
  )
}

Dialog.propTypes = {
  content: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onDialog: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  dialog: { isOpen, title, content }
}) => ({
  isOpen,
  title,
  content,
})

const mapDispatchToProps = dispatch => ({
  onDialog: () => dispatch(onDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dialog)