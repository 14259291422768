import React from 'react'
import PropTypes from 'prop-types'

import displayName from './displayName'
import Form from './Form'

const Update = props => {
  const {
    comment = {},
    onUpdate,
    onClose,
    onDocName,
  } = props
  const handleSubmit = (values) => {
    return onUpdate({
      ...values,
      _id: comment._id,
      href: window.location.href,
      onDocName,
    })
      .then(() => onClose())
      .catch(() => onClose())
  }
  return (
    <Form
      form={`comment${comment._id}`}
      initialValues={{ text: comment.text }}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  )
}

Update.propTypes = {
  comment: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onDocName: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

Update.displayName = `${displayName}Update`

export default Update
