import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconAdd from '@material-ui/icons/Add'
import IconCheck from '@material-ui/icons/Check'
import IconError from '@material-ui/icons/Error'
import IconRemove from '@material-ui/icons/Remove'
import Typography from '@material-ui/core/Typography'
import green from '@material-ui/core/colors/green'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { onAdd } from '../../cart/actions'
import displayName from './displayName'

const styles = theme => ({
  buttonContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
  },
  button: {
    flex: '1 1 auto',
  },
  plusMinusButtons: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: 8
  },
  quantity: {
    flex: '1 1 auto',
    textAlign: 'center',
    borderBottom: '1px solid rgb(224, 224, 224)'
  },
  isSuccess: {
    backgroundColor: green[500],
    color: `${theme.palette.common.white} !important`,
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: green[700],
      color: `${theme.palette.common.white} !important`,
    },
  },
  isError: {
    backgroundColor: theme.palette.error.main,
    color: `${theme.palette.common.white} !important`,
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: `${theme.palette.common.white} !important`,
    },
  },
})

let isMounted = false

const Buttons = props => {
  const { classes, onAdd, variantId } = props
  const [state, setState] = useState({
    isError: false,
    isFetching: false,
    isOpen: false,
    isSuccess: false,
    quantity: 1,
    timeoutId: null,
  })
  const handleTimeout = (obj) => {
    state.timeoutId && clearTimeout(state.timeoutId)
    const timeoutId = setTimeout(() => {
      if (!isMounted) return
      clearTimeout(state.timeoutId)
      setState({
        ...state,
        isError: false,
        isFetching: false,
        isSuccess: false,
        timeoutId: null,
      })
    }, 5000)
    return setState({
      ...state,
      ...obj,
      timeoutId,
    })
  }
  useEffect(() => {
    isMounted = true
    return () => {
      isMounted = false
      state.timeoutId && clearTimeout(state.timeoutId)
    }
  }, [])
  const handleAdd = (e) => {
    e.stopPropagation()
    const quantity = state.quantity + 1
    setState({ ...state, quantity })
  }
  const handleSubmit = () => {
    const update = {
      type: 'ADD_TO_CART',
      variantId: variantId,
      variantQty: state.quantity,
    }
    return onAdd(update)
      .then(() => handleTimeout({ isSuccess: true, isError: false, isFetching: false, isOpen: true }))
      .catch(() => handleTimeout({ isError: true, isSuccess: false, isFetching: false }))
  }
  const handleSubtract = (e) => {
    e.stopPropagation()
    if (state.quantity > 1) {
      const quantity = state.quantity - 1
      setState({ ...state, quantity })
    }
  }
  const handleToggle = () => setState({ ...state, isOpen: !state.isOpen })
  const renderButtonChildren = () => {
    const { isSuccess, isError, isFetching } = state
    if (isError) return <IconError />
    if (isSuccess) return <IconCheck />
    if (isFetching) return <CircularProgress size={20} />
    return 'ADD TO CART'
  }
  const renderButtonClass = () => {
    const { isSuccess, isError } = state
    if (isError) return classes.isError
    if (isSuccess) return classes.isSuccess
    return null
  }
  return (
    <div>
      <div className={classes.plusMinusButtons}>
        <Button
          color="primary"
          onClick={handleSubtract}
          variant="contained"
          size="small"
        >
          <IconRemove />
        </Button>
        <div className={classes.quantity}>
          <Typography>{state.quantity}</Typography>
        </div>
        <Button
          color="primary"
          onClick={handleAdd}
          variant="contained"
          size="small"
        >
          <IconAdd />
        </Button>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          onClick={handleSubmit}
          className={classNames(classes.button, renderButtonClass())}
          color="primary"
          size="medium"
          variant="contained"
        >
          {renderButtonChildren()}
        </Button>
      </div>
      {/* !this.state.isOpen ? null :
          <CartDialog
            product={product}
            onToggle={this.handleToggle}
            history={history}
          />
        */}
    </div>
  )
}

Buttons.propTypes = {
  classes: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  variantId: PropTypes.string.isRequired,
}

const mapDispatchToProps = dispatch => ({ onAdd: add => dispatch(onAdd(add)) })

Buttons.displayName = `${displayName}Buttons`

export default connect(null, mapDispatchToProps)(withStyles(styles)(Buttons))
