import React from 'react'
import classNames from 'classnames'
import Icon from '@material-ui/core/Icon'

type ObjectMap = {
  [key: string]: string,
}

interface MaterialIconProps {
  classes: ObjectMap,
  elevation?: number,
  id: string,
  onClick?: (event: React.MouseEvent<HTMLElement>) => void,
  onDrop?: (event: React.MouseEvent<HTMLElement>) => void,
  style: ObjectMap,
}

const MaterialIcon: React.FC<MaterialIconProps> = ({
  classes,
  elevation,
  id,
  onClick,
  onDrop,
  children = 'add',
  style,
}) => {
  console.log('MaterialIcon', {
    classes,
    elevation,
    id,
    onClick,
    onDrop,
    children,
    style,
  })
  return (
    <Icon
      className={classNames(
        classes.root,
        classes.Icon,
        elevation && classes[`elevation${elevation}`],
      )}
      id={id}
      onClick={onClick}
      onDrop={onDrop}
      style={style}
    >
      {children}
    </Icon>
  )
}

export default MaterialIcon
