import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import green from '@material-ui/core/colors/green'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import FieldText from '../../fields/FieldText'
import Total from '../Total'
import { formatDollar } from '../../common/utils/formatters'
import { parseOnlyNums, parseInteger } from '../../common/utils/parsers'
import { discountFindOne } from './api'
import { onUpdate as onUpdateCart } from '../../cart/actions'

const styles = {
  successLabel: {
    color: green[700],
    '&$successLabelFocued': {
      color: green[700],
    },
  },
  successLabelFocued: {},
  successUnderline: {
    '&:after': {
      borderBottomColor: green[700],
    },
  },
}

async function onBlur({
  dispatch,
  input,
  setState,
}) {
  if (input.value && input.value.code.length) {
    return discountFindOne({ code: input.value.code })
      .then((_id) => {
        if (_id) {
          dispatch(onUpdateCart({ discountCode: input.value.code }))
          return setState({ error: null, isValid: true })
        }
        dispatch(onUpdateCart({ discountCode: null }))
        return setState({ error: 'Sorry, that discount code was not found', isValid: false })
      })
      .catch(error => setState({ error: 'Sorry, that discount code was not found', isValid: false }))
  }
  return dispatch(onUpdateCart({ discountCode: null }))
}


function DiscountCodeField(props) {
  const [state, setState] = useState({ error: null, isValid: false })
  const {
    classes,
    input,
    meta,
  } = props
  const { isValid } = state
  return (
    <>
      <Field
        component={FieldText}
        fullWidth
        label={input.value.code && isValid ? 'Code accepted!' : 'Do you have a discount code to redeem?'}
        name={`${input.name}.code`}
        onChange={() => state.error && setState({ error: null, isValid: false })}
        validate={(value) => {
          if (!value) return undefined
          return state.error
        }}
        onBlur={() => onBlur({
          dispatch: meta.dispatch,
          input,
          setState,
        })}
        FormHelperTextProps={{
          classes: {
            root: isValid && classes.successLabel,
          },
        }}
        InputLabelProps={{
          classes: {
            root: isValid && classes.successLabel,
            focused: isValid && classes.successLabelFocued,
          },
        }}
        InputProps={{
          classes: {
            underline: isValid && classes.successUnderline,
          },
        }}
      />
    </>
  )
}

DiscountCodeField.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
}


export default withStyles(styles)(DiscountCodeField)
