import React, { useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconFavoriteBorder from '@material-ui/icons/FavoriteBorder'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import SnackbarContext from '../../snackbar/SnackbarContext'
import displayName from './displayName'
import IconButton from '../../icons/IconButton'
import { reviewReportAbuse } from './api'

const styles = {
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  iconButton: {
    fontSize: 16,
  },
}

const Buttons = ({
  classes,
  onAuth,
  onDelete,
  onDeleteAdmin,
  onDoc,
  onDocName,
  onEdit,
  onModel,
  onReply,
  onUpdate,
  onUpdateLikes,
  onUpdateParentSuccess,
  review,
  user,
}) => {
  const { onSnackOpen } = useContext(SnackbarContext)
  const [isFetching, setFetching] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const isReviewOwner = review.user._id === user._id
  const isAdmin = user.roles.includes('admin') ? user : null

  const handleEdit = useCallback(() => {
    setAnchorEl(null)
    onEdit()
  }, [onEdit])

  const handleDelete = useCallback(() => {
    setAnchorEl(null)
    if (review && review._id) {
      if (isAdmin) {
        onDeleteAdmin({ _id: review._id }).then(({ blog, product }) => onUpdateParentSuccess(blog || product))
      } else {
        onDelete({ _id: review._id }).then(({ blog, product }) => onUpdateParentSuccess(blog || product))
      }
    }
  }, [isAdmin, onDelete, onDeleteAdmin, onUpdateParentSuccess, review])

  const handleReportAbuse = useCallback(() => {
    setFetching(true)
    setAnchorEl(null)
    return reviewReportAbuse({
      href: `${window.location.href}#${review._id}`,
      onDocName,
      onModel: review.onModel,
      text: review.text,
    }).then(() => {
      setFetching(false)
      return onSnackOpen({ severity: 'success', message: 'Abuse reported!' })
    })
  }, [onDocName, onSnackOpen, review._id, review.onModel, review.text])

  const handleAuth = useCallback(() => {
    if (!user._id) onAuth()
  }, [onAuth, user._id])

  const handleReply = useCallback(() => {
    handleAuth()
    if (user._id) setAnchorEl(null)
  }, [handleAuth, user._id])

  const handleLike = useCallback(() => {
    handleAuth()
    if (user._id) {
      const hasLike = review.likes.find((like) => like === user._id)
      onUpdateLikes({
        _id: review._id,
        like: hasLike ? null : user._id,
        unlike: hasLike ? user._id : null,
      })
    }
  }, [handleAuth, onUpdateLikes, review._id, review.likes, user._id])

  return (
    <div className={classes.root}>
      <div className={classes.root}>
        <IconButton onClick={handleLike} tooltip="Like">
          <IconFavoriteBorder className={classes.iconButton} />
        </IconButton>
        <Typography>
          {review.likes.length > 0 && review.likes.length}
        </Typography>
      </div>
      <Button size="small" onClick={() => onReply(true)}>Reply</Button>
      <IconButton
        aria-label="More"
        aria-owns={anchorEl ? 'long-menu' : null}
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {isFetching ? <CircularProgress size={20} /> : <MoreHoriz />}
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {isReviewOwner ? <MenuItem onClick={handleEdit}>Edit</MenuItem> : null}
        {isReviewOwner || isAdmin ? <MenuItem onClick={handleDelete}>Delete</MenuItem> : null}
        {!isReviewOwner ? <MenuItem onClick={handleReportAbuse}>Report abuse</MenuItem> : null}
      </Menu>
    </div>
  )
}

Buttons.propTypes = {
  classes: PropTypes.object.isRequired,
  onAuth: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onDeleteAdmin: PropTypes.func.isRequired,
  onDoc: PropTypes.string.isRequired,
  onDocName: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onModel: PropTypes.string.isRequired,
  onReply: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  onUpdateLikes: PropTypes.func.isRequired,
  review: PropTypes.object.isRequired,
  user: PropTypes.object,
}

Buttons.displayName = `${displayName}Buttons`

export default withStyles(styles)(Buttons)
