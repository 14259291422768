

export const validateCompose = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

export const validateEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

export const validateImage = value => {
  if (!value) return undefined
  return value.invalid && 'Image requires save to reduce size first'
}


export const validatePhone = value =>
  value && value.replace(/\D+/g, '').length < 10
    ? 'Invalid phone number, must be 10 digits'
    : undefined

export const validateRequired = value => (value ? undefined : 'Required')

export const validateZip = value =>
  value && value.length < 5
    ? 'Invalid zip code, must be 5 digits'
    : undefined