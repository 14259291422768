import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = () => ({
  navLinkActive: {
    '& span:first-child': {
      borderBottom: '2px solid !important',
      width: 'inherit'
    }
  },
})

const FooterPage = ({
  classes,
  color,
  page,
}) => {
  const buttonProps = page.url ? {
    component: 'a',
    href: page.url
  } : {
    component: NavLink,
    to: `/${page.slug}`,
    activeClassName: classes.navLinkActive
  }
  return (
    <Button
      className={classes.button}
      style={{ color }}
      {...buttonProps}
      size="small"
    >
      {page.name}
    </Button>
  )
}


FooterPage.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  page: PropTypes.object.isRequired,
}

export default withStyles(styles)(FooterPage)
