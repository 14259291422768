import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconCheck from '@material-ui/icons/Check'
import IconError from '@material-ui/icons/Error'
import IconSave from '@material-ui/icons/Save'
import classNames from 'classnames'
import green from '@material-ui/core/colors/green'
import withStyles from '@material-ui/core/styles/withStyles'

import Button from './Button'

const styles = (theme) => ({
  root: {
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
  },
  fullWidth: {
    flex: '1 1 auto',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  isSubmittingContained: {
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: theme.shadows[2],
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '& svg': {
        color: theme.palette.common.white,
      },
    },
  },
  isSuccessContained: {
    '&:disabled': {
      backgroundColor: green[500],
      color: theme.palette.common.white,
      boxShadow: theme.shadows[2],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
  },
  isErrorContained: {
    '&:disabled': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      boxShadow: theme.shadows[2],
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
  },
  isSuccessText: {
    '&:disabled': {
      color: green[500],
      '&:hover': {
        color: green[700],
      },
    },
  },
  isErrorText: {
    '&:disabled': {
      color: theme.palette.error.main,
      '&:hover': {
        color: theme.palette.error.dark,
      },
    },
  },
})


class ButtonSubmit extends PureComponent {
  state = {
    isError: false,
    isSuccess: false,
    timeoutId: null,
  }

  handleState = (StateObj, fn) => {
    this.state.timeoutId && clearTimeout(this.state.timeoutId)
    const timeoutId = setTimeout(() => {
      clearTimeout(this.state.timeoutId)
      fn && fn()
      this.setState({
        isError: false,
        isSuccess: false,
        timeoutId: null,
      })
    }, 6000)
    return this.setState({
      ...StateObj,
      timeoutId,
    })
  }

  handleClear = () => {
    this.state.timeoutId && clearTimeout(this.state.timeoutId)
    return this.setState({ isError: false, isSuccess: false, timeoutId: null })
  }

  componentDidUpdate(prevProps) {
    const {
      error,
      pristine,
      submitSucceeded,
      submitFailed,
      reset,
    } = this.props
    switch (true) {
      case ((this.state.isSuccess || this.state.isError) && pristine !== prevProps.pristine): {
        return this.handleClear()
      }
      case (submitSucceeded !== prevProps.submitSucceeded && submitSucceeded): {
        return this.handleState({
          isSuccess: true,
          isError: false,
        })
      }
      case (error !== prevProps.error && error !== undefined || submitFailed !== prevProps.submitFailed && submitFailed): {
        return this.handleState({
          isError: true,
          isSuccess: false,
        })
      }
      default:
    }
  }

  renderChildren = () => {
    const { children, submitText, submitting, pristine, invalid, submitFailed } = this.props
    const { isError, isSuccess } = this.state
    if (isError || (submitFailed && !pristine && invalid)) return <IconError />
    if (isSuccess) return <IconCheck />
    if (submitting) return <CircularProgress size={20} />
    if (children) return children
    if (submitText) return submitText
    return <IconSave />
  }

  renderClassName = () => {
    const { classes, variant, submitting, pristine, invalid, submitFailed } = this.props
    const { isError, isSuccess } = this.state
    if (variant === 'contained' || variant === 'fab' || variant === 'raised') {
      if (isError || (submitFailed && !pristine && invalid)) return classes.isErrorContained
      if (isSuccess) return classes.isSuccessContained
      if (submitting) return classes.isSubmittingContained
      return null
    }
    if (isError || (submitFailed && !pristine && invalid)) return classes.isErrorText
    if (isSuccess) return classes.isSuccessText
    return null
  }

  componentWillUnmount() {
    const { timeoutId } = this.state
    timeoutId && clearTimeout(timeoutId)
  }

  render() {
    const {
      children,
      className,
      classes,
      clearSubmitErrors,
      form,
      invalid,
      isClearSubmitErrors,
      onClick,
      onSubmit,
      pristine,
      reset,
      submitFailed,
      submitSucceeded,
      submitting,
      ...rest
    } = this.props
    const { isSuccess, isError } = this.state
    return (
      <Button
        {...rest}
        onClick={onSubmit}
        className={classNames(
          className,
          classes.root,
          this.props.fullWidth ? classes.fullWidth : null,
          this.renderClassName(),
        )}
        disabled={invalid || pristine || submitting || isError || isSuccess || this.props.disabled || submitFailed}
        type="submit"
      >
        {this.renderChildren()}
      </Button>
    )
  }
}


ButtonSubmit.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  clearSubmitErrors: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  form: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isClearSubmitErrors: PropTypes.bool,
  onClick: PropTypes.func,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  variant: PropTypes.string,
}

export default withStyles(styles)(ButtonSubmit)
