import fetchGraphQL from '../graphql/fetchGraphQL'


export const getShippingPrice = ({ appShipping, cart, service }) => {
  const isShippable = cart.items.find(item => item.type === 'Simple')
  if (isShippable) {
    if (service === 'priority') {
      return appShipping.priority
    }
    return cart.shipping
  }
  return 0
}

export const getTaxPrice = ({
  cart,
  deliveryAddress,
  tax,
}) => {
  if (!cart.items.length) throw Error('cart items must be greater than 0')
  if (!deliveryAddress.state) throw Error('deliveryAddress must include state')
  if (!tax.rate) throw Error('tax rate is required')
  const isTaxable = deliveryAddress.state === 'CA'
  const itemsIncludingTax = isTaxable ? cart.items.map(i => ({
    ...i,
    taxPrice: i.variant.taxable ? Math.round(i.subTotal * tax) : 0,
  })) : []
  const taxPrice = isTaxable ? itemsIncludingTax.reduce((a, b) => a + b.taxPrice, 0) : 0
  return taxPrice
}

function getAmountToSubtract(balance, total) {
  if (!balance) return 0
  if (total - balance > 0) return balance
  return total - balance
}

function getNextBalance(balance, total) {
  if (!balance) return 0
  if (balance - total > 0) return balance - total
  return 0
}

export function getTotal({
  cart = {},
  credit = {},
  tax = {},
  giftCard = {},
}) {
  const cartSubtotal = cart.subtotalDiscounted || cart.subtotal
  const itemsIncludingTax = cart.items.map(i => ({
    ...i,
    taxPrice: i.variant.taxable ? i.subtotal * tax.rate : 0,
  }))
  const taxPrice = itemsIncludingTax.reduce((a, b) => a + b.taxPrice, 0)
  const shippingPrice = cart.shipping.price
  const total = Math.round(cartSubtotal + taxPrice + shippingPrice)

  // gift card
  const giftCardToSubtract = getAmountToSubtract(giftCard.balance, total)
  const totalMinusGiftCard = total - giftCardToSubtract
  const giftCardBalanceNext = getNextBalance(giftCard.balance, total)

  // user credit
  const creditToSubtract = getAmountToSubtract(credit.balance, totalMinusGiftCard)
  const totalMinusCredit = totalMinusGiftCard - creditToSubtract
  const creditBalanceNext = getNextBalance(credit.balance, totalMinusGiftCard)

  return {
    credit,
    creditBalance: credit.balance,
    creditBalanceNext,
    creditToSubtract,
    giftCard,
    giftCardBalance: giftCard.balance,
    giftCardBalanceNext,
    giftCardToSubtract,
    shippingPrice,
    subtotal: cart.subtotal,
    subtotalDiscounted: cart.subtotalDiscounted,
    taxPrice,
    taxRate: tax.rate,
    total,
    totalDue: totalMinusCredit,
    totalMinusCredit,
    totalMinusGiftCard,
  }
}


export const discountFindOne = async (input) => {
  try {
    const { discountFindOne: item } = await fetchGraphQL({
      query: `
        query DiscountFindOne($input: DiscountFindOneInput!) {
          discountFindOne(input: $input) {
            _id
          }
        }
      `,
      variables: { input },
    })
    return item._id
  } catch (error) {
    return Promise.reject(error)
  }
}


export const creditFindOne = async (input) => {
  try {
    const { creditFindOne: item } = await fetchGraphQL({
      query: `
        query CreditFindOne($input: CreditFindOneInput!) {
          creditFindOne(input: $input) {
            _id
            email
            balance
          }
        }
      `,
      variables: { input },
    })
    const { balance } = item
    return balance
  } catch (error) {
    return Promise.reject(error)
  }
}
