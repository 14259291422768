import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import IconAdd from '@material-ui/icons/Add'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Button from '../../common/components/Button'
import ListItem from './ListItem'
import Loading from '../../common/components/Loading'
import displayName from './displayName'
import useDnDSortList from '../../common/hooks/useDnDSortList'
import withDnDContext from '../../common/hocs/withDnDContext'
import SnackbarContext from '../../snackbar/SnackbarContext'

const styles = (theme) => ({
  ul: {
    margin: 0,
    padding: 0,
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    display: 'flex',
    listStyle: 'none',
  },
  btn: {
    margin: 16,
    alignSelf: 'center',
  },
})

const List = (props) => {
  const {
    addresses,
    classes,
    match,
    isFetching,
    onFind,
    onUpdateAddresses,
    user,
  } = props
  const { params: { _id } } = match

  const { onSnackOpen } = useContext(SnackbarContext)
  const { items, onMove } = useDnDSortList({ items: user.addresses })
  const handleDrop = (e) => {
    e.stopPropagation()
    const userIdVariable = _id ? { _id } : {}
    return onUpdateAddresses({
      ...userIdVariable,
      addresses: items,
    }).then(() => onSnackOpen({ severity: 'success', message: 'Address order updated!' }))
  }
  useEffect(() => {
    onFind({})
  }, [onFind])

  if (isFetching) return <Loading />
  return (
    <ul className={classes.ul}>
      {items.map((id, index) => (
        <ListItem
          key={id}
          address={addresses[id]}
          id={id}
          index={index}
          match={match}
          onDrop={handleDrop}
          onMove={onMove}
          user={user}
        />
      ))}

      <Button
        color="primary"
        size="small"
        variant="fab"
        className={classes.btn}
        component={Link}
        to={`${match.url}/add`}
        tooltip="Add Address"
      >
        <IconAdd />
      </Button>
    </ul>
  )
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  addresses: PropTypes.object.isRequired,
  onUpdateAddresses: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
}

List.displayName = `${displayName}List`

export default withDnDContext(withStyles(styles)(List))
