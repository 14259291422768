import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IconFilterList from '@material-ui/icons/FilterList'
import withStyles from '@material-ui/core/styles/withStyles'

import IconButton from '../icons/IconButton'
import FilterSearch from './FilterSearch'
import FilterDialog from './FilterDialog3'

const styles = {
  root: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
  },
  filter: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flex: '1 1 auto',
    justifyContent: 'flex-end',
  }
}



const Filter = (props) => {
  const [filterState, setFilterState] = useState({ ...props.filterDefaults })
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [isSearchOpen, setSearchOpen] = useState(false)
  const [isPristine, setPristine] = useState(true)
  const [searchValue, setSearchValue] = useState(null)

  const handleFilter = () => {
    if (isPristine) return setDialogOpen(false)
    const searchObj = searchValue && searchValue.length && { searchValue }
    setDialogOpen(false)
    setPristine(true)
    return props.onFilter({ ...filterState, ...searchObj })
  }

  const handleFilterChange = name => event => {
    setPristine(false)
    setFilterState({ ...filterState, [name]: event.target.value })
  }

  const handleSearch = (e) => {
    const searchValue = e.target.value
    setSearchValue(searchValue)
    setPristine(false)
    const searchObj = searchValue && searchValue.length && { searchValue }
    return props.onFilter({ ...filterState, ...searchObj })
  }

  const handleSearchClose = () => {
    setPristine(true)
    setSearchOpen(false)
    setSearchValue(null)
    return props.onFilter({ ...filterState, searchValue: null })
  }

  const { classes, filterOptions, searchPlaceholder } = props
  return (
    <div className={classes.filter}>
      <FilterSearch
        isOpen={isSearchOpen}
        onClose={handleSearchClose}
        onOpen={() => setSearchOpen(true)}
        onSearch={handleSearch}
        searchValue={searchValue}
        placeholder={searchPlaceholder}
      />
      <IconButton
        onClick={() => setDialogOpen(true)}
        size="small"
        tooltip="Filter"
      >
        <IconFilterList />
      </IconButton>
      <FilterDialog
        filterOptions={filterOptions}
        filterState={filterState}
        isOpen={isDialogOpen}
        onChange={handleFilterChange}
        onClose={() => setDialogOpen(false)}
        onOk={handleFilter}
      />
    </div>
  )
}

Filter.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  filterOptions: PropTypes.object.isRequired,
  headline: PropTypes.string,
}

export default withStyles(styles)(Filter)
