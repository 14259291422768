import React from 'react'
import { useSelector } from 'react-redux'

import { formatPhone } from '../utils/formatters'
import { AppProps } from '../../app/types'

const Phone: React.FC = ({ children }) => {
  const { isFetching, brand } = useSelector(({ app }: { app: AppProps }) => app)
  if (isFetching) return null
  return <span>{formatPhone(children, brand.phoneFormat)}</span>
}

export default Phone

