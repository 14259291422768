import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import Image from '../image/common/Image'
import Svg from '../svg/Svg'

type ObjectMap = {
  [key: string]: any;
}

interface AppBrandProps {
  color?: string;
  isImageDisplayed: boolean;
  isNameDisplayed: boolean;
  isSvgDisplayed: boolean;
  style: ObjectMap;
}

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
  },
  horizontal: {
    marginRight: 4,
    marginLeft: 4,
  },
  vertical: {
    margin: 4,
  }
}))

const AppBrand: React.FC<AppBrandProps> = ({
  isImageDisplayed,
  isNameDisplayed,
  isSvgDisplayed,
  style,
}) => {
  const brand = useSelector(({ app }: ObjectMap) => app.brand)
  const isFetching = useSelector(({ app }: ObjectMap) => app.isFetching)
  const classes = useStyles()
  if (isFetching) return null
  const {
    image,
    name,
    svg,
  } = brand
  const className = style.flexFlow && style.flexFlow !== 'column' ? classes.horizontal : classes.vertical
  return (
    <Link to="/" className={classNames(classes.root, className)} style={style}>
      {(isImageDisplayed && image && image.src) ? <Image {...image} className={className} /> : null}
      {(isSvgDisplayed && svg.children) ? <Svg {...svg} className={className} /> : null}
      {isNameDisplayed ? <Typography {...name} className={className} color="inherit" noWrap /> : null}
    </Link>
  )
}

export default AppBrand
