import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import AppBarNavigation from './AppBarNavigation'
import SearchField from '../search/SearchField'
import AppBrand from '../app/AppBrand'

const styles = (theme) => ({
  root: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  iconButton: {
    marginLeft: -12,
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
  },
  navigationContainer: {
    width: '100%',
  },
  navigation: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
  },
})

function AppBar(props) {
  const {
    classes,
    header,
    onDrawer,
    search,
    ...rest
  } = props
  const {
    brand,
    style,
  } = header
  const { color, backgroundColor, fill } = style
  return (
    <MuiAppBar position="sticky" className={classes.root} style={{ backgroundColor, color, fill }}>
      <Toolbar>
        <IconButton
          className={classes.iconButton}
          aria-label="Menu"
          onClick={onDrawer}
        >
          <MenuIcon style={{ color }} />
        </IconButton>
        <div className={classes.content}>
          {search.isOpen ? <SearchField color={color} />
            : (
              <>
                <AppBrand
                  color="inherit"
                  isImageDisplayed={brand.isImageDisplayed}
                  isNameDisplayed={brand.isNameDisplayed}
                  isSvgDisplayed={brand.isSvgDisplayed}
                  style={brand.style}
                />
                <AppBarNavigation header={header} {...rest} />
              </>
            )}
        </div>
      </Toolbar>
    </MuiAppBar>
  )
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  isBlogs: PropTypes.bool.isRequired,
  isProducts: PropTypes.bool.isRequired,
  header: PropTypes.object.isRequired,
  onDrawer: PropTypes.func.isRequired,
  pageIds: PropTypes.array,
  pages: PropTypes.object,
}


export default withStyles(styles)(AppBar)
