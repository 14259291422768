import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'

import FieldText from '../fields/FieldText'
import FormBase from '../forms/FormBase'
import FormButtonSubmit from '../forms/FormButtonSubmit'
import displayName from './displayName'
import { validateRequired } from '../common/utils/validators'

const fields = [
  { label: 'Comments', name: 'text', multiline: true, component: FieldText, validate: validateRequired },
]

const styles = {
  root: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'column',
  },
  btnContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignSelf: 'flex-end'
  }
}

const Form = props => {
  const {
    classes,
    form,
    onSubmit,
    initialValues,
    onClose,
  } = props
  return (
    <FormBase
      form={form}
      onSubmit={onSubmit}
      initialValues={initialValues}
      className={classes.root}
    >
      {fields.map(field => <Field key={field.name} {...field} />)}
      <div className={classes.btnContainer}>
        <FormButtonSubmit type="submit">
          {Object.keys(initialValues).length ? 'Save' : 'Reply'}
        </FormButtonSubmit>
        <Button type="button" onClick={onClose} size="small">
          Cancel
          </Button>
      </div>
    </FormBase>
  )
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

Form.displayName = `${displayName}Form`

export default withStyles(styles)(Form)
