import React from 'react'
import PropTypes from 'prop-types'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import FormBase from './FormBase'
import FormButtons from './FormButtons'
import Card from '../common/components/Card'

const styles = {
  fields: {
    padding: '0 8px'
  }
}

const FormCard = props => {
  const {
    children,
    classes,
    form,
    initialValues,
    isReset,
    onSubmit,
    successMessage,
    title,
    onDelete,
  } = props
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">{title}</Typography>
      </CardContent>
      <FormBase
        form={form}
        initialValues={initialValues}
        isReset={isReset}
        onSubmit={onSubmit}
        successMessage={successMessage}
      >
        <CardContent className={classes.fields}>
          {children}
        </CardContent>
        <CardActions>
          <FormButtons
            onDelete={onDelete}
          />
        </CardActions>
      </FormBase>
    </Card>
  )
}

FormCard.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  isReset: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
}


export default withStyles(styles)(FormCard)