import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardActionArea from '@material-ui/core/CardActionArea'

import Card from '../../common/components/Card'
import displayName from './displayName'

const styles = theme => ({
  li: {
    flex: '1 1 100%',
  },
  headline: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
  },
})

const ListItem = (props) => {
  const {
    classes,
    item,
    match
  } = props
  const {
    _id,
    email,
    name,
  } = item
  return (
    <li className={classes.li}>
      <Card to={`${match.url}/${_id}`} >
        <CardActionArea>
          <CardContent className={classes.headline}>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="h6">{email}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </li>
  )
}

ListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

ListItem.displayName = `${displayName}ListItem`

export default withStyles(styles)(ListItem)