import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StripeProvider, Elements } from 'react-stripe-elements'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

import onLoadScript from '../common/onLoadScript'
import Loading from '../../common/components/Loading'
import None from '../../common/components/None'
import StripeCardInput from './StripeCardInput'

async function stripeReadyHandler(setStripeReady) {
  try {
    console.log('starting stripe')
    await onLoadScript('https://js.stripe.com/v3/')
    console.log('completed stripe')
    return setStripeReady(true)
  } catch (error) {
    console.log('errored in stripe', error)
    throw Error(error)
  }
}

function StripeCardField(props) {
  const [isStripeReady, setStripeReady] = useState(false)
  const {
    payment,
    ...rest
  } = props
  const {
    publicKeyLive,
    publicKeyTest,
    isLive,
  } = payment

  useEffect(() => {
    stripeReadyHandler(setStripeReady)
  }, [])

  if (!publicKeyLive && !publicKeyTest) return <None>No Stripe account found</None>
  const apiKey = isLive ? publicKeyLive : publicKeyTest
  if (!isStripeReady) return null
  return (
    <StripeProvider apiKey={apiKey}>
      <Elements>
        <StripeCardInput {...rest} />
      </Elements>
    </StripeProvider>
  )
}

StripeCardField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
  }).isRequired,
}

const mapStateToProps = ({
  app: { isFetching, payment },
}) => ({
  isFetching,
  payment,
})


export default connect(mapStateToProps)(StripeCardField)
