import React from 'react'
import PropTypes from 'prop-types'
import IconShip from '@material-ui/icons/LocalShipping'
import IconEdit from '@material-ui/icons/Edit'

import Button from '../../common/components/Button'
import displayName from './displayName'
import ButtonAsync from '../../common/components/ButtonAsync'


const Buttons = props => {
  const {
    onShipRequest,
    history,
    match,
    item = {}
  } = props
  const {
    _id,
    shipRequestedAt,
    shippedAt,
  } = item
  return (
    <>
      {(shipRequestedAt || shippedAt) ? null :
        <Button
          onClick={() => history.push(`${match.url}/delivery`)}
          tooltip="Update Delivery"
        >
          <IconEdit />
        </Button>
      }
      {shipRequestedAt ? null :
        <ButtonAsync
          confirm="Are you sure you want to request shipping for this order?"
          onClick={() => onShipRequest({ _id })}
          tooltip="Request Shipping"
          displaySuccess={true}
        >
          <IconShip />
        </ButtonAsync>
      }
    </>
  )
}

Buttons.propTypes = {
  _id: PropTypes.string,
  onShipRequest: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
}

Buttons.displayName = `${displayName}Buttons`

export default Buttons
