import Article from './Article'
import Button from './Button'
import ButtonBase from './ButtonBase'
import Carousel from './Carousel'
import CategoryListHorizontal from './CategoryListHorizontal'
import CategoryListVertical from './CategoryListVertical'
import Contact from './Contact'
import Div from './Div'
import Email from './Email'
import Iframe from './Iframe'
import Image from './Image'
import Main from './Main'
import MaterialIcon from './MaterialIcon'
import ProductListHorizontal from './ProductListHorizontal'
import ProductListVertical from './ProductListVertical'
import Section from './Section'
import Svg from './Svg'
import SvgIcon from './SvgIcon'
import Typography from './Typography'
import TypographyList from './TypographyList'
import Wysiwyg from './Wysiwyg'

const dictionary = {
  Article,
  Button,
  ButtonBase,
  Carousel,
  CategoryListHorizontal,
  CategoryListVertical,
  Contact,
  Div,
  Email,
  Iframe,
  Image,
  Main,
  MaterialIcon,
  ProductListHorizontal,
  ProductListVertical,
  Section,
  Svg,
  SvgIcon,
  Typography,
  TypographyList,
  Wysiwyg,
}

export default dictionary
