import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import AccountRouter from './AccountRouter'
import List from './List'
import Page from '../../common/components/Page'
import displayName from './displayName'

const Router = (props) => {
  const { match, user } = props
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        {route => (
          <Page>
            <List
              {...props}
              {...route}
              key={props.match.url}
              none="No suppliers"
              rootUrl={match.path}
              title="Suppliers"
            />
          </Page>
        )}
      </Route>
      <Route path={`${match.path}/:_id`}>
        {route => (
          <AccountRouter {...props} {...route} />
        )}
      </Route>
    </Switch>
  )
}

Router.propTypes = {
  match: PropTypes.object.isRequired,
}

Router.displayName = `${displayName}Router`

export default Router