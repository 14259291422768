import fetchGraphQL from '../../graphql/fetchGraphQL'

const values = `
  categories
  description
  detail
  images { 
    _id 
    src 
  }
  name
`


// QUERIES
export const blogFind = async input => {
  const { blogFind: items } = await fetchGraphQL({
    query: `
      query BlogFind($input: BlogFindInput!) {
        blogFind(input: $input) {
          _id
          appName
          imageSrc
          name
          rating
          reviews
          slug
          publishedAt
        }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return items
}


export const blogFindOne = async input => {
  const { blogFindOne: item } = await fetchGraphQL({
    query: `
      query BlogFindOne($input: BlogFindOneInput!) {
        blogFindOne(input: $input) {
          _id
          isDraft
          isPublished
          published { ${values} }
          rating
          reviews
          slug
          publishedAt
        }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}
