import React from 'react'
import classNames from 'classnames'

type ObjectMap = {
  [key: string]: string | number;
}

export interface SvgProps {
  classes: ObjectMap;
  children: string;
  elevation: number;
  id: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onDrop?: (event: React.MouseEvent<HTMLElement>) => void;
  style: ObjectMap;
}

const Svg: React.FC<SvgProps> = ({
  children,
  classes,
  elevation,
  id,
  onClick,
  onDrop,
  style,
}) => {
  const className = classNames(
    classes.root,
    classes.Svg,
    elevation && classes[`elevation${elevation}`],
  )
  return (
    <div
      className={className}
      style={style}
      id={id}
      onClick={onClick}
      onDrop={onDrop}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}

export default Svg
