import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { connect } from 'react-redux'

import { formatPhone } from '../common/utils/formatters'
import { validatePhone } from '../common/utils/validators'
import { parseOnlyNums } from '../common/utils/parsers'

const FieldPhone = ({
  isFetching,
  phoneFormat,
  validate,
  ...rest
}) => {
  if (isFetching) return null
  const validateProp = validate ? [validatePhone, validate] : validatePhone
  return (
    <Field
      {...rest}
      format={value => formatPhone(value, phoneFormat)}
      parse={parseOnlyNums}
      validate={validateProp}
      type="tel"
      autoComplete="phone"
    />
  )
}

FieldPhone.propTypes = {
  name: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  phoneFormat: PropTypes.string,
}

const mapStateToProps = ({
  app: { isFetching, brand: { phoneFormat } }
}) => ({
  isFetching,
  phoneFormat
})

export default connect(mapStateToProps)(FieldPhone)

