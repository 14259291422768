import { reducerName } from './actions'

const dialog = (state = {
  content: null,
  isOpen: false,
  title: null,
}, action) => {
  switch (action.type) {
    case `OPEN_${reducerName}`:
      return {
        ...state,
        isOpen: true,
        ...action.dialog
      }
    case `CLOSE_${reducerName}`:
      return {
        isOpen: false,
        content: null,
        title: null,
      }
    default:
      return state
  }
}

export default dialog