export const addressType = `
  addressId
  city
  email
  firstName
  lastName
  phone
  state
  street1
  street2
  user
  zip
`

export const itemType = `
  _id
  discount
  name
  price
  priceDiscounted
  product
  quantity
  requiresShipping
  slug
  subtotal
  taxPrice
  taxable
  type
  variant {
    _id
    image {
      _id
      src
    }
  }
`

export const paymentChargeType = `
  amount
  brand
  exp_month
  exp_year
  id
  last4
  provider
`

export const userType = `
  addresses
  email
  firstName
  lastName
  phone
  roles
`

export const orderType = `
  _id
  createdAt
  deliverBy
  deliveredAt
  deliveryAddress { ${addressType} }
  id
  isGift
  items { ${itemType} }
  paymentAddress { ${addressType} }
  paymentCharge { ${paymentChargeType} }
  paymentCredit { number amount }
  priceRules
  quantity
  refundId
  refundRequestedAt
  refundedAt
  shipRequestedAt
  shippedAt
  shipping { service price }
  subtotal
  subtotalDiscounted
  supplier { name }
  tax { rate price }
  total
  updatedAt
  user
`
