import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import displayName from './displayName'
import DetailBase from '../common/DetailBase'
import withGoogleAnalytics from '../../common/hocs/withGoogleAnalytics'

const Confirmation = props => {
  const { location, match, shop } = props
  const {
    order,
    user
  } = location.state
  const { orderId } = match.params
  if (!order) return <Redirect to="/user/signin" />
  return (
    <div>
      <CardContent>
        <Typography variant="subtitle1">
          Hi {user.firstName}, thank you for your order {orderId}!
          </Typography>
      </CardContent>
      <DetailBase
        match={match}
        item={order}
        user={user}
        shop={shop}
      />
    </div>
  )
}

Confirmation.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

const mapStateToProps = ({
  app: { isFetching, shop },
}) => ({
  isFetching,
  shop,
})

Confirmation.displayName = `${displayName}Confirmation`

export default withGoogleAnalytics(connect(mapStateToProps)(Confirmation))
