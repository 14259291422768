import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import displayName from './displayName'

const Div = props => {
  const {
    children,
    classes,
    elevation,
    style,
    ...rest
  } = props
  return (
    <div
      {...rest}
      className={classNames(
        classes.root,
        classes.Contact,
        style.background && style.background.backgroundImage && classes.backgroundImage,
        elevation && classes[`elevation${elevation}`]
      )}
      style={style}
    >
      {children}
    </div>
  )
}

Div.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
}

Div.displayName = `${displayName}Div`

export default Div