import React from 'react'
import { Field, FieldArray } from 'redux-form'

import FieldPhone from '../../fields/FieldPhone'
import FieldText from '../../fields/FieldText'
import displayName from '../admin/displayName'
import { parseZip } from '../../common/utils/parsers'
import { validateRequired, validateEmail } from '../../common/utils/validators'
import FieldSelectState from '../../fields/FieldSelectState'

const Fields = () => (
  <>
    <Field
      component={FieldText}
      label="Name"
      name="name"
      validate={validateRequired}
    />
    <Field
      component={FieldText}
      label="Email"
      name="email"
      validate={[validateRequired, validateEmail]}
    />
    <FieldPhone
      component={FieldText}
      label="Phone"
      name="phone"
    />
    <Field
      component={FieldText}
      label="Street"
      name="street"
      validate={validateRequired}
    />
    <Field
      component={FieldText}
      label="City"
      name="city"
      validate={validateRequired}
    />
    <Field
      component={FieldSelectState}
      label="State"
      name="state"
      validate={validateRequired}
    />
    <Field
      component={FieldText}
      label="Zip"
      name="zip"
      parse={parseZip}
      postal-code={'postal-code'}
      validate={validateRequired}
    />
  </>
)


Fields.displayName = `${displayName}Fields`

export default Fields