import { reducerName } from './reducers'

const app = (state = {
  _id: '',
  brand: {
    image: {
      src: '',
    },
  },
  checkout: {},
  error: '',
  footer: {},
  header: {},
  isBlogs: false,
  isFetching: false,
  isProducts: false,
  page: {},
  seo: {},
  shipping: {},
  shop: {},
  socialMedia: {},
  tax: {},
}, action) => {
  switch (action.type) {
    case `ADD_${reducerName}`:
      return {
        ...state,
        isFetching: false,
        ...action.item,
      }
    case `DELETE_${reducerName}`:
      return {
        ...state,
        isFetching: false,
        ...state.item,
      }
    case `ERROR_${reducerName}`:
      return {
        ...state,
        isFetching: false,
      }
    case `RECEIVE_${reducerName}`:
      return {
        ...state,
        isFetching: false,
        ...action.item,
      }
    case `REQUEST_${reducerName}`:
      return {
        ...state,
        isFetching: true,
      }
    case `UPDATE_${reducerName}`:
      return {
        ...state,
        isFetching: false,
        ...action.item,
      }
    default:
      return state
  }
}

export default app
