import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'


function FilterCheckbox({
  field,
  onChange,
  value
}) {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{field.name}</FormLabel>
      <FormGroup>
        {field.options.map(option => (
          <FormControlLabel
            key={option.name}
            control={
              <Checkbox
                checked={value}
                onChange={onChange}
                value={option.value}
              />
            }
            label={option.name}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

FilterCheckbox.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default FilterCheckbox
