const pageCreateStyles = (theme) => {
  const elevations = {}
  theme.shadows.forEach((shadow, index) => {
    elevations[`elevation${index}`] = {
      boxShadow: shadow,
      borderRadius: theme.shape.borderRadius,
    }
  })
  return {
    creatorRoot: {
      width: '100%',
    },
    pageRoot: {
      minHeight: '85vh',
    },
    root: {
      display: 'flex',
      position: 'relative',
      minWidth: 0,
      maxWidth: '100%',
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeInOut,
      }),
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'none !important',
        gridTemplateRows: 'none !important',
        gridColumn: 'auto !important',
        gridRow: 'auto !important',
      },
    },
    backgroundImage: {
      backgroundRepeat: 'no-repeat !important',
      backgroundSize: 'cover !important',
      width: '100%',
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    Button: {
      flex: '1 1 auto',
      borderColor: 'inherit',
    },
    CategoryList: {
      width: '100%',
      maxWidth: '100%',
    },
    CategoryListItem: {
      flex: '0 0 300px',
    },
    contactContainer: {
      width: '100%',
    },
    Contact: {
      flexFlow: 'column',
    },
    Iframe: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iframeParent: {
      height: 0,
      paddingBottom: '56.25%',
      position: 'relative',
    },
    iframe: {
      border: 'none',
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
    Image: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
    },
    image: {
      maxWidth: '100%',
      minWidth: '100%',
      verticalAlign: 'top',
      width: '100%',
    },
    Svg: {
      '& > svg': {
        width: '100%',
        height: '100%',
        fill: 'inherit',
      }
    },
    ProductList: {
      maxWidth: '100%',
    },
    TypographyList: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
    },
    Typography: {
      zIndex: 1,
      whiteSpace: 'pre-wrap',
      color: 'inherit',
    },
    Wysiwyg: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
    },
    wysiwyg: {
      '& >*': {
        '&:last-child': {
          margin: 0,
        },
        margin: 0,
        minHeight: '1rem',
      },
    },
    ...elevations,
  }
}

export default pageCreateStyles
