import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AppBar from './AppBar'
import Drawer from './Drawer'
import { onToggle } from '../search/actions'

const Header = (props) => {
  const { isFetching, ...rest } = props
  const [isOpen, setOpen] = useState(false)
  if (isFetching) return null
  return (
    <>
      <AppBar
        {...rest}
        onDrawer={() => Promise.resolve().then(() => setOpen(!isOpen))}
      />
      <Drawer
        {...rest}
        isOpen={isOpen}
        onDrawer={() => Promise.resolve().then(() => setOpen(!isOpen))}
      />
    </>
  )
}

const mapStateToProps = ({
  app: {
    _id: appId,
    isBlogs,
    isProducts,
    header,
    shop,
    pages: pageIds,
    isFetching: appIsFetching,
  },
  drawer,
  categories: { isFetching: categoriesIsFetching, items: categories },
  pages: { items: pages, isFetching: pagesIsFetching },
  search,
  user,
}) => ({
  appId,
  categories,
  drawer,
  header,
  isBlogs,
  isFetching: appIsFetching || categoriesIsFetching || pagesIsFetching,
  isProducts,
  pageIds,
  pages,
  shop,
  search,
  user,
})

const mapDispatchToProps = dispatch => ({ onSearch: bool => dispatch(onToggle(bool)) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
