import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'

import IconFacebook from '../icons/IconFacebook'
import IconGithub from '../icons/IconGithub'
import IconGoogleplus from '../icons/IconGoogleplus'
import IconInstagram from '../icons/IconInstagram'
import IconLinkedin from '../icons/IconLinkedin'
import IconTwitter from '../icons/IconTwitter'
import IconYelp from '../icons/IconYelp'
import IconYoutube from '../icons/IconYoutube'

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.mixins.gutters({
      paddingTop: 16,
      paddingBottom: 16,
    }),
  }
})


const FooterSocialMedia = ({
  classes,
  color,
  socialMedia: {
    facebook,
    github,
    googleplus,
    instagram,
    linkedin,
    twitter,
    yelp,
    youtube
  },
}) => {
  return (
    <div className={classes.root}>
      {facebook &&
        <a href={facebook}>
          <IconButton style={{ color }}>
            <IconFacebook color="inherit" />
          </IconButton>
        </a>
      }
      {github &&
        <a href={github}>
          <IconButton style={{ color }}>
            <IconGithub color="inherit" />
          </IconButton>
        </a>
      }
      {googleplus &&
        <a href={googleplus}>
          <IconButton style={{ color }}>
            <IconGoogleplus color="inherit" />
          </IconButton>
        </a>
      }
      {instagram &&
        <a href={instagram}>
          <IconButton style={{ color }}>
            <IconInstagram color="inherit" />
          </IconButton>
        </a>
      }
      {linkedin &&
        <a href={linkedin}>
          <IconButton style={{ color }}>
            <IconLinkedin color="inherit" />
          </IconButton>
        </a>
      }
      {twitter &&
        <a href={twitter}>
          <IconButton style={{ color }}>
            <IconTwitter color="inherit" />
          </IconButton>
        </a>
      }
      {yelp &&
        <a href={yelp}>
          <IconButton style={{ color }}>
            <IconYelp color="inherit" />
          </IconButton>
        </a>
      }
      {youtube &&
        <a href={youtube}>
          <IconButton style={{ color }}>
            <IconYoutube color="inherit" />
          </IconButton>
        </a>
      }
    </div>
  )
}

FooterSocialMedia.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  socialMedia: PropTypes.object.isRequired,
}



export default withStyles(styles)(FooterSocialMedia)
