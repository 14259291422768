import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Star from '@material-ui/icons/Star'
import StarHalf from '@material-ui/icons/StarHalf'
import StarBorder from '@material-ui/icons/StarBorder'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  icon: {
    width: 'auto',
  },
  typography: {
    marginLeft: 16
  }
}

class Rating extends Component {
  constructor(props) {
    super(props)
    const maxStars = new Array(5).fill()
    this.state = {
      hoverValue: null,
      maxStars
    }
  }
  renderIcon = (i) => {
    const { stars } = this.props
    const floor = Math.floor(stars)
    const ceiling = floor + 1
    const fraction = stars - Math.floor(stars)
    switch (true) {
      case (i > floor && i <= ceiling && fraction >= .01):
        return <StarHalf color="primary" />
      case (i >= ceiling):
        return <StarBorder color="disabled" />
      case (i <= floor):
        return <Star color="primary" />
      default:
        return <StarBorder color="disabled" />
    }
  }
  render() {
    const { classes } = this.props
    const { maxStars } = this.state
    return (
      <div className={classes.root}>
        {maxStars.map((s, i) => (
          <div key={i + 1} className={classes.icon}>
            {this.renderIcon(i + 1)}
          </div>
        ))}
      </div>
    )
  }
}

Rating.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  rating: PropTypes.object,
}

export default withStyles(styles)(Rating)
