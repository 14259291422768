import createActions from '../../redux/createActions'

import {
  productFind,
  productFindOne,
} from './api'

import { reducerName } from './reducers'

const {
  ADD,
  DELETE,
  FILTER,
  RECEIVE,
  REQUEST,
  UPDATE,
  RESET,
  ERROR,
} = createActions(reducerName)


const onError = error => ({ type: ERROR, error })
const onRequest = () => ({ type: REQUEST })
export const onReset = () => ({ type: RESET })


const onFilterSuccess = items => ({ type: FILTER, items })
export const onFilter = input => async dispatch => {
  dispatch(onRequest())
  const items = await productFind(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onFilterSuccess(items))
}

const onFindSuccess = items => ({ type: RECEIVE, items })
export const onFind = input => async dispatch => {
  dispatch(onRequest())
  const items = await productFind(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onFindSuccess(items))
}


const onFindOneSuccess = (item) => ({ type: ADD, item })
export const onFindOne = input => async dispatch => {
  dispatch(onRequest())
  const item = await productFindOne(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onFindOneSuccess(item))
}


export const onUpdateSuccess = (item) => ({ type: UPDATE, item })