import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import renderHTML from 'react-render-html'
import withStyles from '@material-ui/core/styles/withStyles'

import moment from 'moment'
import FadeInIfNeeded from '../../common/components/FadeInIfNeeded'
import HeadArticle from '../../head/HeadArticle'
import Images from '../../image/common/Images'
import Loading from '../../common/components/Loading'
import RatingSummary from '../../rating/RatingSummary'
import ReviewList from '../../review/public/ListVertical'
import displayName from './displayName'
import withGoogleAnalytics from '../../common/hocs/withGoogleAnalytics'

const styles = (theme) => ({
  detailContainer: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexFlow: 'row wrap',
  },
  headlineContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItem: 'center',
  },
  listHeadline: {
    paddingBottom: 0,
  },
  content: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    flex: '1 1 300px',
  },
  contentItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  detail: {
    marginTop: 16,
  },
  categories: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
})

function Detail(props) {
  const {
    classes,
    history,
    match,
    onUpdateSuccess,
    blog,
  } = props
  const {
    _id,
    published = {},
    rating,
    reviews,
    slug,
    publishedAt,
  } = blog
  const {
    categories: categoryIds,
    description,
    detail,
    images = [],
    name,
  } = published
  const [imageSrcs, setImageSrcs] = useState({})
  const [imageItems, setImageItems] = useState({})
  const [isChildrenReady, setChildrenReady] = useState(false)
  useEffect(() => {
    setImageSrcs(images.map((i) => i.src))
    setImageItems(images.reduce((a, v) => { a[v._id] = v; return a }, {}))
    setChildrenReady(true)
  }, [images])
  if (!isChildrenReady) return <Loading />
  return (
    <>
      <HeadArticle
        _id={_id}
        description={description}
        imageSrc={imageSrcs[0]}
        name={name}
        rating={rating}
        reviews={reviews}
        publishedAt={publishedAt}
      />
      <FadeInIfNeeded srcs={imageSrcs}>
        <div className={classes.detailContainer}>
          <Images imageItems={imageItems} alt={name} selectedId={images[0]._id} />
          <CardContent className={classes.content}>
            <Typography variant="h5">{name}</Typography>
            <Typography>{moment(publishedAt).format('dddd, MMMM Do YYYY')}</Typography>
            {reviews ? <RatingSummary rating={rating} reviews={reviews} /> : null}
            <Typography variant="body1" className={classes.contentItem}>
              {description}
            </Typography>

            {/*
              <div className={classes.categories}>
                {categoryIds && categoryIds.map(id => (
                  <Button key={id} component={Link} to={`/${shop.slug}/${categories[id].slug}`} size="small">
                    {categories[id].name}
                  </Button>
                ))}
              </div>
            */}

          </CardContent>
        </div>

        {detail.length < 10 ? null
          : (
            <CardContent className={classes.detail}>
              <Typography component="div">{renderHTML(detail)}</Typography>
            </CardContent>
          )}

        <div className={classes.contentItem}>
          <ReviewList
            auth={false}
            reviewCount={reviews}
            onModel="Blog"
            onDoc={blog._id}
            onDocName={name}
            onUpdateParentSuccess={onUpdateSuccess}
          />
        </div>
      </FadeInIfNeeded>
    </>
  )
}

Detail.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
}

Detail.displayName = `${displayName}Detail`

export default withGoogleAnalytics(withStyles(styles)(Detail))
