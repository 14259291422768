import React from 'react'
import PropTypes from 'prop-types'

import Fields from '../common/Fields'
import FormCard from '../../forms/FormCard'
import displayName from './displayName'

const Update = ({
  item,
  onUpdate,
}) => {
  const { _id, ...rest } = item
  return (
    <FormCard
      form={`supplier${_id}`}
      initialValues={rest}
      onSubmit={values => onUpdate({ _id, ...values })}
      successMessage="Supplier Updated!"
      title={`Update Supplier ${_id}`}
    >
      <Fields />
    </FormCard>
  )
}

Update.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

Update.displayName = `${displayName}Update`

export default Update