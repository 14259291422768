import getImageSrc from '../../image/common/getImageSrc'

const buildString = (obj, join) => {
  if (!obj) return
  const string = Object
    .keys(obj)
    .map(key => obj[key] ? obj[key] : '')
    .filter(x => x.length > 0)
    .join(join)
  return string
}

const buildBackground = background => {
  if (!background) return
  const {
    backgroundAttachment,
    backgroundClip,
    backgroundColor,
    backgroundImage: imageUrl,
    backgroundOrigin,
    backgroundPositionX,
    backgroundPositionY,
    backgroundRepeatX,
    backgroundRepeatY,
    backgroundSize,
    linearGradientColorStop1,
    linearGradientColorStop2,
  } = background
  const gradient = linearGradientColorStop1 || linearGradientColorStop2 ? `linear-gradient(rgba(0,0,0,0) ${linearGradientColorStop1 || 0}%, rgba(0,0,0,.9) ${linearGradientColorStop2 || 0}%)` : null
  const backgroundImage = buildString({ gradient, backgroundImage: `url(${getImageSrc(imageUrl)})` }, ',')

  return {
    backgroundAttachment,
    backgroundClip,
    backgroundColor,
    backgroundImage,
    backgroundOrigin,
    backgroundPositionX,
    backgroundPositionY,
    backgroundRepeatX,
    backgroundRepeatY,
    backgroundSize,
    linearGradientColorStop1,
    linearGradientColorStop2,
  }
}

export default buildBackground