import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import Clear from '@material-ui/icons/Clear'
import IconSearch from '@material-ui/icons/Search'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'

import { onToggle, onAdd, onFetch, onReady } from './actions'

const styles = theme => ({
  SearchField: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flex: '1 1 auto',
    marginLeft: -20
  },
  input: {
    ...theme.typography.body1,
    flex: '1 1 auto',
  }
})

class SearchField extends Component {
  state = {
    timeoutId: null,
  }
  handleKeyUp = () => {
    const { onFetch } = this.props
    const timeoutId = setTimeout(() => {
      clearTimeout(timeoutId)
      this.setState({ timeoutId: null })
      return onFetch()
    }, 600)
    return this.setState({ timeoutId })
  }
  handleKeyDown = () => {
    const { timeoutId } = this.state
    timeoutId && clearTimeout(timeoutId)
    this.setState({ timeoutId: null })
  }
  componentWillUnmount() {
    const { timeoutId } = this.state
    timeoutId && clearTimeout(timeoutId)
  }
  render() {
    const {
      classes,
      color,
      onAdd,
      onToggle,
      search,
    } = this.props
    return (
      <span className={classes.SearchField}>
        <IconButton style={{ color }}>
          <IconSearch />
        </IconButton>
        <Input
          autoFocus={true}
          disableUnderline={true}
          style={{ color }}
          className={classes.input}
          placeholder="Search"
          value={search.value}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          onChange={(e) => onAdd(e.target.value)}
        />
        <IconButton style={{ color }} onClick={onToggle}>
          <Clear />
        </IconButton>
      </span>
    )
  }
}

const mapStateToProps = ({ search }) => ({ search })

const mapDispatchToProps = dispatch => ({
  onAdd: searchValue => dispatch(onAdd(searchValue)),
  onFetch: () => dispatch(onFetch()),
  onReady: bool => dispatch(onReady(bool)),
  onToggle: () => dispatch(onToggle()),
})

SearchField.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
  onReady: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchField))
