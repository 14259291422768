import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import IconButton from '@material-ui/core/IconButton'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'

import Loading from './Loading'

const styles = {
  root: {
    position: 'relative',
    overflow: 'hidden',
  },
  ul: {
    WebkitOverflowScrolling: 'touch',
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row nowrap',
    listStyle: 'none',
    overflowY: 'auto',
    padding: 0,
    scrollBehavior: 'smooth',
    willChange: 'transform',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  btnContainer: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    zIndex: 1,
  },
  chevronLeft: {
    left: 0,
  },
  chevronRight: {
    right: 0,
  },
}

let isTicking = false

function onNext(ulRef) {
  const { current } = ulRef
  current.scrollLeft += current.clientWidth
}

function onPrevious(ulRef) {
  const { current } = ulRef
  current.scrollLeft -= current.clientWidth
}

function handleUpdate({ ulRef, isIndicators, setIndicators }) {
  isTicking = false
  const requiresIndicators = ulRef.current.scrollWidth > ulRef.current.clientWidth
  if (requiresIndicators !== isIndicators) setIndicators(requiresIndicators)
}

function handleResize({ ulRef, isIndicators, setIndicators }) {
  if (!isTicking) {
    isTicking = true
    requestAnimationFrame(() => handleUpdate({ ulRef, isIndicators, setIndicators }))
  }
}

function ULHorizontalBase({
  children,
  className,
  classes,
  count = 0,
  isFetching = false,
  onFind,
  ulEndRef: ulEndRefProp,
  ulRef: ulRefProp,
  ...rest
}) {
  const ulEndRefNew = useRef()
  const ulRefNew = useRef()
  const [isIndicators, setIndicators] = useState(false)

  const ulEndRef = ulEndRefProp || ulEndRefNew
  const ulRef = ulRefProp || ulRefNew

  useEffect(() => {
    window.addEventListener('resize', () => handleResize({ ulRef, isIndicators, setIndicators }), false)
    window.addEventListener('orientationchange', () => handleResize({ ulRef, isIndicators, setIndicators }), false)
    setIndicators(ulRef.current.scrollWidth > ulRef.current.clientWidth)
    return () => {
      window.removeEventListener('resize', () => handleResize({ ulRef, isIndicators, setIndicators }), false)
      window.removeEventListener('orientationchange', () => handleResize({ ulRef, isIndicators, setIndicators }), false)
    }
  }, [])

  useEffect(() => {
    setIndicators(ulRef.current.scrollWidth > ulRef.current.clientWidth)
  }, [children.length])

  return (
    <div {...rest} className={classNames(classes.root, className)}>
      {!isIndicators ? null
        : (
          <div className={classNames(classes.btnContainer, classes.chevronLeft)}>
            <IconButton onClick={() => onPrevious(ulRef)} color="primary">
              <ChevronLeft />
            </IconButton>
          </div>
        )}
      <ul className={classes.ul} ref={ulRef}>
        {children}
        <li ref={ulEndRef}>
          {isFetching ? <Loading /> : null}
          {count === 0 ? null : (
            <IconButton
              onClick={onFind}
              tooltip="Fetch More"
              type="button"
              variant="contained"
            >
              <ChevronRight />
            </IconButton>
          )}
        </li>
      </ul>
      {!isIndicators ? null
        : (
          <div className={classNames(classes.btnContainer, classes.chevronRight)}>
            <IconButton onClick={() => onNext(ulRef)} color="primary">
              <ChevronRight />
            </IconButton>
          </div>
        )}
    </div>
  )
}

ULHorizontalBase.defaultProps = {
  className: undefined,
  ulEndRef: undefined,
  ulRef: undefined,
}

ULHorizontalBase.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.instanceOf(Object).isRequired,
  count: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onFind: PropTypes.func.isRequired,
  ulEndRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ulRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
}

export default withStyles(styles)(ULHorizontalBase)
