import { useEffect, useRef, useState } from 'react'

let isTicking = false

const usePaginateVertical = () => {
  const ulRef = useRef()
  const ulEndRef = useRef()
  const [isVisible, setVisible] = useState(false)
  const onScrollUpdate = () => {
    isTicking = false
    const ulEnd = ulEndRef.current.getBoundingClientRect()
    const hasVisibility = ulEnd.y <= window.innerHeight
    setVisible(hasVisibility)
  }
  const onScroll = () => {
    if (!isTicking) {
      isTicking = true
      requestAnimationFrame(onScrollUpdate)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', onScroll, false)
    return () => window.removeEventListener('scroll', onScroll, false)
  }, [])
  return {
    ulRef,
    ulEndRef,
    isVisible
  }
}

export default usePaginateVertical