import React from 'react'
import RootRef from '@material-ui/core/RootRef'

import List from './List'
import cleanObj from '../../common/utils/cleanObj'
import dictionary from '../common/dictionary'
import displayName from './displayName'
import useDnDSortListItem from '../../common/hooks/useDnDSortListItem'


const ListItem = (props) => {
  const {
    classes,
    component,
    components,
    id,
    index,
    isEditing,
    itemType,
    onComponentUpdateComponents,
    onDrop,
    onEdit,
    onMove,
    onPageUpdateComponents,
    page,
  } = props

  const { ref, isDragging } = useDnDSortListItem({ itemType, index, onMove, id })

  const { published = {}, draft = {}, isDraft, isPublished, parent } = component

  const componentValues = isDraft ? draft : published

  const { style = {}, children, component: c, components: componentValuesComponents, ...rest } = componentValues
  const Comp = dictionary[c] || c

  return (
    <RootRef rootRef={ref}>
      <Comp
        {...rest}
        classes={classes}
        id={id}
        onDrop={onDrop}
        style={{ ...style, opacity: isDragging ? 0 : 1 }}
        onClick={(e) => {
          e.stopPropagation()
          return onEdit({
            _id: id,
            form: 'component',
            ...parent && { parent },
            initialValues: { component: cleanObj({ style, children, component: c, ...rest }) },
          })
        }}
      >
        {(children && componentValuesComponents && componentValuesComponents.length) ? (
          <>
            {children}
            <List
              childComponents={componentValuesComponents}
              classes={classes}
              component={id}
              components={components}
              isEditing={isEditing}
              itemType={`component_${id}`}
              onComponentUpdateComponents={onComponentUpdateComponents}
              onEdit={onEdit}
              onPageUpdateComponents={onPageUpdateComponents}
              page={page}
            />
          </>
        ) : children || (
          <List
            childComponents={componentValuesComponents}
            classes={classes}
            component={id}
            components={components}
            isEditing={isEditing}
            itemType={`component_${id}`}
            onComponentUpdateComponents={onComponentUpdateComponents}
            onEdit={onEdit}
            onPageUpdateComponents={onPageUpdateComponents}
            page={page}
          />
        )}
      </Comp>
    </RootRef>
  )
}

ListItem.displayName = `${displayName}ListItem`

export default ListItem
