import { useEffect, useRef, useState } from 'react'

let isTicking = false

const usePaginateHorizontal = () => {
  const ulRef = useRef()
  const ulEndRef = useRef()
  const [isVisible, setVisible] = useState(false)
  const onScrollUpdate = () => {
    isTicking = false
    const ul = ulRef.current
    const ulEnd = ulEndRef.current.getBoundingClientRect()
    const ulOffsetLeft = window.innerWidth >= ul.offsetWidth ? (window.innerWidth - ul.offsetWidth) / 2 : 0
    const ulWidth = ulOffsetLeft + ul.offsetWidth
    const hasVisibility = ulEnd.x - ulEnd.width - 16 <= ulWidth
    setVisible(hasVisibility)
  }
  const onScroll = () => {
    if (!isTicking) {
      isTicking = true
      requestAnimationFrame(onScrollUpdate)
    }
  }
  useEffect(() => {
    ulRef.current.addEventListener('scroll', onScroll, true)
    return () => ulRef.current.removeEventListener('scroll', onScroll, false)
  }, [])
  return {
    ulRef,
    ulEndRef,
    isVisible
  }
}

export default usePaginateHorizontal