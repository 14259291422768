import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from "react-helmet"
import { connect } from 'react-redux'

import getImageSrc from '../image/common/getImageSrc'

function HeadProduct(props) {
  const {
    _id,
    businessName,
    description,
    imageSrcs,
    name,
    price,
    rating,
    reviews,
  } = props
  const images = imageSrcs.map(src => `"${getImageSrc(src)}"`)
  const aggregateRating = rating || reviews ? `
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "${rating}",
      "reviewCount": "${reviews}"
    }${price ? ',' : ''}
   ` : ''
  const offers = price ? `
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": "${(price / 100).toFixed(2)}",
      "itemCondition": "http://schema.org/UsedCondition",
      "availability": "http://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "name": "${businessName}"
      }
    }
   ` : ''
  return (
    <Helmet>
      <title>{`${businessName} - ${name}`}</title>
      <meta name="description" content={description} />
      {description ? <meta property="og:description" content={description} /> : null}
      <meta property="og:title" content={`${businessName} - ${name}`} />
      <meta property="og:url" content={window.location.pathname} />
      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org/",
          "@type": "Product",
          "name": "${name}",
          "image": ${images},
          ${description ? `"description": "${description}"` : ''},
          ${_id ? `"mpn": "${_id}"${rating || reviews ? ',' : ''}` : ''}
          ${aggregateRating}
          ${offers}
        }`}
      </script>
    </Helmet>
  )
}


HeadProduct.propTypes = {
  _id: PropTypes.string,
  businessName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageSrcs: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  rating: PropTypes.number,
  reviews: PropTypes.number,
}

const mapStateToProps = ({
  app: {
    business: {
      name: { children: businessName },
      image: { src: businessImageSrc },
    }
  }
}) => ({
  businessName,
  businessImageSrc
})

export default connect(mapStateToProps)(HeadProduct)
