import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MuiCard from '@material-ui/core/Card'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link } from 'react-router-dom'

import useImage from '../hooks/useImage'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    margin: theme.spacing(1),
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  isLoading: {
    visibility: 'hidden',
    opacity: 0,
  },
  isLoaded: {
    visibility: 'visible',
    opacity: 1,
  },
})


const Card = (props) => {
  const {
    className,
    classes,
    children,
    to,
    onClick,
    imageSrc,
    ...rest
  } = props
  const [elevation, setElevation] = useState(props.elevation || 2)
  const isLoading = useImage(imageSrc)
  const mouseProps = (to || onClick) && {
    onMouseEnter: () => setElevation(props.elevation ? props.elevation + 4 : 6),
    onMouseLeave: () => setElevation(elevation > props.elevation ? elevation - 4 : props.elevation),
  }
  const onClickProps = onClick && {
    onClick: (e) => {
      e.stopPropagation()
      return onClick()
    },
  }
  const toProps = to && {
    component: Link,
    to,
  }
  return (
    <MuiCard
      {...rest}
      {...mouseProps}
      {...onClickProps}
      {...toProps}
      className={classNames(classes.root, isLoading ? classes.isLoading : classes.isLoaded, className)}
      elevation={elevation}
    >
      {children}
    </MuiCard>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  elevation: PropTypes.number,
}

export default withStyles(styles)(Card)
