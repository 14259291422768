import React from 'react'
import TextField from '@material-ui/core/TextField'

import displayName from './displayName'

const CVC = props => {
  const {
    input,
    inputRef,
    classes,
    onKeyDown,
    meta: { error, submitError, touched }
  } = props
  return (
    <div className={classes.cardCVC}>
      <TextField
        {...input}
        fullWidth
        label="CVC"
        inputRef={inputRef}
        error={touched && Boolean(error)}
        helperText={touched && error ? error || submitError : ' '}
        onKeyDown={e => onKeyDown(e, input.value)}
      />
    </div>
  )
}

CVC.displayName = `${displayName}CVC`

export default CVC