import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconButtonMui from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'


class IconButton extends Component {
  renderButton = () => {
    const {
      children,
      className,
      ...rest
    } = this.props
    return (
      <IconButtonMui className={className} {...rest}>
        {children}
      </IconButtonMui>
    )
  }
  render() {
    const { tooltip } = this.props
    if (tooltip) {
      return (
        <Tooltip
          enterDelay={300}
          id={tooltip}
          leaveDelay={100}
          title={tooltip}
        >
          {this.renderButton()}
        </Tooltip>
      )
    }
    return this.renderButton()
  }
}

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.string,
}

export default IconButton
