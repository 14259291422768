import fetchGraphQL from '../../graphql/fetchGraphQL'



// QUERIES
export const addressFind = async input => {
  try {
    const { addressFind: items } = await fetchGraphQL({
      query: `
        query AddressFind($input: AddressFindInput!) {
          addressFind(input: $input) {
            _id
            city
            email
            firstName
            lastName
            phone
            state
            street1
            street2
            user
            zip
          }
        }
    `,
      variables: { input }
    })
    return items
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addressFindOne = async input => {
  try {
    const { addressFindOne: item } = await fetchGraphQL({
      query: `
        query AddressFindOne($input: AddressFindOneInput!) {
          addressFindOne(input: $input) {
            _id
            city
            email
            firstName
            lastName
            phone
            state
            street1
            street2
            user
            zip
          }
        }
    `,
      variables: { input }
    })
    return item
  } catch (error) {
    return Promise.reject(error)
  }
}







// MUTATIONS
export const addressAdd = async input => {
  try {
    const { addressAdd: item } = await fetchGraphQL({
      query: `
      mutation AddressAdd($input: AddressAddInput!) {
        addressAdd(input: $input) {
          user {
            _id
            addresses
          }
          address {
            _id
            city
            email
            firstName
            lastName
            phone
            state
            street1
            street2
            zip
          }
        }
      }
      `,
      variables: { input }
    })
    const { address, user } = item
    return {
      address,
      user
    }
  } catch (error) {
    return Promise.reject(error)
  }
}




export const addressDelete = async input => {
  try {
    const { addressDelete: item } = await fetchGraphQL({
      query: `
        mutation AddressDelete($input: AddressDeleteInput!) {
          addressDelete(input: $input) {
            address {
              _id
            }
            user {
              _id
              addresses
              email
              firstName
              lastName
              phone
              roles
            }
          }
        }
      `,
      variables: { input },
    })
    const { address, user } = item
    return {
      address,
      user
    }
  } catch (error) {
    return Promise.reject(error)
  }
}



export const addressUpdate = async input => {
  const { addressUpdate: item } = await fetchGraphQL({
    query: `
      mutation AddressUpdate($input: AddressUpdateInput!) {
        addressUpdate(input: $input) {
          _id
          city
          email
          firstName
          lastName
          phone
          state
          street1
          street2
          zip
        }
      }
    `,
    variables: { input },
  }).catch(error => Promise.reject(error))
  return item
}
