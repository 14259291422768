import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

function RoutePrivate(props) {
  const {
    children,
    requiredRoles,
    user,
    ...rest
  } = props
  if (user.isFetching) return null
  if (requiredRoles.some(v => user.roles.indexOf(v) !== -1)) {
    const adminUserProp = user.roles.some(r => ['admin', 'owner'].includes(r)) && { adminUser: user }
    return (
      <Route {...rest}>
        {route => children({
          ...props,
          ...route,
          ...adminUserProp,
        })}
      </Route>
    )
  }
  localStorage.setItem('redirect', props.location.pathname)
  return <Redirect to="/user/signin" />
}

RoutePrivate.propTypes = {
  children: PropTypes.func,
  requiredRoles: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
}

const mapStateToProps = ({ user }) => ({ user })

export default connect(mapStateToProps)(RoutePrivate)
