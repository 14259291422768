import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import IconAccount from '@material-ui/icons/AccountCircle'
import IconAttachMoney from '@material-ui/icons/AttachMoney'
import IconCart from '@material-ui/icons/ShoppingCart'
import IconLocation from '@material-ui/icons/LocationOn'
import IconReview from '@material-ui/icons/RateReview'
import IconShipping from '@material-ui/icons/LocalShipping'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import displayName from './displayName'
import Card from '../../common/components/Card'

const styles = (theme) => ({
  ul: {
    display: 'flex',
    flexFlow: 'row wrap',
    listStyle: 'none',
    margin: 0,
    padding: `0 ${theme.spacing(1)}px`,
  },
  cardContent: {
    paddingBottom: theme.spacing(1),
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
  },
})

const Account = (props) => {
  const {
    classes,
    match: { url },
    user,
    userId,
  } = props
  return (
    <div>
      <CardContent className={classes.cardContent}>
        <Typography variant="h5">
          {userId ? 'Account for' : 'Welcome'}
          {' '}
          {user.firstName}
        </Typography>
      </CardContent>
      <ul className={classes.ul}>

        <li>
          <Card to={`${url}/profile`}>
            <CardContent>
              <Typography variant="body1">Profile</Typography>
              <div className={classes.icon}>
                <IconAccount color="primary" />
              </div>
            </CardContent>
          </Card>
        </li>

        <li>
          <Card to={`${url}/addresses`}>
            <CardContent>
              <Typography variant="body1">Addresses</Typography>
              <div className={classes.icon}>
                <IconLocation color="primary" />
              </div>
            </CardContent>
          </Card>
        </li>

        <li>
          <Card to={`${url}/credit`}>
            <CardContent>
              <Typography variant="body1">Credit</Typography>
              <div className={classes.icon}>
                <IconAttachMoney color="primary" />
              </div>
            </CardContent>
          </Card>
        </li>

        <li>
          <Card to={`${url}/deliveries`}>
            <CardContent>
              <Typography variant="body1">Deliveries</Typography>
              <div className={classes.icon}>
                <IconCart color="primary" />
              </div>
            </CardContent>
          </Card>
        </li>

        <li>
          <Card to={`${url}/orders`}>
            <CardContent>
              <Typography variant="body1">Orders</Typography>
              <div className={classes.icon}>
                <IconCart color="primary" />
              </div>
            </CardContent>
          </Card>
        </li>

        <li>
          <Card to={`${url}/reviews`}>
            <CardContent>
              <Typography variant="body1">Reviews</Typography>
              <div className={classes.icon}>
                <IconReview color="primary" />
              </div>
            </CardContent>
          </Card>
        </li>

        {user && user.suppliers && user.suppliers.length
          ? (
            <li>
              <Card to={`${url}/suppliers`}>
                <CardContent>
                  <Typography variant="body1">Suppliers</Typography>
                  <div className={classes.icon}>
                    <IconShipping color="primary" />
                  </div>
                </CardContent>
              </Card>
            </li>
          )
          : null}


      </ul>
    </div>
  )
}

Account.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}

Account.displayName = `${displayName}Account`

export default withStyles(styles)(Account)
