import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'

import Background from '../background/Background'
import Loading from '../common/components/Loading'
import Head from '../head/Head'

const styles = (theme) => ({
  top: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
    marginBottom: theme.spacing(2),
  },
  bottom: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 100,
    marginBottom: theme.spacing(2),
  },
})

function ShopHeader({
  classes,
  isFetching,
  name,
  shop = {},
  description,
}) {
  if (isFetching) return <Loading />
  const { image, backgroundColor, color } = shop
  return (
    <>
      <Head name={name} description={description} />
      <Background image={image} className={classes.top} style={{ backgroundColor, color }}>
        <CardContent>
          <Typography align="center" color="inherit" variant="h4">{name}</Typography>
        </CardContent>
      </Background>
      {description
        ? (
          <CardContent className={classes.bottom}>
            <Typography align="center" color="inherit" variant="subtitle1">
              {description}
            </Typography>
          </CardContent>
        )
        : null}
    </>
  )
}

ShopHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  shop: PropTypes.object.isRequired,
}

const mapStateToProps = ({
  app: { isFetching, shop },
}) => ({
  isFetching,
  shop,
})

export default connect(mapStateToProps)(withStyles(styles)(ShopHeader))
