import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import displayName from './displayName'

const Article = props => {
  const {
    children,
    classes,
    elevation,
    style,
    ...rest
  } = props

  return (
    <article
      {...rest}
      className={classNames(
        classes.root,
        classes.article,
        style.background && style.background.backgroundImage && classes.backgroundImage,
        elevation && classes[`elevation${elevation}`]
      )}
      style={style}
    >
      {children}
    </article>
  )
}

Article.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  style: PropTypes.object,
}

Article.displayName = `${displayName}Article`

export default Article