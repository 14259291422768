import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import useSnackbar from './useSnackbar'
import SnackbarContext from './SnackbarContext'

const SnackbarProvider: React.FC = ({ children }) => {
  const snackbar = useSnackbar()
  const {
    isOpen,
    severity,
    onSnackClose,
    message,
  } = snackbar
  return (
    <SnackbarContext.Provider value={snackbar}>
      {children}
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={() => onSnackClose()}
      >
        <Alert onClose={() => onSnackClose()} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
