const itemReducer = (state, action) => {
  switch (action.type) {
    case 'ADD': {
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        item: {
          ...state.item,
          ...action.item,
        },
      }
    }
    case 'DELETE': {
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        item: {},
      }
    }
    case 'ERROR': {
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isFetching: false,
      }
    }
    case 'RECEIVE': {
      return {
        ...state,
        error: null,
        isFetching: false,
        isLoading: false,
        item: {
          ...state.item,
          ...action.item,
        },
      }
    }
    case 'REQUEST': {
      return {
        ...state,
        isFetching: true,
        isLoading: false,
      }
    }
    case 'RESET': {
      return {
        error: null,
        isFetching: false,
        isLoading: false,
        item: {},
      }
    }
    case 'UPDATE': {
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        item: {
          ...state.item,
          ...action.item,
        },
      }
    }
    default:
      return state
  }
}

export default itemReducer
