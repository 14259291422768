import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'

import Loading from './Loading'

const styles = theme => ({
  ul: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-around',
    listStyle: 'none',
    margin: `-${theme.spacing(1) / 4}px`,
    padding: 0,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  ulEnd: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 100%',
  },
})

const ULVerticalBase = ({
  children,
  className,
  classes,
  count = 0,
  isFetching = false,
  onFind,
  ulEndRef,
  ulRef,
  none,
  ...rest
}) => (
    <ul {...rest} className={classNames(classes.ul, className)} ref={ulRef}>
      {!isFetching && !children.length && none ? <li><Typography>{none}</Typography></li> : children}
      <li className={classes.ulEnd} ref={ulEndRef}>
        {isFetching ? <Loading /> : count === 0 ? null
          : (
            <IconButton
              type="button"
              variant="contained"
              onClick={onFind}
              tooltip="Fetch More"
            >
              <IconExpandMore />
            </IconButton>
          )
        }
      </li>
    </ul>
  )

ULVerticalBase.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  count: PropTypes.number,
  isFetching: PropTypes.bool,
  onFind: PropTypes.func,
  ulEndRef: PropTypes.object,
  ulRef: PropTypes.object,
}

export default withStyles(styles)(ULVerticalBase)
