import React from 'react'
import PropTypes from 'prop-types'
import Badge from '@material-ui/core/Badge'
import IconShip from '@material-ui/icons/LocalShipping'
import green from '@material-ui/core/colors/green'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  badge: {
    top: '46%',
    right: '60%',
    color: '#fff'
  },
})

const IconDelivered = props => {
  const { classes } = props
  return (
    <Badge badgeContent="&#10003;" classes={{ badge: classes.badge }}>
      <IconShip />
    </Badge>
  )
}

IconDelivered.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(IconDelivered)
