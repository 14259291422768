import cleanObj from './cleanObj'

const cleanNull = arg => {
  if (Array.isArray(arg)) {
    const cleaned = arg.map(cleanObj)
    return cleaned
  }
  const cleaned = cleanObj(arg)
  return cleaned
}

export default cleanNull