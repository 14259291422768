
import fetchGraphQL from '../../graphql/fetchGraphQL'

export const variantFind = async input => {
  try {
    const { variantFind: items } = await fetchGraphQL({
      query: `
        query VariantFind($input: VariantFindInput!) {
          variantFind(input: $input) {
            _id
            appName
            image
            name
            price
            product
            requiresShipping
            taxable
          }
        }
      `,
      variables: { input }
    })
    return items
  } catch (error) {
    return Promise.reject(error)
  }
}



export const variantFindProductCategories = async input => {
  const { variantFindProductCategories: items } = await fetchGraphQL({
    query: `
        query VariantFindProductCategories($input: VariantFindProductCategoriesInput!) {
          variantFindProductCategories(input: $input) {
            _id
            appName
            categories
            imageSrc
            name
            price
          }
        }
      `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return items
}


