import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'

import displayName from './displayName'
import Background from '../../background/Background'
import Head from '../../head/Head'


const styles = (theme) => ({
  top: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
    marginBottom: theme.spacing(2),
  },
  bottom: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 100,
    marginBottom: theme.spacing(2),
  },
})

function Header({
  classes,
  name,
  shop = {},
  description,
}) {
  const { image, backgroundColor, color } = shop
  return (
    <>
      <Head name={name} description={description} />
      <Background image={image} className={classes.top} style={{ backgroundColor, color }}>
        <CardContent>
          <Typography align="center" color="inherit" variant="h4">{name}</Typography>
        </CardContent>
      </Background>
      {description
        ? (
          <CardContent className={classes.bottom}>
            <Typography align="center" color="inherit" variant="subtitle1">
              {description}
            </Typography>
          </CardContent>
        )
        : null}
    </>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  shop: PropTypes.object.isRequired,
}

Header.displayName = `${displayName}Header`

export default withStyles(styles)(Header)
