import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import IconLock from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link } from 'react-router-dom'

import Card from '../../common/components/Card'
import FieldText from '../../fields/FieldText'
import Form from '../../forms/FormBase'
import FormButtonSubmit from '../../forms/FormButtonSubmit'
import FormFields from '../../forms/FormFields'
import Main from '../../common/components/Main'
import displayName from './displayName'
import withGoogleAnalytics from '../../common/hocs/withGoogleAnalytics'
import { validateEmail } from '../../common/utils/validators'


const styles = (theme) => ({
  root: {
    width: 'auto',
    maxWidth: theme.breakpoints.values.sm,
    margin: 'auto',
  },
  header: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  fields: {
    padding: '0 8px',
  },
  actions: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
  },
  link1: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  link2: {
    color: theme.palette.primary.main,
  },
})

const Signin = (props) => {
  const {
    classes,
    history,
    onSignin,
  } = props
  return (
    <Main justifyContent="center">
      <Card>
        <CardContent className={classes.header}>
          <Avatar className={classes.avatar}>
            <IconLock />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
        </CardContent>
        <Form
          form="signin"
          onSubmit={(values) => onSignin(values).then(() => {
            const redirect = localStorage.getItem('redirect')
            if (redirect) {
              localStorage.removeItem('redirect')
              return history.push(redirect)
            }
            history.push('/')
          })}
          validate={(values) => {
            const errors = {}
            const requiredFields = ['email', 'password']
            requiredFields.forEach((field) => {
              if (!values[field]) {
                errors[field] = 'Required'
              }
            })
            return errors
          }}
          enableReinitialize
        >
          <FormFields
            className={classes.fields}
            fields={[
              { name: 'email', label: 'Email', type: 'text', autoFocus: true, validate: validateEmail, component: FieldText },
              { name: 'password', label: 'Password', type: 'password', component: FieldText },
            ]}
            flexFlow="column"
          />
          <CardActions>
            <FormButtonSubmit color="primary" variant="contained" fullWidth>
              Sign In
            </FormButtonSubmit>
          </CardActions>
        </Form>
        <CardContent className={classes.actions}>
          <Typography>
            Don&#39;t have an account?
            {' '}
            <Link to="/user/signup" className={classes.link1}>Sign Up!</Link>
            {' '}
          </Typography>
          <Typography>
            Forgot your password?
            {' '}
            <Link to="/user/recovery" className={classes.link2}>Recover account</Link>
          </Typography>
        </CardContent>
      </Card>
    </Main>
  )
}

Signin.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onSignin: PropTypes.func.isRequired,
}

Signin.displayName = `${displayName}Signin`

export default withGoogleAnalytics(withStyles(styles)(Signin))
