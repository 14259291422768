const itemReducerFn = (
  reducerName,
  state = {
    error: null,
    isLoading: true,
    isFetching: false,
    item: {},
  },
  action
) => {
  switch (action.type) {
    case `ADD_${reducerName}`: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        item: {
          ...state.item,
          ...action.item,
        }
      }
    }
    case `DELETE_${reducerName}`: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        item: {},
      }
    }
    case `ERROR_${reducerName}`: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        isFetching: false,
      }
    }
    case `RECEIVE_${reducerName}`: {
      return {
        ...state,
        isLoading: false,
        error: null,
        isFetching: false,
        item: {
          ...state.item,
          ...action.item,
        }
      }
    }
    case `REQUEST_${reducerName}`: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case `RESET`: {
      return {
        error: null,
        isLoading: false,
        isFetching: false,
        item: {}
      }
    }
    case `UPDATE_${reducerName}`: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        item: {
          ...state.item,
          ...action.item,
        }
      }
    }
    default:
      return state
  }
}

export default itemReducerFn