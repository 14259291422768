import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import FooterBrand from './FooterBrand'
import FooterPage from './FooterPage'
import FooterSocialMedia from './FooterSocialMedia'
import FooterCreditCards from './FooterCreditCards'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
  },
  parentList: {
    flexFlow: 'row wrap-reverse',
    justifyContent: 'space-between',
  },
  navigation: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navLinkActive: {
    '& span:first-child': {
      borderBottom: '2px solid !important',
      width: 'inherit',
    },
  },
  icons: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: theme.breakpoints.values.md,
    margin: '0 auto',
  },
  creditCards: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  card: {
    margin: theme.spacing(1.5),
  },
})


const Footer = (props) => {
  const {
    classes,
    brand,
    categories,
    footer = {},
    isFetching,
    pageIds,
    pages,
    shop,
    socialMedia,
  } = props
  if (isFetching) return null
  return (
    <Paper component="footer" style={footer.style} className={classes.root}>
      <div className={classes.navigation} style={footer.navigationStyle}>
        {Object.keys(categories).filter((id) => categories[id].level === 1).map((id) => (
          <Button
            key={id}
            style={{ color: footer.style.color }}
            size="small"
            component={NavLink}
            to={`/${shop.slug}/${categories[id].slug}`}
            activeClassName={classes.navLinkActive}
          >
            {categories[id].name}
          </Button>
        ))}
        {pageIds ? pageIds.filter((id) => pages[id].slug !== 'home').map((id) => (
          <FooterPage key={id} page={pages[id]} color={footer.style.color} />
        )) : null}
      </div>
      <div className={classes.icons}>
        {Object.keys(socialMedia).length ? <FooterSocialMedia socialMedia={socialMedia} color={footer.style.color} style={footer.socialMediaStyle} /> : null}
        <FooterCreditCards color={footer.style.color} />
      </div>
      {brand.name.children ? <FooterBrand /> : null}
      {brand.disclaimer ? <Typography variant="caption">{brand.disclaimer}</Typography> : null}
    </Paper>
  )
}

Footer.propTypes = {
  brand: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  categories: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
  isBlogs: PropTypes.bool.isRequired,
  isProducts: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  pageIds: PropTypes.array,
  pages: PropTypes.object,
  shop: PropTypes.object.isRequired,
  socialMedia: PropTypes.object,
}

const mapStateToProps = ({
  app: {
    _id: appId,
    isFetching: appIsFetching,
    brand,
    footer,
    socialMedia,
    isBlogs,
    isProducts,
    pages: pageIds,
    shop,
  },
  pages: { items: pages, isFetching: pagesIsFetching },
  categories: { isFetching: categoriesIsFetching, items: categories },
}) => ({
  appId,
  brand,
  categories,
  isProducts,
  isBlogs,
  isFetching: appIsFetching || pagesIsFetching || categoriesIsFetching,
  footer,
  socialMedia,
  pageIds,
  pages,
  shop,
})


export default withRouter(connect(mapStateToProps)(withStyles(styles)(Footer)))
