import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import IconAccount from '@material-ui/icons/AccountCircle'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import Card from '../../common/components/Card'
import FieldText from '../../fields/FieldText'
import FormBase from '../../forms/FormBase'
import FormButtonSubmit from '../../forms/FormButtonSubmit'
import FormFields from '../../forms/FormFields'
import displayName from './displayName'
import Main from '../../common/components/Main'

const validate = (values) => {
  const errors = {}
  const requiredFields = ['password', 'passwordConfirm']
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  if (values.password !== values.passwordConfirm) {
    errors.passwordConfirm = 'Passwords must match'
  }
  return errors
}


const styles = (theme) => ({
  root: {
    width: 'auto',
    maxWidth: theme.breakpoints.values.sm,
    margin: 'auto',
  },
  header: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  fields: {
    padding: '0 8px',
  },
  actions: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
  },
  link1: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  link2: {
    color: theme.palette.primary.main,
  },
})

const Reset = (props) => {
  const {
    classes,
    history,
    match,
    onReset,
  } = props
  const { params: { resetToken } } = match
  return (
    <Main justifyContent="center">
      <Card>
        <CardContent className={classes.header}>
          <Avatar className={classes.avatar}>
            <IconAccount />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
        </CardContent>
        <FormBase
          form="reset"
          onSubmit={(values) => onReset({ password: values.password, resetToken }).then(() => {
            const redirect = localStorage.getItem('redirect')
            if (redirect) {
              localStorage.removeItem('redirect')
              return history.push(redirect)
            }
            history.push('/')
          })}
          validate={validate}
        >
          <FormFields
            className={classes.fields}
            fields={[
              { name: 'password', label: 'Password', type: 'password', component: FieldText },
              { name: 'passwordConfirm', label: 'Password Confirm', type: 'password', component: FieldText },
            ]}
          />
          <CardActions>
            <FormButtonSubmit color="primary" variant="contained" fullWidth>
              Reset
            </FormButtonSubmit>
          </CardActions>
        </FormBase>
      </Card>
    </Main>
  )
}

Reset.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onReset: PropTypes.func.isRequired,
  user: PropTypes.object,
}

Reset.displayName = `${displayName}Reset`

export default withStyles(styles)(Reset)
