import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'

import ULVerticalBase from '../../common/components/ULVerticalBase'
import ULHorizontalBase from '../../common/components/ULHorizontalBase'
import CategoryListItem from '../../category/user/ListItem'
import Loading from '../../common/components/Loading'
import displayName from './displayName'


const CategoryListHorizontal = props => {
  const {
    dispatch,
    categories,
    categoryItems,
    classes,
    isFetching,
    elevation,
    shop,
    style,
    ...rest
  } = props
  if (isFetching) return <Loading />
  const ids = Object.keys(categoryItems).filter(id => categoryItems[id].categories.some(v => categories.includes(v)))
  return (
    <ULHorizontalBase
      {...rest}
      className={classNames(
        classes.root,
        classes.CategoryList,
        style.background && style.background.backgroundImage && classes.backgroundImage,
        elevation && classes[`elevation${elevation}`]
      )}
      style={style}
    >
      {ids.map(id => {
        return (
          <CategoryListItem
            key={id}
            className={classes.CategoryListItem}
            category={categoryItems[id]}
            to={`/${shop.slug}/${categoryItems[id].slug}`}
          />
        )
      })}
    </ULHorizontalBase>
  )
}

CategoryListHorizontal.propTypes = {
  categories: PropTypes.array.isRequired,
  categoryItems: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  direction: PropTypes.string,
  id: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  shop: PropTypes.object.isRequired,
  style: PropTypes.object,
}

const mapStateToProps = ({
  app: { isFetching: appIsFetching, shop },
  categories: { isFetching: categoriesIsFetching, items: categoryItems },
}) => ({
  categoryItems,
  isFetching: appIsFetching || categoriesIsFetching,
  shop,
})

CategoryListHorizontal.displayName = `${displayName}CategoryListHorizontal`

export default connect(mapStateToProps)(CategoryListHorizontal)