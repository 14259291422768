import { reducerName } from './reducers'

const user = (state = {
  addresses: [],
  isFetching: false,
  roles: [],
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
  redirect: null,
}, action) => {
  switch (action.type) {
    case `ADD_${reducerName}`:
      return {
        ...state,
        ...action.item,
        isFetching: false,
      }
    case `DELETE_${reducerName}`:
      return {
        addresses: [],
        isFetching: false,
        roles: [],
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
      }
    case `RECEIVE_${reducerName}`:
      return {
        ...state,
        ...action.item,
        isFetching: false,
      }
    case `REQUEST_${reducerName}`:
      return {
        ...state,
        isFetching: true
      }
    case `UPDATE_${reducerName}`:
      return {
        ...state,
        ...action.item,
        isFetching: false,
      }
    case `RESET_${reducerName}`:
      return {
        ...state,
        ...action.user
      }
    default:
      return state
  }
}

export default user