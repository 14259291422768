import { formatDollar } from '../../common/utils/formatters'

function creditOptions(max) {
  const options = {
    ' ': undefined,
    '$5.00': 500,
    '$10.00': 1000,
    '$20.00': 2000,
    '$30.00': 3000,
    '$40.00': 4000,
    '$50.00': 5000,
    '$60.00': 6000,
    '$70.00': 7000,
    '$80.00': 8000,
    '$90.00': 9000,
    '$100.00': 10000,
    '$150.00': 15000,
    '$200.00': 20000,
    '$300.00': 30000,
    [`${formatDollar(max)}`]: max,
  }
  return Object.keys(options)
    .filter(k => k === ' ' || options[k] <= max)
    .reduce((a, v) => {
      a[v] = options[v]
      return a
    }, {})
}

export default creditOptions
