import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'


import ScrollToTopOnMount from '../common/components/ScrollToTopOnMount'
import displayName from './displayName'
import ButtonAsync from '../common/components/ButtonAsync'
import CheckoutTotal from './Total'
import OrderCartListItem from '../order/common/CartListItem'
import Phone from '../common/components/Phone'
import creditCardImages from '../creditCard/common/creditCardImages'
import { getTotal } from './utils'
import { orderAdd } from '../order/user/api'

const styles = theme => ({
  creditCardImage: {
    height: '1em',
    marginLeft: 8,
  },
  delivery: {
    flex: '12 12 auto',
  },
  details: {
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  name: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'row wrap',
  },
  payment: {
    flex: '1 1 auto',
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
  },
  textAlignRight: {
    paddingRight: 0,
    textAlign: 'right',
  },
  ul: {
    display: 'flex',
    flexFlow: 'column',
    listStyle: 'none',
    margin: `-${theme.spacing(1)}px`,
    padding: theme.spacing(1),
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
})

async function onFormSubmit({
  cart,
  history,
  onAddressAddSuccess,
  onCartDeleteSuccess,
  onCreditAddSuccess,
  onUserUpdateSuccess,
  values,
}) {
  try {
    const body = {
      cartId: cart._id,
      ...values,
    }
    const res = await orderAdd(body)
    const {
      address,
      credit,
      order,
      user,
    } = res
    if (user) onUserUpdateSuccess(user)
    if (address) onAddressAddSuccess(address)
    if (credit) onCreditAddSuccess(credit)
    onCartDeleteSuccess()
    localStorage.removeItem('cartId')

    history.push({
      pathname: `/order-confirmation/${order._id}`,
      state: {
        order,
        user: {
          firstName: values.paymentAddress.firstName,
          lastName: values.paymentAddress.lastName,
        },
      },
    })
    return Promise.resolve(true)
  } catch (error) {
    return Promise.reject(error)
  }
}

function Review(props) {
  const {
    appCheckout,
    appShipping = {},
    appTax,
    cart,
    classes,
    credit,
    creditCard,
    deliverBy,
    deliveryAddress = {},
    giftCard,
    handleSubmit,
    history,
    onAddressAddSuccess,
    onBack,
    onCartDeleteSuccess,
    onCreditAddSuccess,
    onUserUpdateSuccess,
    paymentAddress = {},
    shop,
  } = props
  const totals = getTotal({
    cart,
    credit,
    deliveryAddress,
    tax: appTax,
    giftCard,
  })
  return (
    <ScrollToTopOnMount>
      <CardContent>
        <Typography variant="h5">
          Order summary
        </Typography>
      </CardContent>

      <ul className={classes.ul}>
        {cart.items.map(item => (
          <OrderCartListItem
            item={item}
            key={item._id}
            to={`/${shop.slug}/${item.slug}`}
          />
        ))}
      </ul>

      <CardContent>
        <CheckoutTotal
          {...totals}
          credit={credit}
          className={classes.checkoutTotal}
          typography="body2"
        />
      </CardContent>

      <CardContent className={classes.details}>
        <div className={classes.delivery}>
          <Typography variant="h6" gutterBottom>
            Delivery Details
          </Typography>
          <Typography>
            {deliveryAddress.firstName}
            {' '}
            {deliveryAddress.lastName}
          </Typography>
          {deliveryAddress.phone ? <Typography><Phone>{deliveryAddress.phone}</Phone></Typography> : null}
          <Typography>{deliveryAddress.email}</Typography>
          {deliveryAddress.street1 ? <Typography>{deliveryAddress.street1}</Typography> : null}
          {deliveryAddress.street2 ? <Typography>{deliveryAddress.street2}</Typography> : null}
          {deliveryAddress.city ? (
            <Typography>
              {deliveryAddress.city}
              {' '}
              {deliveryAddress.state}
              ,
              {' '}
              {deliveryAddress.zip}
            </Typography>
          ) : null}
          {deliverBy ? (
            <Typography>
              Deliver by
              {' '}
              {deliverBy}
            </Typography>
          ) : null}
        </div>
        <div className={classes.payment}>
          <Typography variant="h6" gutterBottom>
            Payment Details
          </Typography>
          <div className={classes.name}>
            <Typography>
              {paymentAddress.firstName}
              {' '}
              {paymentAddress.lastName}
            </Typography>
            {creditCard && creditCard.brand
              ? (
                <img
                  src={creditCardImages[creditCard.brand]}
                  alt={creditCard.brand}
                  className={classes.creditCardImage}
                />
              )
              : null}
          </div>
          <Typography><Phone>{paymentAddress.phone}</Phone></Typography>
          <Typography>{paymentAddress.email}</Typography>
          <Typography>{paymentAddress.street1}</Typography>
          {paymentAddress.street2
            ? <Typography gutterBottom>{paymentAddress.street2}</Typography>
            : null}
          {paymentAddress.city ? (
            <Typography gutterBottom>
              {paymentAddress.city}
              {' '}
              {paymentAddress.state}
              ,
              {' '}
              {paymentAddress.zip}
            </Typography>
          ) : null}
        </div>
      </CardContent>
      <CardActions className={classes.flexEnd}>
        <Button onClick={onBack}>
          Back
        </Button>
        <ButtonAsync
          variant="contained"
          color="primary"
          onClick={handleSubmit(values => onFormSubmit({
            cart,
            history,
            onAddressAddSuccess,
            onCartDeleteSuccess,
            onCreditAddSuccess,
            onUserUpdateSuccess,
            values,
          }))}
        >
          Place Order
        </ButtonAsync>
      </CardActions>
    </ScrollToTopOnMount>
  )
}

Review.defaultProps = {
  deliverBy: undefined,
  giftCard: undefined,
}

Review.propTypes = {
  appCheckout: PropTypes.instanceOf(Object).isRequired,
  appShipping: PropTypes.instanceOf(Object).isRequired,
  appTax: PropTypes.instanceOf(Object).isRequired,
  cart: PropTypes.instanceOf(Object).isRequired,
  credit: PropTypes.instanceOf(Object).isRequired,
  creditCard: PropTypes.instanceOf(Object).isRequired,
  deliverBy: PropTypes.string,
  deliveryAddress: PropTypes.instanceOf(Object).isRequired,
  giftCard: PropTypes.instanceOf(Object),
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  onAddressAddSuccess: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onCartDeleteSuccess: PropTypes.func.isRequired,
  onCreditAddSuccess: PropTypes.func.isRequired,
  onUserUpdateSuccess: PropTypes.func.isRequired,
  paymentAddress: PropTypes.instanceOf(Object).isRequired,
  shop: PropTypes.instanceOf(Object).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
}

const selector = formValueSelector('checkout')

const mapStateToProps = (state) => {
  const {
    creditCard,
    deliverBy,
    deliveryAddress,
    giftCard,
    paymentAddress,
    shipping,
  } = selector(state,
    'creditCard',
    'deliverBy',
    'deliveryAddress',
    'giftCard',
    'paymentAddress',
    'shipping')
  const { app: { shop } } = state
  return {
    shop,
    creditCard,
    deliverBy,
    deliveryAddress,
    giftCard,
    paymentAddress,
    shipping,
  }
}

Review.displayName = `${displayName}Review`

export default connect(mapStateToProps)(reduxForm({
  form: 'checkout', // Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withStyles(styles)(Review)))
