import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import displayName from './displayName'
import DeliveryAddressFields from './common/DeliveryAddressFields'
import ShippingSelect from './ShippingSelect'
import FieldCheckbox from '../fields/FieldCheckbox'
import FieldList from '../fields/FieldList'
import FieldText from '../fields/FieldText'
import FieldPhone from '../fields/FieldPhone'
import { validateRequired, validateEmail } from '../common/utils/validators'

const styles = theme => ({
  justifyContentFlexEnd: {
    justifyContent: 'flex-end'
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'row wrap',
    justifyContent: 'space-between'
  },
})


const Delivery = props => {
  const {
    addresses,
    appShipping,
    change,
    classes,
    deliveryAddress = {},
    handleSubmit,
    invalid,
    isShippable,
    onNext,
    submitting,
    user = {},
  } = props
  const onSubmit = values => {
    const { paymentAddress = {} } = values
    const { isDelivery } = paymentAddress
    if (isDelivery) {
      const newDeliveryAddress = { isDelivery, ...values.deliveryAddress }
      change('deliveryAddress', newDeliveryAddress)
    }
    return onNext()
  }
  const onChangeAddress = (e, value) => {
    const newDelivery = value === 'new' ? {} : addresses[value]
    return change('deliveryAddress', newDelivery)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Delivery Details
          </Typography>
        <FieldList>

          <Field
            component={FieldText}
            fullWidth
            label="Write an optional gift message"
            multiline
            name="deliveryMessage.package"
          />

          {isShippable && addresses && Object.keys(addresses).length ?
            <Field
              label="Select Delivery Address"
              name="deliveryAddress.addressId"
              component={FieldText}
              onChange={onChangeAddress}
              fullWidth
              select
            >
              {Object.keys(addresses).length && user.addresses.map((id) => {
                const { firstName, lastName, street1, city, state, zip } = addresses[id]
                return (
                  <MenuItem key={id} value={id}>
                    {`${firstName} ${lastName} ${street1} ${city} ${state} ${zip}`}
                  </MenuItem>
                )
              })}
              <MenuItem value="new">
                New Address
                </MenuItem>
            </Field>
            : null}

          {isShippable ?
            <DeliveryAddressFields name="deliveryAddress" label="Delivery" />
            :
            <>
              <Field component={FieldText} label="First Name" name="deliveryAddress.firstName" validate={validateRequired} />
              <Field component={FieldText} label="Last Name" name="deliveryAddress.lastName" validate={validateRequired} />
              <FieldPhone component={FieldText} label="Phone" name="deliveryAddress.phone" validate={validateRequired} fullWidth />
              <Field component={FieldText} label="Email" name="deliveryAddress.email" validate={[validateRequired, validateEmail]} />
            </>
          }

          {isShippable && user._id && deliveryAddress.email && (deliveryAddress.email === user.email) && (!deliveryAddress.addressId || deliveryAddress.addressId === 'new') ?
            <Field
              component={FieldCheckbox}
              name="deliveryAddress.isSave"
              label="Save this address for future orders"
            />
            : null}


          {isShippable || (appShipping.standardPrice > 0) ?
            <ShippingSelect {...props} />
            : null}

        </FieldList>
      </CardContent>
      <CardActions className={classes.justifyContentFlexEnd}>
        <Button type="button" component={Link} to="/cart">
          Back
          </Button>
        <Button
          variant="contained"
          disabled={submitting || invalid}
          color="primary"
          type="submit"
        >
          Next
          </Button>
      </CardActions>
    </form>
  )
}


Delivery.propTypes = {
  addresses: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  delivery: PropTypes.object,
  user: PropTypes.object.isRequired,
  appShipping: PropTypes.object.isRequired,
  appTax: PropTypes.object.isRequired,
}


const selector = formValueSelector('checkout')

const mapStateToProps = state => {
  const {
    deliveryAddress,
    isGift,
    shippingService
  } = selector(state,
    'deliveryAddress',
    'isGift',
    'shippingService'
  )
  return {
    deliveryAddress,
    isGift,
    shippingService
  }
}

Delivery.displayName = `${displayName}Delivery`

export default connect(mapStateToProps)(reduxForm({
  form: 'checkout', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withStyles(styles)(Delivery)))