import React from 'react'
import PropTypes from 'prop-types'

import displayName from './displayName'
import FormCard from '../../forms/FormCard'
import FieldText from '../../fields/FieldText'
import FormFields from '../../forms/FormFields'
import { validateEmail, validateRequired, validateCompose } from '../../common/utils/validators'
import { userUpdate, userUpdatePassword } from './api'


const validatePassword = values => {
  const errors = {}
  const requiredFields = ['password', 'passwordConfirm']
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  if (values.password !== values.passwordConfirm) {
    errors.passwordConfirm = 'Passwords must match'
  }
  return errors
}


const Profile = props => {
  const {
    classes,
    history,
    onUpdate,
    onUpdatePassword,
    onDelete,
    user
  } = props
  const { firstName, lastName, email, phone } = user
  return (
    <div>
      <FormCard
        form={`user${user._id}`}
        initialValues={{ firstName, lastName, email, phone }}
        onDelete={() => onDelete({ _id: user._id }).then(() => history.push('/'))}
        onSubmit={values => onUpdate(values)}
        title="User"
      >
        <FormFields
          fields={[
            { label: 'First Name', name: 'firstName', type: 'text', component: FieldText, validate: validateRequired },
            { label: 'Last Name', name: 'lastName', type: 'text', component: FieldText, validate: validateRequired },
            { label: 'Email', name: 'email', type: 'email', validate: validateCompose(validateEmail, validateRequired), component: FieldText },
            { label: 'Phone', name: 'phone', type: 'tel', component: FieldText },
          ]}
        />
      </FormCard>

      <FormCard
        form={`userPassword${user._id}`}
        isReset={true}
        onSubmit={values => onUpdatePassword({ password: values.password })}
        title="Update Password"
        validate={validatePassword}
      >
        <FormFields
          fields={[
            { name: 'password', label: 'Password', type: 'password', component: FieldText },
            { name: 'passwordConfirm', label: 'Password Confirm', type: 'password', component: FieldText },
          ]}
        />
      </FormCard>
    </div>
  )
}

Profile.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

Profile.displayName = `${displayName}Profile`

export default Profile
