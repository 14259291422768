import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Badge from '@material-ui/core/Badge'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import withStyles from '@material-ui/core/styles/withStyles'

import ActionButton from './ActionButton'

const styles = theme => ({
  root: {
    paddingTop: 18,
    paddingBottom: 6
  },
  actionButton: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1) / 2,
  },
})


const DrawerActions = ({
  classes,
  quantity,
  actionButton,
  onDrawer,
}) => {
  if (quantity) {
    return (
      <ListItem
        button
        onClick={onDrawer}
        className={classes.root}
        component={Link}
        to="/cart"
      >
        <ListItemIcon>
          <Badge badgeContent={quantity} color="secondary">
            <ShoppingCart />
          </Badge>
        </ListItemIcon>
      </ListItem>
    )
  }
  if (actionButton) {
    return (
      <div className={classes.actionButton}>
        <ActionButton actionButton={actionButton} />
      </div>
    )
  }
  return null
}


DrawerActions.propTypes = {
  actionButton: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onDrawer: PropTypes.func.isRequired,
  quantity: PropTypes.number,
}

const mapStateToProps = ({
  app: { header: { actionButton }},
  cart: { quantity }
}) => ({
  actionButton,
  quantity,
})

export default connect(mapStateToProps)(withStyles(styles)(DrawerActions))
