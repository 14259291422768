import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import Page from '../../common/components/Page'
import displayName from './displayName'
import UpdateRouter from './UpdateRouter'
import List from './List'

const Router = props => {
  const { match, user = {} } = props
  return (
    <Switch>
      <Route exact path={match.path}>
        {route => (
          <Page>
            <List
              {...props}
              {...route}
              key="all"
              none="No deliveries"
              title={user._id ? `Deliveries for ${user.email}` : `Deliveries`}
            />
          </Page>
        )}
      </Route>
      <Route exact path={`${match.path}/requires-shipping`}>
        {route => (
          <Page>
            <List
              {...props}
              {...route}
              input={{ ...props.input, shippedAt: { $exists: false } }}
              key="requires-shipping"
              none="No orders"
              title={user._id ? `Deliveries for ${user.email}` : `Deliveries`}
            />
          </Page>
        )}
      </Route>
      <Route path={`${match.path}/:_id`}>
        {route => (
          <UpdateRouter {...props} {...route} />
        )}
      </Route>
    </Switch>
  )
}

Router.displayName = `${displayName}Router`

Router.propTypes = {
  match: PropTypes.object.isRequired,
}

export default Router