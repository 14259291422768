import React, { Component } from 'react'
import PropTypes from 'prop-types'

import None from '../common/components/None'
import logError from './logError'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isError: false
    }
  }
  componentDidCatch(error, info) {
    if (this.state.isError) return
    this.setState({ isError: true })
    return logError(error, info)
  }
  render() {
    if (this.state.isError) {
      return <None>An error occured, please refresh the page.</None>
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary