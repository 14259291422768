import React from 'react'
import PropTypes from 'prop-types'
import Transition from 'react-transition-group/Transition'

const FadeIn = ({
  children,
  className,
  in: inProp,
  style,
  transform,
  transition,
}) => {
  const defaultStyle = {
    opacity: 0,
    transition: transition || `all ${300}ms ease-in-out`,
    transform: transform && 'translateY(30%)'
  }
  const transitionStyles = {
    entering: { opacity: 0.1 },
    entered:  {
      opacity: 1,
      transform: transform && 'translateY(0%)',
    },
  }
  return (
    <Transition
      in={inProp}
      timeout={0}
      appear={true}
    >
      {(state) => (
        <div
          className={className}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
            ...style,
            width: '100%',
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  )
}

FadeIn.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  in: PropTypes.bool.isRequired,
  style: PropTypes.object,
  transform: PropTypes.string,
  transition: PropTypes.string,
}

export default FadeIn
