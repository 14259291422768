import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ProductListVerticalBase from '../../product/public/ListVertical'
import displayName from './displayName'

const ProductListVertical = props => {
  const {
    classes,
    elevation,
    input,
    style,
    ...rest
  } = props
  return (
    <div
      {...rest}
      className={classNames(
        classes.root,
        classes.ProductList,
        style.background && style.background.backgroundImage && classes.backgroundImage,
        elevation && classes[`elevation${elevation}`]
      )}
      style={style}
    >
      <ProductListVerticalBase input={input} />
    </div>
  )
}

ProductListVertical.propTypes = {
  input: PropTypes.object,
}

ProductListVertical.displayName = `${displayName}ProductListVertical`

export default ProductListVertical