import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    margin: `-${theme.spacing(1) / 2}px -${theme.spacing(1)}px`,
  },
})

const FieldList = ({
  children,
  className,
  classes,
}) => {
  return (
    <div className={classNames(classes.root, className)}>
      {children}
    </div>
  )
}

FieldList.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FieldList)
