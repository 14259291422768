import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import Detail from './Detail'
import Page from '../../common/components/Page'
import displayName from './displayName'
import List from './List'

const Router = props => {
  const { match, user = {} } = props
  return (
    <Switch>
      <Route exact path={match.path}>
        {route => (
          <Page>
            <List
              {...props}
              {...route}
              key="all"
              none="No orders"
              title={user._id ? `Orders for ${user.email}` : `Orders`}
            />
          </Page>
        )}
      </Route>
      <Route exact path={`${match.path}/requires-shipping`}>
        {route => (
          <Page>
            <List
              {...props}
              {...route}
              input={{ ...props.input, isShipped: false }}
              key="requires-shipping"
              none="No orders"
              title={user._id ? `Orders for ${user.email}` : `Orders`}
            />
          </Page>
        )}
      </Route>
      <Route path={`${match.path}/:_id`}>
        {route => (
          <Page>
            <Detail rootUrl={match.path} {...props} {...route} />
          </Page>
        )}
      </Route>
    </Switch>
  )
}

Router.displayName = `${displayName}Router`

Router.propTypes = {
  match: PropTypes.object.isRequired,
}

export default Router