import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import SnackbarContext from '../../snackbar/SnackbarContext'
import ButtonAsync from '../../common/components/ButtonAsync'
import Card from '../../common/components/Card'
import FieldCreditCard from '../../creditCard/common/FieldCreditCard'
import FieldText from '../../fields/FieldText'
import displayName from './displayName'
import { creditUserAdd } from './api'
import { formatDollar } from '../../common/utils/formatters'
import { onAdd } from './actions'
import { parseInteger } from '../../common/utils/parsers'
import { validateRequired } from '../../common/utils/validators'
import creditOptions from '../common/creditOptions'

const styles = theme => ({
  fields: {
    padding: '0 8px',
  },
  cardActions: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end'
  }
})

const UserPurchase = props => {
  const { onSnackOpen } = useContext(SnackbarContext)
  const {
    classes,
    history,
    change,
    reset,
    handleSubmit,
    submitting,
    invalid,
    onAdd,
  } = props

  const onSubmit = useCallback(values => {
    return onAdd(values).then(() => {
      reset()
      return onSnackOpen({ severity: 'success', message: 'Credit Purchased!' })
    })
  }, [])

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Purchase Credit</Typography>
      </CardContent>
      <form>
        <CardContent className={classes.fields}>
          <Field
            component={FieldText}
            label={`Amount`}
            name={`amount`}
            validate={validateRequired}
            parse={parseInteger}
            select={true}
            SelectProps={{
              native: true,
            }}
          >
            {creditOptions.map(option => (
              <option key={formatDollar(option)} value={option}>
                {formatDollar(option)}
              </option>
            ))}
          </Field>
          <Field
            component={FieldCreditCard}
            name="creditCard"
            label="Credit Card"
          />
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button onClick={() => history.goBack()}>
            Back
          </Button>
          <ButtonAsync
            disabled={submitting || invalid}
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            type="submit"
          >
            Process
          </ButtonAsync>
        </CardActions>
      </form>
    </Card>
  )
}

UserPurchase.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => ({
  onAdd: input => dispatch(onAdd(input)),
})

UserPurchase.displayName = `${displayName}UserPurchase`

export default connect(null, mapDispatchToProps)(reduxForm({
  form: 'creditUserPurchase',
  enableReinitialize: true
})(withStyles(styles)(UserPurchase)))