import React, { Component } from 'react'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'

function withDnDContext(WrappedComponent) {
  class WithDnDContext extends Component {
    render() {
      return (
        <DndProvider backend={Backend}>
          <WrappedComponent {...this.props} />
        </DndProvider>
      )
    }
  }
  return WithDnDContext
}

export default withDnDContext
