import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import green from '@material-ui/core/colors/green'
import IconPublish from '@material-ui/icons/Publish'
import IconRemove from '@material-ui/icons/Remove'

import ButtonAsync from './ButtonAsync'

const styles = {
  publish: {
    color: green[500],
    '&:hover': {
      color: green[700],
    },
  },
}

class ButtonPublish extends Component {
  renderTooltip = () => {
    const { isDraft, isPublished } = this.props
    if (isDraft && isPublished) return 'Publish'
    if (isDraft) return 'Publish'
    if (isPublished) return 'Unpublish'
    return 'Publish'
  }
  renderChildren = () => {
    const { classes, isDraft, isPublished } = this.props
    if (isDraft && isPublished) return <IconPublish className={classes.publish} />
    if (isDraft) return <IconPublish className={classes.publish} />
    if (isPublished) return <IconRemove />
    return <IconPublish className={classes.publish} />
  }
  render() {
    const {
      classes,
      isDraft,
      isPublished,
      onClick,
      ...rest
    } = this.props
    return (
      <ButtonAsync
        {...rest}
        onClick={onClick}
        tooltip={this.renderTooltip()}
        displaySuccess={true}
      >
        {this.renderChildren()}
      </ButtonAsync>
    )
  }
}

ButtonPublish.propTypes = {
  classes: PropTypes.object.isRequired,
  isPublished: PropTypes.bool.isRequired,
  isDraft: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(ButtonPublish)
