import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import IconLock from '@material-ui/icons/LockOutlined'

import FieldText from '../fields/FieldText'
import Form from '../forms/FormBase'
import FormButtonSubmit from '../forms/FormButtonSubmit'
import FormFields from '../forms/FormFields'
import { validateEmail } from '../common/utils/validators'
import { onAuth } from '../auth/actions'
import { onSignin, onSignup, onRecovery } from '../user/user/actions'

const forms = {
  signin: {
    fields: [{
      name: 'email', label: 'Email', type: 'text', autoFocus: true, validate: validateEmail, component: FieldText,
    }, {
      name: 'password', label: 'Password', type: 'password', component: FieldText,
    }],
    method: onSignin,
    name: 'Sign In',
    otherForm: { name: 'Sign Up', form: 'signup', link: 'Have an Account?' },
    validator: (values) => {
      const errors = {}
      const requiredFields = ['email', 'password']
      requiredFields.forEach((field) => {
        if (!values[field]) {
          errors[field] = 'Required'
        }
      })
      return errors
    },
  },
  signup: {
    fields: [{
      name: 'firstName', label: 'First Name', autoFocus: true, type: 'text', component: FieldText,
    }, {
      name: 'lastName', label: 'Last Name', type: 'text', component: FieldText,
    }, {
      name: 'email', label: 'Email', type: 'text', validate: validateEmail, component: FieldText,
    }, {
      name: 'password', label: 'Password', type: 'password', component: FieldText,
    }, {
      name: 'passwordConfirm', label: 'Password Confirm', type: 'password', component: FieldText,
    }],
    method: onSignup,
    name: 'Sign Up',
    otherForm: { name: 'Sign In', form: 'signin', link: 'New?' },
    validator: (values) => {
      const errors = {}
      const requiredFields = ['firstName', 'lastName', 'email', 'password', 'passwordConfirm']
      requiredFields.forEach((field) => {
        if (!values[field]) {
          errors[field] = 'Required'
        }
      })
      if (values.password !== values.passwordConfirm) {
        errors.passwordConfirm = 'Passwords must match'
      }
      return errors
    },
  },
  recovery: {
    fields: [
      {
        name: 'email', label: 'Email', type: 'text', autoFocus: true, validate: validateEmail, component: FieldText,
      },
    ],
    method: onRecovery,
    name: 'Recover Account',
    otherForm: { name: 'Sign In', form: 'signin', link: 'Have an Account?' },
    validator: (values) => {
      const errors = {}
      const requiredFields = ['email']
      requiredFields.forEach((field) => {
        if (!values[field]) {
          errors[field] = 'Required'
        }
      })
      return errors
    },
  },
}


const styles = theme => ({
  formFields: {
    flexFlow: 'column',
    margin: 8,
  },
  header: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 300,
    width: 400,
    paddingBottom: 4,
  },
  title: {
    paddingTop: 0,
    textAlign: 'center',
  },
  otherActions: {
    paddingBottom: 8,
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
})

function handleSubmit({
  form,
  values,
  dispatch,
}) {
  return dispatch(forms[form].method(values))
    .then(() => dispatch(onAuth()))
}

function DialogAuth(props) {
  const [form, setForm] = useState('signin')
  const {
    classes,
    dispatch,
    isOpen,
  } = props
  return (
    <Dialog
      open={isOpen}
      onClose={() => dispatch(onAuth())}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent className={classes.header}>
        <Avatar className={classes.avatar}>
          <IconLock />
        </Avatar>
      </DialogContent>
      <DialogTitle id="form-dialog-title" className={classes.title}>
        {forms[form].name}
      </DialogTitle>
      <Form
        key={form}
        form={form}
        onSubmit={values => handleSubmit({
          form,
          values,
          dispatch,
        })}
        validate={forms[form].validator}
      >
        <FormFields fields={forms[form].fields} className={classes.formFields} />
        <DialogContent className={classes.otherActions}>
          <Typography
            onClick={() => setForm(forms[form].otherForm.form)}
            className={classes.actionItem}
          >
            <span>
              {forms[form].otherForm.link}
              {' '}
            </span>
            <span className={classes.link}>{forms[form].otherForm.name}</span>
          </Typography>
          <Typography className={classes.actionItem} onClick={() => setForm('recovery')}>
            <span>Forgot password? </span>
            <span className={classes.link}>Recovery</span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClose={() => dispatch(onAuth())}>
            Cancel
          </Button>
          <FormButtonSubmit variant="contained" color="primary">
            {forms[form].name}
          </FormButtonSubmit>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

DialogAuth.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

function mapStateToProps({
  auth: { form, isOpen },
}) {
  return {
    form,
    isOpen,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(DialogAuth))
