function isArrayEqual(a1, a2) {
  let isEqual = true
  a1.forEach((item, index) => {
    if (a1[index] !== a2[index]) {
      isEqual = false
    }
  })
  return isEqual
}

export default isArrayEqual
