import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as actions from './actions'
import ListItem from './ListItem'
import ListVerticalBase from '../../common/components/ListVerticalBase'
import bindActionCreators from '../../common/utils/bindActionCreators'
import displayName from './displayName'
import filter from './filter'
import useReducer from '../../common/hooks/useReducer'
import { itemsReducer } from './reducers'

const List = props => {
  const [state, dispatch] = useReducer(itemsReducer)
  const boundActions = bindActionCreators(actions, dispatch)
  return (
    <ListVerticalBase
      {...props}
      {...state}
      {...boundActions}
      actions={actions}
      filter={filter}
      listItem={ListItem}
      none="No reviews"
      title="Reviews"
    />
  )
}

List.propTypes = {
  user: PropTypes.object,
  input: PropTypes.object.isRequired,
}

const mapStateToProps = ({
  app: { isFetching: appIsFetching, shop },
  user
}) => ({
  isFetching: appIsFetching || user.isFetching,
  shop,
  user
})

List.displayName = `${displayName}List`

export default connect(mapStateToProps)(List)