import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import * as actions from './actions'
import ListItem from './ListItem'
import ListVerticalBase from '../../common/components/ListVerticalBase'
import bindActionCreators from '../../common/utils/bindActionCreators'
import combineReducers from '../../common/reducers/combineReducers'
import displayName from './displayName'
import filter from './filter'
import useReducer from '../../common/hooks/useReducer'
import { itemsReducer } from './reducers'

const rootReducer = combineReducers({
  blogs: itemsReducer,
})

const ListVertical = props => {
  const [state, dispatch] = useReducer(rootReducer)
  const boundActions = bindActionCreators(actions, dispatch)
  return (
    <ListVerticalBase
      {...props}
      {...state.blogs}
      {...boundActions}
      filter={filter}
      headProps={{ name: 'Blogs', slug: 'blogs' }}
      listItem={ListItem}
      none="No blogs"
      title="Blogs"
    />
  )
}


ListVertical.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

ListVertical.displayName = `${displayName}ListVertical`

export default ListVertical