import React, { Component } from 'react'
import PropTypes from 'prop-types'

import loadImages from '../../image/common/loadImages'
import FadeIn from './FadeIn'
import Loading from './Loading'
import getImageSrc from '../../image/common/getImageSrc'

function isImageLoaded(src) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = getImageSrc(src)
    if (image.complete) resolve(true)
    reject(false)
  })
}

function checkImages(srcs) {
  const promises = srcs.map(src => isImageLoaded(src))
  return Promise.all(promises)
    .then(result => result)
    .catch(error => error)
}


class FadeInIfNeeded extends Component {
  state = {
    hasImage: false,
    hadUnloaded: false,
    isLoading: false,
    isReady: false,
  }
  mounted = false
  handleImages = (srcs) => {
    this.setState({ hasImage: true })
    return this.mounted && checkImages(srcs)
      .then(() => this.mounted && this.setState({ isLoading: false, isReady: true }))
      .catch(() => {
        this.mounted && this.setState({ isLoading: true, hadUnloaded: true })
        return loadImages(srcs)
          .then(() => this.mounted && this.setState({ isLoading: false, isReady: true }))
      })
  }
  componentDidMount() {
    this.mounted = true
    const { src, srcs } = this.props
    if (src) return this.handleImages([src])
    if (srcs && srcs.length) return this.handleImages(srcs)
    this.setState({ isReady: true })
  }
  componentWillUnmount() {
    this.mounted = false
  }
  render() {
    const {
      children,
      className,
      style,
      transform,
      transition,
    } = this.props
    const {
      hadUnloaded,
      hasImage,
      isLoading,
      isReady,
    } = this.state
    switch (true) {
      case (!isReady):
        return null
      case (hasImage && isLoading):
        return (
          <Loading />
        )
      case (hasImage && hadUnloaded && isReady):
        return (
          <FadeIn
            className={className}
            in={true}
            style={style}
            transform={transform}
            transition={transition}
          >
            {children}
          </FadeIn>
        )
      case (isReady):
        return (
          <div className={className} style={style}>
            {children}
          </div>
        )
      default:
        return null
    }
  }
}

FadeInIfNeeded.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  src: PropTypes.string,
  srcs: PropTypes.array,
  style: PropTypes.object,
  transform: PropTypes.string,
  transition: PropTypes.string,
}

export default FadeInIfNeeded
