import buildEndpoint from '../common/utils/buildEndpoint'
import createActions from '../redux/createActions'
import fetchApi from '../fetch/fetchApi'
import { reducerName } from './reducers'

const route = 'searches'

const {
  ADD,
  DELETE,
  DELETES,
  RECEIVE,
  REQUEST,
} = createActions(reducerName)

const READY = `READY_${reducerName}`
const TOGGLE = `TOGGLE_${reducerName}`

const onRequest = () => ({ type: REQUEST })

// Search
export const onToggle = () => ({ type: TOGGLE })


export const onReady = bool => ({
  type: READY,
  isReady: bool,
})

export const onAdd = value => ({
  type: ADD,
  value,
})

export const onDelete = () => ({
  type: DELETE,
})

const onFetchSuccess = items => ({ type: RECEIVE, items })
export const onFetch = () => (dispatch, getState) => {
  dispatch(onRequest())
  const {
    value,
    lastBlogId,
    lastComponentId,
    lastProductId,
    limit,
  } = getState().search
  const params = {
    searchValue: value,
    lastBlogId,
    lastComponentId,
    lastProductId,
    limit,
  }
  return fetchApi({
    auth: false,
    body: null,
    method: 'GET',
    endpoint: buildEndpoint({ route, params }),
  })
    .then((json) => {
      const { blogs, components, products } = json
      return dispatch(onFetchSuccess({ blogs, components, products }))
    })
}


export const onDeletes = () => ({ type: DELETES })
