import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

import FieldText from '../../fields/FieldText'
import creditOptions from './creditOptions'
import { formatDollar } from '../../common/utils/formatters'
import { parseInteger } from '../../common/utils/parsers'

function CreditSelect({
  label,
  name,
  max,
  validate,
}) {
  const options = creditOptions(max)
  return (
    <Field
      {...validate && { validate }}
      SelectProps={{ native: true }}
      component={FieldText}
      label={label}
      name={name}
      parse={parseInteger}
      select
    >
      {Object.keys(options).map(k => (
        <option key={k} value={options[k]}>
          {k}
        </option>
      ))}
    </Field>
  )
}

CreditSelect.defaultProps = {
  validate: undefined,
}

CreditSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired,
  validate: PropTypes.func,
}

export default CreditSelect
