import { useCallback, useContext, useMemo } from 'react'
import filterEmpty from 'filter-empty'
import fetchApi from './fetchApi'
import SnackbarContext from '../snackbar/SnackbarContext'

function useFetch() {
  const { onSnackOpen } = useContext(SnackbarContext)

  const onFetch = useCallback(async (props) => {
    try {
      const res = await fetchApi(props)
      return res
    } catch (error) {
      const message = error.errors ? error.errors[0].message : error.message
      onSnackOpen({ severity: 'error', message })
      return Promise.reject(error)
    }
  }, [onSnackOpen])


  const onFetchGraphQL = useCallback(async (body) => {
    try {
      const { data } = await fetchApi({ body })
      const cleaned = filterEmpty(data)
      return cleaned
    } catch (error) {
      const message = error.errors ? error.errors[0].message : error.message
      onSnackOpen({ severity: 'error', message })
      return Promise.reject(error)
    }
  }, [onSnackOpen])

  return useMemo(() => ({
    onFetch,
    onFetchGraphQL,
  }), [onFetch, onFetchGraphQL])
}


export default useFetch
