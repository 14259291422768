import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ProductListHorizontalBase from '../../product/public/ListHorizontal'
import displayName from './displayName'

const ProductListHorizontal = props => {
  const {
    classes,
    elevation,
    input,
    style,
    ...rest
  } = props
  return (
    <div
      {...rest}
      className={classNames(
        classes.root,
        classes.ProductList,
        style.background && style.background.backgroundImage && classes.backgroundImage,
        elevation && classes[`elevation${elevation}`]
      )}
      style={style}
    >
      <ProductListHorizontalBase input={input} />
    </div>
  )
}

ProductListHorizontal.propTypes = {
  input: PropTypes.object,
}

ProductListHorizontal.displayName = `${displayName}ProductListHorizontal`

export default ProductListHorizontal