import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import ShoppingCart from '@material-ui/icons/ShoppingCart'

import ActionButton from './ActionButton'

const AppBarActions = ({
  isFetching,
  actionButton,
  color,
  quantity,
}) => {
  return (
    isFetching ? null : quantity ?
      <IconButton to="/cart" component={Link} style={{ color }}>
        <Badge badgeContent={quantity} color="secondary">
          <ShoppingCart />
        </Badge>
      </IconButton>
      :
      actionButton && actionButton.children ?
        <ActionButton actionButton={actionButton} />
        : null
  )
}


AppBarActions.propTypes = {
  color: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  actionButton: PropTypes.object,
}

const mapStateToProps = ({
  app: {
    isFetching: appIsFetching,
    header: {
      actionButton,
      style: { color }
    }
  },
  cart: { isFetching: cartIsFetching, quantity }
}) => ({
  isFetching: appIsFetching || cartIsFetching ? true : false,
  actionButton,
  color,
  quantity,
})

export default connect(mapStateToProps)(AppBarActions)
