import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'

type ObjectMap = {
  [key: string]: string | number;
}

type StyleProps = {
  elevation?: number;
  style: ObjectMap;
}

export interface SvgProps {
  children: string;
  className?: string;
  elevation?: number;
  id?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onDrop?: (event: React.MouseEvent<HTMLElement>) => void;
  style: ObjectMap;
}


const useStyles = makeStyles((theme: Theme) => {
  const { shadows } = theme
  return createStyles({
    root: (props: StyleProps) => ({
      '& > svg': {
        fill: 'inherit',
        ...props.elevation && { boxShadow: shadows[props.elevation] },
        ...props.style,
      }
    })
  })
})

const Svg: React.FC<SvgProps> = ({
  children,
  elevation,
  className,
  id,
  onClick,
  onDrop,
  style,
}) => {
  const classes: ObjectMap = useStyles({ style, elevation })
  console.log('Svg ', {
    classes,
    className,
    id,
    style,
  })
  return (
    <div
      className={classNames(
        classes.root,
        className,
      )}
      id={id}
      onClick={onClick}
      onDrop={onDrop}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}

export default Svg
