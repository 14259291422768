import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import { Field } from 'redux-form'
import { connect } from 'react-redux'

import FieldText from '../../fields/FieldText'
import Form from '../../forms/FormBase'
import FormButtonSubmit from '../../forms/FormButtonSubmit'
import Loading from '../../common/components/Loading'
import {
  validateCompose,
  validateEmail,
  validateRequired,
} from '../../common/utils/validators'
import displayName from './displayName'


const defaults = {
  subject: `Subject`,
  message: `Message`,
  personalizedMessage: `Personalized message`
}

class Email extends Component {
  handleSubmit = async (values) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_NAME}/users/email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
      })
      const json = await res.json()
      if (res.ok) return json
      throw Error(json)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  render() {
    const {
      classes,
      elevation,
      dispatch,
      isFetching,
      style,
      phoneFormat,
      user,
      ...rest
    } = this.props
    if (isFetching) return <Loading />
    return (
      <div
        {...rest}
        className={classNames(
          classes.root,
          classes.Email,
          style.background && style.background.backgroundImage && classes.backgroundImage,
          elevation && classes[`elevation${elevation}`]
        )}
        style={style}
      >
        <CardContent style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
          <Typography variant="h5">Spread The Word</Typography>
        </CardContent>
        <Form
          form="email"
          initialValues={{
            ...user,
            ...defaults,
          }}
          onSubmit={this.handleSubmit}
        >
          <CardContent style={{ display: 'flex', flexFlow: 'row wrap', margin: `-8px` }}>
            <div style={{ padding: 8, flex: '1 1 auto' }}>
              <Field
                name="firstName"
                label="First name"
                fullWidth
                component={FieldText}
                validate={validateRequired}
              />
            </div>
            <div style={{ padding: 8, flex: '1 1 auto' }}>
              <Field
                name="lastName"
                label="Last name"
                fullWidth
                component={FieldText}
                validate={validateRequired}
              />
            </div>
            <div style={{ padding: 8, flex: '1 1 100%' }}>
              <Field
                name="email"
                label="Email"
                fullWidth
                component={FieldText}
                validate={validateCompose(validateEmail, validateRequired)}
              />
            </div>
            <div style={{ padding: 8, flex: '1 1 auto' }}>
              <Field
                name="recipentFirstName"
                label="Recipent First name"
                fullWidth
                component={FieldText}
                validate={validateRequired}
              />
            </div>
            <div style={{ padding: 8, flex: '1 1 auto' }}>
              <Field
                name="recipentLastName"
                label="Recipent Last name"
                fullWidth
                component={FieldText}
                validate={validateRequired}
              />
            </div>
            <div style={{ padding: 8, flex: '1 1 100%' }}>
              <Field
                name="recipentEmail"
                label="Recipent Email"
                fullWidth
                component={FieldText}
                validate={validateCompose(validateEmail, validateRequired)}
              />
            </div>
            <div style={{ padding: 8, flex: '1 1 100%' }}>
              <Field
                name="subject"
                label="Subject"
                fullWidth
                component={FieldText}
                validate={validateRequired}
              />
            </div>
            <div style={{ padding: 8, flex: '1 1 100%' }}>
              <Field
                name="message"
                label="Message"
                fullWidth
                component={FieldText}
                validate={validateRequired}
                multiline
              />
            </div>
            <div style={{ padding: 8, flex: '1 1 100%' }}>
              <Field
                name="personalizedMessage"
                label="Personalized Message"
                fullWidth
                component={FieldText}
                validate={validateRequired}
                multiline
              />
            </div>
          </CardContent>
          <CardActions>
            <FormButtonSubmit variant="contained" fullWidth>
              Spread The Word
              </FormButtonSubmit>
          </CardActions>
        </Form>
      </div>
    )
  }
}

Email.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  handler: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  phone: PropTypes.string,
  phoneFormat: PropTypes.string,
  user: PropTypes.object,
}

Email.displayName = `${displayName}Email`

export default connect(
  ({
    app: { isFetching: appIsFetching, business: { phone, phoneFormat } },
    user
  }) => ({
    isFetching: appIsFetching || user.isFetching ? true : false,
    user,
    phone,
    phoneFormat,
  })
)(Email)
