import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Page from '../common/components/Page'
import SearchList from '../search/SearchList'
import BlogsRouter from '../blog/user/Router'
import CartDetail from '../cart/Detail'
import CategoryRouter from '../category/user/Router'
import CheckoutContainer from '../checkout/Container'
import CreditPublicPurchase from '../credit/public/Purchase'
import OrderConfirmation from '../order/user/Confirmation'
import PageCreator from '../page/public/Creator'
import PageNotFound from '../common/components/PageNotFound'
import RoutePrivate from './RoutePrivate'
import SupplierRouter from '../supplier/user/Router'
import UserRouter from '../user/user/Router'
import asyncComponent from '../common/components/asyncComponent'

const RouterAdmin = asyncComponent(() => import('./RouterAdmin'))

const Router = (props) => {
  const { shop, search } = props
  if (search.isOpen && search.value && search.value.length) {
    return (
      <Page>
        <SearchList shop={shop} />
      </Page>
    )
  }

  return (
    <Switch>

      <Route path="/blogs">
        {route => (
          <BlogsRouter {...props} {...route} />
        )}
      </Route>

      <Route exact path="/order-confirmation/:orderId">
        {route => (
          <Page>
            <OrderConfirmation {...props} {...route} />
          </Page>
        )}
      </Route>

      <Route exact path="/cart">
        {route => (
          <Page>
            <CartDetail {...props} {...route} />
          </Page>
        )}
      </Route>

      <Route exact path="/checkout">
        {route => (
          <Page>
            <CheckoutContainer {...props} {...route} />
          </Page>
        )}
      </Route>

      <Route path="/purchase-credit">
        {route => (
          <Page>
            <CreditPublicPurchase {...props} {...route} />
          </Page>
        )}
      </Route>

      <Route path="/search">
        {route => (
          <Page>
            <SearchList {...props} {...route} />
          </Page>
        )}
      </Route>

      <Route path="/supplier">
        {route => (
          <SupplierRouter {...props} {...route} />
        )}
      </Route>

      <Route path="/user">
        {route => (
          <UserRouter {...props} {...route} />
        )}
      </Route>

      <RoutePrivate path="/admin" requiredRoles={['admin']}>
        {route => (
          <RouterAdmin {...props} {...route} />
        )}
      </RoutePrivate>

      <Route path={`/${shop.slug}`}>
        {route => (
          <CategoryRouter {...props} {...route} />
        )}
      </Route>

      <Route exact path="/:slug" component={PageCreator} />

      <Route exact path="/" component={PageCreator} />

      <Route component={PageNotFound} />

    </Switch>
  )
}

Router.propTypes = {
  shop: PropTypes.instanceOf(Object).isRequired,
}

const mapStateToProps = ({ app: { shop }, search }) => ({ search, shop })

export default withRouter(connect(mapStateToProps)(Router))
