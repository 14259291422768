import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import getSlug from '../common/utils/getSlug'

const DrawerPage = props => {
  const {
    item,
    pages,
    onDrawer,
    classes,
  } = props
  const pagesString = item.pages.map(id => `/${pages[id].slug}`).join(' ')
  const hrefIncludes = pagesString.includes(window.location.pathname)
  const [isOpen, setOpen] = useState(hrefIncludes)
  if (item.pages.length > 1) {
    return (
      <List disablePadding>
        <ListItem button onClick={() => setOpen(!isOpen)}>
          <ListItemText primary={item.name} />
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse component="li" in={isOpen} timeout="auto" unmountOnExit>
          <List disablePadding>
            {item.pages.map(id => {
              return (
                <ListItem
                  activeClassName={classes.navLinkActive}
                  button
                  className={classNames(classes.nested)}
                  component={NavLink}
                  key={id}
                  onClick={onDrawer}
                  to={`/${pages[id].slug}`}
                >
                  <ListItemText primary={pages[id].name} />
                </ListItem>
              )
            })}
          </List>
        </Collapse>
      </List>
    )
  }
  return (
    <ListItem
      activeClassName={classes.navLinkActive}
      button
      component={NavLink}
      onClick={onDrawer}
      to={`/${pages[item.pages[0]].slug}`}
    >
      <ListItemText primary={item.name} />
    </ListItem>
  )
}


DrawerPage.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawer: PropTypes.func.isRequired,
  pages: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
}

export default DrawerPage
