import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'

import IconMasterCard from '../icons/IconMasterCard'
import IconVisa from '../icons/IconVisa'

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 4,
    marginBottom: 4
  }
})

const FooterCreditCards = ({
  classes,
  color,
}) => {
  return (
    <div className={classes.root}>
      <a href="https://www.mastercard.us/en-us.html">
        <IconButton style={{ color }}>
          <IconMasterCard />
        </IconButton>
      </a>
      <a href="https://usa.visa.com/">
        <IconButton style={{ color }}>
          <IconVisa className={classes.card} />
        </IconButton>
      </a>
    </div>

  )
}

FooterCreditCards.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
}



export default withStyles(styles)(FooterCreditCards)
