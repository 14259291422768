import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import displayName from './displayName'
import { AppProps } from '../../app/types'

interface HeadProps {
  name: string,
  description: string,
}

const Head: React.FC<HeadProps> = ({
  name,
  description
}) => {
  const brandName = useSelector(({ app }: { app: AppProps }) => app.brand.name.children)
  return (
    <Helmet>
      <title>{name === 'Home' ? `${brandName}` : `${brandName} - ${name}`}</title>
      {description ? <meta name="description" content={description} /> : null}
      {description ? <meta property="og:description" content={description} /> : null}
      <meta property="og:title" content={name === 'Home' ? brandName : `${brandName} - ${name}`} />
    </Helmet>
  )
}

Head.displayName = `${displayName}Head`

export default Head
