import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import displayName from './displayName'

const Iframe = ({
  children,
  classes,
  elevation,
  src,
  style,
  ...rest
}) => {
  const { borderRadius, ...styleRest } = style
  return (
    <div
      {...rest}
      className={classNames(
        classes.root,
        classes.Iframe,
        style.background && style.background.backgroundImage && classes.backgroundImage,
      )}
      style={styleRest}
    >
      <div className={classes.iframeParent}>
        <iframe
          className={classNames(classes.iframe, elevation && classes[`elevation${elevation}`])}
          title={process.env.REACT_APP_NAME}
          style={{ borderRadius }}
          src={src}
          allowFullScreen
        >
        </iframe>
      </div>
      {children}
    </div>
  )
}


Iframe.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  elevation: PropTypes.number,
  src: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
}

Iframe.displayName = `${displayName}Iframe`

export default Iframe
