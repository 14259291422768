import React from 'react'
import PropTypes from 'prop-types'

import * as actions from './actions'
import ListItem from '../common/ListItem'
import ListVerticalBase from '../../common/components/ListVerticalBase'
import bindActionCreators from '../../common/utils/bindActionCreators'
import displayName from './displayName'
import filter from './filter'
import useReducer from '../../common/hooks/useReducer'
import { itemsReducer } from './reducers'

const List = props => {
  const [state, dispatch] = useReducer(itemsReducer)
  const boundActions = bindActionCreators(actions, dispatch)
  return (
    <ListVerticalBase
      {...props}
      {...state}
      {...boundActions}
      filter={filter}
      listItem={ListItem}
    />

  )
}

List.displayName = `${displayName}List`

List.propTypes = {
  match: PropTypes.object.isRequired,
}

export default List