import { combineReducers } from 'redux'
import { reducer as reduxForm } from 'redux-form'

import app from '../app/reducer'
import auth from '../auth/reducer'
import cart from '../cart/reducer'
import dialog from '../dialogs/reducer'
import { search } from '../search/reducers'
import swipeables from '../swipeables/reducer'
import theme from '../theme/reducer'
import user from '../user/user/reducer'
import { itemsReducer as addressesUserReducer } from '../address/user/reducers'
import { itemsReducer as categoriesUserReducer } from '../category/user/reducers'
import { itemReducer as creditUserReducer } from '../credit/user/reducers'
import { itemsReducer as componentsPublicReducer } from '../component/public/reducers'
import { itemsReducer as pagesPublicReducer } from '../page/public/reducers'
import { itemReducer as priceRulesReducer } from '../priceRule/reducers'

const rootReducer = combineReducers({
  form: reduxForm,
  addresses: addressesUserReducer,
  app,
  auth,
  cart,
  categories: categoriesUserReducer,
  credit: creditUserReducer,
  components: componentsPublicReducer,
  dialog,
  pages: pagesPublicReducer,
  priceRules: priceRulesReducer,
  search,
  swipeables,
  theme,
  user,
})

export default rootReducer
