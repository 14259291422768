import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Detail from './Detail'
import Loading from '../../common/components/Loading'
import None from '../../common/components/None'
import displayName from './displayName'
import useReducer from '../../common/hooks/useReducer'
import withGoogleAnalytics from '../../common/hocs/withGoogleAnalytics'
import { itemReducer } from './reducers'
import { onFindOne, onUpdateSuccess } from './actions'


function DetailContainer(props) {
  const [state, dispatch] = useReducer(itemReducer, {
    error: null,
    isFetching: false,
    isLoading: true,
    item: {},
  })
  const {
    item: blog,
    isFetching,
    isLoading,
  } = state

  useEffect(() => {
    dispatch(onFindOne({ slug: props.match.params.slug }))
  }, [])

  if (isLoading || isFetching) return <Loading />
  if (!blog) return <None>Sorry, that product was not found</None>
  return (
    <Detail
      {...props}
      blog={blog}
      onUpdateSuccess={item => dispatch(onUpdateSuccess(item))}
    />
  )
}

DetailContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

DetailContainer.displayName = `${displayName}DetailContainer`

export default withGoogleAnalytics(DetailContainer)
