import React from 'react'
import PropTypes from 'prop-types'

import displayName from './displayName'
import AddressFields from '../common/Fields'
import FormCard from '../../forms/FormCard'


const Add = props => {
  const {
    history,
    onAdd,
    user,
  } = props
  return (
    <FormCard
      title={`Add New Addresses for ${user.firstName}, ${user.email}`}
      form={'addressAdd'}
      initialValues={{}}
      onSubmit={values => onAdd(values).then(history.goBack)}
    >
      <AddressFields />
    </FormCard>
  )
}

Add.propTypes = {
  address: PropTypes.object,
  classes: PropTypes.object,
  onAdd: PropTypes.func.isRequired,
}

Add.displayName = `${displayName}Add`

export default Add
