import fetchGraphQL from '../../graphql/fetchGraphQL'

import { orderType } from '../common/types'

// QUERIES 
export const orderSupplierFind = async input => {
  const { orderSupplierFind: items } = await fetchGraphQL({
    query: `
        query OrderSupplierFind($input: OrderFindInput!) {
          orderSupplierFind(input: $input) { ${orderType} }
        }
      `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return items
}


export const orderSupplierFindOne = async input => {
  const { orderSupplierFindOne: item } = await fetchGraphQL({
    query: `
      query OrderSupplierFindOne($input: OrderFindOneInput!) {
        orderSupplierFindOne(input: $input) { ${orderType} }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}




// MUTATIONS

export const orderSupplierDeliver = async input => {
  const { orderSupplierDeliver: item } = await fetchGraphQL({
    query: `
      mutation OrderSupplierDeliver($input: OrderUpdateInput!) {
        orderSupplierDeliver(input: $input) { ${orderType} }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}

export const orderSupplierShip = async input => {
  const { orderSupplierShip: item } = await fetchGraphQL({
    query: `
      mutation OrderSupplierShip($input: OrderUpdateInput!) {
        orderSupplierShip(input: $input) { ${orderType} }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}