import fetchGraphQL from '../../graphql/fetchGraphQL'

// QUERIES
export const reviewFind = async (input) => {
  const { reviewFind: items } = await fetchGraphQL({
    query: `
      query ReviewFind($input: ReviewFindInput!) {
        reviewFind(input: $input) {
          _id
          createdAt
          onModel
          onDoc
          likes
          rating
          text
          user {
            _id
            firstName
            lastName
          }
        }
      }
      `,
    variables: { input },
  }).catch(error => Promise.reject(error))
  return items
}


// MUTATIONS
export const reviewAdd = async (input) => {
  const { reviewAdd = {} } = await fetchGraphQL({
    query: `
      mutation ReviewAdd($input: ReviewAddInput!) {
        reviewAdd(input: $input) {
          blog {
            _id
            rating
            reviews
          }
          product {
            _id
            rating
            reviews
          }
          review {
            _id
            appName
            createdAt
            isPublished
            onModel
            onDoc
            likes
            rating
            text
            user {
              _id
              firstName
              lastName
            }
          }
        }
      }
      `,
    variables: { input },
  }).catch(error => Promise.reject(error))
  const { blog, product, review } = reviewAdd
  return {
    blog,
    product,
    review,
  }
}


export const reviewDelete = async (input) => {
  const { reviewDelete } = await fetchGraphQL({
    query: `
      mutation ReviewDelete($input: ReviewDeleteInput!) {
        reviewDelete(input: $input) {
          blog {
            _id
            rating
            reviews
          }
          product {
            _id
            rating
            reviews
          }
          review {
            _id
          }
        }
      }
      `,
    variables: { input },
  }).catch(error => Promise.reject(error))
  const { blog, product, review } = reviewDelete
  return {
    blog,
    product,
    review,
  }
}


export const reviewDeleteAdmin = async (input) => {
  const { reviewDeleteAdmin } = await fetchGraphQL({
    query: `
      mutation ReviewDeleteAdmin($input: ReviewDeleteInput!) {
        reviewDeleteAdmin(input: $input) {
          blog {
            _id
            rating
            reviews
          }
          product {
            _id
            rating
            reviews
          }
          review {
            _id
          }
        }
      }
    `,
    variables: { input },
  }).catch(error => Promise.reject(error))
  const { blog, product, review } = reviewDeleteAdmin
  return {
    blog,
    product,
    review,
  }
}


export const reviewReportAbuse = async (input) => {
  const { reviewReportAbuse } = await fetchGraphQL({
    query: `
      mutation reviewReportAbuse($input: ReviewReportAbuseInput!) {
        reviewReportAbuse(input: $input) {
          onModel
        }
      }
    `,
    variables: { input },
  }).catch(error => Promise.reject(error))
  return reviewReportAbuse
}


export const reviewUpdate = async (input) => {
  const { reviewUpdate = {} } = await fetchGraphQL({
    query: `
      mutation ReviewUpdate($input: ReviewUpdateInput!) {
        reviewUpdate(input: $input) {
          blog {
            _id
            rating
            reviews
          }
          product {
            _id
            rating
            reviews
          }
          review {
            _id
            appName
            createdAt
            isPublished
            onModel
            onDoc {
              _id
              published {
                images {
                  src
                }
                name
              }
              slug
            }
            likes
            rating
            text
            user {
              _id
              firstName
              lastName
            }
          }
        }
      }
    `,
    variables: { input },
  }).catch(error => Promise.reject(error))
  const { blog, product, review } = reviewUpdate
  return {
    blog,
    product,
    review,
  }
}


export const reviewUpdateLikes = async (input) => {
  const { reviewUpdateLikes = {} } = await fetchGraphQL({
    query: `
      mutation ReviewUpdateLikes($input: ReviewUpdateLikesInput!) {
        reviewUpdateLikes(input: $input) {
          review {
            _id
            likes
            createdAt
          }
        }
      }
    `,
    variables: { input },
  }).catch(error => Promise.reject(error))
  const { review } = reviewUpdateLikes
  return { review }
}
