import fetchGraphQL from '../../graphql/fetchGraphQL'

export const discountFindOne = async input => {
  try {
    const { discountFindOne: item } = await fetchGraphQL({
      query: `
        query DiscountFindOne($input: DiscountFindOneInput!) {
          discountFindOne(input: $input) {
            _id
          }
        }
      `,
      variables: { input },
    })
    return item._id
  } catch (error) {
    return Promise.reject(error)
  }
}



export const creditFindOne = async input => {
  try {
    const { creditFindOne: item } = await fetchGraphQL({
      query: `
        query CreditFindOne($input: CreditFindOneInput!) {
          creditFindOne(input: $input) {
            _id
            email
            balance
          }
        }
      `,
      variables: { input },
    })
    return item
  } catch (error) {
    return Promise.reject(error)
  }
}