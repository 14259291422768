import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const LinkedinIcon = (props) => (
  <SvgIcon
    width="1792"
    height="1792"
    viewBox="0 0 1792 1792"
    {...props}
  >
    <path d="M365 1414h231v-694h-231v694zm246-908q-1-52-36-86t-93-34-94.5 34-36.5 86q0 51 35.5 85.5t92.5 34.5h1q59 0 95-34.5t36-85.5zm585 908h231v-398q0-154-73-233t-193-79q-136 0-209 117h2v-101h-231q3 66 0 694h231v-388q0-38 7-56 15-35 45-59.5t74-24.5q116 0 116 157v371zm468-998v960q0 119-84.5 203.5t-203.5 84.5h-960q-119 0-203.5-84.5t-84.5-203.5v-960q0-119 84.5-203.5t203.5-84.5h960q119 0 203.5 84.5t84.5 203.5z" />
  </SvgIcon>
)

export default LinkedinIcon
