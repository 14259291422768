import React from 'react'
import PropTypes from 'prop-types'
import { CardElement } from 'react-stripe-elements'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = () => ({
  root: {
    width: '100%',
    cursor: 'text',
    padding: '6px 0 7px',
    boxShadow: 'unset',
    margin: 0,
  },
})

function StripeCardElement(props) {
  const {
    classes: c,
    isError,
    onBlur,
    onChange,
    onFocus,
    theme,
  } = props
  const cardStyle = {
    base: {
      ...isError && { iconColor: theme.palette.error.main },
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      fontSize: '16px',
      fontWeight: theme.typography.fontWeightLight,
      '::placeholder': {
        color: isError ? theme.palette.error.main : 'rgba(0, 0, 0, 0.54)',
      },
    },
    invalid: {
      color: theme.palette.error.main,
    },
  }
  return (
    <CardElement
      className={c.root}
      hidePostalCode={false}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      style={cardStyle}
    />
  )
}

StripeCardElement.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  isError: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  theme: PropTypes.instanceOf(Object).isRequired,
}

export default withStyles(styles, { withTheme: true })(StripeCardElement)
