import createActions from '../../redux/createActions'

import {
  reviewUserFind,
  reviewUserDelete,
  reviewUserUpdate,
} from './api'


import { reducerName } from './reducers'

const {
  ADD,
  DELETE,
  FILTER,
  RECEIVE,
  REQUEST,
  UPDATE,
  RESET,
  ERROR,
} = createActions(reducerName)

const onError = error => ({ type: ERROR, error })
const onRequest = () => ({ type: REQUEST })
export const onReset = () => ({ type: RESET })


// QUERIES
const onFindSuccess = items => ({ type: RECEIVE, items })
export const onFind = input => async dispatch => {
  dispatch(onRequest())
  const items = await reviewUserFind(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onFindSuccess(items))
}





// MUTATIONS
const onDeleteSuccess = _id => ({ type: DELETE, _id })
export const onDelete = input => async dispatch => {
  const item = await reviewUserDelete(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onDeleteSuccess(item._id))
}

const onUpdateSuccess = item => ({ type: UPDATE, item })
export const onUpdate = input => async dispatch => {
  const item = await reviewUserUpdate(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onUpdateSuccess(item))
}




