import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import Loading from '../common/components/Loading'
import { onAddSuccess as onCreditAddSuccess } from '../credit/user/actions'
import { onDeleteSuccess as onCartDeleteSuccess } from '../cart/actions'
import { onFind as onAddressesFindAction, onAddSuccess as onAddressAddSuccess } from '../address/user/actions'
import { onUpdateSuccess as onUserUpdateSuccess } from '../user/user/actions'

const withCheckout = (ComposedComponent) => {
  class WithCheckout extends Component {
    state = {
      isLoading: true,
    }

    componentDidMount() {
      const { user, onAddressesFind } = this.props
      if (user && user._id) {
        return onAddressesFind({}).then(() => this.setState({ isLoading: false }))
      }
      return this.setState({ isLoading: false })
    }

    render() {
      const { isFetching, cart } = this.props
      const { isLoading } = this.state
      if (isLoading || isFetching) return <Loading />
      if (!cart._id) return <Redirect to="/" />
      return <ComposedComponent {...this.props} />
    }
  }
  WithCheckout.propTypes = {
    cart: PropTypes.instanceOf(Object).isRequired,
    isFetching: PropTypes.bool.isRequired,
    onAddressAddSuccess: PropTypes.func.isRequired,
    onAddressesFind: PropTypes.func.isRequired,
    onCartDeleteSuccess: PropTypes.func.isRequired,
    onCreditAddSuccess: PropTypes.func.isRequired,
    onUserUpdateSuccess: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
  }
  const mapStateToProps = ({
    addresses: { isFetching: addressesIsFetching, items: addresses },
    app: {
      checkout: appCheckout,
      isFetching: appIsFetching,
      payment: appPayment,
      shipping: appShipping,
      tax: appTax,
    },
    cart,
    credit: { isFetching: creditIsFetching, item: credit },
    priceRules: { isFetching: priceRulesIsFetching, items: priceRules },
    user,
  }) => ({
    addresses,
    appCheckout,
    appPayment,
    appShipping,
    appTax,
    cart,
    credit,
    isFetching: !!(
      addressesIsFetching
      || appIsFetching
      || cart.isFetching
      || creditIsFetching
      || priceRulesIsFetching
      || user.isFetching
    ),
    priceRules,
    user,
  })
  const mapDispatchToProps = dispatch => ({
    onAddressAddSuccess: add => dispatch(onAddressAddSuccess(add)),
    onAddressesFind: args => dispatch(onAddressesFindAction(args)),
    onCartDeleteSuccess: () => dispatch(onCartDeleteSuccess()),
    onCreditAddSuccess: args => dispatch(onCreditAddSuccess(args)),
    onUserUpdateSuccess: update => dispatch(onUserUpdateSuccess(update)),
  })
  return connect(mapStateToProps, mapDispatchToProps)(WithCheckout)
}

export default withCheckout
