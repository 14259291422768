import fetchGraphQL from '../graphql/fetchGraphQL'



// QUERIES
export const priceRuleFind = async input => {
  try {
    const { priceRuleFind: items } = await fetchGraphQL({
      query: `
        query PriceRuleFind($input: PriceRuleFindInput!) {
          priceRuleFind(input: $input) {
            _id
            categories
            name
            description
            prerequisiteQuantity
            prerequisiteShippingPrice
            prerequisiteSubtotal
            products
            target
            value
            valueType
          }
        }
    `,
      variables: { input }
    })
    return items
  } catch (error) {
    return Promise.reject(error)
  }
}


export const priceRuleAdminFindOne = async input => {
  try {
    const { priceRuleAdminFindOne: item } = await fetchGraphQL({
      query: `
        query PriceRuleAdminFindOne($input: PriceRuleFindOneInput!) {
          priceRuleAdminFindOne(input: $input) {
            _id
            categories
            name
            description
            prerequisiteQuantity
            prerequisiteShippingPrice
            prerequisiteSubtotal
            products
            target
            value
            valueType
          }
        }
    `,
      variables: { input }
    })
    return item
  } catch (error) {
    return Promise.reject(error)
  }
}


export const priceRuleFindApp = async input => {
  try {
    const { priceRuleFindApp: items } = await fetchGraphQL({
      query: `
        query PriceRuleFindApp($input: PriceRuleFindApp) {
          priceRuleFindApp(input: $input) {
            _id
            categories
            name
            description
            prerequisiteQuantity
            prerequisiteShippingPrice
            prerequisiteSubtotal
            products
            target
            value
            valueType
          }
        }
      `,
      variables: { input }
    })
    return items
  } catch (error) {
    return Promise.reject(error)
  }
}








// MUTATIONS
export const priceRuleAdd = async input => {
  try {
    const { priceRuleAdd: item } = await fetchGraphQL({
      query: `
      mutation PriceRuleAdd($input: PriceRuleAddInput!) {
        priceRuleAdd(input: $input) {
          _id
          categories
          name
          description
          prerequisiteQuantity
          prerequisiteShippingPrice
          prerequisiteSubtotal
          products
          target
          value
          valueType
        }
      }
      `,
      variables: { input }
    })
    return item
  } catch (error) {
    return Promise.reject(error)
  }
}


export const priceRuleUpdate = async input => {
  const { priceRuleUpdate: item } = await fetchGraphQL({
    query: `
      mutation PriceRuleUpdate($input: PriceRuleUpdateInput!) {
        priceRuleUpdate(input: $input) {
          _id
          categories
          name
          description
          prerequisiteQuantity
          prerequisiteShippingPrice
          prerequisiteSubtotal
          products
          target
          value
          valueType
        }
      }
      `,
    variables: { input },
  }).catch(error => Promise.reject(error))
  return item
}

export const priceRuleDelete = async input => {
  try {
    const { paymentDelete: item } = await fetchGraphQL({
      query: `
        mutation PriceRuleDelete($input: PriceRuleDeleteInput!) {
          priceRuleDelete(input: $input) {
            _id
          }
        }
      `,
      variables: { input },
    })
    return item
  } catch (error) {
    return Promise.reject(error)
  }
}
