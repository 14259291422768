import createActions from '../../redux/createActions'

import {
  orderSupplierFind,
  orderSupplierFindOne,
  orderSupplierDeliver,
  orderSupplierShip,
} from './api'

import { reducerName } from './reducers'

const {
  ADD,
  DELETE,
  FILTER,
  ERROR,
  RECEIVE,
  RESET,
  REQUEST,
  UPDATE,
} = createActions(reducerName)


const onError = error => ({ type: ERROR, error })
const onRequest = () => ({ type: REQUEST })
export const onReset = () => ({ type: RESET })



const onFilterSuccess = items => ({ type: FILTER, items })
export const onFilter = input => async dispatch => {
  dispatch(onRequest())
  const items = await orderSupplierFind(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onFilterSuccess(items))
}

const onFindSuccess = items => ({ type: RECEIVE, items })
export const onFind = input => async dispatch => {
  dispatch(onRequest())
  const items = await orderSupplierFind(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onFindSuccess(items))
}


const onFindOneSuccess = item => ({ type: ADD, item })
export const onFindOne = input => async dispatch => {
  dispatch(onRequest())
  const item = await orderSupplierFindOne(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onFindOneSuccess(item))
}


const onUpdateSuccess = item => ({ type: UPDATE, item })
export const onDeliver = input => async dispatch => {
  const item = await orderSupplierDeliver(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onUpdateSuccess(item))
}
export const onShip = input => async dispatch => {
  const item = await orderSupplierShip(input).catch(error => {
    dispatch(onError(error))
    return Promise.reject(error)
  })
  return dispatch(onUpdateSuccess(item))
}




