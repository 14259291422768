import React from 'react'
import { Redirect } from 'react-router-dom'

const onError = (error) => {
  const message = error.errors ? error.errors[0].message : error.message
  if (message && message.includes('expired')) {
    localStorage.removeItem('x-access-token')
    localStorage.removeItem('x-refresh-token')
    return <Redirect to="/user/signin" />
  }
}

export default onError
