import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MuiDrawer from '@material-ui/core/Drawer'
import Paper from '@material-ui/core/Paper'
import Search from '@material-ui/icons/Search'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import { NavLink } from 'react-router-dom'

import AppBrand from '../app/AppBrand'
import DrawerActions from './DrawerActions'
import DrawerAdmin from './DrawerAdmin'
import DrawerPage from './DrawerPage'
import DrawerUser from './DrawerUser'

const styles = (theme) => ({
  drawerPaper: {
    width: 240,
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
    paddingLeft: 20,
    borderRadius: 'unset',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  list: {
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(5),
  },
  nested2: {
    paddingLeft: theme.spacing(7),
  },
  nested3: {
    paddingLeft: theme.spacing(9),
  },
  cartListItem: {
    paddingTop: 18,
    paddingBottom: 6,
  },
  navLinkActive: {
    backgroundColor: theme.palette.action.selected,
  },
})

class Drawer extends Component {
  state = {
    timeoutId: null,
  }

  handleSearch = () => {
    const { onSearch, onDrawer } = this.props
    onDrawer()
    const timeoutId = setTimeout(() => {
      clearTimeout(this.state.timeoutId)
      this.setState({ timeoutId: null })
      return onSearch()
    }, 300)
    return this.setState({ timeoutId })
  }

  componentWillUnmount() {
    const { timeoutId } = this.state
    timeoutId && clearTimeout(timeoutId)
  }

  render() {
    const {
      appId,
      classes,
      categories,
      isBlogs,
      isProducts,
      header,
      imageClassName,
      isOpen,
      onDrawer,
      pageIds,
      pages,
      shop,
      sections,
      user,
    } = this.props
    const {
      brand,
      style = {},
    } = header
    const { backgroundColor, color, fill } = style
    return (
      <MuiDrawer
        variant="temporary"
        classes={{ paper: classes.drawerPaper }}
        anchor="left"
        open={isOpen}
        onClose={onDrawer}
      >
        <Paper
          className={classNames(classes.drawerHeader, imageClassName)}
          onClick={onDrawer}
          style={{ backgroundColor, color, fill }}
        >
          <AppBrand
            {...brand}
          />
        </Paper>

        <Divider />

        <List className={classes.list}>
          {user && user.roles.includes('admin' || 'owner')
            ? (
              <DrawerAdmin
                appId={appId}
                user={user}
                classes={classes}
                onDrawer={onDrawer}
                pageIds={pageIds}
                pages={pages}
              />
            )
            : null}
          <DrawerActions onDrawer={onDrawer} />
          <DrawerUser
            user={user}
            classes={classes}
            onDrawer={onDrawer}
          />
          {isProducts
            ? (
              <ListItem
                activeClassName={classes.navLinkActive}
                button
                component={NavLink}
                onClick={onDrawer}
                to={`/${shop.slug}`}
                exact
              >
                <ListItemText primary={shop.name} />
              </ListItem>
            )
            : null}
          {isProducts && shop.categories && shop.categories.length
            ? shop.categories.map((id) => {
              const category = categories[id]
              return (
                <ListItem
                  key={id}
                  button
                  onClick={onDrawer}
                  component={NavLink}
                  to={`/${shop.slug}/${category.slug}`}
                >
                  <ListItemText primary={category.name} />
                </ListItem>
              )
            }) : null}

          {header.navigation.length && header.navigation.map((item) => (
            <DrawerPage
              classes={classes}
              key={item.name}
              onDrawer={onDrawer}
              pages={pages}
              item={item}
            />
          ))}
          {isBlogs
            ? (
              <ListItem
                activeClassName={classes.navLinkActive}
                button
                component={NavLink}
                onClick={onDrawer}
                to="/blogs"
              >
                <ListItemText primary="Blogs" />
              </ListItem>
            )
            : null}
          <ListItem button onClick={this.handleSearch}>
            <ListItemIcon>
              <Search />
            </ListItemIcon>
          </ListItem>
        </List>
      </MuiDrawer>
    )
  }
}


Drawer.propTypes = {
  backgroundColor: PropTypes.string,
  classes: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  isBlogs: PropTypes.bool.isRequired,
  isProducts: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onDrawer: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  pageIds: PropTypes.array,
  pages: PropTypes.object,
  sections: PropTypes.object,
  user: PropTypes.object,
}

export default withStyles(styles)(Drawer)
