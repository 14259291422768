const itemsReducer = (state, action) => {
  switch (action.type) {
    case `ADD`: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        count: state.count + 1,
        items: {
          [action.item._id]: { ...action.item },
          ...state.items,
        },
      }
    }
    case `DELETE`: {
      const { [action._id]: deleted, ...rest } = state.items
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        items: { ...rest },
      }
    }
    case `ERROR`: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isLoading: false,
      }
    }
    case `FILTER`: {
      const items = action.items.reduce((a, v) => { a[v._id] = v; return a }, {})
      const count = action.items.length
      const lastItem = count > 0 ? action.items[count - 1] : state.lastItem
      return {
        ...state,
        count,
        error: null,
        isFetching: false,
        isLoading: false,
        items,
        lastItem,
      }
    }
    case `RECEIVE`: {
      const items = action.items.reduce((a, v) => { a[v._id] = v; return a }, {})
      const count = action.items.length
      const lastItem = count > 0 ? action.items[count - 1] : state.lastItem
      return {
        ...state,
        count,
        error: null,
        isFetching: false,
        isLoading: false,
        items: {
          ...state.items,
          ...items
        },
        lastItem,
      }
    }
    case `REQUEST`: {
      return {
        ...state,
        isLoading: false,
        isFetching: true,
      }
    }
    case `RESET`: {
      return {
        error: null,
        isFetching: false,
        isLoading: false,
        items: {}
      }
    }
    case `UPDATE`: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        items: {
          ...state.items,
          [action.item._id]: {
            ...state.items[action.item._id],
            ...action.item
          }
        },
      }
    }
    default:
      return state
  }
}

export default itemsReducer