import fetchGraphQL from '../../graphql/fetchGraphQL'
import { creditType } from '../common/types'

// QUERIES

export const creditFindOne = async input => {
  try {
    const { creditFindOne: items } = await fetchGraphQL({
      query: `
        query CreditFindOne($input: CreditFindOneInput!) {
          creditFindOne(input: $input) { ${creditType} }
        }
    `,
      variables: { input }
    })
    return items
  } catch (error) {
    return Promise.reject(error)
  }
}







// MUTATIONS

export const creditUserAdd = async input => {
  try {
    const { creditUserAdd: item } = await fetchGraphQL({
      query: `
        mutation CreditUserAdd($input: CreditUserAddInput!) {
          creditUserAdd(input: $input) { ${creditType} }
        }
      `,
      variables: { input }
    })
    return item
  } catch (error) {
    return Promise.reject(error)
  }
}
