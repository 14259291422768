import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from "react-helmet"
import { connect } from 'react-redux'

import getImageSrc from '../image/common/getImageSrc'

function HeadArticle(props) {
  const {
    businessImageSrc,
    businessName,
    publishedAt,
    description,
    imageSrc,
    name,
    url,
  } = props
  return (
    <Helmet>
      <title>{`${businessName} - ${name}`}</title>
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:title" content={`${businessName} - ${name}`} />
      <meta property="og:url" content={url} />
      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org",
          "@type": "NewsArticle",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://google.com/article"
          },
          "headline": "${name}",
          "image": [
            "${getImageSrc(imageSrc)}"
          ],
          "datePublished": "${publishedAt}",
          "author": {
            "@type": "Person",
            "name": "${businessName}"
          },
          "publisher": {
            "@type": "Organization",
            "name": "${businessName}",
            "logo": {
              "@type": "ImageObject",
              "url": "${businessImageSrc}"
            }
          },
          "description": "${description}"
        }`}
      </script>
    </Helmet>
  )
}

HeadArticle.propTypes = {
  businessImageSrc: PropTypes.string,
  businessName: PropTypes.string.isRequired,
  publishedAt: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

const mapStateToProps = ({
  app: {
    business: {
      name: { children: businessName },
      image: { src: businessImageSrc },
    }
  }
}) => ({
  businessName,
  businessImageSrc
})

export default connect(mapStateToProps)(HeadArticle)
