import fetchGraphQL from '../../graphql/fetchGraphQL'

// QUERIES
export const reviewUserFind = async input => {
  const { reviewUserFind: items } = await fetchGraphQL({
    query: `
      query ReviewUserFind($input: ReviewAdminFindInput!) {
        reviewUserFind(input: $input) {
          _id
          createdAt
          isPublished
          onDocImageSrc
          onDocName
          firstName
          lastName
          rating
          text
          user
        }
      }
      `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return items
}










// MUTATIONS
export const reviewUserDelete = async input => {
  const { reviewUserDelete: item } = await fetchGraphQL({
    query: `
      mutation ReviewUserDelete($input: ReviewDeleteInput!) {
        reviewUserDelete(input: $input) {
          _id
        }
      }
      `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}



export const reviewUserUpdate = async input => {
  const { reviewUserUpdate: item } = await fetchGraphQL({
    query: `
      mutation ReviewUserUpdate($input: ReviewUpdateInput!) {
        reviewUserUpdate(input: $input) {
          _id
          rating
          text
        }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}

























