const logError = async (error, info) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_NAME}/errors`, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ error, info }),
      method: 'POST',
    })
  } catch (error) {
    console.error(error)
  }
}

export default logError
