import React from 'react'

import FetchContext from './FetchContext'
import useFetch from './useFetch'

const FetchProvider: React.FC = ({ children }) => {
  const value = useFetch()
  return (
    <FetchContext.Provider value={value}>
      {children}
    </FetchContext.Provider>
  )
}

export default FetchProvider
