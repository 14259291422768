const getImageSrc = (src: string) => {
  if (!src) return ''
  const { REACT_APP_NAME, REACT_APP_ASSETS_ENDPOINT } = process.env
  if (REACT_APP_NAME && src.includes(REACT_APP_NAME)) {
    return `${REACT_APP_ASSETS_ENDPOINT}/${src}`
  }
  return `${REACT_APP_ASSETS_ENDPOINT}/${REACT_APP_NAME}/${src}`
}

export default getImageSrc
