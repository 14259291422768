import React, { useCallback, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'

import SnackbarContext from '../../snackbar/SnackbarContext'
import Form from '../common/Form'
import displayName from './displayName'

const styles = {
  root: {
    paddingBottom: 0,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}

const Add = ({
  classes,
  onAuth,
  onDoc,
  onDocName,
  onModel,
  onAdd,
  onUpdateParentSuccess,
  user,
}) => {
  const { onSnackOpen } = useContext(SnackbarContext)
  const [state, setState] = useState({ isOpen: false, shouldOpen: false })

  const handleAuth = useCallback(() => {
    if (!user._id) {
      setState({ ...state, shouldOpen: true })
      onAuth()
    }
  }, [onAuth, state, user._id])

  const handleReply = useCallback(() => {
    handleAuth()
    if (user._id) setState({ ...state, isOpen: true })
  }, [handleAuth, state, user._id])

  const handleSubmit = useCallback((values) => onAdd({
    ...values,
    href: window.location.href,
    onDoc,
    onDocName,
    onModel,
  })
    .then(({ blog, product, review }) => {
      if (values.rating < 3) {
        onSnackOpen({ severity: 'success', message: 'Review submitted!' })
      }
      onUpdateParentSuccess(blog || product)
      setState({ ...state, isOpen: false })
    })
    .catch(() => setState({ ...state, isOpen: false })), [onAdd, onDoc, onDocName, onModel, onSnackOpen, onUpdateParentSuccess, state])

  useEffect(() => {
    if (user && state.shouldOpen) {
      setState({ ...state, isOpen: true, shouldOpen: false })
    }
  }, [state, user])
  return (
    <CardContent className={classes.root}>
      {state.isOpen
        ? (
          <Form
            form="reviewAdd"
            onSubmit={handleSubmit}
            initialValues={{}}
            onClose={() => setState({ ...state, isOpen: false })}
          />
        )
        : (
          <div className={classes.buttons}>
            <Button variant="contained" color="primary" onClick={handleReply}>
              Write a review
            </Button>
          </div>
        )}
    </CardContent>
  )
}

Add.propTypes = {
  classes: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onAuth: PropTypes.func.isRequired,
  onDoc: PropTypes.string.isRequired,
  onDocName: PropTypes.string.isRequired,
  onModel: PropTypes.string.isRequired,
  user: PropTypes.object,
}


Add.displayName = `${displayName}Add`

export default withStyles(styles)(Add)
