import React from 'react'
import PropTypes from 'prop-types'

import withCheckout from './withCheckout'
import displayName from './displayName'
import withGoogleAnalytics from '../common/hocs/withGoogleAnalytics'
import Stepper from './Stepper'

function getInitialAddressValues(address) {
  const { _id, ...rest } = address
  return rest
}

function Container(props) {
  const {
    addresses,
    cart,
    user,
  } = props
  const initialAddressId = user._id && user.addresses.length && addresses && user.addresses[0]
  const initialAddressIdObj = initialAddressId && { addressId: initialAddressId }
  const initialAddressObj = initialAddressId && getInitialAddressValues(addresses[initialAddressId])
  const isShippable = cart.items.some(i => i.requiresShipping)
  const initialDeliveryAddress = isShippable && {
    deliveryAddress: {
      ...initialAddressIdObj,
      ...initialAddressObj,
    },
  }
  const initialPaymentAddress = {
    paymentAddress: {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
    },
  }
  const initialValues = {
    ...initialDeliveryAddress,
    ...initialPaymentAddress,
    discount: cart.discountCode && { code: cart.discountCode },
    shippingService: cart.shipping.service,
  }

  return <Stepper {...props} isShippable={isShippable} initialValues={initialValues} />
}


Container.propTypes = {
  cart: PropTypes.instanceOf(Object).isRequired,
  addresses: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
}

Container.displayName = `${displayName}Container`

export default withGoogleAnalytics(withCheckout(Container))
