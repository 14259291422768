import fetchGraphQL from '../../graphql/fetchGraphQL'

import { orderType } from '../common/types'
import { creditType } from '../../credit/common/types'


// QUERIES 
export const orderFind = async input => {
  const { orderFind: items } = await fetchGraphQL({
    query: `
        query OrderFind($input: OrderFindInput!) {
          orderFind(input: $input) { ${orderType} }
        }
      `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return items
}


export const orderFindOne = async input => {
  const { orderFindOne: item } = await fetchGraphQL({
    query: `
      query OrderFindOne($input: OrderFindOneInput!) {
        orderFindOne(input: $input) { ${orderType} }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}







// MUTATIONS
export const orderAdd = async input => {
  const { orderAdd: item } = await fetchGraphQL({
    query: `
      mutation OrderAdd($input: OrderAddInput!) {
        orderAdd(input: $input) { 
          cart { _id }
          order { ${orderType} }
          credit { ${creditType} }
          user {           
            _id
            accounts
            addresses
            email
            firstName
            lastName
            phone
            roles
            suppliers
          }
        }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}

export const orderRefundRequest = async input => {
  const { orderRefundRequest: item } = await fetchGraphQL({
    query: `
      mutation OrderRefundRequest($input: OrderUpdateInput!) {
        orderRefundRequest(input: $input) { ${orderType} }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}

export const orderShipRequest = async input => {
  const { orderShipRequest: item } = await fetchGraphQL({
    query: `
      mutation OrderShipRequest($input: OrderUpdateInput!) {
        orderShipRequest(input: $input) { ${orderType} }
      }
    `,
    variables: { input }
  }).catch(error => Promise.reject(error))
  return item
}