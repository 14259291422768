import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import renderHTML from 'react-render-html'
import withStyles from '@material-ui/core/styles/withStyles'

import BreadCrumbs from '../../common/components/BreadCrumbs'
import Buttons from './Buttons'
import FadeInIfNeeded from '../../common/components/FadeInIfNeeded'
import HeadProduct from '../../head/HeadProduct'
import Images from '../../image/common/Images'
import ListHorizontal from './ListHorizontal'
import Loading from '../../common/components/Loading'
import RatingSummary from '../../rating/RatingSummary'
import ReviewList from '../../review/public/ListVertical'
import displayName from './displayName'
import withGoogleAnalytics from '../../common/hocs/withGoogleAnalytics'
import { formatDollar } from '../../common/utils/formatters'

const styles = (theme) => ({
  productContainer: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexFlow: 'row wrap',
  },
  listHeadline: {
    paddingBottom: 0,
  },
  content: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    flex: '1 1 300px',
  },
  contentItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  detail: {
    marginTop: theme.spacing(2),
  },
  categories: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
})

function Detail(props) {
  const {
    categories,
    classes,
    history,
    match,
    onUpdateSuccess,
    product,
    shop,
  } = props
  const {
    _id,
    published = {},
    rating,
    reviews,
    slug,
  } = product
  const {
    categories: categoryIds,
    description,
    detail,
    images = [],
    name,
    variants = [],
  } = published
  const [variantId, setVariantId] = useState(variants[0]._id)
  const [imageSrcs, setImageSrcs] = useState({})
  const [imageItems, setImageItems] = useState({})
  const [variantItems, setVariantItems] = useState({})
  const [isChildrenReady, setChildrenReady] = useState(false)
  useEffect(() => {
    setImageSrcs(images.map((i) => i.src))
    setImageItems(images.reduce((a, v) => { a[v._id] = v; return a }, {}))
    setVariantItems(variants.reduce((a, v) => { a[v._id] = v; return a }, {}))
    setChildrenReady(true)
  }, [images, variants])
  if (!isChildrenReady) return <Loading />
  return (
    <>
      <HeadProduct
        _id={_id}
        description={description}
        imageSrcs={imageSrcs}
        name={name}
        price={variantItems[variantId].price}
        rating={rating}
        reviews={reviews}
      />
      <FadeInIfNeeded srcs={imageSrcs}>
        <BreadCrumbs crumbs={[{ name: shop.name, slug: shop.slug }, { name, slug }]} />
        <div className={classes.productContainer}>
          <Images imageItems={imageItems} alt={name} selectedId={variantItems[variantId].image} />
          <div className={classes.content}>
            <CardContent>
              <Typography variant="h5" gutterBottom>{name}</Typography>
              <Typography variant="h5" gutterBottom>{formatDollar(variantItems[variantId].price)}</Typography>
              {reviews
                ? <RatingSummary rating={rating} reviews={reviews} />
                : null}
              <Typography variant="body1" className={classes.contentItem}>
                {description}
              </Typography>
              {variants.length > 1
                ? (
                  <div>
                    <TextField
                      id="variant-select"
                      variant="outlined"
                      select
                      label="Options"
                      fullWidth
                      value={variantId}
                      onChange={(event) => setVariantId(event.target.value)}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      {variants.map((variant) => (
                        <option key={variant._id} value={variant._id}>
                          {variant.name}
                          {' '}
                          -
                          {' '}
                          {formatDollar(variant.price)}
                        </option>
                      ))}
                    </TextField>
                  </div>
                )
                : null}

              <div className={classes.contentItem}>
                <Buttons
                  history={history}
                  price={variantItems[variantId].price}
                  variantId={variantId}
                />
              </div>
            </CardContent>

            {/*
              <div className={classes.categories}>
                {categoryIds && categoryIds.map(id => (
                  <Button key={id} component={Link} to={`/${shop.slug}/${categories[id].slug}`} size="small">
                    {categories[id].name}
                  </Button>
                ))}
              </div>
            */}
          </div>
        </div>

        {detail.length < 10 ? null
          : (
            <CardContent className={classes.detail}>
              <Typography component="div">{renderHTML(detail)}</Typography>
            </CardContent>
          )}
        <div>
          <CardContent className={classes.listHeadline}>
            <Typography variant="h5">Similar Items</Typography>
          </CardContent>
          <ListHorizontal
            input={{ _id: { nin: [_id] }, categories: { in: categoryIds } }}
            shop={shop}
            match={match}
          />
        </div>

        <div className={classes.contentItem}>
          <ReviewList
            auth={false}
            reviewCount={reviews}
            onModel="Product"
            onDoc={product._id}
            onDocName={name}
            onUpdateParentSuccess={onUpdateSuccess}
          />
        </div>
      </FadeInIfNeeded>
    </>
  )
}

Detail.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
}

Detail.displayName = `${displayName}Detail`

export default withGoogleAnalytics(withStyles(styles)(Detail))
