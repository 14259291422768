import fetchGraphQL from '../../graphql/fetchGraphQL'



// QUERIES
export const supplierUserFind = async input => {
  try {
    const { supplierUserFind: items } = await fetchGraphQL({
      query: `
        query SupplierUserFind($input: SupplierFindInput!) {
          supplierUserFind(input: $input) {
            _id
            city
            country
            email
            name
            phone
            state
            street
            zip
          }
        }
    `,
      variables: { input }
    })
    return items
  } catch (error) {
    return Promise.reject(error)
  }
}

export const supplierUserFindOne = async input => {
  try {
    const { supplierUserFindOne: items } = await fetchGraphQL({
      query: `
        query SupplierUserFindOne($input: SupplierFindOneInput!) {
          supplierUserFindOne(input: $input) {
            _id
            city
            country
            email
            name
            phone
            state
            street
            zip
          }
        }
      `,
      variables: { input }
    })
    return items
  } catch (error) {
    return Promise.reject(error)
  }
}








// MUTATIONS
export const supplierUserUpdate = async input => {
  const { supplierUserUpdate: item } = await fetchGraphQL({
    query: `
      mutation SupplierUserUpdate($input: SupplierUpdateInput!) {
        supplierUserUpdate(input: $input) {
          _id
          city
          country
          email
          name
          phone
          state
          street
          zip
        }
      }
    `,
    variables: { input },
  }).catch(error => Promise.reject(error))
  return item
}