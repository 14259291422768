import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    lineHeight: 'initial',
    paddingTop: 3,
    marginBottom: -3,
    justifyContent: 'flex-end',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
})

const AppBarPhone = ({
  classes,
  phoneDisplay,
  isFetching,
  phone,
}) => {
  if (isFetching || !phoneDisplay || !phone) return null
  return (
    <Typography
      variant="h5"
      className={classes.root}
      component="a"
      href={`tel:${phone.replace(/\D+/g, '')}`}
    >
      {phone}
    </Typography>
  )
}

const mapStateToProps = ({
  app: {
    isFetching,
    business: { phone },
    header: { phoneDisplay }
  },
}) => ({
  isFetching,
  phone,
  phoneDisplay,
})

AppBarPhone.propTypes = {
  classes: PropTypes.object.isRequired,
  phoneDisplay: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  phone: PropTypes.string,
}

export default connect(mapStateToProps)(withStyles(styles)(AppBarPhone))
