import React from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import displayName from './displayName'
import payment from 'payment'
import images from '../common/creditCardImages'

const Number = props => {
  const {
    input,
    inputRef,
    classes,
    label,
    type,
    meta: { active, dirty, error, submitError, touched },
  } = props
  const isError = (touched && error) || submitError ? true : false
  const cardBrand = payment.fns.cardType(input.value) || 'error'
  const src = isError ? images['error'] : input.value && cardBrand ? images[cardBrand] : images.placeholder
  return (
    <div className={classes.cardNumber}>
      <TextField
        {...input}
        fullWidth
        type={type}
        label={label}
        inputRef={inputRef}
        error={isError}
        helperText={touched && error ? error || submitError : ' '}
        InputLabelProps={{
          shrink: dirty || active,
          classes: {
            root: classes.labelRoot,
            shrink: classes.labelShrink
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img className={classes.image} alt="credit card" src={src} />
            </InputAdornment>
          )
        }}
      />
    </div>
  )
}

Number.displayName = `${displayName}Number`

export default Number