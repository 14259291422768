import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import IconImage from '@material-ui/icons/Image'

import { formatDollar } from '../../common/utils/formatters'
import Card from '../../common/components/Card'
import getImageSrc from '../../image/common/getImageSrc'
import displayName from './displayName'


const styles = theme => ({
  content: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  textContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    flex: '12 12 auto',
    padding: 8,
    alignItems: 'center',
  },
  imageContainer: {
    flex: '0 1 90px',
  },
  image: {
    maxWidth: '100%',
    minWidth: '100%',
    verticalAlign: 'top',
    width: '100%',
  },
  name: {
    flex: '3 3 auto',
    margin: 8,
  },
  text: {
    flex: '1 1 auto',
    textAlign: 'right',
    margin: 8,
  },
  icon: {
    width: '100%',
    height: '100%',
  },
})

function CartListItem(props) {
  const {
    classes,
    item = {},
    onClick,
    to,
  } = props
  const {
    name,
    price,
    priceDiscounted,
    quantity,
    slug,
    subtotal,
    variant = {},
  } = item
  const { image = {} } = variant
  return (
    <li>
      <Card onClick={onClick} to={to} imageSrc={image && image.src}>
        <div className={classes.content}>
          <div className={classes.imageContainer}>
            {image.src
              ? (
                <img
                  alt={name}
                  src={getImageSrc(image.src)}
                  className={classes.image}
                />
              )
              : <IconImage className={classes.icon} />
            }
          </div>
          <div className={classes.textContainer}>
            <Typography className={classes.name}>{name}</Typography>
            <Typography>
              <span style={priceDiscounted ? { textDecorationLine: 'line-through' } : null}>{formatDollar(price)}</span>
              {priceDiscounted ? (
                <span>
                  &nbsp;
                  {formatDollar(priceDiscounted)}
                </span>
              ) : null}
            </Typography>
            <Typography className={classes.text}>{quantity}</Typography>
            <Typography className={classes.text}>{formatDollar(subtotal)}</Typography>
          </div>
        </div>
      </Card>
    </li>
  )
}

CartListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
}

CartListItem.displayName = `${displayName}CartListItem`

export default withStyles(styles)(CartListItem)
