import React from 'react'
import PropTypes from 'prop-types'
import MuiTypography from '@material-ui/core/Typography'
import classNames from 'classnames'

import displayName from './displayName'

const getProps = ({ length, variant, index }) => {
  if (index < length - 1) {
    if (variant === 'body1' || variant === 'body2') {
      return { paragraph: true }
    }
  }
  return null
}


const TypographyList = (props) => {
  const {
    classes,
    elevation,
    style = {},
    typographies = [],
    ...rest
  } = props
  return (
    <div
      {...rest}
      className={classNames(
        classes.root,
        classes.TypographyList,
        style.background && style.background.backgroundImage && classes.backgroundImage,
        elevation && classes[`elevation${elevation}`],
      )}
      style={style}
    >
      {typographies.map((typography, index) => (
        <MuiTypography
          key={typography._id}
          className={classNames(
            classes.typography,

          )}
          variant={typography.variant}
        >
          {typography.children}
        </MuiTypography>
      ))}
    </div>
  )
}

TypographyList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  style: PropTypes.object,
  typographies: PropTypes.array,
}

TypographyList.displayName = `${displayName}TypographyList`

export default TypographyList
