import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

import getImageSrc from './getImageSrc'

const styles = theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flex: '2 2 300px',
    flexFlow: 'column',
    justifyContent: 'center',
  },
  selectedContainer: {
    width: '100%',
    padding: theme.spacing(1),
  },
  ul: {
    WebkitOverflowScrolling: 'touch',
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row',
    listStyle: 'none',
    overflowY: 'auto',
    padding: 0,
    margin: '0px',
    scrollBehavior: 'smooth',
    willChange: 'transform',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  li: {
    flex: '0 0 80px',
    margin: theme.spacing(1),
  },
  img: {
    maxWidth: '100%',
    minWidth: '100%',
    verticalAlign: 'top',
    width: '100%',
    boxShadow: theme.shadows[2],
  },
  isSelected: {
    boxShadow: theme.shadows[6],
  },
})

const Images = (props) => {
  const { alt, classes, imageItems } = props
  const [selectedId, setSelectedId] = useState(props.selectedId || 0)
  useEffect(() => {
    setSelectedId(props.selectedId)
  }, [props.selectedId])

  return (
    <div className={classes.root}>
      <div className={classes.selectedContainer}>
        <img
          alt={alt}
          src={getImageSrc(imageItems[selectedId].src)}
          className={classes.img}
        />
      </div>
      {!Object.keys(imageItems).length ? null : (
        <ul className={classes.ul}>
          {Object.keys(imageItems).map(id => (
            <li key={id} onClick={() => setSelectedId(id)} className={classes.li}>
              <img
                alt={`${alt} ${id}`}
                src={getImageSrc(imageItems[id].src)}
                className={classNames(classes.img, id === selectedId ? classes.isSelected : null)}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

Images.propTypes = {
  alt: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  imageItems: PropTypes.object.isRequired,
}

export default withStyles(styles)(Images)
