import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const ThemeProvider = ({
  children,
  isFetching,
  palette,
  typography,
}) => {
  if (isFetching) return null
  const theme = createMuiTheme({
    palette,
    typography,
    overrides: {
      MuiMenuItem: {
        root: typography.subtitle1,
      },
    },
  })
  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  )
}

ThemeProvider.propTypes = {
  _id: PropTypes.string,
  children: PropTypes.node,
  isFetching: PropTypes.bool.isRequired,
  palette: PropTypes.object,
  typography: PropTypes.object,
}


const mapStateToProps = ({
  theme: {
    _id,
    isFetching,
    palette,
    typography,
  },
}) => ({
  _id,
  isFetching,
  palette,
  typography,
})

export default connect(mapStateToProps)(ThemeProvider)
