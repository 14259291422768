const filterOptions = {
  sort: {
    name: 'Sort',
    type: 'radio',
    default: 'name-asc',
    options: [
      'createdAt',
      'email',
      'name',
    ]
  }
}

const filterDefaults = filterOptions => {
  if (!filterOptions) return {}
  const defaults = Object.keys(filterOptions).reduce((a, v) => {
    a[v] = filterOptions[v].default
    return a
  }, {})
  return defaults
}

const filter = {
  filterOptions,
  filterDefaults: filterDefaults(filterOptions)
}

export default filter