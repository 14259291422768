import React from 'react'
import PropTypes from 'prop-types'
import Clear from '@material-ui/icons/Clear'
import IconSearch from '@material-ui/icons/Search'
import Input from '@material-ui/core/Input'
import withStyles from '@material-ui/core/styles/withStyles'

import IconButton from '../icons/IconButton'

const styles = theme => ({
  searchField: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'row nowrap',
    marginLeft: theme.spacing(1),
  },
  input: {
    flex: '1 1 auto',
  }
})

function FilterSearch({
  classes,
  isOpen,
  onSearch,
  onClose,
  onOpen,
  searchValue,
  placeholder,
}) {
  return (
    isOpen ?
      <span className={classes.searchField}>
        <IconButton onClick={onClose}>
          <IconSearch />
        </IconButton>
        <Input
          autoFocus={true}
          className={classes.input}
          margin="dense"
          placeholder={placeholder || 'Search'}
          value={searchValue || ''}
          onChange={onSearch}
        />
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </span>
      :
      <IconButton onClick={onOpen} tooltip="Search">
        <IconSearch />
      </IconButton>
  )
}


FilterSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  placeholder: PropTypes.string,
}

export default withStyles(styles)(FilterSearch)
